import { Button } from "components/shared"
import styled from "styled-components"
import { AccountBalanceWallet } from "styled-icons/material-sharp"
import { mandolinTheme } from "theme"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  min-height: 80vh;
  @media only screen and (min-width: ${mandolinTheme.breakpoints.lg}px) {
    flex-direction: row;
    justify-content: space-evenly;
    gap: 2rem;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  ${mandolinTheme.fonts.TTNormsProNormal};
  color: ${mandolinTheme.colors.elevation7};
  text-align: center;

  h1 {
    ${mandolinTheme.fonts.TTNormsProMedium};
    font-size: 2.5rem;
    color: rgb(255, 117, 117);
    margin: 0;
  }
  h2 {
    ${mandolinTheme.fonts.TTNormsProMedium};
    font-size: 1.5rem;
    margin: 0;
  }
  h3 {
    ${mandolinTheme.fonts.TTNormsProMedium};
    font-size: 1rem;
    margin: 0;
  }
  p {
    font-size: 0.875rem;
    margin: 0;
  }
  hr {
    width: 100%;
    border: 1px solid ${mandolinTheme.colors.elevation7};
  }
  strong {
    ${mandolinTheme.fonts.TTNormsProBold};
  }

  @media only screen and (min-width: ${mandolinTheme.breakpoints.lg}px) {
    order: 2;
    max-width: 777px;
    text-align: left;
    align-items: flex-start;
  }
`

export const NftWrapper = styled.div`
  margin: 2.5rem 0;
  flex-shrink: 0;

  @media only screen and (min-width: ${mandolinTheme.breakpoints.lg}px) {
    order: 1;
  }
`

export const WalletIcon = styled(AccountBalanceWallet)`
  width: 20px;
  margin-right: 0.5rem;
`

export const MetamaskConnect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;

  @media only screen and (min-width: ${mandolinTheme.breakpoints.lg}px) {
    flex-direction: row;
  }
`
export const MetamaskConnectButton = styled(Button)`
  background: transparent;
  div {
    color: ${mandolinTheme.colors.elevation7} !important;
  }
  border: 2px solid ${mandolinTheme.colors.elevation8};
`

export const MetamaskWallet = styled.img`
  margin-right: 0.5rem;
`

export const WelcomeImage = styled.img`
  width: 100%;
  max-width: 400px;
`
