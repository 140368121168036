import styled, { css } from "styled-components/macro"
import { lighten, darken, readableColor, rgba } from "polished"
import { Link } from "react-router-dom"
import { Close, Menu, KeyboardArrowDown } from "@styled-icons/material-sharp"
import { Collapse } from "react-bootstrap"

const DesktopContainer = styled.div`
  align-items: center;
  display: flex;
  min-height: 56px;
  width: 100%;
  background: ${({ theme }) => theme.elements.chatHeaderBackground};
  justify-content: space-between;
  padding: 8px 16px;
  position: relative;
  @media (max-width: 1111px) {
    display: none;
  }
`

const StyledInfo = styled.div`
  align-items: center;
  display: flex;
`

const StyledAvatar = styled.img`
  border-radius: 50%;
  height: 24px;
  margin-right: 16px;
  width: 24px;
  margin-bottom: 2px;
`

const StyledName = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  color: ${({ theme }) => theme.elements.chatHeaderText};
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const StyledNamePlaceholder = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  color: ${({ theme }) => rgba(theme.elements.chatHeaderText, 0.75)};
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  &:hover {
    color: ${({ theme }) => theme.elements.chatHeaderText};
  }
`

const StyledArrow = styled(KeyboardArrowDown)`
  height: 24px;
  width: 24px;
  fill: ${({ theme }) => theme.elements.chatHeaderText};
  transition: 0.15s ease transform;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
`

const StyledDropdown = styled(Collapse)`
  top: 56px;
  z-index: 15;
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${({ theme }) => theme.elements.chatHeaderBackground};
  padding: 0 16px;
  #inner {
    display: flex;
    flex-direction: column;
  }
`

const MobileContainer = styled.div`
  display: none;
  height: 40px;
  width: 100%;
  padding: 8px 24px;
  background: ${({ theme }) => theme.elements.headerBar};
  @media (max-width: 1111px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const Logo = styled.img`
  height: auto;
  max-height: 24px;
  max-width: 116px;
  object-fit: contain;
  object-position: left center;
  width: auto;
`

const MenuIcon = styled(Menu)`
  height: 24px;
  width: 24px;
  fill: ${({ theme }) => readableColor(theme.elements.headerBar)};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const CloseIcon = styled(Close)`
  height: 24px;
  width: 24px;
  fill: ${({ theme }) => readableColor(theme.elements.headerBar)};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const DrawerContainer = styled.div`
  width: ${(props) => (props.open ? "100%" : "0")};
  right: 0;
  top: 0;
  height: 100%;
  position: fixed;
  z-index: 1000;
  transition: ${(props) =>
    props.open
      ? "transform .3s cubic-bezier(.7,.3,.1,1),-webkit-transform .3s cubic-bezier(.7,.3,.1,1)"
      : "transform .3s cubic-bezier(.7,.3,.1,1),height 0s ease .3s,width 0s ease .3s,-webkit-transform .3s cubic-bezier(.7,.3,.1,1)"};
  margin: 0;
  padding: 0;
  display: block;
  z-index: 1000;
`

const DrawerMask = styled.div`
  transition: opacity 0.3s ease;
  height: ${(props) => (props.open ? "100%" : "0")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  pointer-events: ${(props) => (props.open ? "auto" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
`

const DrawerBody = styled.div`
  right: 0;
  height: 100%;
  background: ${({ theme }) => theme.elements.headerBar};
  transform: ${(props) => (props.open ? "translateX(0%)" : "translateX(100%)")};
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 315px;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
`

const DrawerHeader = styled.div`
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  ${StyledName} {
    color: ${({ theme }) => readableColor(theme.elements.headerBar)};
  }
`

const DesktopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  border-radius: 20px;
  transition: 0.15s ease background;
  padding: 8px;
  &:hover {
    background: ${({ theme }) => {
      const { chatHeaderBackground } = theme.elements
      const isLight = readableColor(chatHeaderBackground) === "#000"
      return isLight ? darken(0.1, chatHeaderBackground) : lighten(0.1, chatHeaderBackground)
    }};
  }
`

const OptionStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  padding: 0 12px;
  border-radius: 20px;
  margin: 12px 0;
  transition: 0.15s ease background;
  &:hover {
    background: ${({ theme }) => {
      const { chatHeaderBackground } = theme.elements
      const isLight = readableColor(chatHeaderBackground) === "#000"
      return isLight ? darken(0.1, chatHeaderBackground) : lighten(0.1, chatHeaderBackground)
    }};
    text-decoration: none;
  }
`

const Option = styled.div`
  ${OptionStyles};
`

const OptionAnchor = styled.a`
  ${OptionStyles};
`

const OptionLink = styled(Link)`
  ${OptionStyles};
`

const OptionTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  user-select: none;
  font-size: 16px;
  color: ${({ theme }) => theme.elements.chatHeaderText};
  letter-spacing: -0.23px;
`

const OptionButton = styled.button`
  background: none;
  border: 0;
  width: 100%;
  padding: 12px 0;
  margin-bottom: 12px;
  ${OptionTitle} {
    padding: 6px;
    background: ${({ theme }) => theme.colors.elevation8};
    color: ${({ theme }) => theme.colors.elevation1}!important;
    width: 100%;
    border-radius: 1rem;
  }
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.7;
  }
`

const OptionIcon = styled.div`
  svg {
    height: 24px;
    width: 24px;
    color: ${({ theme }) => theme.elements.chatHeaderText};
  }
`

const DrawerOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  ${OptionTitle} {
    color: ${({ theme }) => readableColor(theme.elements.headerBar)};
  }
  ${OptionIcon} svg {
    color: ${({ theme }) => readableColor(theme.elements.headerBar)};
  }
  ${Option}, ${OptionAnchor}, ${OptionLink} {
    &:hover {
      background: ${({ theme }) => {
        const { headerBar } = theme.elements
        const isLight = readableColor(headerBar) === "#000"
        return isLight ? darken(0.1, headerBar) : lighten(0.1, headerBar)
      }};
    }
  }
`

export {
  CloseIcon,
  DesktopContainer,
  DesktopHeader,
  DrawerBody,
  DrawerContainer,
  DrawerHeader,
  DrawerMask,
  DrawerOptions,
  Logo,
  MenuIcon,
  MobileContainer,
  Option,
  OptionAnchor,
  OptionButton,
  OptionIcon,
  OptionLink,
  OptionTitle,
  StyledArrow,
  StyledAvatar,
  StyledDropdown,
  StyledInfo,
  StyledName,
  StyledNamePlaceholder,
}
