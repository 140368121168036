import styled, { css } from "styled-components"
import { toRem } from "theme"
import { typographySpacing } from "theme/spacing"
import { headingStyles, typeStyles } from "theme/typography"
import { variantOptions, gutterOptions } from "."

const reset = css`
  margin: 0px;
  padding: 0px;
`

const getVariantStyles = (variant: variantOptions) => {
  switch (variant) {
    case "h1":
      return headingStyles.h1
    case "h2":
      return headingStyles.h2
    case "h3":
      return headingStyles.h3
    case "h4":
      return headingStyles.h4
    case "h5":
      return headingStyles.h5
    case "h6":
      return headingStyles.h6
    case "body":
      return typeStyles.body
    case "cap":
      return typeStyles.cap
    case "middleCap":
      return typeStyles.middleCap
    case "label":
      return typeStyles.label
    case "small":
      return typeStyles.small
    case "smallBold":
      return typeStyles.smallBold
    case "smallCap":
      return typeStyles.smallCap
    case "smallCapBold":
      return typeStyles.smallCapBold
    case "smallItalic":
      return typeStyles.smallItalic
    case "normalTitle":
      return typeStyles.normalTitle
    default:
      return typeStyles.body
  }
}

export default styled.span<{
  variant?: variantOptions
  gutterBottom?: gutterOptions
  textColor?: string
  textAlign?: "left" | "center" | "right" | "justify"
}>`
  ${reset}
  ${({ variant }) => (variant ? getVariantStyles(variant) : null)}
  text-align: ${({ textAlign }) => textAlign || "left"};
  margin-bottom: ${({ gutterBottom }) =>
    gutterBottom ? toRem(typographySpacing[gutterBottom]) : `0px`};
  ${({ textColor, theme }) =>
    textColor && textColor.includes("linear-gradient")
      ? `
    background-image: ${textColor};
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  `
      : `
    color: ${textColor || theme.elements.pageText || theme.colors.elevation8};
  `}
`
