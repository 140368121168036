import styled, { css, keyframes } from "styled-components/macro"
import { readableColor, rgba } from "polished"
import { Spinner8 } from "styled-icons/icomoon"

const BaseLabel = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: ${({ fontSize = "16px" }) => fontSize};
  letter-spacing: ${({ letterSpacing = "-0.2px" }) => letterSpacing};
  line-height: 1.1;
  white-space: nowrap;
  ${({ cobrand }) =>
    cobrand === "firefly" &&
    /* Temporary custom color for firefly to safely meet deadline until we can support linear gradients */
    "background-image: linear-gradient(0.25turn, #6FC8D0 0%, #90C9CD 53.13%, #C5BFCE 100%)"};
`

const OutlineButtonStyles = css`
  background: transparent;
  border-color: transparent;
  border-style: none;
  position: relative;
  ::before,
  ::after {
    border-radius: ${({ radius = "24px" }) => radius};
    content: "";
    margin: -1px 0;
    position: absolute;
    z-index: -1;
  }
  ::before {
    ${({ cobrand, theme }) =>
      /* Temporary custom color for firefly to safely meet deadline until we can support linear gradients */
      cobrand === "firefly"
        ? "background: linear-gradient(0.25turn, #6FC8D0 0%, #90C9CD 53.13%, #C5BFCE 100%)"
        : `background-color: ${theme.elements.buttonBackground}`};
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  ::after {
    background-color: ${({ bg = "elevation1", theme }) => theme.colors[bg]};
    bottom: 2px;
    left: 2px;
    right: 2px;
    top: 2px;
  }
  &:not(:disabled):active,
  &:not(:disabled):focus {
    outline: none;
    ::before {
      box-shadow: 0 0 0 4px ${({ theme }) => rgba(theme.colors.primarySolid, 0.6)};
    }
  }
  ${BaseLabel} {
    background-color: ${({ theme }) => theme.elements.buttonBackground};
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  svg {
    fill: ${({ theme }) => theme.elements.buttonBackground};
  }
`

const SecondaryButtonStyles = css`
  background-color: ${({ theme }) => readableColor(theme.colors.elevation1)};
  &:not(:disabled):active,
  &:not(:disabled):focus {
    box-shadow: 0 0 0 4px ${({ theme }) => rgba(readableColor(theme.colors.elevation1), 0.6)};
    outline: none;
  }
  &:not(:disabled):hover {
    opacity: 0.7;
  }
  ${BaseLabel} {
    color: ${({ theme }) => theme.colors.elevation1};
  }
  svg {
    fill: ${({ theme }) => theme.colors.elevation1};
  }
`
const borderlessButtonStyles = css`
  background-color: transparent;
  border-color: transparent;
  border-style: none;
  ${BaseLabel} {
    color: ${({ theme }) => theme.elements.buttonBackground};
  }
  svg {
    fill: ${({ theme }) => theme.elements.buttonBackground};
  }
`

const PrimaryButtonStyles = css`
  background-color: ${({ theme }) => theme.elements.buttonBackground};
  &:not(:disabled):active,
  &:not(:disabled):focus {
    box-shadow: 0 0 0 4px ${({ theme }) => rgba(theme.colors.primarySolid, 0.6)};
    outline: none;
  }
  ${BaseLabel} {
    color: ${({ theme }) => theme.elements.buttonText};
  }
  svg {
    fill: ${({ theme }) => theme.elements.buttonText};
  }
`

const CobrandButtonStyles = css`
  background-color: ${({ theme }) => theme.elements.buttonBackground};
  ${({ cobrand }) =>
    /* Temporary custom color for firefly to safely meet deadline until we can support linear gradients */
    cobrand === "firefly" &&
    "background: linear-gradient(0.25turn, #6FC8D0 0%, #90C9CD 53.13%, #C5BFCE 100%)"};
  &:not(:disabled):active,
  &:not(:disabled):focus {
    box-shadow: 0 0 0 4px ${({ theme }) => rgba(theme.elements.buttonBackground, 0.6)};
    outline: none;
  }
  ${BaseLabel} {
    color: ${({ theme }) => theme.elements.starterButtonText || theme.elements.buttonText};
  }
  svg {
    fill: ${({ theme }) => theme.elements.buttonText};
  }
`

const BaseButton = styled.div`
  align-items: center;
  background-clip: border-box;
  background-color: transparent;
  background-image: transparent;
  background-repeat: no-repeat;
  border-radius: ${({ radius = "1000px" }) => radius};
  border: none;
  cursor: pointer;
  display: flex;
  height: ${({ height = "48px" }) => height};
  justify-content: center;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.3, 1);
  width: ${({ width = "268px" }) => width};
  z-index: 1;
  &:not(:disabled):hover {
    box-shadow: none;
    opacity: 0.7;
    text-decoration: none;
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
    pointer-events: none;
  }
  ${({ $variant }) => $variant === "primary" && PrimaryButtonStyles};
  ${({ $variant }) => $variant === "secondary" && SecondaryButtonStyles};
  ${({ $variant }) => $variant === "outline" && OutlineButtonStyles};
  ${({ $variant }) => $variant === "borderless" && borderlessButtonStyles};
  ${({ $variant }) => $variant === "cobrand" && CobrandButtonStyles};
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`

const LoadingSpinner = styled(Spinner8)`
  animation: ${spin} 1s linear infinite;
  height: 13px;
  margin-bottom: 2px;
  margin-left: -8px;
  margin-right: 8px;
  width: 13px;
`

export { BaseButton, BaseLabel, LoadingSpinner }
