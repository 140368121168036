import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchCobrand } from "redux/cobrands"
import { mandolinTheme } from "theme"

import { isCobranded, getSubdomain } from "../services/utils"

const fillInBlanks = (newTheme) => {
  const sanitizedTheme = {}

  // remove null or undefined values.
  const objKeys = ["colors", "elements", "logos", "text"]
  objKeys.forEach((objKey) => {
    if (newTheme[objKey]) {
      Object.keys(newTheme[objKey]).forEach((key) => {
        if (newTheme[objKey][key]) {
          if (!sanitizedTheme[objKey]) {
            sanitizedTheme[objKey] = {}
          }
          sanitizedTheme[objKey][key] = newTheme[objKey][key]
        }
      })
    }
  })

  return {
    ...mandolinTheme,
    colors: {
      ...mandolinTheme.colors,
      ...(sanitizedTheme.colors || {}),
    },
    elements: {
      ...mandolinTheme.elements,
      ...(sanitizedTheme.elements || {}),
    },
    logos: {
      ...mandolinTheme.logos,
      ...(sanitizedTheme.logos || {}),
    },
  }
}

const useCobranding = (cobrand = undefined) => {
  const [state, setState] = useState({
    theme: mandolinTheme,
    loading: true,
  })
  const cobrandKey = cobrand || getSubdomain()
  const dispatch = useDispatch()
  const cobrands = useSelector((reduxState) => reduxState.cobrands)

  useEffect(() => {
    if (cobrands[cobrandKey]?.isAdded) {
      setState({
        theme: fillInBlanks(cobrands[cobrandKey].data.theme),
        loading: false,
        isFestival: cobrands[cobrandKey].data.isFestival || false,
        displayGradient: cobrands[cobrandKey].data.displayGradient || false,
      })
    } else if (isCobranded() || cobrand) {
      setState((prev) => ({
        ...prev,
        loading: true,
      }))
      dispatch(fetchCobrand(cobrand || getSubdomain()))
    } else {
      setState((prev) => ({ ...prev, loading: false }))
    }
  }, [cobrand])

  useEffect(() => {
    if (cobrands[cobrandKey]?.isAdded && state.loading) {
      setState({
        theme: fillInBlanks(cobrands[cobrandKey].data.theme),
        loading: false,
        isFestival: cobrands[cobrandKey].data.isFestival || false,
        displayGradient: cobrands[cobrandKey].data.displayGradient || false,
      })
    }
  }, [cobrands[cobrandKey]?.isAdded])

  const { theme, loading, isFestival, displayGradient } = state
  return { theme, loading, isFestival, displayGradient }
}

export default useCobranding
