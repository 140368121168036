import styled from "styled-components/macro"

const GoodbyeContainer = styled.div`
  background: #0d1115;
  height: 100%;
  display: flex;
  margin: 32px auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  max-width: 480px;
  z-index: 10;
  font-size: 18px;
  img {
    height: 70px;
    width: 70px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 20px;
  }
`

export { GoodbyeContainer }
