import styled from "styled-components/macro"

// eslint-disable-next-line import/prefer-default-export
export const EventEndedContainer = styled.div`
  flex: 1;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(234, 233, 230);
  font-size: 30px;
  letter-spacing: 3px;
  padding-top: 10px;
  text-transform: uppercase;
  text-shadow: rgb(0 0 0) 0px 0px 4px;
`
