import styled from "styled-components/macro"
import { AddToQueue, Close, Send } from "@styled-icons/material-rounded"
import { rgba } from "polished"

export const StartIcon = styled(AddToQueue)`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`

export const SendIcon = styled(Send)`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`

export const InputContainer = styled.div`
  margin-top: 36px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  margin-bottom: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1111px) {
    margin-top: 0;
  }
`

export const SentInvitesContainer = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  margin-bottom: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1111px) {
    margin-top: 0;
  }
`

export const WideContainer = styled.div`
  width: 100%;
`

export const RemoveNumberButton = styled(Close)`
  height: 16px;
  width: 16px;
  margin-left: 4px;
  color: ${({ theme }) => rgba(theme.elements.chatText, 0.6)} !important;
  cursor: pointer;
`

export const NumberContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  > .react-tel-input {
    width: 100%;
    border-radius: 2px;
    border: none !important;
    background-color: ${({ theme }) => rgba(theme.elements.chatText, 0.1)};
  }
  > .react-tel-input .invalid-number {
    background-color: ${({ theme }) => rgba(theme.elements.chatText, 0.1)};
  }
  input.form-control {
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.confirmation};
    border-radius: 2px;
    background-color: ${({ theme }) => rgba(theme.elements.chatText, 0.1)};
    color: ${({ theme }) => theme.elements.chatText};
    ::placeholder {
      color: ${({ theme }) => rgba(theme.elements.chatText, 0.6)};
    }
  }
  input.form-control.invalid-number {
    border: none;
    border-bottom: 2px solid ${({ theme }) => rgba(theme.elements.chatText, 0.1)};
    border-radius: 2px;
  }
  .form-control:focus {
    box-shadow: none !important;
  }
`


export const PreviewButton = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ theme }) => theme.elements.chatText} !important;
  width: 100%;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`

export const InviteHeader = styled.div`
  margin-top: 32px;
  @media (max-width: 1111px) {
    margin-top: 0;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  button {
    @media (max-width: 1111px) {
      height: 36px;
      width: 224px;
    }
  }
`
