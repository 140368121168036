import firebase from "firebase/app"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export enum EntitlementType {
  JOINLIVESTREAM = "JOIN_LIVE_STREAM",
}

export interface TicketState extends firebase.firestore.DocumentData {
  claimData?: {
    timestamp: string
    uid: string
  }
  email?: string
  entitlementTypes?: Array<EntitlementType>
  id?: string
  metadata?: Record<string, any>
  name?: string
  status: string
  userId?: string
}

type TicketStateType = {
  loading: boolean
  data: Array<TicketState>
}

const initialState: TicketStateType = {
  loading: true,
  data: [],
}

const tickets = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setTicketsData(_, action: PayloadAction<Array<TicketState>>) {
      return {
        loading: false,
        data: action.payload,
      }
    },
  },
})

const { actions, reducer } = tickets

export const { setTicketsData } = actions

export default reducer
