import { useEffect, useState } from "react"
import { CustomContent, filterContentSections, getStrapiContent } from "services/customContent"

export type CustomContentQuery = {
  associatedEvent?: boolean
}

export enum CustomContentQueryEnum {
  ASSOCIATED_EVENT = "?associatedEvents_contains",
}

export type CustomContentData = {
  customContentData: CustomContent | undefined
  fetchingCustomContent: boolean
}

const useCustomContent = (
  id: string | undefined,
  options?: CustomContentQuery,
): CustomContentData => {
  const [customContentData, setCustomContentData] = useState<CustomContent>()
  const [fetchingCustomContent, setFetchingCustomContent] = useState<boolean>(true)

  useEffect(() => {
    const getContentData = async () => {
      if (id) {
        const results = await getStrapiContent(id, options)
        let sanitizedResults
        if (Array.isArray(results)) {
          if (results.length) {
            sanitizedResults = filterContentSections(results[0])
          }
        } else {
          sanitizedResults = filterContentSections(results)
        }
        setCustomContentData(sanitizedResults)
      }

      setFetchingCustomContent(false)
    }

    getContentData()
  }, [id])

  return { customContentData, fetchingCustomContent }
}

export default useCustomContent
