import styled from "styled-components"

const Container = styled.div`
  padding: 10vw 20px 40px;
`
const ContentContainer = styled.div`
  padding: 20px;
  max-width: 540px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`
const Logo = styled.img`
  max-width: 180px;

  display: block;
  margin-bottom: 40px;
`
const Headline = styled.div`
  font-size: 36px;
  line-height: 1.1;
  ${({ theme: { fonts } }) => fonts.TTNormsProBold}
`
const Body = styled.div`
  font-size: 21px;
  margin-top: 20px;
  margin-bottom: 30px;
  ${({ theme: { fonts } }) => fonts.TTNormsProRegular}
`
const ErrorMessage = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.warning};
  ${({ theme: { fonts } }) => fonts.TTNormsProRegular}
`

export { Container, ContentContainer, Logo, Headline, Body, ErrorMessage }
