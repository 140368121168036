import React, { useState } from 'react'
import { Form, Alert } from 'react-bootstrap'
import { StyledModal, StyledLabel, StyledFormControl, StyledFormText } from './Content.styled'
import { createTicketForShow } from '../../../../services/tickets'
import { Button } from "../../../shared"

const INITIAL = Object.freeze({ email: '', name: '', sendEmail: true })

const AddTicket = ({ open = false, toggleModal, showId }) => {
  const [formData, setFormData] = useState(INITIAL)
  const [processing, setProcessing] = useState(false)
  const [message, setMessage] = useState(null)
  const handleChange = (e) => {
    let value = e.target.value
    if (e.target.name === 'sendEmail') {
      value = e.target.checked
    }
    setFormData({
      ...formData,
      [e.target.name]: value
    });
  };
  const generateTicket = async (e) => {
    setMessage(null)
    setProcessing(true)
    e.preventDefault()

    const { email, name, sendEmail } = formData
    try {
      await createTicketForShow(showId, email, name, sendEmail)
      setFormData(INITIAL)
      setMessage({ type: 'success', message: "Ticket created!" })
    } catch ({ message }) {
      setMessage({ type: 'danger', message: `Error: ${message}` })
    } finally {
      setProcessing(false)
    }
  }
  return (
    <StyledModal
      show={open}
      onHide={() => {
        setMessage(null)
        setFormData(INITIAL)
        toggleModal(false)
      }}
      size="md"
      centered
      aria-labelledby="add-ticket"
    >
      <Form onSubmit={generateTicket}>
        <StyledModal.Header closeButton>
          <h2>Create a new ticket</h2>
        </StyledModal.Header>
        <StyledModal.Body>
          {message ? <Alert variant={message.type}>{message.message}</Alert> : null}
          <Form.Group controlId="email">
            <StyledLabel>Email Address</StyledLabel>
            <StyledFormControl name="email" value={formData.email} onChange={handleChange} autoComplete="off" type="email" placeholder="Email address" required />
            <StyledFormText className="text-muted">
              If this person already has a mandolin account make sure the email matches their registered email.
            </StyledFormText>
          </Form.Group>
          <Form.Group controlId="name">
            <StyledLabel>Name</StyledLabel>
            <StyledFormControl name="name" value={formData.name} onChange={handleChange} autoComplete="off" type="text" placeholder="Name" required />
          </Form.Group>
          <Form.Group controlId="sendEmail">
            <Form.Check checked={formData.sendEmail} name="sendEmail" onChange={handleChange} type="checkbox" title="Send Email" label="Send email with ticket" />
          </Form.Group>
        </StyledModal.Body>
        <StyledModal.Footer>
          <Button width="140px" height="40px" disabled={processing} type="submit" label="Create Ticket" />
        </StyledModal.Footer>
      </Form>
    </StyledModal>
  )
}

export default AddTicket