import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "redux/store"
import { trackSurveyResponse } from "services/analytics"
import { getSurveyInfo, submitSurveyResponse } from "../../services/survey"
import Questions from "./SurveyStates/Questions"
import Reasons from "./SurveyStates/Reasons"
import Thanks from "./SurveyStates/Thanks"
import { Container } from "./SurveyModal.styled"

enum surveyStateOptions {
  QUESTION = "question",
  REASONS = "reasons",
  THANKS = "thanks",
}

export enum SurveyTypes {
  STAR = "stars",
  TEXT = "textEntry",
}

interface SurveyProps {
  finish: () => void
  surveyId: string
  eventId: string
  shownOn: string
}
const SurveyModal: React.FC<SurveyProps> = ({ finish = () => {}, surveyId, eventId, shownOn }) => {
  const [surveyState, setSurveyState] = useState<surveyStateOptions>(surveyStateOptions.QUESTION)
  const [selectedRating, setSelectedRating] = useState<string>("")
  const [surveyData, setSurveyData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const { user } = useSelector((state: RootState) => state)

  useEffect(() => {
    const getSurveyData = async () => {
      try {
        const info = await getSurveyInfo(surveyId)
        setSurveyData(info)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (surveyId) getSurveyData()
  }, [surveyId])

  const submitSurvey = (rating: string, selectedReasons: string[]) => {
    if (user) {
      submitSurveyResponse(user?.uid || "", selectedReasons, rating, surveyId, eventId)
    }
  }

  const renderSurveyState = () => {
    if (surveyState === surveyStateOptions.QUESTION) {
      return (
        <Questions
          submit={(rating) => {
            setSelectedRating(rating.toString())
            trackSurveyResponse(`Fan rated ${shownOn}`, shownOn)
            if (rating < 4) {
              setSurveyState(surveyStateOptions.REASONS)
            } else {
              submitSurvey(rating.toString(), [])
              setSurveyState(surveyStateOptions.THANKS)
            }
          }}
          skip={() => {
            finish()
            trackSurveyResponse(`Fan dismissed CSAT`, shownOn)
          }}
          surveyQuestion={surveyData.question}
        />
      )
    }
    if (surveyState === surveyStateOptions.REASONS) {
      return (
        <Reasons
          reasons={surveyData.reasonings}
          submit={(selectedReasons) => {
            submitSurvey(selectedRating, selectedReasons)
            setSurveyState(surveyStateOptions.THANKS)
            trackSurveyResponse(`Fan submitted CSAT`, shownOn)
          }}
          skip={() => {
            finish()
            trackSurveyResponse(`Fan dismissed CSAT`, shownOn)
          }}
          shownOn={shownOn}
        />
      )
    }
    return <Thanks leave={finish} />
  }
  return !loading && surveyData && <Container>{renderSurveyState()}</Container>
}

export default SurveyModal
