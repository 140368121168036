import styled from "styled-components"
import { Modal } from "react-bootstrap"
import { readableColor } from "polished"

const ModalContainer = styled(Modal)`
  .modal-dialog {
    .modal-content {
      border-radius: 24px;
      background-color: ${({ theme }) => theme.colors.elevation2};
      background-clip: padding-box;
      border: none;
      color: ${({ theme }) => readableColor(theme.colors.elevation2)};
    }
    max-width: 605px;
    @media (max-width: 760px) {
      margin: 0;
      width: 100vw;
    }
  }
`

const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5%;
`

const CloseButton = styled.button`
  color: ${({ theme }) => readableColor(theme.colors.elevation2)};
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  background-color: transparent;
  border: none;
  svg {
    margin: auto;
    height: 24px;
    width: 24px;
  }
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5% 13% 10% 13%;
  position: relative;
  flex-grow: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @media (max-width: 760px) {
    width: 100%;
  }
`

const ModalBody = styled.form`
  align-items: stretch;
  border-radius: 17px;
  display: flex;
  flex-direction: row;
  height: 704px;
  width: 100%;
  @media (max-width: 760px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`

const AvatarOptions = styled.div`
  margin-top: 54px;
  display: grid;
  place-items: center;
  place-content: center;
  grid-template-columns: repeat(auto-fit, 110px);
  @media (max-width: 760px) {
    grid-template-columns: repeat(auto-fit, 80px);
  }
`

const AvatarOptionContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 7%;
  place-self: center;
  box-sizing: border-box;
  border: ${({ selected, theme }) =>
    selected ? `3px solid ${theme.elements.pageText}` : `3px solid ${theme.colors.elevation2}`};
  border-radius: 100%;
`

const UploadContainer = styled(AvatarOptionContainer)`
  transition: 0.1s ease opacity;
  z-index: 0;
`

const UploadInput = styled.label`
  background-color: #58585a;
  background-image: url(${(props) => props.img});
  background-size: 100%;
  cursor: pointer;
  border-radius: 100%;
  cursor: pointer;
  color: ${({ theme }) => theme.elements.pageText};
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  &:hover {
    ${UploadContainer} {
      opacity: 0.6;
    }
  }
  svg {
    margin: auto;
    height: 24px;
    width: 24px;
    display: ${(props) => (props.img ? "none" : "block")};
  }
`

const AvatarOption = styled.img`
  border-radius: 100%;
  height: 100%;
  width: 100%;
  cursor: pointer;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 54px;
`

export {
  ModalContainer,
  ModalHeader,
  CloseButton,
  ModalContent,
  ModalBody,
  AvatarOptions,
  AvatarOptionContainer,
  UploadContainer,
  UploadInput,
  AvatarOption,
  ButtonContainer,
}
