import { useCallback, useEffect, useState, useMemo, useRef } from "react"
import Daily, { DailyParticipant, DailyEvent } from "@daily-co/daily-js"
import { LayoutType } from "views/VIP/Party/statusTypes/InParty"
import { useDispatch } from "react-redux"
import { setVIPGuests } from "redux/vip"

export enum DailyEventCode {
  CAMERA_ERROR = "camera-error",
  CAMERA_STARTED = "started-camera",
  GENERAL_ERROR = "error",
  JOINED_MEETING = "joined-meeting",
  JOINING_MEETING = "joining-meeting",
  LOADED = "loaded",
  LOAD_ATTEMPT_ERROR = "load-attempt-failed",
  NETWORK_CONNECTION = "network-connection",
  PARTICIPANT_JOINED = "participant-joined",
  PARTICIPANT_LEFT = "participant-left",
  PARTICIPANT_UPDATED = "participant-updated",
  ACTIVE_SPEAKER_CHANGE = "active-speaker-change",
  APP_MESSAGE = "app-message",
}

const PARTICPANT_EVENTS: DailyEvent[] = [
  DailyEventCode.PARTICIPANT_JOINED,
  DailyEventCode.PARTICIPANT_LEFT,
  DailyEventCode.PARTICIPANT_UPDATED,
]

type DailyEventError = {
  id: string
  message: string
  severity: 1 | 2
}

const MAX_VIDS = 19

const useVIPParty = (roomUrl: string | undefined, name: string | undefined) => {
  const [participants, setParticipants] = useState<DailyParticipant[]>([])
  const [dailyEventError, setDailyEventError] = useState<string | null>(null)
  const [networkScore, setNetworkScore] = useState("")
  const [timeUp, setTimeUp] = useState<boolean>(false)
  const volume = useRef(true)
  const dispatch = useDispatch()
  const layout = useRef(LayoutType.GRID)
  const [localParticipant, setLocalParticipant] = useState<DailyParticipant>()
  const dailyCall = useMemo(
    () => Daily.createCallObject({ subscribeToTracksAutomatically: false }),
    [],
  )
  const [activeSpeaker, setActiveSpeaker] = useState<string>()

  useEffect(() => {
    if (roomUrl && name) {
      const url = roomUrl.split("?t=")
      dailyCall.join({
        url: url[0],
        token: url[1],
      })
      dailyCall.setLocalAudio(false)
      dailyCall.setLocalVideo(true)
    }
  }, [dailyCall, roomUrl, name])

  const logDailyEvent = (dailyCode: DailyEventCode, errorEvent: any) => {
    console.log(`[${dailyCode}]: `, errorEvent)
  }

  const mute = (mic: boolean) => {
    dailyCall.setLocalAudio(mic)
  }

  const video = (cam: boolean) => {
    dailyCall.setLocalVideo(cam)
  }

  const leaveMeet = () => {
    dailyCall.leave()
  }
  const setTrackSubscription = (id: string, A: boolean, V: boolean) => {
    dailyCall.updateParticipant(id, {
      setSubscribedTracks: {
        audio: A,
        video: V,
        screenVideo: false,
      },
    })
  }
  const setTracks = (callAudio: boolean, list: DailyParticipant[]) => {
    // eslint-disable-next-line array-callback-return
    list.map((part) => {
      if (
        part.user_name.includes("artist") ||
        part.user_name.includes("host") ||
        part.user_name.includes("participant,s,")
      ) {
        setTrackSubscription(part.session_id || "", !callAudio, true)
      } else {
        setTrackSubscription(part.session_id || "", false, false)
      }
    })
  }
  const setGridLayoutTracks = (callAudio: boolean, list: DailyParticipant[]) => {
    let videoCount = 0
    // eslint-disable-next-line array-callback-return
    list.map((part) => {
      if (
        !part.local &&
        (part.user_name.includes("artist") ||
          part.user_name.includes("host") ||
          part.user_name.includes("participant,s,"))
      ) {
        videoCount += 1
        setTrackSubscription(part.session_id || "", !callAudio, true)
      } else if (layout.current === LayoutType.GRID && videoCount < MAX_VIDS) {
        setTrackSubscription(part.session_id || "", false, true)
        videoCount += 1
      } else {
        setTrackSubscription(part.session_id || "", false, false)
      }
    })
  }

  const mapEnumToArray = (enumeration: any): DailyEvent[] =>
    Object.keys(enumeration).map((key) => enumeration[key])

  useEffect(() => {
    const handleNetworkCondition = async () => {
      try {
        const condition = await dailyCall.getNetworkStats()
        setNetworkScore(condition.threshold)
      } catch (error) {
        console.log(error)
      }
    }
    const interval = setInterval(handleNetworkCondition, 5000)

    return () => clearInterval(interval)
  }, [dailyCall])

  useEffect(() => {
    dailyCall.setLocalAudio(true)
    dailyCall.setLocalVideo(true)
  }, [])

  // Iterate through the partipants in call from dailyCall object
  const handleNewParticipantsState = useCallback(() => {
    const participantList = Object.values(dailyCall.participants())
    if (participantList.length > 0) {
      const fans = participantList.filter((p) => p.user_name.includes("participant"))
      dispatch(setVIPGuests(fans.map((f) => ({ user_id: f.user_id, user_name: f.user_name }))))

      const localPart = participantList.filter((p) => p.local)
      setLocalParticipant(localPart[0])

      const nonLocalParticipants = participantList.filter((p) => !p.local)
      setParticipants(nonLocalParticipants)

      if (layout.current === LayoutType.ONE) {
        setTracks(volume.current, participantList)
      } else {
        setGridLayoutTracks(volume.current, participantList)
      }
    }
  }, [dailyCall])

  const handleEventEmitters = useCallback(() => {
    dailyCall
      .on(DailyEventCode.CAMERA_ERROR, (cameraErrorEvent) => {
        logDailyEvent(DailyEventCode.CAMERA_ERROR, cameraErrorEvent)
      })
      .on(DailyEventCode.GENERAL_ERROR, (errorEvent) => {
        logDailyEvent(DailyEventCode.GENERAL_ERROR, errorEvent)
        setDailyEventError(errorEvent?.errorMsg || "Error")
      })
      .on(DailyEventCode.LOAD_ATTEMPT_ERROR, (loadAttemptFailedEvent) => {
        logDailyEvent(DailyEventCode.LOAD_ATTEMPT_ERROR, loadAttemptFailedEvent)
      })
      .on(DailyEventCode.NETWORK_CONNECTION, (networkConnectionEvent) => {
        logDailyEvent(DailyEventCode.NETWORK_CONNECTION, networkConnectionEvent)
      })
      .on(DailyEventCode.JOINING_MEETING, () => {
        setDailyEventError(null)
      })
      .on(DailyEventCode.JOINED_MEETING, () => {
        console.log(name, "USING THIS")
        dailyCall.setUserName(`participant,${name}`)
        setDailyEventError(null)
      })
      .on(DailyEventCode.ACTIVE_SPEAKER_CHANGE, (meet) => {
        console.log(meet?.activeSpeaker.peerId)
        setActiveSpeaker(meet?.activeSpeaker.peerId)
      })
      .on(DailyEventCode.PARTICIPANT_LEFT, () => {})
  }, [dailyCall])

  const cleanupParticipants = useCallback(() => {
    PARTICPANT_EVENTS.forEach((event) => {
      dailyCall.off(event, handleNewParticipantsState)
    })
  }, [dailyCall, handleNewParticipantsState])

  const cleanupEvents = useCallback(() => {
    const eventValues = mapEnumToArray(DailyEventCode)
    eventValues.forEach((eventValue: DailyEvent) => {
      dailyCall.off(eventValue, handleEventEmitters)
    })
  }, [dailyCall, handleEventEmitters])

  const endCall = async () => {
    setParticipants([])
    await dailyCall.destroy()
  }

  // Participant states
  useEffect(() => {
    if (!dailyCall) return
    // Add new participants
    handleNewParticipantsState()
    // Handle event changes
    PARTICPANT_EVENTS.forEach((event) => {
      dailyCall.on(event, handleNewParticipantsState)
    })

    handleEventEmitters()
    // eslint-disable-next-line consistent-return
    return () => {
      cleanupEvents()
      cleanupParticipants()
      endCall()
    }
  }, [dailyCall, cleanupParticipants, handleNewParticipantsState])

  const updateParticipantName = (newName: string) => {
    dailyCall.setUserName(newName)
  }

  const toggleVolume = (volumeOn: boolean) => {
    volume.current = volumeOn
    if (layout.current === LayoutType.ONE) {
      setTracks(volumeOn, participants)
    } else {
      setGridLayoutTracks(volumeOn, participants)
    }
  }
  const toggleLayout = (layoutType: LayoutType) => {
    layout.current = layoutType
    if (layout.current === LayoutType.ONE) {
      setTracks(volume.current, participants)
    } else {
      setGridLayoutTracks(volume.current, participants)
    }
  }

  const handleAppMessage = (event: any) => {
    if (event?.data) {
      setTimeUp(true)
    }
  }

  const resetTimeUpAlert = () => {
    setTimeUp(false)
  }

  useEffect(() => {
    // Handles app messages from Daily
    dailyCall.on(DailyEventCode.APP_MESSAGE, handleAppMessage)

    return () => {
      dailyCall.off(DailyEventCode.APP_MESSAGE, handleAppMessage)
    }
  }, [dailyCall])

  return {
    participants,
    networkScore,
    mute,
    video,
    leaveMeet,
    activeSpeaker,
    dailyEventError,
    localParticipant,
    updateParticipantName,
    dailyCall,
    toggleVolume,
    volume,
    toggleLayout,
    timeUp,
    resetTimeUpAlert,
  }
}

export default useVIPParty
