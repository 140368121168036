import { transparentize } from "polished"
import styled from "styled-components/macro"
import { toRem } from "theme"

export const SubmitBtn = styled.button`
  height: 60px;
  border-radius: 40px;
  border: none;
  margin-top: 24px;
  color: #fff;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.green};
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => transparentize(0.5, theme.colors.elevation5)};
    color: rgba(255, 255, 255, 0.4);
    &:hover {
      background-color: ${({ theme }) => transparentize(0.5, theme.colors.elevation5)};
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &:hover {
    opacity: 0.8;
    color: rgba(255, 255, 255, 0.7);
  }
`

export const PermissionText = styled.div`
  z-index: 20;
  width: 90%;
  max-width: ${toRem(420)}px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`
export const Badge = styled.img.attrs({ src: "/images/vip-meet.png" })`
  width: 140px;
  height: auto;
  max-height: 100px;
  z-index: 300;
  padding: 18px;
`

export const Title = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold}
  letter-spacing: -0.5px;
  font-size: ${toRem(28)};
  line-height: ${toRem(32)};
  color: ${({ theme }) => theme.colors.elevation8};
  z-index: 1;
  padding-bottom: 30px;
`
