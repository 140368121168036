import styled, { keyframes } from "styled-components/macro"

const stretch = keyframes`
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  } 20% { 
    transform: scaleY(1.0);
  }
`

const SpinnerContainer = styled.div`
  margin: 50px auto;
  font-size: 10px;
  height: 100px;
  text-align: center;
  width: 50px;
`

const SpinnerElement = styled.div`
  background-color: ${({ theme }) => theme.elements.buttonBackground || theme.colors.primarySolid};
  border-radius: 8px;
  animation: ${stretch} 1.2s infinite ease-in-out;
  display: inline-block;
  height: 100%;
  margin: 0 3px 0 0;
  width: 7px;
  &:nth-child(2) {
    animation-delay: -1.1s;
  }
  &:nth-child(3) {
    animation-delay: -1s;
  }
  &:nth-child(4) {
    animation-delay: -0.9s;
  }
  &:nth-child(5) {
    animation-delay: -0.8s;
  }
`

export { SpinnerContainer, SpinnerElement }
