import Typography from "components/Typography"
import React from "react"
import styled from "styled-components"
import { atMediaMin } from "theme"
import { skeletonStyles } from "theme/utils"
import { sectionContainer } from "theme/spacing"

const EventTileContainer = styled.div`
  width: 100%;
  span {
    display: block;
    color: rgba(0, 0, 0, 0);
  }
`

const EventTileCarousel = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  margin-right: -16px;
  padding-bottom: 16px;
  ${atMediaMin("lg")} {
    display: block;
    margin: 0;
  }
`

const Label = styled.div<{ width: number }>`
  ${skeletonStyles};
  width: ${({ width }) => width}px;
  color: rgba(0, 0, 0, 0);
  margin-bottom: 24px;
  user-select: none;
`

const EventTileSkeleton = styled.div`
  ${skeletonStyles}
  flex: 0 0 260px;
  height: 240px;
  margin-right: 16px;
  ${atMediaMin("lg")} {
    width: 100%;
    height: 160px;
    margin-right: 0px;
    margin-bottom: 24px;
  }
`

const TicketSkeleton = styled.div`
  ${skeletonStyles}
  width: 100%;
  padding-bottom: 158.42%;
  ${atMediaMin("md")} {
    width: 48%;
    padding-bottom: 78%;
  }
  ${atMediaMin("lg")} {
    width: 31%;
    padding-bottom: 47%;
  }
  ${atMediaMin("xl")} {
    width: 24%;
    padding-bottom: 37%;
  }
  &.no-color {
    background-image: none !important;
    padding-bottom: 0% !important;
  }
`

const TicketSkeletonContainer = styled.div`
  ${sectionContainer};
  max-width: 100%;
  margin: 20px 0;
  ${TicketSkeleton} {
    margin: 10px 0;
  }
  ${atMediaMin("md")} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const TabContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
  ${Label} {
    display: block;
    margin: 20px 20px 20px 0px;
    span {
      color: rgba(0, 0, 0, 0);
      margin: 0;
    }
  }
`

export const Replay = () => {
  return (
    <EventTileContainer>
      <Label width={160}>
        <Typography variant="h2" tag="span">
          --
        </Typography>
      </Label>
      <EventTileCarousel>
        <EventTileSkeleton />
        <EventTileSkeleton />
        <EventTileSkeleton />
      </EventTileCarousel>
    </EventTileContainer>
  )
}

export const Upcoming = () => {
  return (
    <TicketSkeletonContainer>
      <TabContainer>
        <Label width={100}>
          <Typography variant="h2" tag="span">
            --
          </Typography>
        </Label>
        <Label width={80}>
          <Typography variant="h2" tag="span">
            --
          </Typography>
        </Label>
      </TabContainer>
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton className="no-color" />
      <TicketSkeleton className="no-color" />
      <TicketSkeleton className="no-color" />
    </TicketSkeletonContainer>
  )
}

export const NoTicketSkeleton = () => {
  return (
    <TicketSkeletonContainer>
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton />
      <TicketSkeleton className="no-color" />
      <TicketSkeleton className="no-color" />
      <TicketSkeleton className="no-color" />
    </TicketSkeletonContainer>
  )
}
