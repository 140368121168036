import { darken, lighten, readableColor, transparentize } from "polished"
import styled, { css, keyframes } from "styled-components"
import { toRem, atMediaMin } from "theme"
import { skeletonStyles } from "theme/utils"
import { Spinner8 } from "styled-icons/icomoon"
import { sectionContainer } from "theme/spacing"
import { Modal } from "react-bootstrap"
import { LinkContainer } from "components/CommerceItem/CommerceItem.styled"

export const Container = styled.main`
  padding-bottom: ${toRem(60)};
  background-color: ${({ theme }) => theme.elements.pageBackground || "transparent"};
  transition: background-color ${({ theme }) => theme.transition};
  ${atMediaMin("md")} {
    padding-bottom: ${toRem(80)};
  }
  ${LinkContainer} {
    color: ${({ theme }) => theme.elements.pageTextAccent};
    span {
      color: ${({ theme }) => theme.elements.pageTextAccent};
    }
    h6 {
      color: ${({ theme }) => theme.elements.scheduleLabelText || theme.elements.pageTextAccent};
    }
  }
`
export const ErrorContainer = styled.div`
  text-align: center;
  padding: 20vh 20px;
`
export const ShowContainer = styled.section<{ ta?: string }>`
  margin: ${toRem(30)} 0;
  ${({ ta }) =>
    ta !== undefined
      ? css`
          ${sectionContainer}
          text-align: ${ta}
        `
      : ``}
  ${atMediaMin("md")} {
    margin: ${toRem(60)} 0;
  }
`
export const ShowItem = styled.div`
  width: 80vw;
  max-width: ${toRem(360)};
`
export const DescriptionContainer = styled.div`
  ${sectionContainer}
  ${atMediaMin("md")} {
    display: flex;
    justify-content: space-between;
  }
`
export const FestivalContentContainer = styled.div`
  padding-bottom: ${toRem(16)};
  ${atMediaMin("md")} {
    padding-bottom: ${toRem(32)};
  }
`
export const MapModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 90%;
    .modal-content {
      border-radius: 24px;
      background-clip: padding-box;
      border: none;
      color: #000;
      background-color: transparent;
      overflow: hidden;
    }
  }
`
export const MapModalBody = styled.div<{ direction?: string }>`
  img {
    display: block;
    object-fit: contain;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
  }
`
export const MapCTAContainer = styled.div`
  ${sectionContainer}
  padding-bottom: ${toRem(40)};
  ${atMediaMin("md")} {
    padding-bottom: ${toRem(80)};
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ShowWrapper = styled.div`
  width: 80vw;
  max-width: 366px;
  div {
    min-height: auto;
  }
`
export const GridItemWrapper = styled.div`
  padding-top: 8px;
`
export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
  width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ReminderContainer = styled.div`
  margin-top: 30px;
`

export const DescriptionLeftCol = styled.div`
  margin: ${toRem(20)} 0;
  .website-link {
    white-space: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
    max-width: 420px;
    padding-right: 16px;
  }
  ${atMediaMin("md")} {
    margin: ${toRem(40)} 0;
    flex: 0 0 50%;
  }
  ${atMediaMin("lg")} {
    flex: 0 0 48%;
  }
`
export const DescriptionRightCol = styled.div`
  margin: ${toRem(20)} 0;
  ${atMediaMin("md")} {
    margin: ${toRem(40)} 0;
    flex: 0 0 40%;
  }
  a {
    margin-bottom: 30px;
  }
`
export const ImageContainer = styled.div<{ displayGradient?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60vw;
  background-color: ${({ theme: { elements } }) =>
    readableColor(elements.pageBackground) === "#fff"
      ? lighten(0.03, elements.pageBackground)
      : darken(0.03, elements.pageBackground)};
  ${atMediaMin("lg")} {
    height: 70vh;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
    user-select: none;
    border-radius: 0px;
    background-color: transparent;
  }
  &:after {
    content: "";
    display: ${({ displayGradient }) => (!displayGradient ? "none" : "block")};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 220px;
    background: ${({ theme: { elements } }) =>
      `linear-gradient(0deg, ${elements.pageBackground} 0%, ${transparentize(
        1,
        elements.pageBackground,
      )} 100%)`};
  }
`
export const HeaderContainer = styled.section<{ loading: "true" | "false" }>`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-top: calc(60vw - calc(42px / 1.5));
  ${atMediaMin("md")} {
    padding-top: calc(60vw - calc(42px / 1.25));
  }
  ${atMediaMin("lg")} {
    padding-top: calc(70vh - calc(42px));
  }
  ${({ loading }) =>
    loading === "true"
      ? css`
          h1 {
            color: rgba(0, 0, 0, 0);
            ${skeletonStyles}
            border-radius: 8px;
            width: 70%;
            &:before {
              content: "---";
            }
          }
        `
      : ``}
`
export const HeaderContent = styled.div<{ displayGradient?: boolean }>`
  ${sectionContainer}
  width: 100%;
  position: relative;
  z-index: 2;
  min-height: ${toRem(44)};
  ${atMediaMin("md")} {
    min-height: ${toRem(66)};
  }
  ${atMediaMin("lg")} {
    min-height: ${toRem(88)};
  }
  h1 {
    ${({ theme }) => theme.fonts.TTNormsProDemiBold};
    font-size: calc(80px / 2);
    ${atMediaMin("md")} {
      font-size: calc(80px / 1.33);
    }
    ${atMediaMin("lg")} {
      font-size: calc(80px);
    }
  }
  img {
    display: block;
    width: 100%;
    max-width: ${toRem(250)};
    max-height: ${toRem(110)};
    object-fit: contain;
    background-color: transparent;
    border-radius: 0;
    object-position: left center;
    margin-top: ${({ displayGradient }) => (!displayGradient ? 60 : 0)}px;
  }
`
export const DescriptionLinks = styled.div`
  margin-top: ${toRem(16)};
  a {
    color: ${({ theme }) => theme.elements.pageTextAccent};
    font-size: ${toRem(14)};
  }
`
export const DescriptionSocialIcons = styled.div`
  display: flex;
  margin-top: ${toRem(8)};
  a {
    width: 30px;
    height: 30px;
    color: ${({ theme }) => theme.elements.pageText};
    margin-right: ${toRem(12)};
    &:hover {
      color: ${({ theme }) => theme.elements.pageTextAccent};
    }
    svg {
      display: block;
    }
  }
`
export const DescriptionSkeleton = styled.div`
  div {
    display: block;
    ${skeletonStyles}
    border-radius: 4px;
    color: rgba(0, 0, 0, 0);
    height: 1rem;
    margin-bottom: ${toRem(6.4)};
    &:nth-child(1) {
      width: 98%;
    }
    &:nth-child(2) {
      width: 95%;
    }
    &:last-of-type {
      width: 60%;
    }
  }
  ${DescriptionLinks} {
    height: auto !important;
    background: none !important;
    & > span {
      display: block;
      width: 220px;
      height: 16px;
      ${skeletonStyles}
      border-radius: 4px;
      background: ${({ theme }) => transparentize(0.5, theme.elements.pageTextAccent)};
      &:before {
        content: "--";
      }
    }
  }
  ${DescriptionSocialIcons} {
    background: none !important;
    width: auto !important;
    display: flex !important;
    span {
      ${skeletonStyles}
      display: block;
      width: ${toRem(30)} !important;
      height: ${toRem(30)} !important;
      margin-right: ${toRem(10)};
    }
  }
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`

export const LoadingSpinner = styled(Spinner8)`
  animation: ${spin} 1s linear infinite;
  height: 13px;
  margin-bottom: 2px;
  margin-left: -8px;
  margin-right: 8px;
  width: 13px;
`

export const InfoBulletContainer = styled.div`
  margin-bottom: 25px;
  svg {
    display: inline-block;
    margin-right: ${toRem(16)};
    color: ${({ theme }) => theme.elements.pageText};
  }
`
