import Typography from "components/Typography"
import styled from "styled-components"

const SwitchContainer = styled.label`
  margin: 32px 0 72px 0;
  width: 366px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 760px) {
    width: 100%;
  }
`

const Switch = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 40px;
  &:hover {
    opacity: 0.8;
  }
`

const SwitchLabelText = styled(Typography).attrs({
  variant: "smallCap",
})`
  display: inline-block;
`

const SwitchInput = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  &:checked + .slider {
    background-color: ${({ theme }) => theme.colors.primarySolid};
  }

  &:checked + .slider:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
  }

  &:focus + .slider {
    box-shadow: 0 0 1px ${({ theme }) => theme.colors.primarySolid};
  }
`

const Slider = styled.span`
  width: 72px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.elevation4};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  ::before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`
export { SwitchContainer, Switch, SwitchLabelText, SwitchInput, Slider }
