import { invert, rgba } from "polished"
import styled from "styled-components"
import { Favorite } from "styled-icons/material-rounded"
import { atMediaMin, toRem } from "theme/utils"

export const Container = styled.div`
  display: flex;
  img {
    background-color: ${({ theme }) => rgba(invert(theme.elements.pageBackground), 0.075)};
    height: ${toRem(130)};
    width: ${toRem(130)};
    flex: 0 0 ${toRem(130)};
    border-radius: 50%;
    object-fit: cover;
    ${atMediaMin("md")} {
      height: ${toRem(160)};
      width: ${toRem(160)};
      flex: 0 0 ${toRem(160)};
    }
  }
`

export const Content = styled.div`
  margin: auto 0 auto 16px;
  max-width: 240px;
  color: ${({ theme }) => theme.elements.pageText};
  p {
    ${({ theme }) => theme.fonts.TTNormsProMedium};
    font-size: ${toRem(13)};
    line-height: ${toRem(17)};
    margin-bottom: 16px;
  }
  span {
    ${({ theme }) => theme.fonts.TTNormsProDemiBold};
    font-size: ${toRem(12)};
    letter-spacing: 0.15em;
    line-height: ${toRem(15)};
    text-transform: uppercase;
  }
`

export const Title = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: ${toRem(16)};
  line-height: ${toRem(20)};
  letter-spacing: -0.5px;
  margin-bottom: 8px;
`

export const Icon = styled(Favorite)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`
