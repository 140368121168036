import styled from "styled-components"

export const Video = styled.video`
  width: 100%;
  height: 100%;
`
export const Audio = styled.audio`
  box-shadow: 0px 8px 16px rgb(0 0 0 / 32%);
  border-radius: 2rem;
`

export const Image = styled.img``
