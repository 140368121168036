import styled, { css } from "styled-components/macro"
import { Modal } from "react-bootstrap"
import { readableColor, rgba, transparentize } from "polished"
import Typography from "components/Typography"
import { toRem } from "theme/utils"

const ModalContainer = styled(Modal)`
  .modal-dialog {
    .modal-content {
      border-radius: 24px;
      background-color: #eff2f3;
      background-clip: padding-box;
      border: none;
      color: #000;
    }
    max-width: 586px;
    @media (max-width: 760px) {
      margin: 0;
      max-width: 100vw;
    }
  }
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10% 13%;
  position: relative;
  flex-grow: 1;
  overflow: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @media (max-width: 760px) {
    width: 100%;
  }

  form {
    max-width: 330px;
    margin: 0 auto;

    button {
      background-color: #000 !important;
      svg,
      span,
      div {
        color: #fff !important;
        fill: #fff !important;
      }
    }

    label {
      text-transform: uppercase;
      justify-content: space-around;
    }
  }

  .form-check {
    ${({ theme }) => theme.fonts.TTNormsProMedium};
    font-size: 14px;
    .input {
      height: 12px;
      width: 12px;
    }
  }

  .passwordField-enter {
    opacity: 0;
    margin-bottom: -70px;
  }
  .passwordField-enter-active {
    opacity: 1;
    margin-bottom: 1rem;
    transition: 300ms;
  }

  #passwordField {
    display: flex;
    flex-direction: column;
    a {
      color: #000;
      cursor: pointer;
      font-size: 13px;
      text-align: right;
      margin-bottom: 8px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`
const CreateAccountLink = styled.span`
  display: block;
  margin-top: 16px;
  color: #000;
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  margin-bottom: 8px;
  &:hover {
    text-decoration: underline;
  }
`

const ModalBody = styled.div`
  position: relative;
  align-items: stretch;
  border-radius: 17px;
  display: flex;
  display: flex;
  flex-direction: row;
  flex-direction: row;
  min-height: 760px;
  overflow: hidden;
  width: 100%;
  @media (max-width: 760px) {
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
    min-height: 0px;
  }
  .fade-enter {
    opacity: 0;
    transform: ${({ direction }) =>
      direction === "in" ? "translateX(100%)" : "translateX(-100%)"};
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit-active {
    opacity: 0;
    transform: ${({ direction }) =>
      direction === "in" ? "translateX(-100%)" : "translateX(100%)"};
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 300ms ease-out, transform 300ms ease-out;
  }
`

const Header = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 24px;
  letter-spacing: 0;
  margin-bottom: 38px;
  margin-top: 22px;
  text-align: center;
  @media (max-width: 660px) {
    margin-bottom: 16px;
  }
  img {
    margin-bottom: 12px;
    @media (max-width: 660px) {
      margin-bottom: 8px;
    }
  }
`
export const HeaderText = styled(Typography)`
  color: #000;
`

export const FieldError = styled.aside`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ theme }) => theme.colors.error};
  font-size: 14px;
  padding: 4px 0 0 16px;
`

export const FieldContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

export const Underline = styled.div`
  background-color: ${({ error, theme }) => (error ? theme.colors.eleor : "#000")};
  bottom: 0px;
  height: 2px;
  left: 0px;
  margin: 0;
  position: absolute;
  right: 0px;
  transition: background-color 0.24s cubic-bezier(0.4, 0, 0.3, 1);
`

export const Input = styled.input`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  background: none;
  border: 1px solid #cbcbcb;
  border-radius: 44px;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 40px;
  letter-spacing: 0.2px;
  margin-top: 8px;
  max-width: 336px;
  width: 100%;
  padding: 24px 16px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:active,
  &:focus {
    outline: none;
    & + ${Underline} {
      background-color: ${({ theme }) => theme.colors.confirmation};
    }
  }
`

const Divider = styled.div`
  margin: 2rem auto;
  text-align: center;
  width: 100%;
`

const StyledBackArrow = styled.div`
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  margin-bottom: -8px;
  margin-top: 8px;
  svg {
    fill: #000;
    height: 24px;
    margin-right: 4px;
    width: 24px;
  }
  &:hover {
    text-decoration: underline;
  }
`

const buttonStyles = css`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  align-items: center;
  border-radius: 44px;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  letter-spacing: 0.2px;
  margin: 1rem auto;
  max-width: 336px;
  width: 100%;
  padding-left: 12px;
  background-color: #000;
  &:active,
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #000;
  }
  &:hover {
    text-decoration: none;
  }
  span {
    margin-left: 12px;
  }
`

const StyledAppleButton = styled.button`
  ${buttonStyles}
  background: #000000;
  color: #ffffff;
  justify-content: start;
`

const StyledFacebookButton = styled.button`
  ${buttonStyles}
  background: #1877f2;
  color: #ffffff;
  justify-content: start;
`

const StyledGoogleButton = styled.button`
  ${buttonStyles};
  background: #ffffff;
  border: 1px solid #cbcbcb;
  color: #000000;
  justify-content: start;
`

const StyledPrivacyPolicy = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  bottom: 24px;
  color: #000;
  font-size: 12px;
  position: static;
  padding: 24px;
  margin-top: auto;
  text-align: center;
  margin: 0 auto;
  max-width: 280px;
  a {
    color: #000;
    text-decoration: none;
  }
`

const ErrorText = styled.aside`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: #c5162e;
  font-size: 12px;
`

const PhotoUploadPreview = styled.div`
  align-items: center;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 56px;
  justify-content: center;
  transition: 0.1s ease opacity;
  width: 56px;
  z-index: 1;
  svg {
    color: #fff;
    fill: #fff;
  }
`

const PhotoUploadContainer = styled.div`
  margin-bottom: 30px;
  height: unset;
  input {
    display: none;
  }
  label {
    margin-bottom: 0;
    display: flex;
    position: relative;
    cursor: pointer;
    span {
      color: #000;
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
    }
    &:hover {
      span {
        text-decoration: underline;
      }
      opacity: 0.6;
    }
  }
`
const CriteriaContainer = styled.div`
  padding: 10px;
`

const CriteriaRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 5px;
`

const CriteriaBadge = styled.div`
  border: 1.5px solid;
  border-color: ${({ active }) => (active ? "#38cc67" : "#b4b9bb")};
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: ${({ active }) => (active ? "#38cc67" : "transparent")};
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 5px;
`
const CriteriaText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: #5e6266;
  font-size: 12px;
`
const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  width: ${toRem(50)};
  height: ${toRem(50)};
  background-color: transparent;
  border: none;
  color: #000;
  border-bottom-left-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export {
  Divider,
  ErrorText,
  Header,
  CreateAccountLink,
  ModalBody,
  ModalContainer,
  ModalContent,
  PhotoUploadContainer,
  PhotoUploadPreview,
  StyledBackArrow,
  StyledAppleButton,
  StyledFacebookButton,
  StyledGoogleButton,
  StyledPrivacyPolicy,
  CriteriaBadge,
  CriteriaText,
  CriteriaRow,
  CriteriaContainer,
  CloseButton,
}
