import React from "react"
import Typography from "components/Typography"
import { FeaturedBannerDTO } from "views/Home/utils/useHomeContent"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

import {
  BannerContainer,
  BannerImage,
  BannerInfoContainer,
  DateDivider,
  Cta,
  BannerTitle,
  DateContainer,
} from "./FeaturedBanner.styled"

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.guess()

interface BannerProps {
  bannerInfo: FeaturedBannerDTO | undefined
}
const FeaturedBanner: React.FC<BannerProps> = ({ bannerInfo }) => {
  return bannerInfo ? (
    <BannerContainer>
      <BannerImage image={bannerInfo.image.url} />
      <BannerInfoContainer color={bannerInfo.backgroundColor}>
        <DateContainer>
          <Typography textColor={bannerInfo.textColor} variant="smallCap">
            {dayjs(bannerInfo.startTime).format("MMMM DD")}
          </Typography>
          <DateDivider color={bannerInfo.textColor} />
          <Typography textColor={bannerInfo.textColor} variant="smallCap">
            {`${dayjs(bannerInfo.startTime).format("LT z")}`}
          </Typography>
        </DateContainer>
        <BannerTitle color={bannerInfo.textColor}>{bannerInfo.title}</BannerTitle>
        <Cta
          color={bannerInfo.ctaBackgroundColor}
          onClick={() => {
            window.location.href = `https://${bannerInfo.webUrl}`
          }}
        >
          <Typography variant="body" textColor={bannerInfo.ctaTextColor}>
            see details
          </Typography>
        </Cta>
      </BannerInfoContainer>
    </BannerContainer>
  ) : null
}

export default FeaturedBanner
