import React, { useEffect, useState, useRef } from "react"
import CommerceItem from "components/CommerceItem"
import * as ShopifyBuy from "@shopify/buy-button-js"
import { ShopifyContainer } from "./styled"

const fontFamilyReg = {
  "font-family":
    "'TTNormsPro-Regular', -apple-system,BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
  color: "white",
}

const fontFamilyBold = {
  "font-family":
    "'TTNormsPro-Bold', -apple-system,BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
  color: "white",
}

const backgroundColorPrimary = {
  "background-color": "#1E242D",
  ...fontFamilyReg,
}

const backgroundColorAccent = {
  "background-color": "#EA0254",
  ...fontFamilyReg,
}

const buttonRoundWhite = {
  ...fontFamilyBold,
  "background-color": "white",
  "font-size": "14px",
  color: "#0D1115",
  display: "block",
  "text-transform": "lowercase",
  "border-radius": "25px",
  ":hover": {
    "background-color": "white",
  },
}

const ShopifyItem = ({ item, itemClicked }) => {
  const container = useRef()
  const [success, setSuccess] = useState(true)

  useEffect(() => {
    const createComponent = async () => {
      const client = ShopifyBuy.buildClient({
        domain: item.storeUrl,
        storefrontAccessToken: item.storefrontAccessToken,
      })
      const ui = ShopifyBuy.UI.init(client)

      const createSuccess = await ui.createComponent("product", {
        id: item.productId,
        node: document.getElementById(item.productId),
        options: {
          modal: {
            styles: {
              modal: {
                ...backgroundColorPrimary,
              },
              close: {
                ":focus": {
                  border: "none",
                  outline: "none",
                },
                ":focus-visible": {
                  border: "none",
                  outline: "none",
                },
              },
            },
          },
          modalProduct: {
            templates: {
              button: `
              <div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper">
                <button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} style="width: 100%"
                        class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button">
                  <img src="/images/add-circle.svg" alt="{{data.buttonText}}" 
                       style="display: inline-block; vertical-align: middle; height: 17px; width: 17px;"  />
                  {{data.buttonText}}
                </button>
              </div>`,
            },
            text: {
              button: "add to cart",
            },
          },
          product: {
            templates: {
              button: `<button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}}
                            class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button"></button>`,
              product: `<div class="{{data.classes.product}}"></div>`,
            },
            buttonDestination: "modal",
            order: ["button"],
            iframe: false,
            styles: {
              product: fontFamilyReg,
              button: buttonRoundWhite,
              price: fontFamilyReg,
              title: fontFamilyReg,
              description: fontFamilyReg,
            },
            classes: {
              button: "shopify-buy-button",
              product: "shopify-product",
            },
          },
          option: {
            styles: {
              select: backgroundColorPrimary,
            },
          },
          cart: {
            styles: {
              cart: backgroundColorPrimary,
              footer: backgroundColorPrimary,
              button: buttonRoundWhite,
              header: fontFamilyBold,
              title: fontFamilyBold,
              discount: fontFamilyReg,
              discountText: fontFamilyReg,
              subtotal: fontFamilyReg,
              subtotalText: fontFamilyReg,
              notice: fontFamilyReg,
              currency: fontFamilyReg,
              close: {
                color: "white",
                ":hover": {
                  color: "white",
                },
                ":focus": {
                  border: "none",
                  outline: "none",
                },
                ":focus-visible": {
                  border: "none",
                  outline: "none",
                },
              },
            },
            text: {
              button: "proceed to checkout →",
            },
          },
          lineItem: {
            styles: {
              title: fontFamilyReg,
              variantTitle: fontFamilyReg,
              quantity: fontFamilyReg,
              quantityIncrement: {
                "border-color": "white",
                ...fontFamilyReg,
              },
              quantityDecrement: {
                "border-color": "white",
                ...fontFamilyReg,
              },
              quantityInput: {
                "border-color": "white",
                ...fontFamilyReg,
              },
            },
          },
          toggle: {
            contents: {
              title: false,
            },
            classes: {
              iconPath: "shopify-cart-toggle-icon-path",
            },
            styles: {
              toggle: {
                ...backgroundColorAccent,
                ":hover": backgroundColorAccent,
              },
              iconPath: {
                fill: "white",
              },
            },
          },
        },
      })
      setSuccess(createSuccess)
    }
    createComponent()
  }, [item.productId])

  useEffect(() => {
    let listener
    const shopifyButton = container.current?.querySelector(".shopify-buy-button")
    if (success && shopifyButton) {
      listener = shopifyButton.addEventListener("click", (e) => {
        e.preventDefault()
        if (itemClicked) itemClicked(item)
      })
    }
    return () => {
      if (listener) window.removeEventListener(listener)
    }
  }, [success])

  return (
    <div ref={container}>
      {success ? (
        <ShopifyContainer id={item.productId} img={item.photoURL} key={item.title}>
          <CommerceItem image={item.photoURL} title={item.title} subtitle={item.price} />
        </ShopifyContainer>
      ) : (
        <CommerceItem
          url={item.itemUrl || ""}
          image={item.photoURL}
          title={item.title}
          subtitle={item.price}
        />
      )}
    </div>
  )
}

export default ShopifyItem
