import styled from "styled-components"
import { readableColor } from "polished"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { trackMandolinLogo } from "services/analytics"
import { mandolinTheme } from "theme"
import ImageLoader from "components/ImageLoader"
import { imageNanoServiceAccessor } from "services/utils"
import { ImageAssetSizeProps, ImageAssetSizeType, ImageAssetSizeValue } from "types/ImageAssets"

const LogoWrapper = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
  cursor: pointer;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  .cobrandMandolinLogo {
    display: block;
    max-height: 24px;
    width: 24px;
    @media (max-width: 600px) {
      max-width: 150px;
    }
  }
  .cobrandLogo {
    max-height: 32px;
  }
  .headerLogo {
    display: block;
    width: 152px;
    @media (max-width: 600px) {
      width: 124px;
    }
  }
`

const CobrandLogoWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  height: 40px;
  border-left: ${({ theme }) =>
    `1px solid ${theme.elements.headerBarText || readableColor(theme.elements.headerBar)}`};
`

const LogoContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
  p {
    padding-left: 20px;
    font-size: 30px;
    margin-bottom: 0;
  }
`

const onLogoClicked = () => {
  trackMandolinLogo()
}

const imageAssetSizeProps: ImageAssetSizeProps = {
  type: ImageAssetSizeType.ASSET_WIDTH,
  value: ImageAssetSizeValue.WIDTH_300,
}

const Logo = ({
  to,
  target,
  customHeaderLogo,
  brandLogo,
  lightBrandLogo,
  brandDestination,
  isCobranded,
  elements,
}: {
  to: any
  target?: string
  customHeaderLogo?: string
  brandLogo?: string
  lightBrandLogo?: string
  brandDestination?: string
  isCobranded?: boolean
  elements: any
}) => {
  const getLogoColor = (light?: string, dark?: string) => {
    if (readableColor(elements.headerBarText || elements.headerBar) === "#000") {
      return light
    }
    return dark
  }
  const logoSrc = isCobranded
    ? getLogoColor(mandolinTheme.logos.logoMarkWhite, mandolinTheme.logos.logoMark)
    : mandolinTheme.logos.logoLight

  const brandLogoSrc = customHeaderLogo || getLogoColor(lightBrandLogo, brandLogo)

  return (
    <LogoContainer>
      <LogoWrapper to={to} target={target} onClick={onLogoClicked}>
        <img
          alt="Mandolin logo"
          className={isCobranded ? "cobrandMandolinLogo" : "headerLogo"}
          src={imageNanoServiceAccessor({ imageAssetUrl: logoSrc, imageAssetSizeProps })}
          data-testid="headerLogo"
        />
      </LogoWrapper>
      {isCobranded && (
        <CobrandLogoWrapper>
          <LogoWrapper to={brandDestination} target={target}>
            <img
              alt="Brand logo"
              className={isCobranded ? "cobrandLogo" : "headerLogo"}
              src={imageNanoServiceAccessor({ imageAssetUrl: brandLogoSrc, imageAssetSizeProps })}
            />
          </LogoWrapper>
        </CobrandLogoWrapper>
      )}
    </LogoContainer>
  )
}

export default Logo
