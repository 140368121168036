import Typography from "components/Typography"
import styled from "styled-components"
import { Close } from "@styled-icons/material-sharp"
import { transparentize } from "polished"

type RaiseHandProps = {
  isHandRaisedAndActive: boolean
  disabled?: boolean
}

const EnterButton = styled.button<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 18px 8px 18px;
  border: none;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.elevation7 : theme.colors.elevation5};
  opacity: ${({ active }) => (!active ? "0.7" : "1")};
  border-radius: 16px;
  height: 32px;
  gap: 12px;
  margin-top: 23px;
  margin-bottom: 24px;
  &:hover {
    cursor: ${({ active }) => (active ? "pointer" : "default")};
    opacity: ${({ active }) => (active ? "0.9" : "0.7")};
  }
`

const ButtonContainer = styled.button<RaiseHandProps>`
  display: flex;
  border: none;
  flex-direction: ${({ isHandRaisedAndActive }) => (isHandRaisedAndActive ? "row" : "row-reverse")};
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isHandRaisedAndActive, theme }) =>
    isHandRaisedAndActive ? "#FFE073" : theme.colors.elevation2};
  border-radius: 24px;
  ${({ disabled }) => disabled && "opacity: 0.5"};
  padding: 10px;
  height: 48px;
  @media (max-height: 550px) {
    height: 38px;
  }
`

const EmojiContainer = styled.div<RaiseHandProps>`
  display: flex;
  background-color: ${({ isHandRaisedAndActive }) => (isHandRaisedAndActive ? "#0D1115" : "#fff")};
  border-radius: 20px;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  padding-right: ${({ isHandRaisedAndActive }) => (isHandRaisedAndActive ? "6" : "0")}px;
  ${({ isHandRaisedAndActive }) => (isHandRaisedAndActive ? "margin-left: 0" : "margin-right: 0")};
  @media (max-height: 550px) {
    width: 30px;
    height: 30px;
  }
`

const ButtonText = styled(Typography)<RaiseHandProps>`
  color: ${({ isHandRaisedAndActive }) => (isHandRaisedAndActive ? "#0D1115" : "#fff")};
  font-weight: 600;
  font-size: 12px;
  margin: 10px;
`
const OptionsText = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  ${({ theme }) => theme.fonts.TTNormsPro};
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  @media (max-width: 600px) {
    font-size: 9px;
  }
`
const CompleteModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`
const CompleteBorder = styled.div`
  width: 8px;
  background: linear-gradient(to bottom, #ffcfda50, #ff7d9c50, #b353ff50, #00a5ff50);
`
const ModalInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  justify-content: center;
  align-items: center;
`

const ModalCloseButton = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  border: none;
  background-color: transparent;
  cursor: pointer;
`

const ModalEmoji = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  margin-right: 8px;
`

const ModalText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`
const StyledClose = styled(Close)`
  color: #000;
  width: 20px;
  &:hover {
    opacity: 0.8;
  }
`
const OnMicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: ${({ theme }) => transparentize(0.6, theme.colors.elevation2)};
  border-radius: 8px;
  border: 2px solid green;
  margin-bottom: 8px;
`
const GuestsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(4, 24%);
  grid-template-rows: repeat(auto-fill, 80px);
  grid-auto-flow: row;
  align-items: flex-start;
`

const PartyAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-bottom: 4px;
`
const AvatarText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsPro}
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.12px;
`
const GuestCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export {
  EnterButton,
  ButtonContainer,
  EmojiContainer,
  ButtonText,
  OptionsText,
  CompleteModalContainer,
  CompleteBorder,
  ModalInfoContainer,
  ModalCloseButton,
  ModalEmoji,
  ModalText,
  StyledClose,
  OnMicModalContainer,
  GuestsContainer,
  PartyAvatar,
  AvatarText,
  GuestCell,
}
