import styled from "styled-components"
import { rgba } from "polished"

const ContentContainer = styled.div`
  max-width: 600px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  p {
    ${({ theme: { fonts } }) => fonts.TTNormsPro}
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.elevation6};
  }

  label {
    position: relative;
  }

  label.input:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    width: 14.17px;
    background: url("images/mail.png") center / contain no-repeat;
  }

  label.button:before {
    content: "";
    position: absolute;
    left: 146px;
    top: 0;
    bottom: 0;
    width: 14.17px;
    background: url("images/send.png") center / contain no-repeat;
    z-index: 2;
  }
`
const SuccessContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
  svg {
    margin-bottom: 20px;
  }
`
const Logo = styled.img`
  max-width: 141px;
  margin: 16px 0px;
  display: block;
`
const Headline = styled.div`
  font-size: 32px;
  line-height: 37px;
  letter-spacing: 0.02em;
  margin: 28px 0px;
  ${({ theme: { fonts } }) => fonts.TTNormsPro}
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.warning};
`

const EmailInput = styled.input`
  ${({ theme: { fonts } }) => fonts.TTNormsPro}
  color: ${({ theme }) => theme.colors.elevation6};
  background-color: ${({ theme }) => rgba(theme.elements.pageText, 0)};
  border: 2px solid ${({ theme }) => theme.colors.elevation5};
  font-size: 14px;
  line-height: 150%;
  box-sizing: border-box;
  border-radius: 31px;
  width: 366px;
  height: 53px;
  padding: 16px 45px;
`

export { ContentContainer, SuccessContainer, Logo, Headline, ErrorMessage, EmailInput }
