import React, { HTMLAttributes } from "react"
import StyledTypography from "./Typography.styled"

export type variantOptions =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "label"
  | "cap"
  | "middleCap"
  | "body"
  | "small"
  | "smallBold"
  | "smallItalic"
  | "smallCap"
  | "smallCapBold"
  | "normalTitle"

export type gutterOptions = "xs" | "sm" | "md" | "lg" | "xl"

interface Typography extends HTMLAttributes<any> {
  variant?: variantOptions
  gutterBottom?: gutterOptions
  tag?: keyof JSX.IntrinsicElements
  textColor?: string
  textAlign?: "left" | "center" | "right" | "justify"
}

const getTag = (tag?: string, variant: variantOptions = "body"): any => {
  if (tag) return tag
  switch (variant) {
    case "h1":
      return "h1"
    case "h2":
      return "h2"
    case "h3":
      return "h3"
    case "h4":
      return "h4"
    case "h5":
      return "h5"
    case "h6":
      return "h6"
    default:
      return "span"
  }
}

const Typography: React.FC<Typography> = ({ variant = "body", tag, ...rest }) => {
  return <StyledTypography as={getTag(tag, variant)} variant={variant} {...rest} />
}

export default Typography
