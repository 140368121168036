import styled from "styled-components"
import { motion } from "framer-motion"

const CarouselContainer = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
`

const CarouselWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 40px 0 20px;
  @media screen and (min-width: 1024px) {
    padding: 6vw 0 4vw;
  }
  @media screen and (min-width: 1024px) {
    padding: 4vw 0 3vw;
  }
  @media screen and (min-width: 1920px) {
    padding: 80px 0 40px;
  }
`

const CarouselColorSplashContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  div {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 250ms linear;
    -webkit-mask-image: linear-gradient(to top, transparent 25%, black 75%);
    mask-image: linear-gradient(to top, transparent 25%, black 75%);
    &.active {
      opacity: 1;
    }
  }
  div,
  canvas {
    user-select: none;
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
`

const CarouselARIALabel = styled.h3`
  visibility: hidden;
  margin: 0px;
  padding: 0px;
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`
const ClickCover = styled.div<{ visible?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: none;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    display: ${({ visible }) => (visible ? "block" : "none")};
  }
`

const CarouselItem = styled.li<{
  active: boolean
  animate: boolean
}>`
  display: flex;
  padding-left: 20px;
  min-width: 280px;
  max-width: 280px;
  flex-shrink: 0;
  scroll-snap-align: start;
  touch-action: manipulation;
  figure {
    width: 100%;
    height: 100%;
    @media screen and (min-width: 1024px) {
      h3 {
        font-size: 24px;
        --lh: calc(24px * 1.2);
      }
      figcaption {
        height: 120px;
        padding: 20px;
      }
    }
    @media screen and (min-width: 1440px) {
      h3 {
        font-size: 32px;
        --lh: calc(32px * 1.2);
      }
      figcaption {
        height: 162px;
        padding: 30px;
      }
    }
    @media (prefers-reduced-motion) {
      transition: none !important;
    }
  }
  @media screen and (min-width: 768px) {
    min-width: 42vw;
    max-width: 42vw;
    scroll-snap-align: center;
    padding: 0 2vw;
    position: relative;
    &:hover {
      figure {
        opacity: 0.5;
      }
    }
    figure {
      transform: scale(1) translateZ(0);
      ${({ animate }) =>
        animate
          ? `
      transition: opacity 600ms cubic-bezier(0.4, 0, 0.2, 1),
        transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
      `
          : ``}
      opacity: 0.35;
      ${({ active }) =>
        active
          ? `
      transform: scale(1.1) translateZ(0);
      opacity: 1 !important;
      &:hover {
        transform: scale(1.12) translateZ(0);
      }
      `
          : ``}
    }
  }
  @media screen and (min-width: 1024px) {
    min-width: 35vw;
    max-width: 35vw;
    padding: 0 2vw;
  }
  @media screen and (min-width: 1440px) {
    min-width: 30vw;
    max-width: 30vw;
    padding: 0 1.3vw;
  }
  @media screen and (min-width: 1920px) {
    min-width: 35vw;
    max-width: 35vw;
    padding: 0 1.5vw;
  }
  @media screen and (min-width: 2560px) {
    min-width: 38vw;
    max-width: 38vw;
    padding: 0 1.7vw;
  }
  @media screen and (max-device-width: 450px) and (orientation: portrait) {
    min-width: 260px !important;
    max-width: 260px !important;
    .featured-event-image-container {
      height: 280px !important;
    }
  }
`

const CarouselTrack = styled(motion.ul)<{
  trackWidth: number
}>`
  z-index: 2;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  .duplicate {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  &:after {
    content: "";
    display: block;
    position: relative;
    min-width: 20px;
    @media screen and (min-width: 768px) {
      min-width: 40px;
    }
  }
  @media screen and (min-width: 768px) {
    width: ${({ trackWidth }) => trackWidth}px;
    overflow-x: visible;
    overflow-y: visible;
    -webkit-overflow-scrolling: none;
    .duplicate {
      display: flex;
    }
  }
  @media (prefers-reduced-motion) {
    transition: none !important;
  }
  @media screen and (max-device-width: 450px) and (orientation: portrait),
    screen and (max-device-width: 767px) {
    transform: none !important;
  }
`

export {
  CarouselContainer,
  CarouselColorSplashContainer,
  CarouselItem,
  CarouselARIALabel,
  CarouselTrack,
  CarouselWrapper,
  ClickCover,
}
