import { Network } from "opensea-js"

const DevDemoConfig = {
  FIREBASE: {
    apiKey: "AIzaSyA4iML3jtL-5rJJA5897tA0pQjEe9TM3vc",
    authDomain: window.location.host,
    localhostAuthDomain: "mandolin-dev-demo.firebaseapp.com",
    databaseURL: "https://mandolin-dev-demo.firebaseio.com",
    projectId: "mandolin-dev-demo",
    storageBucket: "mandolin-dev-demo.appspot.com",
    messagingSenderId: "77891271427",
    appId: "1:77891271427:web:a24774ee77f3dcd8dd4736",
  },
  MUX: {
    muxDataEnvKey: "7daj2h2b9cap889ql72tvp46d",
  },
  OPTIMIZELY: {
    optimizelyKey: "qxr1xxLa4gnaxnGijrC4V",
  },
  ETHEREUM: {
    proxyApi: "https://mandolin-nft-proxy-staging.herokuapp.com",
    network: Network.Rinkeby,
    openseaApi: "https://rinkeby-api.opensea.io/api/v1",
    openseaApiKey: "0abe3a760e5240278f6bdeea7de5f7c8",
    provider: "wss://eth-rinkeby.alchemyapi.io/v2/lvrMD2fc5gdpNHgBWC_u59WUxY27gDr6",
    wethContractAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
  },
  STRAPI: {
    restURL: "https://content-dev-testing.mandolin.com",
  },
  API: {
    wsURL: "wss://ws.dev-demo.mndl.in",
    restURL: "https://api.mandolin.com",
  },
  MAGIC: {
    magicPublicKey: "pk_live_D33B4E388775C4ED",
    chainId: 80001,
  },
  ALCHEMY: {
    provider: "https://polygon-mumbai.g.alchemy.com/v2/HA75OM12AyYPEZ-3k520J2bGxU2Fp8kN",
  },
  IPFS: {
    gateway: "https://mandolin.mypinata.cloud/ipfs/",
  },
  MANDOLIN: {
    contractMap: "https://9364xtry97.execute-api.us-east-2.amazonaws.com/contract-map",
    openSeaAssetUrl: "https://testnets.opensea.io/assets/mumbai",
  },
  PRESENTER: {
    baseUrl: "https://present.dev.mandolin.com",
  },
}

export default DevDemoConfig
