import styled from "styled-components/macro"

const LoadingContainer = styled.div`
  background: #0D1115;
  height: 100%;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  z-index: 10;
  img {
    height: 70px;
    width: 70px;
    object-fit: contain;
    object-position: center;
  }
`

export { LoadingContainer }
