import styled, { css } from "styled-components/macro"
import { rgba, readableColor } from "polished"

const Title = styled.div`
  color: ${({ theme }) => theme.elements.chatHeaderText};
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 14px;
  letter-spacing: 0.01em;
`

const Container = styled.div`
  z-index: 1;
  background-color: ${({ theme }) => theme.elements.chatHeaderBackground};
`

const Header = styled.div`
  align-items: center;
  height: 50px;
  width: 100%;
  height: 40px;
  padding: 18px;
  display: none;

  @media (max-width: 1111px) {
    display: flex;
  }
`

const SubHeader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.elements.chatHeaderBackground};
  height: 50px;
  height: 40px;
  z-index: 1;
  display: flex;
`

const SubTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  cursor: pointer;
  color: ${({ theme }) => theme.elements.chatHeaderText};
  font-size: 13px;
  width: ${({ watchPartiesEnabled }) => (watchPartiesEnabled ? "50%" : "100%")};
  line-height: 38px;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-image-source: ${({ theme }) => theme.colors.secondaryGradient};
  border-image-slice: 2;
  border-top-style: none;
  border-top-width: 0;
  border-right-style: none;
  border-right-width: 0;
  ${({ theme, selected }) => selected ? `
    border-bottom-style: solid;
    border-bottom-width: 2px;
  ` : `
    border: none;
    border-bottom: 2px solid ${rgba(theme.elements.chatHeaderText, 0.1)};
  `};
  border-left-style: none;
  border-left-width: 0;
  ${({ theme }) => theme.elements.chatHeaderAccent && css`
    border: none;
    border-bottom: ${({ selected, theme }) => selected ? `2px solid ${theme.elements.chatHeaderAccent}` : `none`};
  `}
`

const BetaTag = styled.div`
  background-image: ${({ theme }) => theme.colors.secondaryGradient};
  color: ${({ theme }) => readableColor(theme.colors.secondarySolid)};
  ${({ theme }) => theme.elements.chatHeaderAccent && css`
    background: none;
    background-color: ${({ theme }) => theme.elements.chatHeaderAccent};
    color: ${({ theme }) => readableColor(theme.elements.chatHeaderAccent)};
  `}
  border-radius: 19px;
  display: inline;
  padding: 3px 8px;
  line-height: 8px;
  height: 8px;
  font-size: 8px;
  margin-left: 5px;
`

const CloseIcon = styled.svg`
  height: 20px;
  width: 20px;
  fill: ${({ theme }) => theme.elements.chatHeaderText};
  cursor: pointer;
  display: none;

  @media (max-width: 1111px) {
    display: block;
    margin-left: auto;
  }
`
export { BetaTag, Container, SubHeader, Header, Title, SubTitle, CloseIcon }
