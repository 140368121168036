/* eslint-disable no-await-in-loop */ // iterations of the loop are not independent of each-other
import { DefaultApi, NftTokenTransactionDTO } from "@mandolin-dev/ts-sdk"
import axios from "axios"
import Config from "config/config"
import { maticWeb3 } from "hooks/useMagic"

export const assetURL = (uri: string) => {
  const [protocol, urn] = uri.split("//")
  return protocol === "ipfs:" ? Config.IPFS.gateway + urn : uri
}

interface PinataAsset {
  attributes: {
    trait_type: string
    value: string
  }[]
  description: string
  asset: string
  preview?: string
  author: string
  date: string
  name: string
  edition: number
  totalSupply: number
  number: number
}

export interface MandolinAsset extends PinataAsset {
  tokenId: number
  contract: {
    address: string
    name: string
    symbol: string
    owner: string
  }
}

export const getAssetsFromPolygon = async (address: string) => {
  const tokens: MandolinAsset[] = []
  try {
    const { data: contractMap } = await axios.get<{ [key: string]: any }>(
      Config.MANDOLIN.contractMap,
    )
    // TODO: add async/multi-thread process
    // eslint-disable-next-line no-restricted-syntax
    for (const [contractAddress, abi] of Object.entries(contractMap)) {
      const contract = new maticWeb3.eth.Contract(abi, contractAddress)

      const balance = await contract.methods.balanceOf(address).call()

      for (let tokenIndex = 0; tokenIndex < balance; tokenIndex++) {
        const tokenId = await contract.methods.tokenOfOwnerByIndex(address, tokenIndex).call()
        const tokenURI = await contract.methods.tokenURI(tokenId).call()
        const contractName = await contract.methods.name().call()
        const contractSymbol = await contract.methods.symbol().call()
        try {
          const { data } = await axios.get<PinataAsset>(assetURL(tokenURI))
          tokens.push({
            tokenId,
            contract: {
              address: contractAddress,
              name: contractName,
              symbol: contractSymbol,
              owner: address,
            },
            ...data,
          })
        } catch (error) {
          console.error("Unable to read token meta ", error)
        }
      }
    }
  } catch (error) {
    console.error("Unable to get tokens info ", error)
  }
  return tokens
}

/**
 * The from property needs to be an address from the (Magic) web3.eth.accounts.wallet.
 * It will then sign locally using the private key (provided by Magic) of that account, and send the transaction via web3.eth.sendSignedTransaction().
 */
export const transferToken = async (
  contractAddress: string,
  from: string,
  to: string,
  tokenId: number,
) => {
  const { data: contractMap } = await axios.get<{ [key: string]: any }>(Config.MANDOLIN.contractMap)
  const contract = new maticWeb3.eth.Contract(contractMap[contractAddress], contractAddress)

  const transactionReceipt = await contract.methods
    .safeTransferFrom(from, to, tokenId)
    .send({ from })

  console.log(`The hash of your transaction is: ${transactionReceipt.transactionHash}!`)
  return transactionReceipt
}

export const getNftToken = async (restClient: DefaultApi, transaction: NftTokenTransactionDTO) =>
  restClient.getNftToken({ id: transaction.nftToken })

export const getTransactionsAndTokens = async (restClient: DefaultApi) => {
  const { data: tokenTransactions } = await restClient.listNftTokenTransaction({
    includeMinted: false,
  })

  const transactionsMap = tokenTransactions.data.map((transaction: any) =>
    getNftToken(restClient, transaction),
  )

  const results = await Promise.all(transactionsMap)

  const newTokens = results.flatMap((result: any) => result.data)
  return {
    transactions: tokenTransactions.data,
    tokens: newTokens,
  }
}
