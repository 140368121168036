import styled from "styled-components/macro"

const Header = styled.div`
  font-size: 32px;
  margin-bottom: 32px;
`

const Subheader = styled.div`
  font-size: 24px;
  text-align: left;
  font-family: "TTNormsPro-Normal";
`

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: #181a1d;
  padding: 32px;
  img {
    width: 260px;
  }
`

const Body = styled.div`
  display: flex;
  font-family: "TTNormsPro-DemiBold";
  flex-direction: column;
  color: #ffffff;
  margin: 88px auto 0 auto;
  width: 664px; ;
`

export { Container, Header, Subheader, Body }
