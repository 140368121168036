import styled from "styled-components/macro"

/* eslint-disable import/prefer-default-export */
export const Input = styled.input`
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #dbdbdb;
  padding: 0 0.75rem;
  height: 3rem;
  position: absolute;
  top: 0;
  left: 0;
`

export const MinimumBid = styled.small`
  color: #006efb !important;
  cursor: pointer;
  margin-top: 3rem;
`

export const EthLabel = styled.label`
  position: absolute;
  font-size: 0.8em;
  top: 1rem;
  right: 1.625rem;
  color: #a4a9ac;
`
export const Form = styled.form`
  position: relative;
`
