import styled from "styled-components"
import { rgba, darken, readableColor } from "polished"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 80px;
  margin-top: 40px;
  @media (max-width: 740px) {
    flex-direction: column;
    margin-top: 0px;
  }
`

const BodyContainer = styled.div`
  color: ${({ theme }) => theme.elements.pageText};
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding-left: 60px;
  width: 100%;
  @media (max-width: 740px) {
    padding: 0 2rem;
    padding-top: 2rem;
    margin: auto;
  }
`

const BodyHeader = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 36px;
  letter-spacing: 0px;
  margin-bottom: 24px;
`

const BodySubheader = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  font-size: 24px;
  letter-spacing: 0px;
`

const BodyField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

const BodyInput = styled.input`
  background-color: ${({ theme }) => rgba(theme.elements.pageText, 0.1)};
  color: ${({ theme }) => theme.elements.pageText};
  ::placeholder {
    color: ${({ theme }) => rgba(theme.elements.pageText, 0.6)};
  }
  border-radius: 0;
  border: none;
  height: 48px;
  outline: none;
  padding: 16px;
  width: 100%;
  &:focus,
  &:active {
    box-shadow: 0 0 0 3px #878888;
    border-radius: 3px;
  }
`

const BodyLabel = styled.label`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  font-size: 14px;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
`

const Divider = styled.div`
  background: rgba(255, 255, 255, 0.2);
  height: 3px;
  margin-bottom: 48px;
  margin-top: 48px;
  width: 100%;
`

const BodyText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: 14px;
  letter-spacing: 0.01em;
  margin-bottom: 24px;
  max-width: 472px;
`

const FieldSubtext = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: 14px;
  letter-spacing: 0.01em;
  margin-top: 8px;
`

export {
  BodyContainer,
  BodyField,
  BodyHeader,
  BodyInput,
  BodyLabel,
  BodySubheader,
  BodyText,
  Container,
  Divider,
  FieldSubtext,
}
