import React from "react"
import Typography from "components/Typography"
import styled from "styled-components/macro"

const Container = styled.div`
  margin: 0 auto;
  max-width: 688px;
  padding: 0 2rem 2rem 2rem;
  text-align: center;
  a {
    color: ${({ theme }) => theme.elements.watchPageAccent};
    text-decoration: underline;
    &:hover {
      opacity: 0.8;
    }
  }
`
const NFTDisclaimer = () => (
  <Container>
    <Typography gutterBottom="sm" variant="small">
      All transactions and activities are facilitated and run by OpenSea (owned and operated by
      Ozone Networks, Inc. d/b/a OpenSea), which is a third-party operating independently of
      Mandolin Software, Inc. By participating in this sale and auction, you agree that you are
      governed by the{" "}
      <a href="https://opensea.io/tos" rel="noopener noreferrer" target="_blank">
        Terms Of Service
      </a>{" "}
      and{" "}
      <a href="https://opensea.io/privacy" rel="noopener noreferrer" target="_blank">
        Privacy Policy
      </a>{" "}
      for OpenSea.
    </Typography>
  </Container>
)

export default NFTDisclaimer
