import { invert, lighten, saturate, transparentize } from "polished"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components/macro"
import { sectionContainer } from "theme/spacing"
import { atMediaMin } from "theme/utils"

import { ClaimCodeModal, ClaimCodeButton } from "../../components/ClaimCodes"

export const Container = styled.main<{ gradientColor?: string }>`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  ${atMediaMin("lg")} {
    flex-direction: row-reverse;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 0;
    background: ${({ gradientColor, theme }) =>
      css`linear-gradient(0deg,${transparentize(
        1,
        theme.elements.pageBackground,
      )} 30%, ${transparentize(
        0.9,
        gradientColor || lighten(0.2, saturate(0.5, theme.elements.pageBackground)),
      )})`};
    height: 60vh;
  }
`
export const ReplaySidebarScroller = styled.ul`
  display: flex;
  list-style: none;
  position: relative;
  padding: 0 0 16px;
  margin: 0 -16px;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scrollbar-width: none;
  overflow-x: auto;
  ${atMediaMin("lg")} {
    display: block;
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
  }
`
export const ReplaySidebarScrollerItem = styled.li`
  margin: 0 16px;
  flex: 0 0 250px;

  ${atMediaMin("lg")} {
    width: 100%;
    margin: 0 0 36px;
  }
`

export const ReplaySidebar = styled.div`
  background: rgba(255, 255, 255, 0.1);
  max-width: 100%;
  margin: 0;
  padding: 16px 16px 0px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  ${atMediaMin("lg")} {
    padding: 36px;
    position: sticky;
    top: 0;
    height: 100vh;
    top: 72px;
    min-width: 332px;
    max-width: 332px;
    overflow: auto;
  }
`

export const TicketContainer = styled.div`
  max-width: 100%;
  min-height: 100%;
  margin: 0;
  position: relative;
  z-index: 1;
  ${atMediaMin("lg")} {
    flex: 1;
  }
`
export const FeaturedTicketContainer = styled.li`
  ${atMediaMin("md")} {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    display: flex !important;
    align-items: center !important;
    margin: 0px !important;
  }
`

export const FeaturedTicketContent = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around;
  ${atMediaMin("md")} {
    padding-left: 30px;
    justify-content: flex-start;
  }
  ${atMediaMin("xl")} {
    padding-left: 40px;
    justify-content: flex-start;
  }
  .title {
    flex: 0 0 100%;
    text-align: center;
    margin-bottom: 8px;
    ${atMediaMin("md")} {
      text-align: left;
    }
  }
`

export const TabContainerTabs = styled.div`
  ${sectionContainer};
  display: flex;
  justify-content: space-between;
  top: 72px;
  z-index: 10;
  ${atMediaMin("md")} {
    justify-content: flex-start;
    margin: 24px 0;
  }
  ${atMediaMin("lg")} {
    margin: 40px 0;
  }
  ${atMediaMin(1200)} {
    margin: 60px 0;
  }
`

export const ButtonContainer = styled.div`
  flex: 0 0 100%;
  margin-top: 16px;
  ${atMediaMin("md")} {
    display: flex;
    justify-content: flex-start;
  }
  a {
    margin: 8px auto 0;
    ${atMediaMin("md")} {
      margin: 0;
    }
  }
  svg {
    width: 24px;
    margin-right: 16px;
  }
`

export const TabContent = styled.ul<{ isActive: boolean }>`
  ${sectionContainer};
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 100%;
  margin: 0;
  list-style: none;
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  flex-wrap: wrap;
  justify-content: flex-start;
  ${atMediaMin("md")} {
    align-items: flex-start;
    padding: 24px 20px 40px;
  }
  ${atMediaMin(1200)} {
    padding: 30px var(--horizontal-margin);
  }
  li {
    display: block;
    margin: 0 auto 20px;
    padding: 0;
    max-width: 240px;
    min-width: 240px;
    ${atMediaMin("md")} {
      margin: 0 24px 20px 0;
    }
  }
  .next-sectionlabel {
    text-align: center;
    margin: 40px auto 20px;
    width: 100%;
    max-width: 100%;
    ${atMediaMin("md")} {
      text-align: left;
      flex: 0 0 100%;
      margin: 60px auto 30px;
    }
  }
`

export const Tab = styled.button<{ isActive: boolean }>`
  appearance: none;
  background: transparent;
  padding: 16px 16px 8px;
  margin: 0;
  border: none;
  flex: 1;
  text-align: center;
  position: relative;
  ${atMediaMin("md")} {
    flex: 0;
    white-space: nowrap;
    &:first-of-type {
      margin-left: -16px;
    }
  }
  ${({ isActive }) =>
    isActive
      ? css`
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            width: 85px;
            left: 50%;
            transform: translate(-50%);
            height: 2px;
            background-color: ${({ theme }) => invert(theme.elements.pageBackground)};
            ${atMediaMin("md")} {
              left: 20px;
              right: 20px;
              transform: none;
              width: auto;
            }
          }
        `
      : ``}
`

export const TabContainer = styled.div`
  min-height: 100%;
`
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.elements.buttonText} !important;
  fill: ${({ theme }) => theme.elements.buttonText} !important;
  background-color: ${({ theme }) => theme.elements.buttonBackground};
  align-self: flex-start;
  justify-self: flex-start;
  padding: 8px 16px 8px 8px;
  border-radius: 100px;
  text-decoration: none !important;
  &:hover {
    opacity: 0.85;
  }
  svg {
    width: 24px;
    margin-right: 16px;
  }
`
export const BrowseButton = styled.button`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.elements.buttonBackground};
  border: none;
  color: ${({ theme }) => theme.elements.buttonText};
  border-radius: 100px;
  margin-right: 16px;
  &:hover {
    opacity: 0.7;
  }
`
export const ContactButton = styled.button`
  padding: 5px;
  background: none;
  border: none;
  color: #56cdf2;
  &:hover {
    opacity: 0.7;
  }
`

export const NoTicketTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-top: 16px;
`
export const NoTicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const TicketsClaimCodeModal = styled(ClaimCodeModal)`
  &${ClaimCodeButton} {
    position: static;
    width: 90%;
    margin: 20px auto 0 auto;

    ${atMediaMin("md")} {
      position: absolute;
      top: 40px;
      right: 100px;
      width: 180px;
    }
  }
`
