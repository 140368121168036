import styled from "styled-components/macro";
import { Cancel } from "@styled-icons/material-sharp";

const ErrorIcon = styled(Cancel)`
  width: 80px;
  height: 80px;
  fill: #e05d5d;
`;
const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
  text-align: center;
`;

const Status = styled.div`
  margin-top: 2rem;
  h5 {
    ${({ theme }) => theme.fonts.TTNormsProExtraBold};
    color: white;
    font-size: 24px;
  }
`;

export { Container, ErrorIcon, Status };
