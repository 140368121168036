import Typography from "components/Typography"
import dayjs from "dayjs"
import { useChatGeneral } from "hooks/useChat"
import React, { useState, useEffect } from "react"
import * as relativeTime from "dayjs/plugin/relativeTime"
import { AnimatePresence } from "framer-motion"
import Reactions from "components/Watch/Reactions"
import { ShowDTO } from "@mandolin-dev/ts-sdk"
import { useRestClient } from "hooks"

import {
  ChatContent,
  ChatHeader,
  ChatItem,
  ReactionContainer,
  ShowVibeContainer,
  VibeAvatar,
} from "../Vibes.styled"

dayjs.extend(relativeTime.default)

type ShowVibeType = {
  id: string
  user: any
}

const ShowVibe = ({ id, user }: ShowVibeType) => {
  const [show, setShow] = useState<ShowDTO | null>(null)
  const chatContext: any = useChatGeneral(user, false, id)
  const { restClient } = useRestClient()
  useEffect(() => {
    if (id) {
      restClient.getShow({ showId: id }).then((res) => {
        setShow(res.data)
      })
    }
  }, [id])
  return (
    <ShowVibeContainer>
      <AnimatePresence>
        {chatContext.messages.slice(-50).map((msg: any) => (
          <ChatItem
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            key={msg.state.sid}
          >
            <VibeAvatar bg={chatContext.participants[msg.state.author]?.photoUrl} />
            <ChatHeader>
              <Typography className="name">
                {chatContext.participants[msg.state.author]?.name}
              </Typography>
              <Typography className="time">{dayjs(msg.state?.timestamp).fromNow()}</Typography>
            </ChatHeader>
            <ChatContent>
              <Typography>{msg.state?.body}</Typography>
            </ChatContent>
          </ChatItem>
        ))}
      </AnimatePresence>
      {show ? (
        <ReactionContainer>
          <Reactions
            showId={id}
            customReactions={show.customReactions}
            hideControls
            customOffsetX={[0, 180]}
            alwaysFocused
          />
        </ReactionContainer>
      ) : null}
    </ShowVibeContainer>
  )
}

export default ShowVibe
