import React, { FC } from "react"
import moment from "moment"

import TicketBadge from "components/TicketBadge"
import AnimatedCardContainer from "components/AnimatedCardContainer"
import Skeleton from "components/Skeleton"
import ImageLoader from "components/ImageLoader"

import { getSubdomain, imageNanoServiceAccessor } from "services/utils"

import { TileBody, Time, Name, ImageContainer } from "./styled"
import { ImageAssetSizeProps, ImageAssetSizeType, ImageAssetSizeValue } from "types/ImageAssets"

interface EventTileProps {
  navigateToWatchPage?: boolean
  disableCobrandRedirect?: boolean
  event?: {
    dateTime?: string | Date
    cobrands?: string[]
    id: string
    isTicketPurchased?: boolean
    headerImg?: string
    name?: string
    toProps?: string
  }
  loading?: boolean
  disabled?: boolean
  trackClickEvent?: () => void
}

const EventTile: FC<EventTileProps> = ({
  event,
  loading,
  disabled,
  navigateToWatchPage = false,
  disableCobrandRedirect = false,
  trackClickEvent = () => null,
}) => {
  let contentPage = "events"
  if (navigateToWatchPage) {
    contentPage = "watch"
  }
  const time = moment(event?.dateTime).format("ddd, MMM DD, YYYY [@] h:mm a")
  let toProps: any = event?.toProps || `/${contentPage}/${event?.id}`
  const needsToRedirect =
    event?.cobrands?.length && getSubdomain() !== event?.cobrands[0] && !disableCobrandRedirect
  if (needsToRedirect) {
    toProps = {
      pathname: `https://${event!.cobrands![0]}.mandolin.com/${contentPage}/${event?.id}`,
    }
  }

  const imageAssetSizeProps: ImageAssetSizeProps = {
    type: ImageAssetSizeType.ASSET_WIDTH,
    value: ImageAssetSizeValue.WIDTH_650,
  }

  return (
    <AnimatedCardContainer
      to={toProps}
      target={needsToRedirect ? "_blank" : undefined}
      loading={loading}
      onClick={trackClickEvent}
    >
      <TicketBadge display={event?.isTicketPurchased}>
        <Skeleton display={loading}>
          <ImageContainer isDisabled={disabled}>
            {event?.headerImg && (
              <ImageLoader
                alt={`Header image for ${event?.name}`}
                loading="lazy"
                src={imageNanoServiceAccessor({
                  imageAssetUrl: event?.headerImg,
                  imageAssetSizeProps,
                })}
              />
            )}
          </ImageContainer>
        </Skeleton>
        <Skeleton display={loading}>
          <TileBody>
            {event?.dateTime ? <Time>{time}</Time> : null}
            <Name>{event?.name}</Name>
          </TileBody>
        </Skeleton>
      </TicketBadge>
    </AnimatedCardContainer>
  )
}

export default EventTile
