/* eslint-disable no-param-reassign  */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { getSubdomain } from "services/utils"

interface FeaturesState {
  isAeg: boolean
  showAegModal: boolean
  isBoondock: boolean
  isUproar: boolean
}

const initialState: FeaturesState = {
  isAeg:
    window.location.hostname === "goldenvoice.mandolin.com" ||
    window.location.hostname === "aeg.mandolin.com" ||
    window.location.hostname === "live.goldenvoice.com" ||
    window.location.hostname === "stream.aegpresents.com",
  showAegModal: false,
  isBoondock: getSubdomain() === "boondockbuild",
  isUproar: getSubdomain() === "uproar",
}

const features = createSlice({
  name: "features",
  initialState,
  reducers: {
    setIsAeg(state: FeaturesState, action: PayloadAction<boolean>) {
      state.isAeg = action.payload
    },
    setShowAegModal(state: FeaturesState, action: PayloadAction<boolean>) {
      state.showAegModal = action.payload
    },
  },
})

const { actions, reducer } = features

export const { setIsAeg, setShowAegModal } = actions

export default reducer
