import styled from "styled-components/macro"

export default styled.div`
  background-image: url(/images/border_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: ${({ bottom }) => (bottom ? "0" : "unset")};
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: ${({ top }) => (top ? "0" : "unset")};
  background: ${({ theme }) => theme.elements.headerBottomLine};
`
