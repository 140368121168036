import Typography from "components/Typography"
import styled from "styled-components"

export const AddressWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.elevation8};
  color: ${({ theme }) => theme.colors.elevation1};
  border-radius: 4px;
  gap: 1rem;
  min-width: 0;

  & + div {
    margin-top: 1rem;
  }
`

export const AddressInfo = styled.div`
  color: "black";
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const WalletBodyText = styled(Typography).attrs({
  variant: "label",
  gutterBottom: "xs",
})`
  display: block;
  color: ${({ theme }) => theme.colors.elevation1};
  white-space: pre-line;
`
export const WalletAddressText = styled(Typography).attrs({
  variant: "small",
})`
  color: ${({ theme }) => theme.colors.elevation1};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
  display: block;
`

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const WalletIcon = styled.div`
  img {
    width: 16px;
  }
`
