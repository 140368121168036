import styled from "styled-components/macro"
import { toRem } from "theme"

const Container = styled.div`
  position: relative;
`

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${toRem(48)};
  height: ${toRem(40)};
  border-radius: 0 16px;
  svg {
    color: #000;
    width: 24px;
    height: 24px;
    display: block;
  }
`

export { Container, Badge }
