import React, { FC } from "react"
import { SurveyQuestionTextarea } from "../CobrandSurvey.styled"
import { SurveyQuestionProps } from "../CobrandSurvey.types"

const MultiLineTextQuestion: FC<SurveyQuestionProps> = ({ question, value, setSurveyResponse }) => (
  <SurveyQuestionTextarea
    value={value as string}
    onChange={(e) => {
      const value = e.target.value
      setSurveyResponse((curr: any) => ({
        ...curr,
        [question.id]: value,
      }))
    }}
  />
)

export default MultiLineTextQuestion
