import { transparentize } from "polished"
import styled from "styled-components/macro"
import { atMediaMin } from "theme/utils"

const BannerContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  ${atMediaMin("sm")} {
    flex-direction: row;
  }
`
const BannerImage = styled.div<{ image: string }>`
  width: 100%;
  height: 50vw;
  background-image: ${({ image }) => `url(${image})`};
  background-position: top center;
  background-size: cover;
  background-color: #aaa;

  ${atMediaMin("sm")} {
    width: 30%;
    height: auto;
    max-width: 420px;
  }
`
const BannerInfoContainer = styled.div<{ color: string }>`
  width: 100%;
  height: 50%;
  background-color: ${({ color }) => transparentize(0.8, color)};
  padding: 20px 30px;
  ${atMediaMin("sm")} {
    height: 100%;
    padding: 20px 20px;
  }
`

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  margin-bottom: 10px;
`
const DateDivider = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 1px;
  height: 50%;
  margin: 0 15px;
`

const Cta = styled.button<{ color: string }>`
  display: flex;
  width: 100%;
  max-width: 400px;
  background-color: ${({ color }) => color};
  height: 48px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  outline: none;
  border: none;
  ${atMediaMin("sm")} {
    max-width: 180px;
  }
`
const BannerTitle = styled.div<{ color: string }>`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: ${({ color }) => color};
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 16px;
  ${atMediaMin("md")} {
    font-size: 34px;
  }
  ${atMediaMin("xl")} {
    font-size: 44px;
  }
`

const CtaIcon = styled.img`
  height: 12px;
  margin-right: 8px;
`
export {
  BannerContainer,
  BannerImage,
  BannerInfoContainer,
  DateDivider,
  Cta,
  BannerTitle,
  CtaIcon,
  DateContainer,
}
