import {
  defaultBreakpoints as breakpoints,
  atMediaMin,
  atMediaMax,
  atMediaBetween,
  toEm,
  toRem,
} from "./utils"
import { fontFamilies as fonts, fallbackFonts } from "./typography"
import GlobalStyle from "./global"

const colors = {
  confirmation: "#2FFF82",
  error: "#E7392B",
  warning: "#FFCF25",
  elevation1: "#0D1115",
  elevation2: "#161B20",
  elevation3: "#303537",
  elevation4: "#565B60",
  elevation5: "#A4A9AC",
  elevation6: "#CFD2D4",
  elevation7: "#EFF2F3",
  elevation8: "#FFFFFF",
  primarySolid: "#EA0254",
  primaryGradient: "linear-gradient(270deg, #EA3274 0%, #EA0254 50%, #EA303B 100%)",
  secondarySolid: "#02D9F6",
  secondaryGradient: "linear-gradient(to right, #01B4F9, #02D9F6, #8AF1FF)",
  green: "#32C759",
}

const elements = {
  chatText: "#ffffff",
  chatHeaderText: "#ffffff",
  footerText: "#ffffff",
  vipColor: "#ffffff",
  emailText: "#ffffff",
  pageText: "#ffffff",
  buttonBackground: "#ffffff",
  buttonText: "#0d1115",
  emailButtonBackground: "#ffffff",
  emailButtonText: "#0d1115",
  mobileButtonBackground: "#ffffff",
  mobileButtonText: "#0d1115",
  headerBottomLine: "#ea0254",
  pageTextAccent: "#ea0254",
  watchPageAccent: "#ea0254",
  chatHeaderAccent: "#ea0254",
  emailTextAccent: "#ea0254",
  mobileTextAccent: "#02d9f6",
  mobileIcon: "#ea0254",
  headerBar: "#0d1115",
  headerBarText: "#ffffff",
  pageBackground: "#0d1115",
  chatHeaderBackground: "#0d1115",
  chatBackground: "#1e242d",
  footerBackground: "#1e242d",
  vipBackground: "#ea0254",
  emailBackground: "#0d1115",
  ticketHeader: "#ea0254",
  authBackground: "#FFFFFF",
  mobileButton: "#5d90c1",
  topNavBackground: "#5d90c1",
  vipFooterBackground: "#1b1f24",
}

const defaults = {
  cubicBezier: "cubic-bezier(0.33, 1, 0.68, 1)",
}

const mandolinTheme = {
  breakpoints,
  colors,
  elements,
  logos: {
    avatar: "/images/avatar-default.svg",
    hero: "/images/hero-default.png",
    logo: "/logo/PNG/Large/logo-white@3x.png",
    logoDark: "/logo/SVG/logo-black.svg",
    logoBlack: "/logo/SVG/logo-black.svg",
    logoLight: "/logo/SVG/logo-white.svg",
    logoWhite: "/logo/PNG/Large/logo-white@3x.png",
    logoMark: "/logo/SVG/mark.svg",
    logoMarkWhite: "/logo/PNG/Large/mark-white@3x.png",
    brandLogo: "/logo/SVG/logo-white.svg",
    ticketLogo: "/logo/SVG/logo-white.svg",
    videoLogo: "/logo/SVG/logo-white.svg",
    authLogo: "/logo/SVG/mark.svg",
  },
  fonts,
  defaults,
}

const ChatTheme = {
  colors: {
    primaryText: "#A4A9AC",
    secondaryText: "#394249",
    primaryHeaderText: "#CFD2D4",
    secondaryHeaderText: "#394249",
    background: "#1E242D",
    border: "#FFFFFF",
    highlight: "rgba(57, 66, 73, 1)",
    alertBackground: "#EAE9E6",
    buttonBackground: "#FFFFFF",
    error: "#FF7575",
    overlay: "rgba(0, 0, 0, 0.8);",
    icons: "#FFFFFF",
    inputText: "#FFFFFF",
    inputBorder: "#394249",
  },
  fonts: {
    light: `
    font-family: "TTNormsPro-Light", ${fallbackFonts};
  `,
    regular: `
    font-family: "TTNormsPro-Regular", ${fallbackFonts};
  `,
    normal: `
    font-family: "TTNormsPro-Normal", ${fallbackFonts};
  `,
    medium: `
    font-family: "TTNormsPro-Medium", ${fallbackFonts};
  `,
    demiBold: `
    font-family: "TTNormsPro-DemiBold", ${fallbackFonts};
  `,
    bold: `
    font-family: "TTNormsPro-Bold", ${fallbackFonts};
  `,
    extraBold: `
    font-family: "TTNormsPro-ExtraBold", ${fallbackFonts};
  `,
    black: `
    font-family: "TTNormsPro-Black", ${fallbackFonts};
  `,
    extraBlack: `
    font-family: "TTNormsPro-ExtraBlack", ${fallbackFonts};
  `,
  },
  scale: {
    fonts: 1,
    images: 1,
  },
}

export {
  GlobalStyle,
  mandolinTheme,
  atMediaMin,
  atMediaMax,
  atMediaBetween,
  toEm,
  toRem,
  ChatTheme,
}
