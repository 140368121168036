import styled from "styled-components"

type CriteriaBadgeProps = {
  active: boolean
}

type CriteriaTextProps = {
  fontColor: string
}

const Container = styled.div`
  padding: 10px;
`

const ErrorText = styled.aside`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: #c5162e;
  font-size: 12px;
`

const CriteriaContainer = styled.div`
  padding: 10px;
`

const CriteriaRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 5px;
`

const CriteriaBadge = styled.div<CriteriaBadgeProps>`
  border: 1.5px solid;
  border-color: ${({ active }) => (active ? "#38cc67" : "#b4b9bb")};
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: ${({ active }) => (active ? "#38cc67" : "transparent")};
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 5px;
`

const CriteriaText = styled.div<CriteriaTextProps>`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ fontColor }) => fontColor};
  font-size: 12px;
`

export { Container, ErrorText, CriteriaContainer, CriteriaRow, CriteriaBadge, CriteriaText }
