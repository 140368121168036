import styled from "styled-components/macro"
import { transparentize } from "polished"
import { toRem } from "theme/utils"

const Container = styled.div`
  flex: 1;
`
const Participant = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 200px;
  position: absolute;
  bottom: 0px;
  right: 0px;
`
const EntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
`
const CamContainer = styled.div`
  z-index: 10;
  position: absolute;
  bottom: 24px;
  right: 24px;
  width: 160px;
  border-radius: 5px;
  overflow: hidden;
`

const CarouselContainer = styled.div`
  z-index: 300;
  border-radius: 10px;
  width: 450px;
  height: 340px;
`

const CarouselArrowContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`
const CarouselArrow = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 17px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
`
const CarouselCard = styled.div`
  display: flex;
  height: 100%;
  width: 80%;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0px 10px;
`
const ReadyCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 325px;
  background-color: ${({ theme }) => theme.colors.elevation7};
  border-radius: 10px;
  align-items: center;
  padding: 20px;
  margin-top: 60px;
`
const CompleteCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 220px;
  width: 460px;
  background-color: ${({ theme }) => theme.colors.elevation7};
  border-radius: 10px;
  align-items: center;
  padding: 30px;
`
const CompleteCardText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold}
  letter-spacing: -0.5px;
  line-height: ${toRem(16)};
  font-size: ${toRem(16)};
  color: ${({ theme }) => theme.colors.elevation1};
  padding: 10px 20px;
`

const ReadyCardTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBlack}
  letter-spacing: -0.5px;
  font-size: ${toRem(44)};
  color: ${({ theme }) => theme.colors.elevation1};
  z-index: 1;
  text-align: center;
`
const ReadyCardText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold}
  letter-spacing: -0.5px;
  line-height: ${toRem(18)};
  font-size: ${toRem(18)};
  color: ${({ theme }) => theme.colors.elevation1};
  z-index: 1;
  padding: 0px 40px;
  text-align: center;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 340px;
  width: 350px;
  background-color: ${({ theme }) => theme.colors.elevation7};
  border-radius: 10px;
  align-items: center;
  padding: 40px;
`
const CheckBoxRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px;
`
const CheckBoxContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 20px 0px;
`
const CardTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold}
  letter-spacing: -0.5px;
  font-size: ${toRem(34)};
  color: ${({ theme }) => theme.colors.elevation1};
  z-index: 1;
`
const CardText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProMedium}
  letter-spacing: -0.5px;
  font-size: ${toRem(18)};
  color: ${({ theme }) => theme.colors.elevation1};
  z-index: 1;
`
const ArtistVideoContainer = styled.div<{ count: number }>`
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  flex-direction: ${({ count }) => (count < 4 ? "row" : "column")};
  flex-wrap: wrap;
`

const AlertBanner = styled.div`
  display: flex;
  background-color: ${({ theme }) => transparentize(0.6, theme.colors.elevation2)};
  flex-direction: row;
  position: absolute;
  bottom: 20px;
  width: 200px;
  height: 30px;
  border-radius: 15px;
  z-index: 26;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
const AlertText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProLight};
  color: ${({ theme }) => theme.colors.elevation8};
  font-size: 12px;
`

const HostList = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  bottom: 16px;
  left: 16px;
  flex-direction: column;
  width: 160px;
  justify-content: flex-end;
  align-items: flex-start;
`
const Flash = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
`
export {
  Participant,
  Container,
  EntryContainer,
  CamContainer,
  CarouselContainer,
  CarouselArrowContainer,
  CarouselArrow,
  CarouselCard,
  Card,
  CheckBoxRow,
  CheckBoxContainer,
  CardTitle,
  CardText,
  ReadyCard,
  ReadyCardTitle,
  ReadyCardText,
  CompleteCard,
  CompleteCardText,
  ArtistVideoContainer,
  AlertBanner,
  AlertText,
  HostList,
  Flash,
}
