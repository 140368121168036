import styled, { css } from "styled-components/macro";
import { lighten, darken, readableColor } from "polished";
import { Link } from "react-router-dom";
import { Close, Menu, KeyboardArrowDown } from "@styled-icons/material-sharp";
import { Collapse } from "react-bootstrap";

const DesktopContainer = styled.div`
  align-items: center;
  display: flex;
  min-height: 56px;
  width: 100%;
  background: #101112;
  justify-content: space-between;
  padding: 8px 16px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => {
    const isLight = readableColor("#101112") === "#000"
    return isLight ? darken(0.1, "#101112") : lighten(0.1, "#101112")
  }};
  @media (max-width: 1111px) {
    display: none;
  }
`;

const StyledInfo = styled.div`
  align-items: center;
  display: flex;
`;

const StyledAvatar = styled.div`
  margin-right: 16px;
  div,
  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
  div {
    background: #414141;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-bottom: 2px;
    }
  }
`;

const StyledName = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  color: ${({ theme }) => readableColor("#101112")};
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const StyledArrow = styled(KeyboardArrowDown)`
  height: 24px;
  width: 24px;
  fill: ${({ theme }) => readableColor("#101112")};
  transition: 0.15s ease transform;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
`;

const StyledDropdown = styled(Collapse)`
  top: 56px;
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  background: #101112;
  padding: 0 16px;
  border-bottom: 1px solid ${({ theme }) => {
    const isLight = readableColor("#101112") === "#000"
    return isLight ? darken(0.1, "#101112") : lighten(0.1, "#101112")
  }};
  box-shadow: 0 8px 6px -6px #161616;
  #inner {
    display: flex;
    flex-direction: column;
  }
`;

const MobileContainer = styled.div`
  display: none;
  height: 40px;
  width: 100%;
  padding: 8px 24px;
  background: #101112;
  @media (max-width: 1111px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 200px;
  height: 24px;
  object-fit: contain;
  object-position: left center;
`;

const MenuIcon = styled(Menu)`
  height: 24px;
  width: 24px;
  fill: ${({ theme }) => readableColor("#101112")};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const CloseIcon = styled(Close)`
  height: 24px;
  width: 24px;
  fill: ${({ theme }) => readableColor("#101112")};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const DrawerContainer = styled.div`
  width: ${(props) => (props.open ? "100%" : "0")};
  right: 0;
  top: 0;
  height: 100%;
  position: fixed;
  z-index: 1000;
  transition: ${(props) =>
    props.open
      ? "transform .3s cubic-bezier(.7,.3,.1,1),-webkit-transform .3s cubic-bezier(.7,.3,.1,1)"
      : "transform .3s cubic-bezier(.7,.3,.1,1),height 0s ease .3s,width 0s ease .3s,-webkit-transform .3s cubic-bezier(.7,.3,.1,1)"};
  margin: 0;
  padding: 0;
  display: block;
  z-index: 1000;
`;

const DrawerMask = styled.div`
  transition: opacity 0.3s ease;
  height: ${(props) => (props.open ? "100%" : "0")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  pointer-events: ${(props) => (props.open ? "auto" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
`;

const DrawerBody = styled.div`
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
    -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  right: 0;
  height: 100%;
  background: #101112;
  transform: ${(props) => (props.open ? "translateX(0%)" : "translateX(100%)")};
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 315px;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
`;

const DrawerHeader = styled.div`
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
`;

const DrawerOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const DesktopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  border-radius: 20px;
  transition: 0.15s ease background;
  padding: 8px;
  &:hover {
    background: ${({ theme }) => {
      const isLight = readableColor("#101112") === "#000"
      return isLight ? darken(0.1, "#101112") : lighten(0.1, "#101112")
    }};
  }
`;

const OptionStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  padding: 0 12px;
  border-radius: 20px;
  margin: 12px 0;
  transition: 0.15s ease background;
  &:hover {
    background: ${({ theme }) => {
      const isLight = readableColor("#101112") === "#000"
      return isLight ? darken(0.1, "#101112") : lighten(0.1, "#101112")
    }};
    text-decoration: none;
  }
`;

const Option = styled.div`
  ${OptionStyles};
`;

const OptionAnchor = styled.a`
  ${OptionStyles};
`;

const OptionLink = styled(Link)`
  ${OptionStyles};
`;

const OptionTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  user-select: none;
  font-size: 16px;
  color: ${({ theme }) => readableColor("#101112")};
  letter-spacing: -0.23px;
`;

const OptionIcon = styled.div`
  svg {
    height: 24px;
    width: 24px;
    color: ${({ theme }) => readableColor("#101112")};
  }
`;

export {
  CloseIcon,
  DesktopContainer,
  DesktopHeader,
  DrawerBody,
  DrawerContainer,
  DrawerHeader,
  DrawerMask,
  DrawerOptions,
  Logo,
  MenuIcon,
  MobileContainer,
  Option,
  OptionAnchor,
  OptionIcon,
  OptionLink,
  OptionTitle,
  StyledArrow,
  StyledAvatar,
  StyledDropdown,
  StyledInfo,
  StyledName
};
