import styled from "styled-components/macro"
import { NavLink } from "react-router-dom"
import { Search } from "@styled-icons/material-sharp"

const Header = styled.div`
  padding: 0 48px 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  @media (${({ theme }) => theme.breakpoint}) {
    padding: 0 48px 24px 48px;
    justify-content: center;
  }
`

const HeaderText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBlack};
  font-size: 40px;
  line-height: 100%;
  color: #ffffff;
  @media (${({ theme }) => theme.breakpoint}) {
    display: none;
  }
`

const SearchInput = styled.div`
  color: #111112;
  display: inline-flex;
  font-size: 14px;
  position: relative;
  label {
    display: none;
  }
  input {
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    border-radius: 8px;
    box-shadow: none;
    border: none;
    height: 6px;
    outline: 0;
    padding: 16px 36px 16px 16px;
    transition: box-shadow 0.2s;
    width: 291px;
    &:focus,
    &:active {
      box-shadow: 0 0 0 2px rgba(224, 90, 90, 0.78);
    }
    ::placeholder {
      ${({ theme }) => theme.fonts.TTNormsProNormal};
      color: #111112;
    }
  }
`

const SearchIcon = styled(Search)`
  backface-visibility: hidden;
  bottom: 0;
  cursor: default;
  fill: #111112;
  height: 24px;
  margin: auto 0;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 0;
  width: 24px;
`

const LetterSections = styled.div`
  padding: 48px 48px 0 48px;
  @media (${({ theme }) => theme.breakpoint}) {
    padding: 0 48px;
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

const SectionHeader = styled.div`
  position: relative;
  height: 40px;
`
const HeaderTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProExtraBlack};
  font-size: 32px;
  line-height: 100%;
  position: absolute;
  left: 1px;
  top: 0px;
`

const HeaderBorder = styled.div`
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to left, #6892ff, #ff3c11);
`

const CardSection = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
`

const CardTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 16px;
  color: white;
  letter-spacing: 0.02em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const Card = styled(NavLink)`
  background: #ffffff26;
  ${({ isLoading, theme }) => isLoading && theme.skeletonLoad};
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1 0 calc(20% - 24px);
  height: 106px;
  justify-content: space-evenly;
  margin: 0 24px 24px 0;
  padding: 16px;
  position: relative;
  @media (max-width: 1280px) {
    flex: 1 0 calc(25% - 24px);
  }
  @media (max-width: 1024px) {
    flex: 1 0 calc(33.33% - 24px);
  }
  @media (max-width: 840px) {
    flex: 1 0 calc(40% - 24px);
  }
  @media (${({ theme }) => theme.breakpoint}) {
    flex: 1 0 100%;
  }

  &:hover {
    text-decoration: none;

    ${CardTitle} {
      color: #ffffff;
      text-decoration: underline;
    }
  }
`

const CardTimestamp = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  letter-spacing: 0.02em;
  font-size: 14px;
  color: #ababab;
`

export {
  Card,
  CardSection,
  CardTimestamp,
  CardTitle,
  Header,
  HeaderBorder,
  HeaderText,
  HeaderTitle,
  LetterSections,
  SearchIcon,
  SearchInput,
  Section,
  SectionHeader,
}
