import { Network } from "opensea-js"

const DevTestingConfig = {
  FIREBASE: {
    apiKey: "AIzaSyCZtkKIIsupxzvt8Xnq6ppdMLiocQ1A1II",
    authDomain: window.location.host,
    localhostAuthDomain: "mandolin-dev-testing.firebaseapp.com",
    databaseURL: "https://mandolin-dev-testing.firebaseio.com",
    projectId: "mandolin-dev-testing",
    storageBucket: "mandolin-dev-testing.appspot.com",
    messagingSenderId: "330318838734",
    appId: "1:330318838734:web:9c2f16a2f76a888e6ec99f",
  },
  MUX: {
    muxDataEnvKey: "7daj2h2b9cap889ql72tvp46d",
  },
  OPTIMIZELY: {
    optimizelyKey: "DBLVTWqBu4fDUoF5bdHMr",
  },
  ETHEREUM: {
    proxyApi: "https://mandolin-nft-proxy-staging.herokuapp.com",
    network: Network.Main,
    openseaApi: "https://rinkeby-api.opensea.io/api/v1",
    openseaApiKey: "0abe3a760e5240278f6bdeea7de5f7c8",
    provider: "wss://eth-rinkeby.alchemyapi.io/v2/lvrMD2fc5gdpNHgBWC_u59WUxY27gDr6",
    wethContractAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
  },
  STRAPI: {
    restURL: "https://content-dev-testing.mandolin.com",
  },
  API: {
    wsURL: "wss://ws.dev-testing.mndl.in",
    restURL: "https://dev-api.mandolin.com",
    contentURL: "https://content-dev-testing.mandolin.com",
  },
  MAGIC: {
    magicPublicKey: "pk_live_D33B4E388775C4ED",
    chainId: 80001,
  },
  ALCHEMY: {
    provider: "https://polygon-mumbai.g.alchemy.com/v2/HA75OM12AyYPEZ-3k520J2bGxU2Fp8kN",
  },
  IPFS: {
    gateway: "https://mandolin.mypinata.cloud/ipfs/",
  },
  MANDOLIN: {
    contractMap: "https://9364xtry97.execute-api.us-east-2.amazonaws.com/contract-map",
    openSeaAssetUrl: "https://testnets.opensea.io/assets/mumbai",
  },
  IMAGE_ACCESSOR: {
    baseUrl: "https://image-accessor-dev-testing.mandolin.com",
  },
  PRESENTER: {
    baseUrl: "https://present.dev.mandolin.com",
  },
  PAGE_CONTENT: {
    homepage: "cDmOrIJHlizDzjptlbl5",
  },
}

export default DevTestingConfig
