import React from "react"
import styled from "styled-components"
import { sectionContainer } from "theme/spacing"
import { atMediaMin, skeletonStyles } from "theme/utils"

const HeaderContainer = styled.div`
  ${sectionContainer}
  max-width: 100%;
  display: flex;
  overflow: hidden;
  padding-top: 40px;
  ${atMediaMin("md")} {
    padding-top: 40px;
    justify-content: center;
    align-items: center;
  }
  div {
    ${skeletonStyles};
    width: 65vw;
    min-width: 65vw;
    height: 90vw;
    margin-right: var(--horizontal-margin);
    ${atMediaMin(468)} {
      width: 280px;
      min-width: 280px;
      height: 240px;
    }
    ${atMediaMin("md")} {
      min-width: 42vw;
      max-width: 42vw;
      height: 32vw;
      margin: 0 1vw;
    }
    ${atMediaMin("lg")} {
      min-width: 35vw;
      max-width: 35vw;
      height: 25vw;
    }
  }
`
const EventTileContainer = styled(HeaderContainer)`
  ${sectionContainer}
  margin-top: 24px;
  margin-bottom: 24px;
  padding-bottom: 60px !important;
  max-width: 1440px !important;
  justify-content: flex-start !important;
  overflow: hidden;
  ${atMediaMin("lg")} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  ${atMediaMin("xl")} {
    margin-top: 60px;
    margin-bottom: 60px;
  }
`
const EventTile = styled.span`
  display: block;
  ${skeletonStyles};
  width: 310px;
  min-width: 310px;
  height: auto;
  padding-bottom: 210px;
  position: relative;
  margin-bottom: 20px;
  margin-right: 16px;
  &:before,
  &:after {
    content: "";
    display: block;
    height: 16px;
    ${skeletonStyles};
    position: absolute;
    top: 110%;
    left: 0;
  }
  &:before {
    width: 250px;
  }
  &:after {
    width: 180px;
    transform: translate(0, 20px);
  }
`

const AvatarTile = styled.span`
  position: relative;
  display: block;
  width: 120px;
  min-width: 120px;
  height: 120px;
  border-radius: 60px !important;
  margin-right: 24px;
  ${skeletonStyles};
  :after {
    content: "";
    display: block;
    height: 16px;
    ${skeletonStyles};
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
  }
`

const loadEventTiles = () => [0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => <EventTile key={i} />)
const loadAvatarTiles = () =>
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => <AvatarTile key={i} />)

const LoadingSkeleton = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden", height: "100vh" }}>
      <HeaderContainer>
        <div />
        <div />
        <div />
        <div />
        <div />
      </HeaderContainer>
      <div style={{ height: 20 }} />
      <EventTileContainer>{loadEventTiles()}</EventTileContainer>
      <EventTileContainer>{loadAvatarTiles()}</EventTileContainer>
      <EventTileContainer>{loadEventTiles()}</EventTileContainer>
      <EventTileContainer>{loadAvatarTiles()}</EventTileContainer>
      <EventTileContainer>{loadEventTiles()}</EventTileContainer>
    </div>
  )
}

export default LoadingSkeleton
