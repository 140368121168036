import { transparentize } from "polished"
import styled, { css } from "styled-components/macro"

interface AnnounceProps {
  backImg: string
  backColor: string
}

interface ButtonContainerProps {
  spaceBetween: boolean
  backgroundColor: string
}

interface NextButtonProps {
  nextColor: string
  nextTextColor: string
}

const cardStyles = (color: string) => css`
  ${({ theme }) => theme.fonts.TTNormsProBlack};
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: #000;
  background-size: cover;

  .details {
    height: 221px;
    background: ${color};
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
`

const CardContainer = styled.div<{ color: string }>`
  ${(props) => cardStyles(props.color)}
`
const StyledImage = styled.img`
  height: 173px;
  width: 100%;
  background-color: #aaa;
`

const ImageCardContainer = styled.div<{ color: string }>`
  ${({ theme }) => theme.fonts.TTNormsProBlack};
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #fff;
  background-size: cover;

  .details {
    height: 79px;
    background: ${({ color }) => color};
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
`
const HelperImage = styled.img`
  height: 312px;
  width: 100%;
  object-fit: contain;
  background: linear-gradient(to right, #ffcfda70, #ff7d9c70, #b353ff70, #00a5ff70);
`

const CommerceImage = styled.img`
  height: 312px;
  width: 100%;
  object-fit: contain;
  background: #fff;
`

const TitleText = styled.div<{ color: string }>`
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 1.02px;
  margin: 8px 0px;
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: ${({ color }) => color};
`
const BodyText = styled.div<{ color?: string }>`
  font-size: 14px;
  line-height: 20px;
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: ${({ theme, color }) => color || theme.colors.elevation7};
`

const WelcomeText = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.5px;
  margin: 8px 0px;
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #006efb;
`
const ArtistLabel = styled.div`
  display: table;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 4px 8px;
  width: fit-content;
  margin-top: 24px;

  .artistInfo {
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    font-size: 14px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.elevation1};
    flex-direction: row;
  }
`
const CardButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  background-color: ${(ButtonContainerProps) => ButtonContainerProps.backgroundColor};
  justify-content: ${(ButtonContainerProps) =>
    ButtonContainerProps.spaceBetween ? "space-between" : "flex-end"};
  padding: 10px;
  max-height: 60px;
`
const BackButton = styled.div<{ color: string }>`
  display: flex;
  border: none;
  border-radius: 4px;
  border-radius: 40px;
  gap: 8px;
  font-size: 14px;
  line-height: 17px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  .text {
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    color: ${({ color }) => color};
    line-height: 14px;
    font-size: 14px;
  }
`

const NextButton = styled.div<NextButtonProps>`
  display: flex;
  border: none;
  border-radius: 24px;
  padding: 12px 24px;
  background-color: ${(NextButtonProps) => NextButtonProps.nextColor};
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: 14px;
  line-height: 1;
  align-items: center;
  color: ${(NextButtonProps) => NextButtonProps.nextTextColor};
  flex-direction: row;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`

const EmojiContainer = styled.div`
  height: 48px;
  width: 48px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-size: 32px;
`

const AnnounceCardContainer = styled.div<{ backColor: string }>`
  ${cardStyles("transparent")}
  background-color: #fff;
`
const AnnounceEmojiHeader = styled.div<AnnounceProps>`
  background: ${(AnnounceProps) =>
    `linear-gradient(${transparentize(0, AnnounceProps.backColor)},${transparentize(
      0.2,
      AnnounceProps.backColor,
    )}), url(${AnnounceProps.backImg})`};
  background-size: auto 200%;
  background-position: top center;
  height: 173px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  span {
    border-radius: 100px;
    background-color: white;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 40%;
  }
`
const WaitCardContainer = styled.div`
  height: 340px;
  width: 95%;
  background-color: ${({ theme }) => theme.colors.elevation7};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`
const TimeText = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: #000;
  font-size: 30px;
`
const TimeLabel = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #000;
  font-size: 16px;
`
export {
  TimeText,
  TimeLabel,
  WaitCardContainer,
  AnnounceEmojiHeader,
  CardContainer,
  ImageCardContainer,
  TitleText,
  WelcomeText,
  ArtistLabel,
  NextButton,
  BackButton,
  CardButtonContainer,
  EmojiContainer,
  BodyText,
  HelperImage,
  CommerceImage,
  StyledImage,
  AnnounceCardContainer,
}
