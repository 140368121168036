import { Link } from "react-router-dom"
import styled from "styled-components"
import { Blurhash } from "react-blurhash"
import ImageLoader from "components/ImageLoader"
import { atMediaMin, skeletonStyles } from "theme/utils"
import { invert, transparentize } from "polished"

const FeaturedEventContainer = styled.figure.attrs({ className: "featured-event" })`
  margin: 0px;
  padding: 0px;
  border-radius: 16px;
  overflow: hidden;
`

const FeaturedEventButton = styled(Link).attrs({ className: "featured-event-button" })`
  display: block;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  outline: none;
  color: ${({ theme }) => theme.elements.pageText};
  text-decoration: none !important;
  border-radius: 0px;
  .featured-event-image-container,
  img {
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &:hover {
    color: ${({ theme }) => theme.elements.pageText};
  }
  &:focus {
    &:after {
      content: "";
      cursor: none;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 4px 2px ${({ theme }) => theme.colors.primarySolid};
      z-index: 2;
    }
  }
`

const FeaturedEventContent = styled.figcaption`
  position: relative;
  padding: 16px;
  height: 98px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${({ theme }) => transparentize(0.8, invert(theme.elements.pageBackground))};
  canvas {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
  h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    ${({ theme }) => theme.fonts.TTNormsProNormal}
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 8px;
  }
  h3 {
    overflow: hidden;
    ${({ theme }) => theme.fonts.TTNormsProBold}
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    position: relative;
    margin: 0px;
    /* hack to make sure we clip at 2 lines */
    --max-lines: 2;
    --lh: calc(16px * 1.2);
    max-height: calc(var(--lh) * var(--max-lines));
    /* modern browser ellipsis */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`

const FeaturedEventImage = styled(ImageLoader)`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

const FeaturedEventImageContainer = styled.div.attrs({
  className: "featured-event-image-container",
})`
  ${skeletonStyles}
  padding-bottom: 52.25%;
  position: relative;
`

const FeaturedEventContentHash = styled(Blurhash)`
  position: absolute !important;
  width: auto !important;
  height: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  opacity: 0.25;
`

const FeaturedEventDate = styled.h4`
  font-size: 10px !important;

  ${atMediaMin("md")} {
    font-size: 13px !important;
  }
`

export {
  FeaturedEventContainer,
  FeaturedEventButton,
  FeaturedEventContent,
  FeaturedEventImage,
  FeaturedEventContentHash,
  FeaturedEventImageContainer,
  FeaturedEventDate,
}
