import axios from "axios"
import Config from "config/config"
import { database } from "firebaseApp"
import useWallet from "hooks/useWallet"
import { EventType } from "opensea-js"
import React, { useEffect, useState } from "react"
import AssetWrapper, { convertToWei, ERROR_MESSAGES } from "types/AssetWrapper"
import { Asset } from "types/OpenSeaAsset"
import { Timer } from "../../Timer"
import { StatefulCard } from "../Card"
import { Button, CardSubtitle, CardTitle, Error } from "../Card.styled"
import { CTAContainer } from "../ForSale/ForSale.styled"
import Loading from "../Loading"
import seaport from "../seaport"
import WithWallet from "../WithWallet"
import BiddingForm from "./BiddingForm"
import {
  BidBackFace,
  BiddingContent,
  BidFrontFace,
  BidPrice,
  CurrentBidInfo,
  ExpandMoreIcon,
  GhostButton,
  HighBid,
  OutBid,
  PlaceBid,
  TimerContainer,
} from "./ForBid.styled"

interface ForBidProps extends StatefulCard {
  asset: AssetWrapper
}

export enum BidState {
  NO_BID = "Enter bid amount",
  HIGH_BID = "You have the high bid!",
  OUTBID = "You were outbid!",
}

const timer: { [key: string]: any } = {}

export default function ForBid(props: ForBidProps) {
  const { asset, handleStateUpdate } = props
  const [isBidding, setIsBidding] = useState(
    window.localStorage.getItem(`${asset.tokenId}.isBidding`) === "true",
  )
  const [error, setError] = useState<string>()
  const [loadingMessage, setLoadingMessage] = useState("")
  const [isLoadingBids, setIsLoadingBids] = useState(true)
  const [price, setPrice] = useState(asset.price)
  const [highestBidder, setHighestBidder] = useState("")
  const [hasBid, setHasBid] = useState(false)

  const [timerEndAuction, setTimerEndAuction] = useState(asset.closingDate)
  const { address, balance } = useWallet()
  const minBid = +`${Math.ceil(+`${price * 1.06}e+4`)}e-4`
  let bidState = BidState.NO_BID

  if (address && hasBid) {
    if (address === highestBidder) {
      bidState = BidState.HIGH_BID
    } else {
      bidState = BidState.OUTBID
    }
  }

  useEffect(() => {
    if (handleStateUpdate) {
      handleStateUpdate(bidState)
    }
  }, [address, hasBid, highestBidder])

  useEffect(() => {
    seaport.addListener(EventType.CreateOrder, () => {
      setLoadingMessage("Signing transaction")
    })
  }, [])

  const enqueueGetHighestBid = () => {
    clearTimeout(timer[asset.tokenId])
    timer[asset.tokenId] = setTimeout(getHighestBid, 300)
  }

  const handleBid = async (bid: number) => {
    if (bid < minBid) {
      setError(`${ERROR_MESSAGES.INSUFFICIENT_BID} (${minBid.toFixed(4)} ETH)`)
      enqueueGetHighestBid()
      return
    }
    if (bid > balance) {
      setError(ERROR_MESSAGES.INSUFFICIENT_FUNDS_BID)
      enqueueGetHighestBid()
      return
    }
    setLoadingMessage("Waiting wallet")
    setError("")
    try {
      const response = await asset.createBidOrder(address, bid)
      if (response?.hash) {
        setPrice(bid)
        setHighestBidder(address)
        await fetchAssetWithRetry(address)
      }
    } catch (e) {
      setError(e.message)
    } finally {
      enqueueGetHighestBid()
      setLoadingMessage("")
    }
  }

  const fetchAssetWithRetry = async (address?: string): Promise<Asset> => {
    const path = `asset/${asset.contractAddress}/${asset.tokenId}?format=json`
    let endpoint = `${Config.ETHEREUM.proxyApi}/${path}&ts=${window.performance.now()}`
    if (address) {
      endpoint += `&address=${address}`
    }

    try {
      const { data } = await axios.get<any>(endpoint)
      return data
    } catch (e) {
      return fetchAssetWithRetry(address)
    }
  }

  const getHighestBid = async (data?: any) => {
    const assetInformation = data || (await fetchAssetWithRetry())
    const { orders } = assetInformation
    const sortedOrders = orders.sort((a: any, b: any) => {
      const ap = a.current_price
      const bp = b.current_price
      return +bp - +ap
    })

    const order = sortedOrders[0]
    const newEndAuctionDate = sortedOrders[orders.length - 1].closing_date

    const bid = orders.find((o: any) => o.maker.address === address)
    const newPrice = convertToWei(order?.current_price)
    const newHighestBidder = order?.maker.address || ""
    setPrice(newPrice)
    setHighestBidder(newHighestBidder)
    setHasBid(bid !== undefined)
    setIsLoadingBids(false)
    setTimerEndAuction(newEndAuctionDate)
  }

  useEffect(() => {
    getHighestBid()
    if (address && asset.isBid && !asset.isSold) {
      database.ref(asset.tokenId).on("value", (snapshot: any) => {
        if (snapshot.val()?.address !== address || !asset.price) {
          getHighestBid(snapshot.val()?.data)
        }
      })
    }

    return function cleanUp() {
      clearInterval(timer[asset.tokenId])
      delete timer[asset.tokenId]
    }
  }, [address])

  if (isLoadingBids) {
    return <Loading />
  }

  const setIsBiddingLocally = (value: boolean) => {
    setIsBidding(value)
    window.localStorage.setItem(`${asset.tokenId}.isBidding`, value.toString())
  }

  return (
    <div>
      <BidFrontFace id="bid-front" visible={!isBidding || !address}>
        <div>
          {bidState !== BidState.NO_BID && <BidTitle state={bidState} />}
          <small> Current Bid </small>
          <div> {price} WETH </div>
        </div>
        <CTAContainer>
          <WithWallet>
            <>
              <Button type="button" onClick={() => setIsBiddingLocally(true)}>
                place a bid
              </Button>
              {asset.closingDate && !isBidding && (
                <TimerContainer>
                  <Timer closingDate={asset.closingDate} />
                </TimerContainer>
              )}
            </>
          </WithWallet>
        </CTAContainer>
      </BidFrontFace>
      <BidBackFace id="bid-back" visible={isBidding && !!address}>
        <BiddingContent>
          <div>
            <CardTitle>
              <div>{asset.name}</div>
              <GhostButton type="button" onClick={() => setIsBiddingLocally(false)}>
                <ExpandMoreIcon />
              </GhostButton>
            </CardTitle>
          </div>

          <CurrentBidInfo>
            <small>Current high bid is</small>
            <BidPrice>{price.toFixed(4)} WETH</BidPrice>
          </CurrentBidInfo>
          <hr />
          {!error && <BidTitle state={bidState} />}
          {!!error && <Error> {error} </Error>}
          <BiddingForm
            handleFormSubmit={handleBid}
            minBid={minBid}
            loadingMessage={loadingMessage}
          />
          {asset.closingDate && isBidding && (
            <TimerContainer>
              <Timer closingDate={timerEndAuction} />
            </TimerContainer>
          )}
        </BiddingContent>
      </BidBackFace>
    </div>
  )
}

interface BidTitleProps {
  state: BidState
}
const BidTitle = ({ state }: BidTitleProps) => {
  if (state === BidState.HIGH_BID) {
    return <HighBid>{state}</HighBid>
  }
  if (state === BidState.OUTBID) {
    return <OutBid>{state}</OutBid>
  }
  return <PlaceBid>{state}</PlaceBid>
}
