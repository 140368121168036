/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getRestClient } from "services/restClient"
import { mandolinTheme } from "theme"

export type Cobranding = {
  id: string
  data: any | null
  isAdded: boolean
}

const initialCobrandState: Cobranding = {
  id: "",
  data: mandolinTheme,
  isAdded: false,
}

type CobrandingStoreState = {
  [id: string]: Cobranding
}

const initialCobrandStoreState: CobrandingStoreState = {
  "": initialCobrandState,
}

export const fetchCobrand = createAsyncThunk(
  "cobrands/fetchCobrand",
  // eslint-disable-next-line consistent-return
  async (cobrandId: string, { getState }) => {
    const { cobrands } = getState() as { cobrands: CobrandingStoreState }
    const { restClient, restConnected } = getRestClient()

    if (cobrands[cobrandId].isAdded === false && restConnected) {
      const cobrandData = await restClient?.getCobrand({ subdomain: cobrandId })
      return cobrandData?.data
    }
  },
)

const cobrands = createSlice({
  name: "cobrands",
  initialState: initialCobrandStoreState,
  reducers: {},
  extraReducers: {
    [fetchCobrand.pending.type]: (state, action) => {
      if (!state[action.meta.arg]) {
        state[action.meta.arg] = {
          id: action.meta.arg,
          data: initialCobrandState,
          isAdded: false,
        }
      }
    },
    [fetchCobrand.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state[action.payload.id] = {
          id: action.payload.id,
          data: action.payload,
          isAdded: true,
        }
      }
    },
  },
})

const { reducer } = cobrands

export default reducer
