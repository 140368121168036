import { invert, rgba, tint } from "polished"
import { css, keyframes } from "styled-components"

export const defaultBreakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}

type mediaValue = "sm" | "md" | "lg" | "xl" | "xxl" | number
type mediaValueType = "px" | "rem" | "em" | "vw" | "vh"
type mediaResponsiveType = "min" | "max"
interface MediaQueryConfig {
  value: mediaValue
  overrideCollisionCheck?: boolean
}

const convertPxToEm = (value: number | mediaValue) =>
  (typeof value === "number" ? value : defaultBreakpoints[value]) / 16

/**
 * @summary Helper method to get the em value based off of 16px
 * @param {(mediaValue|number)} value  - Accepts either a theme breakbpoint or number
 * @example
 * // Basic usaged with pre-defined breakpoint
 * const StyledDiv = styled.div`
 *  font-size: ${toEm(21)};
 * `
 */
const toEm = (value: number | mediaValue) => `${convertPxToEm(value)}em`

/**
 * @summary Helper method to get the rem value based off of 16px
 * @param {(mediaValue|number)} value  - Accepts either a theme breakbpoint or number
 * @example
 * // Basic usaged with pre-defined breakpoint
 * const StyledDiv = styled.div`
 *  font-size: ${toRem(21)};
 * `
 */
const toRem = (value: number | mediaValue) => `${convertPxToEm(value)}rem`

const buildQuery = (
  props: MediaQueryConfig | mediaValue | number,
  type: mediaResponsiveType,
  includeMediaTag: boolean = true,
): string => {
  let mediaQuery: string = ""

  if (includeMediaTag) mediaQuery += "@media "

  if (typeof props === "number" || typeof props === "string") {
    return `${mediaQuery} (${type}-width: ${toEm(props)})`
  }

  const defaults: MediaQueryConfig = {
    overrideCollisionCheck: false,
    ...props,
  }

  if (typeof defaults.value === "number") {
    return `${mediaQuery}(${type}-width: ${toEm(defaults.value)})`
  }

  return `${mediaQuery}(${type}-width: ${toEm(
    defaultBreakpoints[defaults.value] - (defaults.overrideCollisionCheck ? 0 : 0.02),
  )})`
}

/**
 * @summary Generates a media query using min-width.
 * @param {(MediaQueryConfig|string|number)} props - Accepts either a media query config object, string, or number
 * @example
 * // Basic usaged with pre-defined breakpoint
 * const StyledDiv = styled.div`
 *  ${atMediaMin("md")} {
 *    background-color: tomato;
 *  }
 * `
 * @example
 * // Advanced usaged with config object
 * const StyledDiv = styled.div`
 *  ${atMediaMin({ value: 50, valueType: "em", overrideCollisionCheck: true })} {
 *    background-color: tomato;
 *  }
 * `
 */
const atMediaMin = (props: MediaQueryConfig | mediaValue | number) => buildQuery(props, "min")

/**
 * @summary Generates a media query using max-width.
 * @description To prevent collisions between min and max width we subtract 0.02 from pre-defined breakpoints. Custom values will be un-changed.
 * @param {(MediaQueryConfig|string|number)} props - Accepts either a media query config object, string, or number
 * @example
 * // Basic usaged with pre-defined breakpoint
 * const StyledDiv = styled.div`
 *  ${atMediaMax("md")} {
 *    background-color: tomato;
 *  }
 * `
 * @example
 * // Advanced usaged with config object
 * const StyledDiv = styled.div`
 *  ${atMediaMax({ value: 1920, valueType: "em", overrideCollisionCheck: true })} {
 *    background-color: tomato;
 *  }
 * `
 */
const atMediaMax = (props: MediaQueryConfig | mediaValue | number) => buildQuery(props, "max")

/**
 * @summary Generates a media query using min-width and max-width.
 * @param {(mediaValue|number)} min - Accepts either a theme breakbpoint or number
 * @param {(mediaValue|number)} max - Accepts either a theme breakbpoint or number
 * @example
 * // Basic usaged with pre-defined breakpoint
 * const StyledDiv = styled.div`
 *  ${atMediaBetween("md", "xl")} {
 *    background-color: tomato;
 *  }
 * `
 */
const atMediaBetween = (
  min: MediaQueryConfig | mediaValue | number,
  max: MediaQueryConfig | mediaValue | number,
) => `${buildQuery(min, "min")} and ${buildQuery(max, "max", false)}`

const skeletonAnimation = keyframes`
  from {
    background-position: 200% 0
  }
  to {
    background-position: -200% 0
  }
`

const skeletonStyles = css`
  animation: ${skeletonAnimation} 8s infinite;
  border-radius: 16px;
  background-image: ${({ theme }) => `linear-gradient(
    270deg,
    ${rgba(invert(theme.elements.pageBackground), 0.075) || "rgba(0, 0, 0, 0.15)"},
    ${tint(0.02, rgba(invert(theme.elements.pageBackground), 0.075) || "rgba(0, 0, 0, 0.15)")},
    ${tint(0.02, rgba(invert(theme.elements.pageBackground), 0.075) || "rgba(0, 0, 0, 0.15)")},
    ${rgba(invert(theme.elements.pageBackground), 0.075) || "rgba(0, 0, 0, 0.15)"}
  )`};
`

export { atMediaMin, atMediaMax, atMediaBetween, toEm, toRem, skeletonAnimation, skeletonStyles }
