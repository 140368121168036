/* eslint-disable no-param-reassign  */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { DefaultApi, IContentSection } from "@mandolin-dev/ts-sdk"
import { AppDispatch } from "./store"

export interface ShowState {
  allowUnauthedStreamAccess: boolean
  chatDisabled: boolean
  cobrands: Array<string>
  currentTab: string
  customReactions: Array<object>
  customVideoLogo?: boolean
  dateTime: string
  donations?: Array<object>
  external_event?: object
  externalID?: string
  features: object
  headerImg: string
  eventAvatar?: string
  headline?: string
  description?: string
  hideVideoLogo: false
  isTicketed: boolean | null
  loading: boolean
  merch?: Array<object>
  name: string
  nftCollections: Array<string>
  wallet?: string
  setList?: Array<string>
  showCustomReactions: boolean
  showUnmuteModal: boolean
  sponsors?: Array<object>
  state: string
  stream?: object
  theme?: object
  ticketURL?: string
  titleImage?: string
  unticketedChatDisabled: boolean
  artist?: {
    avatar?: string
    name?: string
  }
  venue?: {
    avatar?: string
    name?: string
    city?: string
    state?: string
  }
  contentSections: IContentSection[]
  venueId?: string
  streamLoading: boolean
  startTime?: Date
  surveyEntry?: {
    csatSurveyId: string
    eventId: string
  }
}

const defaultVenue = {
  avatar: "",
  name: "",
  nftCollections: [],
  city: "",
  state: "",
  isTicketed: null,
}

export const initialState: ShowState = {
  allowUnauthedStreamAccess: false,
  chatDisabled: false,
  currentTab: "CHAT",
  customReactions: [],
  cobrands: [],
  dateTime: "",
  description: "",
  features: {},
  headerImg: "",
  eventAvatar: "",
  hideVideoLogo: false,
  isTicketed: null,
  loading: true,
  name: "",
  nftCollections: [],
  showCustomReactions: false,
  showUnmuteModal: false,
  state: "",
  stream: {},
  unticketedChatDisabled: false,
  venue: defaultVenue,
  streamLoading: false,
  contentSections: [],
}

const user = createSlice({
  name: "show",
  initialState,
  reducers: {
    setShowData(currentState: ShowState, action: PayloadAction<ShowState>) {
      const {
        allowUnauthedStreamAccess,
        chatDisabled,
        customReactions,
        customVideoLogo,
        cobrands,
        dateTime,
        donations,
        external_event,
        externalID,
        features,
        headerImg,
        eventAvatar,
        headline,
        description,
        hideVideoLogo,
        isTicketed,
        merch,
        name,
        nftCollections,
        wallet,
        setList,
        showCustomReactions,
        sponsors,
        state,
        theme,
        ticketURL,
        unticketedChatDisabled,
        venue,
        venueId,
        contentSections,
        artist,
        surveyEntry,
      } = action.payload

      currentState.allowUnauthedStreamAccess = allowUnauthedStreamAccess
      currentState.artist = artist
      currentState.chatDisabled = chatDisabled
      currentState.cobrands = cobrands
      currentState.customReactions = customReactions
      currentState.customVideoLogo = customVideoLogo
      currentState.dateTime = dateTime
      currentState.donations = donations
      currentState.external_event = external_event
      currentState.features = features
      currentState.headerImg = headerImg
      currentState.eventAvatar = eventAvatar
      currentState.headline = headline
      currentState.description = description
      currentState.hideVideoLogo = hideVideoLogo
      currentState.isTicketed = isTicketed
      currentState.loading = false
      currentState.merch = merch
      currentState.name = name
      currentState.nftCollections = nftCollections
      currentState.wallet = wallet
      currentState.setList = setList
      currentState.showCustomReactions = showCustomReactions
      currentState.sponsors = sponsors
      currentState.state = state
      currentState.theme = theme
      currentState.ticketURL = ticketURL
      currentState.unticketedChatDisabled = unticketedChatDisabled
      currentState.contentSections = contentSections
      if (externalID) {
        currentState.externalID = externalID
      }
      if (venue) {
        currentState.venue = venue
      } else {
        currentState.venue = defaultVenue
      }
      currentState.venueId = venueId
      currentState.surveyEntry = surveyEntry
    },
    setShowState(state: ShowState, action: PayloadAction<{ state: string; startTime: Date }>) {
      state.state = action.payload.state
      state.startTime = action.payload.startTime
    },
    setShowChatDisabled(state: ShowState, action: PayloadAction<boolean>) {
      state.chatDisabled = action.payload
    },
    setStreamDetails(state: ShowState, action: PayloadAction<object>) {
      state.stream = action.payload
    },
    setStreamLoading(state: ShowState, action: PayloadAction<boolean>) {
      state.streamLoading = action.payload
    },
    setShowUnmuteModal(state: ShowState, action: PayloadAction<boolean>) {
      state.showUnmuteModal = action.payload
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload
    },
  },
})

const { actions, reducer } = user

export const {
  setCurrentTab,
  setStreamDetails,
  setStreamLoading,
  setShowState,
  setShowUnmuteModal,
  setShowChatDisabled,
  setShowData,
} = actions

export const getSDKStreamInfo = (showId: string, restClient: DefaultApi) => async (
  dispatch: AppDispatch,
) => {
  try {
    dispatch(setStreamLoading(true))
    const { data } = await restClient.getShowStreamDetails({ showId })
    dispatch(setStreamDetails(data))
    dispatch(setStreamLoading(false))
  } catch (e) {
    console.log(e.message)
  }
}

export const updateShowData = (newData: any) => async (dispatch: AppDispatch) => {
  dispatch(setShowData(newData))
}

export default reducer
