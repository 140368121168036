import styled from "styled-components"
import { readableColor } from "polished"
import { Link } from "react-router-dom"
import { atMediaMax, atMediaMin } from "theme/utils"

export const Dropdown = styled.div`
  position: relative;
`

export const DropdownButton = styled.button<{ active: boolean }>`
  color: ${({ theme }) => theme.elements.buttonText} !important;
  fill: ${({ theme }) => theme.elements.buttonText} !important;
  background-color: ${({ theme }) => theme.elements.buttonBackground};
  align-self: flex-start;
  align-items: center;
  justify-self: flex-start;
  display: flex;
  justify-content: space-between;
  height: 48px;
  width: 313px;
  padding: 3% 7.7%;
  border-radius: ${({ active }) => (!active ? "24px" : "24px 24px 0 0")};
  text-decoration: none !important;
  border: none;
  outline: none;
  svg {
    width: 20px;
    margin: 0 2px 0 2px;
  }
  ${atMediaMax("md")} {
    width: 100%;
  }
`

export const DropdownMenu = styled.div<{ active: boolean }>`
  background-color: ${({ theme }) => theme.colors.elevation7};
  color: ${({ theme }) => readableColor(theme.colors.elevation7)};
  border-radius: ${({ active }) => (!active ? "27px" : "0 0 27px 27px")};
  position: absolute;
  transform: translate3d(0px, 46px, 0px);
  top: 0px;
  left: 0px;
  right: auto;
  bottom: auto;
  will-change: transform;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 8px;
  ${atMediaMin("md")} {
    width: 313px;
    height: 128px;
  }

  z-index: 1000;
  ${atMediaMax("md")} {
    width: 100%;
  }
`

export const DropdownContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

export const DropdownItemContainer = styled.div`
  ${atMediaMin("md")} {
    display: flex;
    justify-content: flex-start;
  }
  a {
    margin: 8px auto 0;
    ${atMediaMin("md")} {
      margin: 0;
    }
  }
  svg {
    width: 23px;
    margin: auto;
  }
`

export const DropdownItem = styled(Link)`
  color: ${({ theme }) => theme.elements.buttonText} !important;
  fill: ${({ theme }) => theme.elements.buttonText} !important;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none !important;
  width: 48px;
  height: 68px;
`
