import styled from "styled-components/macro"
import { NavLink } from "react-router-dom"
import { rgba } from "polished"

const CardRowContainer = styled.div`
  margin-top: 24px;
  position: relative;
  overflow: hidden;
  width: 100%;
`
const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  &:after {
    content: "";
    display: block;
    position: relative;
    min-width: 12px;
  }
`

const CardTime = styled.div`
  color: #ababab;
  font-size: 14px;
  height: 14px;
  margin-bottom: 4px;
  text-decoration: none;
`

const CardInner = styled.div`
  background: ${({ theme }) => theme.colors.card};
  ${({ loading, theme }) => loading && theme.skeletonLoad};
  ${({ theme }) => theme.fonts.TTNormsProBold};
  align-self: flex-start;
  border-radius: 4px;
  flex-grow: 0;
  flex: none;
  height: 112px;
  letter-spacing: 0.02em;
  line-height: 100%;
  position: relative;
  width: 192px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 10px 16px;

  ${CardTime} {
    ${({ loading }) => loading && `display: none`};
  }

  @media (${({ theme }) => theme.breakpoint}) {
    width: 181px;
    height: 96px;
  }
`

const CardTitle = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  bottom: 32px;
  display: -webkit-box;
  font-size: 16px;
  height: 32px;
  overflow: hidden;
`

const Card = styled(NavLink)`
  color: #ffffff;
  padding-left: 12px;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    ${CardTime} {
      color: #ababab;
      text-decoration: none;
    }

    ${CardTitle} {
      color: #ffffff;
      text-decoration: underline;
    }
  }
`

const RowArrowContainer = styled.div`
  @media (${({ theme }) => theme.breakpoint}) {
    display: none;
  }
  position: absolute;
  ${({ back }) =>
    back
      ? `
  left: 0px;
  background: linear-gradient(-90deg, rgba(7, 7, 22, 0) 0%, rgba(7, 7, 22, 0.85) 100%);
  justify-content: flex-start;
  `
      : `
  right: 0px;
  background: linear-gradient(
    90deg,
    rgba(7, 7, 22, 0) 0%,
    rgba(7, 7, 22, 1) 100%
  );
  justify-content: flex-end;
  `}
  top: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  padding: 0px 16px;
`
const RowArrowButton = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s cubic-bezier(0.33, 1, 0.68, 1);
  cursor: pointer;
  svg {
    transition: all 0.1s cubic-bezier(0.33, 1, 0.68, 1);
    display: block;
    width: 16px;
    height: 16px;
  }

  &:hover {
    transform: scale(1.3);
    svg {
      transform: scale(0.8);
    }
  }
`

const StartingSoon = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 0 5px;
  color: white;
  border-radius: 4px;
  background-color: #73d689;
  font-size: 14px;
  height: 20px;
  line-height: 22px;
`

const PastShow = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 0 5px;
  color: white;
  border-radius: 4px;
  background-color: gray;
  font-size: 14px;
  height: 20px;
  line-height: 22px;
`

export {
  Card,
  CardInner,
  CardRowContainer,
  CardTime,
  CardTitle,
  PastShow,
  Row,
  RowArrowButton,
  RowArrowContainer,
  StartingSoon,
}
