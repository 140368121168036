import React, { useState, useCallback } from "react"
import Filter from "bad-words"
import {
  CloseWindow,
  Input,
  InputContainer,
  InputOuter,
  ProfaneClear,
  ProfaneContainer,
  ProfaneHeader,
  ProfaneMessage,
  SubmitIcon,
} from "./styled"
import EmojiPicker from "./EmojiPicker"

const supportFilter = new Filter({ emptyList: true })
supportFilter.addWords("buffering", "buffer", "freezing", "frozen", "lag", "lagging", "loading")

const badWordFilter = new Filter()
badWordFilter.removeWords("god", "fuck", "fuckin", "fucking", "shit", "hell")

const ChatInput = ({
  isBannedFromChatroom,
  isJoiningChat,
  scrollToBottom,
  sendMessage,
  showDesktopChat,
}) => {
  const [inputValue, setInputValue] = useState("")
  const [profaneMessage, setProfaneMessage] = useState(false)
  const [supportMessage, setSupportMessage] = useState(false)

  const clearProfane = useCallback(() => {
    setProfaneMessage(false)
    setInputValue("")
  }, [])

  const clearSupport = useCallback(() => {
    setSupportMessage(false)
    setInputValue("")
  }, [])

  const contactSupport = () => {
    if (window.zE) window.zE.activate()
    setInputValue("")
    setSupportMessage(false)
  }

  const confirmSendMessage = () => {
    if (!isJoiningChat) sendMessage(inputValue)
    setSupportMessage(false)
    setProfaneMessage(false)
    setInputValue("")
  }

  const submitInput = async () => {
    if (badWordFilter.isProfane(inputValue)) {
      setProfaneMessage(true)
    } else if (supportFilter.isProfane(inputValue)) {
      setSupportMessage(true)
    } else if (!isBannedFromChatroom && inputValue !== "") {
      sendMessage(inputValue)
      setInputValue("")
    }
    scrollToBottom()
  }

  const inputProps = {
    value: inputValue,
    id: "messageInput",
    onChange: (e) => setInputValue(e.target.value),
    onKeyUp: (e) => e.keyCode === 13 && submitInput(),
    type: "text",
    autoComplete: "off",
    placeholder: isBannedFromChatroom ? "You have been banned from posting" : "Positive vibes...",
    disabled: isBannedFromChatroom,
  }

  if (profaneMessage) {
    return (
      <ProfaneContainer>
        <ProfaneHeader>Woah!</ProfaneHeader>
        <ProfaneMessage>
          You can&apos;t say "{badWordFilter.clean(inputValue)}</ProfaneMessage>
        <ProfaneClear
          onClick={clearProfane}
          onKeyUp={(e) => e.keyCode === 13 && clearProfane()}
          role="button"
          tabIndex={0}
        >
          It won&apos;t happen again!
        </ProfaneClear>
      </ProfaneContainer>
    )
  }

  if (supportMessage) {
    return (
      <ProfaneContainer>
        <CloseWindow onClick={() => clearSupport()} />
        <ProfaneHeader>Streaming Issues?</ProfaneHeader>
        <ProfaneMessage>Have you tried refreshing?</ProfaneMessage>
        <ProfaneClear
          onClick={() => contactSupport()}
          onKeyUp={(e) => e.keyCode === 13 && clearSupport()}
          role="button"
          tabIndex={0}
        >
          Contact Support
        </ProfaneClear>
        <ProfaneClear
          onClick={() => !isJoiningChat && confirmSendMessage() }
          onKeyUp={(e) => {
            e.keyCode === 13 && !isJoiningChat && confirmSendMessage()
          }}
          role="button"
          tabIndex={0}
        >
          Send Message
        </ProfaneClear>
      </ProfaneContainer>
    )
  }

  return (
    <InputOuter>
      <InputContainer showDesktopChat={showDesktopChat}>
        <Input {...inputProps} />
        <EmojiPicker isBannedFromChatroom={isBannedFromChatroom} setInputValue={setInputValue} />
        <SubmitIcon aria-label="send message" viewBox="0 0 24 24" onClick={submitInput} hasText={inputValue.length > 0}>
          <path d="M22,12c0-5.523-4.477-10-10-10S2,6.477,2,12s4.477,10,10,10S22,17.523,22,12z M11,17v-6.586l-2.293,2.293l-1.414-1.414 L12,6.586l4.707,4.707l-1.414,1.414L13,10.414V17H11z" />
        </SubmitIcon>
      </InputContainer>
    </InputOuter>
  )
}

export default ChatInput
