/* eslint-disable camelcase */
import React, { SyntheticEvent, useEffect } from "react"
import { KeyboardArrowRight } from "@styled-icons/material"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import advanced from "dayjs/plugin/advancedFormat"
import { MandolinLogo, VIPIcon } from "assets/icons"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "redux/store"
import { fetchCobrand } from "redux/cobrands"
import { isTablet } from "react-device-detect"
import { getVIPEventURL } from "services/mobileUtils"
import useVIPAllowJoiningSubscription from "hooks/useVIPAllowJoiningSubscription"
import { ExtendedShow } from "views/Tickets"
import { displayEventVIPOneEntry, toEventUrl } from "services/tickets"
import { imageNanoServiceAccessor } from "services/utils"
import { ImageAssetSizeProps, ImageAssetSizeType, ImageAssetSizeValue } from "types/ImageAssets"
import {
  CobrandHeader,
  CobrandHeaderImage,
  HeaderPhoto,
  HeaderPhotoContainer,
  InvisibleDivider,
  LinkContainer,
  MandolinLogoContainer,
  ShowDetailContainer,
  ShowTitle,
  TicketBottom,
  TicketCodeContainer,
  TicketContainer,
  TicketCount,
  TicketDetails,
  TicketDivider,
  TicketStubDateTime,
  TicketStubLabel,
  TicketStubText,
  TransferContainer,
  VIPTagContainer,
  VenueTitle,
} from "./Ticket.styled"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

interface TicketProps {
  show: ExtendedShow
  featured?: boolean
  trackClickEvent?: () => void
  openClaimCodeModal?: (id: string) => void
}

const Ticket: React.FC<TicketProps> = ({
  show,
  featured = false,
  trackClickEvent = () => null,
  openClaimCodeModal = () => null,
}) => {
  const { isNativeVIP, vipEvent, isParty } = useVIPAllowJoiningSubscription(show)
  const { cobrands } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const formattedDate = dayjs(show.dateTime).format("MMM DD YYYY").toUpperCase()
  const formattedTime = dayjs(show.dateTime).tz().format("h:mm A z").toUpperCase()
  const futureDateTime = dayjs(show.dateTime).isAfter(dayjs())
  const onDemandEvent = show.state === "replay" && futureDateTime

  const { claimCodes = [] } = show

  useEffect(() => {
    if (show.cobrands.length && !cobrands[show.cobrands[0]]) {
      dispatch(fetchCobrand(show.cobrands[0]))
    }
  }, [])

  const mandolinIconSize = (): number => {
    if (isTablet && featured) {
      return 25
    }

    if (isTablet) {
      return 20
    }

    return 18
  }

  const vipIconSize = (): number => {
    if (isTablet && featured) {
      return 54
    }

    if (isTablet) {
      return 48
    }

    return 40
  }

  const cobrand = show.cobrands && cobrands ? cobrands[show.cobrands[0]] : null

  const cobrandHeaderImageAssetSizeProps: ImageAssetSizeProps = {
    type: ImageAssetSizeType.ASSET_WIDTH,
    value: ImageAssetSizeValue.WIDTH_350,
  }

  const standardHeaderImageAssetSizeProps: ImageAssetSizeProps = {
    type: ImageAssetSizeType.ASSET_WIDTH,
    value: ImageAssetSizeValue.WIDTH_800,
  }

  const handleTransferClick = (e: SyntheticEvent, showId: string) => {
    e.preventDefault()
    openClaimCodeModal(show.id)
  }

  const availableClaimCodes = claimCodes.filter((code) => !code.startsWith("consumed:")).length
  const showTransferLink = availableClaimCodes > 0 && openClaimCodeModal !== undefined
  return (
    <LinkContainer
      to={toEventUrl(show, vipEvent, isNativeVIP, isParty, onDemandEvent)}
      featured={featured ? 1 : 0}
      onClick={() => {
        if (displayEventVIPOneEntry(vipEvent, show)) {
          window.location.href = getVIPEventURL(show.id, isParty)
        }
        trackClickEvent()
      }}
    >
      <TicketContainer>
        <HeaderPhotoContainer>
          {cobrand && (
            <CobrandHeader headerColor={cobrand?.data?.theme?.elements?.ticketHeader}>
              <CobrandHeaderImage
                src={imageNanoServiceAccessor({
                  imageAssetUrl: cobrand?.data?.theme?.logos?.ticketLogo,
                  imageAssetSizeProps: cobrandHeaderImageAssetSizeProps,
                })}
              />
            </CobrandHeader>
          )}
          <HeaderPhoto
            src={imageNanoServiceAccessor({
              imageAssetUrl: show.headerImg,
              imageAssetSizeProps: standardHeaderImageAssetSizeProps,
            })}
            featured={featured}
            cobrand={cobrand}
          />
          {!cobrand && (
            <MandolinLogoContainer featured={featured}>
              <MandolinLogo width={mandolinIconSize()} />
            </MandolinLogoContainer>
          )}
        </HeaderPhotoContainer>
        <InvisibleDivider>
          {show.external_event && (
            <VIPTagContainer featured={featured}>
              <VIPIcon
                height={vipIconSize()}
                width={vipIconSize()}
                fill={cobrand?.data?.theme?.elements?.ticketHeader}
              />
            </VIPTagContainer>
          )}
        </InvisibleDivider>
        <ShowDetailContainer featured={featured}>
          <ShowTitle variant="h3" gutterBottom="sm" featured={featured}>
            {show.name}
          </ShowTitle>
          <VenueTitle variant="smallCap" gutterBottom="xs" featured={featured}>
            {show.venue?.name}
          </VenueTitle>
          {!onDemandEvent && (
            <TicketDetails>
              <TicketStubText>
                <TicketStubLabel variant="h6">DATE</TicketStubLabel>
                <TicketStubDateTime variant="h5" textColor="#000000">
                  {formattedDate}
                </TicketStubDateTime>
              </TicketStubText>
              <TicketStubText>
                <TicketStubLabel variant="h6">TIME</TicketStubLabel>
                <TicketStubDateTime variant="smallCapBold" textColor="#000000">
                  {formattedTime}
                </TicketStubDateTime>
              </TicketStubText>
            </TicketDetails>
          )}
        </ShowDetailContainer>
        <TicketDivider />
        <TicketBottom featured={featured} cobrand={cobrand}>
          {showTransferLink && !onDemandEvent && (
            <TicketCodeContainer onClick={(e) => handleTransferClick(e, show.id)}>
              <TicketCount>
                {availableClaimCodes}
                {availableClaimCodes > 1 ? " Tickets" : " Ticket"}
              </TicketCount>
              <TransferContainer>
                Transfer
                <KeyboardArrowRight size={14} />
              </TransferContainer>
            </TicketCodeContainer>
          )}
          {onDemandEvent && (
            <TicketCodeContainer>
              <TicketCount>On Demand Access</TicketCount>
            </TicketCodeContainer>
          )}
          {!showTransferLink && !onDemandEvent && (
            <TicketCodeContainer>
              <TicketCount>1 Ticket</TicketCount>
            </TicketCodeContainer>
          )}
        </TicketBottom>
      </TicketContainer>
    </LinkContainer>
  )
}

export default Ticket
