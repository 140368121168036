import styled, { keyframes, css } from "styled-components/macro"
import { invert, rgba, tint, transparentize } from "polished"
import { Link } from "react-router-dom"
import { toRem } from "theme"

export const TileBody = styled.div`
  min-height: 71px;
  padding: 12px 0px 0px 4px;
`

export const Time = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ theme }) => rgba(invert(theme.elements.pageBackground), 0.75)};
  font-size: 13px;
  letter-spacing: 1.4px;
  line-height: 17px;
  margin: 0 0 4px 0;
  text-transform: uppercase;
`

export const Name = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${({ theme }) => theme.elements.pageText};
  display: -webkit-box;
  font-size: 16px;
  line-height: 120%;
  overflow: hidden;
  word-break: break-word;
`

const skeletonAnimation = keyframes`
  from {
    background-position: 200% 0
  }
  to {
    background-position: -200% 0
  }
`

export const Backdrop = styled.div`
  animation: ${skeletonAnimation} 8s infinite;
  background-image: ${({ theme }) => `linear-gradient(
    270deg,
    ${theme.elements.pageBackground},
    ${tint(0.02, theme.elements.pageBackground)},
    ${tint(0.02, theme.elements.pageBackground)},
    ${theme.elements.pageBackground}
  )`};
  background-size: 400% 100%;
  border-radius: 16px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const ImageContainer = styled.div<{ isDisabled?: boolean }>`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  border-radius: 16px;
  background-color: ${({ theme }) => transparentize(0.8, invert(theme.elements.pageBackground))};

  img {
    border-radius: 16px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: cover;
  }
`
export const TicketBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${toRem(48)};
  height: ${toRem(40)};
  border-radius: 0 16px;
  svg {
    color: #000;
    width: 24px;
    height: 24px;
    display: block;
  }
`
export const Container = styled(Link) <{ disabled?: boolean }>`
  position: relative;
  transition: transform 0.2s ease-in-out;
  will-change: transform;
  display: block;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  &:hover {
    transform: translate3d(0, -6px, 0);
    text-decoration: none;
  }
  @media (prefers-reduced-motion) {
    transition: none !important;
  }

  ${ImageContainer} {
    ${({ disabled }) =>
    disabled
      ? css`
            img {
              filter: grayscale(100%);
            }
          `
      : ``}
  }
`
