import { css } from "styled-components"
import { atMediaMin, toRem } from "./utils"

export const typographySpacing = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 40,
}

export const sectionSpacing = {
  xs: 20,
  sm: 40,
  md: 60,
  lg: 80,
  xl: 100,
}

export const sectionContainer = css`
  padding: 0 ${toRem(sectionSpacing.xs)};
  margin: 0 auto;
  max-width: ${toRem(1440)};
  --horizontal-margin: ${toRem(sectionSpacing.xs)};
  ${atMediaMin("md")} {
    padding: 0 ${toRem(sectionSpacing.sm)};
    --horizontal-margin: ${toRem(sectionSpacing.sm)};
  }
  ${atMediaMin("lg")} {
    padding: 0 ${toRem(sectionSpacing.md)};
    --horizontal-margin: ${toRem(sectionSpacing.md)};
  }
`
