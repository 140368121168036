import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface WSState {
  wsConnected: boolean
}

const initialState: WSState = {
  wsConnected: false,
}

const websocket = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    setConnected(state, action: PayloadAction<boolean>) {
      state.wsConnected = action.payload
    },
  },
})

const { actions, reducer } = websocket

export const { setConnected } = actions

export default reducer
