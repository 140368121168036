import styled from "styled-components/macro"
import { transparentize } from "polished"
import { toRem } from "theme/utils"
import { GridOn, RecordVoiceOver } from "@styled-icons/material-sharp"

const Participant = styled.div`
  width: 92%;
  height: 26%;
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(8, 12%);
  grid-template-rows: repeat(2, 49%);
  grid-auto-flow: row;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  @media (max-height: 700px) {
    visibility: hidden;
  }
  @media (max-height: 500px) {
    visibility: hidden;
  }
`
const onmicParticipant = styled.div`
  display: flex;
  flex-grow: 1;
`

const SubmitBtn = styled.button`
  height: 80px;
  border-radius: 40px;
  border: none;
  margin-top: 24px;
  color: #fff;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primarySolid};
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => transparentize(0.5, theme.colors.elevation5)};
    color: rgba(255, 255, 255, 0.4);
    &:hover {
      background-color: ${({ theme }) => transparentize(0.5, theme.colors.elevation5)};
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &:hover {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.primarySolid)};
    color: rgba(255, 255, 255, 0.7);
  }
`

const CompleteCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 220px;
  width: 460px;
  background-color: ${({ theme }) => theme.colors.elevation7};
  border-radius: 10px;
  align-items: center;
  padding: 30px;
`
const CompleteCardText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold}
  letter-spacing: -0.5px;
  line-height: ${toRem(16)};
  font-size: ${toRem(16)};
  color: ${({ theme }) => theme.colors.elevation1};
  padding: 10px 20px;
`

const CardTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold}
  letter-spacing: -0.5px;
  font-size: ${toRem(34)};
  color: ${({ theme }) => theme.colors.elevation1};
  z-index: 1;
`
const ArtistVideoContainer = styled.div<{ count: number }>`
  height: 100%;
  width: 61%;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
`

const AlertBanner = styled.div`
  display: flex;
  background-color: ${({ theme }) => transparentize(0.6, theme.colors.elevation2)};
  flex-direction: row;
  position: absolute;
  top: 20px;
  width: 200px;
  height: 30px;
  border-radius: 15px;
  z-index: 26;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
const AlertText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProLight};
  color: ${({ theme }) => theme.colors.elevation8};
  font-size: 12px;
`

const HostList = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  bottom: 55px;
  left: 20px;
  flex-direction: column;
  width: 200px;
  justify-content: flex-end;
  align-items: flex-start;
`
const InPartyBottomBar = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: 480px) {
    visibility: hidden;
  }
`

const ArtistSpeakerContainer = styled.div<{
  grid: boolean
  onmic: boolean
}>`
  display: flex;
  flex-direction: ${({ grid }) => (grid ? "row" : "column")};
  width: 90%;
  height: ${({ grid }) => (grid ? "fit-content" : "80%")};
  max-height: 80%;
  margin-top: ${({ grid, onmic }) => (!grid && onmic ? "10px" : "45px")};
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  @media (max-height: 500px) {
    height: 65%;
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`

const setWidth = (grid: boolean, onmic: boolean) => {
  if (!grid && onmic) {
    return "30%"
  }
  if (grid && onmic) {
    return "50%"
  }
  if (grid) {
    return "30%"
  }

  return "70%"
}
const MainSpeakersContainer = styled.div<{
  grid: boolean
  onmic: boolean
}>`
  display: ${({ onmic }) => (onmic ? "flex" : "grid")};
  grid-template-columns: ${({ grid }) => (grid ? "repeat(1, 100%)" : "repeat(2, 50%)")};
  grid-template-rows: ${({ grid }) => (!grid ? "repeat(1, 100%)" : "repeat(2, 50%)")};
  margin: 6px;
  width: ${({ grid, onmic }) => setWidth(grid, onmic)};
  height: ${({ grid }) => (grid ? "98%" : "38%")};
  gap: 2px;
  justify-items: center;
  align-items: center;
`

const InPartyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 80px);
  align-items: center;
  position: relative;
  @media (max-height: 500px) {
    justify-content: center;
  }
`
const SizeErrorContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => transparentize(0.2, theme.colors.elevation2)};
`

const LayoutTypeButton = styled.button<{
  color: string
  active: boolean
}>`
  width: 38px;
  height: 23px;
  background-color: ${({ color, active }) => (active ? transparentize(0.5, color) : "transparent")};
  border-radius: 4px;
  border: ${({ color, active }) => (active ? "1px solid #fff" : `1px solid ${color}`)};
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledGridIcon = styled(GridOn)<{ active: boolean }>`
  width: 16px;
  color: ${({ active }) => (active ? "#000" : "#B6B3AE")};
`

const StyledIndividualIcon = styled(RecordVoiceOver)<{ active: boolean }>`
  width: 16px;
  color: ${({ active }) => (active ? "#000" : "#B6B3AE")};
`

const LayoutOptionContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const HandRaiseContainer = styled.div`
  justify-content: center;
  padding: 5px;
  display: flex;
`

const OffMicContainer = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
`

export {
  Participant,
  onmicParticipant,
  ArtistVideoContainer,
  AlertBanner,
  AlertText,
  CompleteCard,
  CardTitle,
  CompleteCardText,
  SubmitBtn,
  HostList,
  InPartyBottomBar,
  ArtistSpeakerContainer,
  InPartyContainer,
  SizeErrorContainer,
  LayoutTypeButton,
  StyledGridIcon,
  StyledIndividualIcon,
  LayoutOptionContainer,
  MainSpeakersContainer,
  HandRaiseContainer,
  OffMicContainer,
}
