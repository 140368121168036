import styled from "styled-components/macro"
import { readableColor, transparentize, rgba } from "polished"

export const JoinWatchPartyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px 24px;
  button {
    margin-top: auto;
    @media (max-width: 1111px) {
      height: 36px;
      width: 224px;
      margin: auto 0;
    }
  }
`
export const ButtonView = styled.div`
  margin-top: auto;
`
export const ExitJoin = styled.div`
  margin-top: 20px;
`
export const JoinBody = styled.div`
  margin: 56px auto 0 auto;
  text-align: center;
  width: 256px;
`
export const KeyInput = styled.input`
  width: 36px;
  height: 40px;
  margin-right: 5px;
  border-radius: 5px;
  border-color: ${({theme}) => theme.elements.chatBackground};
  border-width: 1px;
  text-align: center;
  outline: none;
`

export const NameContainer = styled.div`
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;
  @media (max-width: 1111px) {
    margin-top: 16px;
    margin-bottom: 8px;
  }
`

export const SMSHeader = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
`

export const StepTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  color: ${({ theme }) => theme.elements.chatText};
  font-size: 16px;
  float: right;
  display: inline;
`

export const Emoji = styled.div`
  align-items: center;
  display: flex;
  font-size: 64px;
  justify-content: center;
  line-height: 64px;
  margin-top: auto;
  width: 100%;
  @media (max-width: 1111px) {
    font-size: 48px;
    line-height: 48px;
  }
`

export const PrivacyStatement = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ theme }) => theme.elements.chatText};
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
  a {
    color: ${({ theme }) => theme.elements.chatText};
    text-decoration: underline;
  }
`

export const PartyNameInput = styled.input`
  height: 48px;
  line-height: 48px;
  width: 100%;
  background-color: ${({ theme }) => rgba(theme.elements.chatText, 0.1)};
  color: ${({ theme }) => theme.elements.chatText};
  ::placeholder {
    color: ${({ theme }) => rgba(theme.elements.chatText, 0.6)};
  }
  outline: none;
  border: none;
  padding-left: 10px;
  transition: 0.25s ease;

  &:active,
  &:focus,
  &:hover {
    box-shadow: 0 0 0 3px ${({ theme }) => rgba(theme.elements.chatText, 0.2)};
    border-radius: 1px;
    outline: none;
  }
  @media (max-width: 1111px) {
    height: 36px;
    line-height: 36px;
    font-size: 14px;
  }
`
export const SupportContainer = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    border-top: 1px solid ${({theme}) => theme.elements.buttonBackground || theme.colors.primarySolid};
`
