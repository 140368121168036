import Typography from "components/Typography"
import { invert, mix } from "polished"
import styled from "styled-components"
import { Close } from "styled-icons/material-rounded"
import { atMediaMin } from "theme"

export const BannerContainer = styled.div<{
  bg: string
}>`
  background-color: ${({ bg, theme }) => bg || theme.colors.elevation3};
  padding: 12px 32px 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: sticky;
  top: 0;
  ${atMediaMin("md")} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 60px;
  }
`

export const Button = styled.a<{
  bgColor?: string
  textColor?: string
}>`
  line-height: 1;
  text-decoration: none;
  display: inline-block;
  border: none;
  padding: 6px 14px;
  text-decoration: none;
  background-color: ${({ bgColor }) => bgColor || "#fff"};
  cursor: pointer;
  text-align: center;
  transition: background 150ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: 8px;
  border-radius: 32px;
  white-space: nowrap;
  span {
    text-decoration: none;
    font-size: 14px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${({ bgColor }) => mix(0.85, bgColor || "#fff", invert(bgColor || "#fff"))};
  }
  ${atMediaMin("md")} {
    margin-top: 0px;
    margin-left: 24px;
  }
`

export const ClosedIcon = styled(Close)<{ textColor: string }>`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 10px;
  z-index: 2;
  color: ${({ textColor }) => textColor || "#fff"};
  &:hover,
  &:focus {
    color: ${({ textColor }) => mix(0.85, textColor || "#fff", invert(textColor || "#fff"))};
  }
  ${atMediaMin("md")} {
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
  }
`

export const StyledText = styled(Typography)<{ textColor: string }>`
  white-space: pre-wrap;
  color: ${({ textColor }) => textColor || "#fff"};
  font-weight: 600;
  ${atMediaMin("md")} {
    text-align: center;
  }
`
