/* eslint-disable no-param-reassign  */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface ExternalLoginState {
  accessToken?: string
  result?: string
  token?: string
  userID?: string
}

interface AuthState {
  uid?: string
  isAuthenticated: boolean
  isPending: boolean
  isAdmin?: boolean
  authProvider?: string
  email?: string
  externalLogin?: ExternalLoginState
  externalContentID?: string
  modalOpen?: boolean
}

const initialState: AuthState = {
  isPending: true,
  isAuthenticated: false,
  isAdmin: false,
  externalLogin: {
    accessToken: "",
    result: "pending",
    token: "0",
    userID: "0",
  },
  modalOpen: false,
}

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleAuthModal(state: AuthState, action: PayloadAction<boolean | undefined>) {
      state.modalOpen = action.payload ?? !state.modalOpen
    },
    setAuthData(state: AuthState, action: PayloadAction<AuthState>) {
      const user = action.payload
      if (user.uid) {
        state.uid = user.uid
        state.email = user.email
        state.isAdmin = user.isAdmin
        state.authProvider = user.authProvider
        state.isAuthenticated = true
      }
      state.isPending = false
    },
    setExternalLogin(state: AuthState, action: PayloadAction<ExternalLoginState>) {
      state.externalLogin = action.payload
    },
    setExternalContentID(state: AuthState, action: PayloadAction<string>) {
      state.externalContentID = action.payload
    },
  },
})

const { actions, reducer } = auth

export const { setAuthData, setExternalLogin, setExternalContentID, toggleAuthModal } = actions

export default reducer
