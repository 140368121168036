import React, { useContext } from "react";
import { Toast } from "react-bootstrap";
import { ToastContext } from "../../providers/ToastContext";
import { CheckCircleOutline, ErrorOutline } from "@styled-icons/material-sharp"
import { InfoCircle } from "@styled-icons/boxicons-regular"
import { StyledContainer, StyledToast } from "./ToastConsumer.styled";

const ToastConsumer = () => {
  const { toast, hide } = useContext(ToastContext);

  return (
    <StyledContainer>
      <StyledToast onClose={() => hide()} show={toast.visible} delay={4000} autohide>
        <Toast.Header>
          {toast.error && <ErrorOutline />}
          {toast.info && <InfoCircle />}
          {toast.success && <CheckCircleOutline />}
          <strong>{toast.title}</strong>
        </Toast.Header>
        <Toast.Body>{toast.message}</Toast.Body>
      </StyledToast>
    </StyledContainer>
  );
};

export default ToastConsumer;
