import Backoff from "backo2"
import { remoteConfig } from "firebaseApp"

const fetchBackoff = new Backoff({
  min: 60 * 1000, // 1 mimute
  max: 5 * 60 * 1000, // 5 minutes
  factor: 1.1,
  jitter: 0.5,
})

export const initRemoteConfig = async () => {
  try {
    await remoteConfig.fetchAndActivate()
    fetchBackoff.reset()
  } catch (e: any) {
    setTimeout(initRemoteConfig, fetchBackoff.duration())
  }
}

export const getRemoteConfig = async () => {
  await initRemoteConfig()
  return remoteConfig
}
