/* eslint-disable import/prefer-default-export */
import styled from "styled-components/macro"
import { fadeIn } from "../Card.styled"

export const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 3rem auto;
  animation: ${fadeIn} 0.8s both;
  margin-top: 16px;
`

export const CTAContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-self: end;
`

export const LimitedRun = styled.p`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #a4a9ac;
  font-size: 0.8rem !important;
  line-height: 14px;
  letter-spacing: 1.5px;
  margin: 0;
  font-weight: 600;
`

export const ForSaleError = styled.div`
  color: #e7392b;
  line-height: 0.85rem;
  width: 100%;
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  text-align: center;
`
