import styled, { keyframes } from "styled-components/macro"
import { AddCircle, CloseCircle } from "styled-icons/ionicons-sharp"

export const ReactionHolderContainer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 8px;
  height: 30px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
`
export const ReactionHolder = styled.div`
  outline-style: none;
  user-select: none;
  margin: 0 4px;
  font-size: 16px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 40px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  touch-action: manipulation;
  transition: 0.1s ease background;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  img {
    height: 16px;
    width: 16px;
    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }
`

export const ReactionTunnel = styled.div`
  outline-style: none;
  user-select: none;
  position: absolute;
  overflow-y: hidden;
  bottom: 70px;
  right: 0px;
  z-index: 1;
  width: 250px;
  height: 100%;
`

const floatUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }

  50% {
    transform: translateY(-1000px);
    opacity: 0.8;
  }

  100% {
    transform: translateY(-2000px);
    opacity: 0.8;
  }
`

export const Reaction = styled.div`
  position: absolute;
  animation-delay: ${({ animationDelay }) => animationDelay}s;

  > .reactionY {
    opacity: 0;
    font-size: 18px;
    display: inline-block;
    width: 15px;
    height: 15px;
    animation: ${floatUp} 10s linear;
    transform: translateY(0);
    animation-delay: ${({ animationDelay }) => animationDelay}s;

    &.reactionYImg {
      width: 30px;
      height: 30px;
    }
  }
`

export const CloseIcon = styled(CloseCircle)`
  fill: #ffffff;
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: center;
`

export const OpenIcon = styled(AddCircle)`
  fill: #ffffff;
  width: 20px;
  height: 20px;
  cursor: pointer;
`
