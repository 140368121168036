import styled from "styled-components/macro"
import { rgba } from "polished"

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.elevation2};
  background-image: url("/images/signin_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: scroll;
  @media (max-width: 1524px) {
    background-position: left;
  }
  main {
    padding: 56px 24px;
    @media (max-width: 660px) {
      padding: 36px 36px 0 36px;
    }
    margin: 0 auto;
    min-width: 275px;
    max-width: 375px;
    form {
      display: grid;
      row-gap: 24px;
    }
  }
`

export const Border = styled.div`
  background-image: url(/images/border_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  bottom: 0;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
`

export const Header = styled.h1`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  color: ${({ theme }) => theme.colors.elevation8};
  font-size: 2.25rem;
  letter-spacing: -0.75px;
  line-height: 2.5rem;
  margin-bottom: 24px;
  text-align: center;
  @media (max-width: 660px) {
    margin-bottom: 16px;
  }
  img {
    margin-bottom: 24px;
    @media (max-width: 660px) {
      margin-bottom: 16px;
    }
  }
`

export const FieldError = styled.aside`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ theme }) => theme.colors.primarySolid};
  font-size: 14px;
  padding: 4px 0 0 16px;
`

export const FieldContainer = styled.div`
  background-color: ${({ theme }) => rgba(theme.colors.elevation8, 0.2)};
  border-radius: 8px;
  height: 56px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 100%;
`

export const Label = styled.label`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ theme }) => rgba(theme.colors.elevation8, 0.5)};
  display: block;
  font-size: 12px;
  left: 16px;
  letter-spacing: normal;
  line-height: 15px;
  margin: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 10px;
  white-space: nowrap;
`

export const Underline = styled.div`
  background-color: ${({ error, theme }) => error ? theme.colors.primarySolid : theme.colors.elevation8};
  bottom: 0px;
  height: 2px;
  left: 0px;
  margin: 0;
  position: absolute;
  right: 0px;
  transition: background-color 0.24s cubic-bezier(0.4, 0, 0.3, 1);
`

export const Input = styled.input`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.elevation8};
  font-size: 14px;
  height: 100%;
  line-height: 18px;
  padding: 28px 4px 8px 16px;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => rgba(theme.colors.elevation8, 0.5)};
  }

  &:active,
  &:focus {
    outline: none;
    & + ${Underline} {
      background-color: ${({ theme }) => theme.colors.confirmation};
    }
  }
`
