import styled from "styled-components"
import { css } from "styled-components/macro"
import { Star, StarFill } from "@styled-icons/bootstrap"
import { CheckCircleOutline } from "@styled-icons/material-rounded"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow-y: scroll;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 600;
  height: 100vh;
  background-color: ${({ theme }) => theme.elements.pageBackground};
`
const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 660px) {
    width: 308px;
  }
  @media (max-height: 400px) {
    width: 308px;
    padding-top: 40px;
    justify-content: flex-start;
  }
`
const StarContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 32px;
`

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
`

const SurveyRowContainer = styled.button<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 4px;
  border-color: #fff;
  border-width: 0px 0px 0px 2px;
  background-color: ${({ selected, theme }) => (selected ? "#006EFB" : theme.colors.elevation2)};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  width: 100%;
`

const ThanksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  padding: 0 20px;
  align-items: center;
`

const starCss = css`
  cursor: pointer;
  height: 40px;
  width: 40px;
  fill: #ffe073;
  &:hover,
  &:focus {
    opacity: 0.6;
  }
  @media (max-width: 660px) {
    height: 30px;
    width: 30px;
  }
`
const StarIcon = styled(Star)`
  ${starCss}
`
const StarFillIcon = styled(StarFill)`
  ${starCss}
`

const CheckIcon = styled(CheckCircleOutline)`
  height: 16px;
  width: 16px;
  fill: #000;
`
const SubmitCta = styled.button<{ reverse?: boolean }>`
  background-color: #fff;
  border: none;
  width: 366px;
  padding: 12px 24px 12px 22px;
  border-radius: 60px;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  justify-content: center;
  align-items: center;
  gap: 10px;
  &:hover {
    opacity: 0.9;
  }
  @media (max-width: 660px) {
    padding: 6px 24px 6px 22px;
    width: 280px;
  }
`
const SkipCta = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }
`
const ReasonContainer = styled.div`
  margin-bottom: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  @media (max-width: 660px) {
    margin-bottom: 6px;
  }
`
const ReasonText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #fff;
`

export {
  Container,
  SurveyContainer,
  StarContainer,
  CtaContainer,
  SurveyRowContainer,
  ThanksContainer,
  StarIcon,
  StarFillIcon,
  CheckIcon,
  SubmitCta,
  SkipCta,
  ReasonContainer,
  ReasonText,
}
