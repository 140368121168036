import Typography from "components/Typography"
import React from "react"
import { imageNanoServiceAccessor } from "services/utils"
import { ImageAssetSizeProps, ImageAssetSizeType, ImageAssetSizeValue } from "types/ImageAssets"
import {
  Figure,
  LinkContainer,
  CommerceItemImage,
  FigureCaption,
  ItemImageContainer,
} from "./CommerceItem.styled"

interface Props {
  url: string
  image: string
  title?: string
  subtitle?: string
  padding?: number
  onClick?: () => void
  target?: "_blank" | "_self"
  fullWidth?: boolean
}

const imageAssetSizeProps: ImageAssetSizeProps = {
  type: ImageAssetSizeType.ASSET_WIDTH,
  value: ImageAssetSizeValue.WIDTH_450,
}
const CommerceItem = ({
  url,
  image,
  title,
  subtitle,
  padding = 0,
  onClick = () => {},
  target = "_blank",
  fullWidth,
}: Props) => {
  return (
    <LinkContainer
      href={url}
      target={target}
      aria-label={`${title} – ${subtitle}`}
      onClick={onClick}
    >
      <Figure fullWidth={fullWidth}>
        <ItemImageContainer data-testid="item-image-container" spacing={padding}>
          <CommerceItemImage
            src={imageNanoServiceAccessor({ imageAssetUrl: image, imageAssetSizeProps })}
            alt={`Image for ${title}`}
          />
        </ItemImageContainer>
        <FigureCaption>
          {title ? (
            <Typography variant="h6" gutterBottom="xs">
              {title}
            </Typography>
          ) : null}
          {subtitle ? <Typography variant="smallCap">{subtitle}</Typography> : null}
        </FigureCaption>
      </Figure>
    </LinkContainer>
  )
}

export default CommerceItem
