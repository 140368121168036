import { Network } from "opensea-js"

const DevLofiConfig = {
  FIREBASE: {
    apiKey: "AIzaSyAmFx9h19NG76pzCZCKISyi2iwXAidNVq0",
    authDomain: window.location.host,
    localhostAuthDomain: "mandolin-dev-lofi.firebaseapp.com",
    databaseURL: "https://mandolin-dev-lofi.firebaseio.com",
    projectId: "mandolin-dev-lofi",
    storageBucket: "mandolin-dev-lofi.appspot.com",
    messagingSenderId: "789879229699",
    appId: "1:789879229699:web:693eeddc32d58eaa6b2d36",
  },
  MUX: {
    muxDataEnvKey: "7daj2h2b9cap889ql72tvp46d",
  },
  OPTIMIZELY: {
    optimizelyKey: "2AEUhRAm7QgSFjZTmyhDN",
  },
  ETHEREUM: {
    proxyApi: "https://mandolin-nft-proxy-staging.herokuapp.com",
    network: Network.Main,
    openseaApi: "https://api.opensea.io/api/v1",
    openseaApiKey: "0abe3a760e5240278f6bdeea7de5f7c8",
    provider: "wss://eth-rinkeby.alchemyapi.io/v2/lvrMD2fc5gdpNHgBWC_u59WUxY27gDr6",
    wethContractAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  },
  STRAPI: {
    restURL: "https://content-dev-testing.mandolin.com",
  },
  API: {
    wsURL: "wss://ws.dev-lofi.mndl.in",
    restURL: "https://api-dev-lofi.mandolin.com",
  },
  MAGIC: {
    magicPublicKey: "pk_live_D33B4E388775C4ED",
    chainId: 80001,
  },
  ALCHEMY: {
    provider: "https://polygon-mumbai.g.alchemy.com/v2/HA75OM12AyYPEZ-3k520J2bGxU2Fp8kN",
  },
  IPFS: {
    gateway: "https://mandolin.mypinata.cloud/ipfs/",
  },
  MANDOLIN: {
    contractMap: "https://9364xtry97.execute-api.us-east-2.amazonaws.com/contract-map",
    openSeaAssetUrl: "https://testnets.opensea.io/assets/mumbai",
  },
  PRESENTER: {
    baseUrl: "https://present.dev.mandolin.com",
  },
}

export default DevLofiConfig
