import styled from "styled-components/macro"
import { sectionContainer } from "theme/spacing"

const Container = styled.div`
  ${sectionContainer}
`

const Screen = styled.div`
  display: flex;
  flex-direction: column;
`

const Page = styled.div`
  color: ${({ theme }) => theme.colors.elevation8};
`

const EventsGrid = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  margin-top: 32px;
`

export { Container, Screen, Page, EventsGrid }
