/* eslint-disable camelcase */
/* eslint-disable no-param-reassign  */
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type DailyParticipant = {
  user_name: string
  user_id: string
}
interface VIPState {
  guests: DailyParticipant[]
}

const initialState: VIPState = {
  guests: [],
}

const VIPParty = createSlice({
  name: "VIP Party",
  initialState,
  reducers: {
    setVIPGuests(state: VIPState, action: PayloadAction<DailyParticipant[]>) {
      state.guests = action.payload
    },
  },
})

const { actions, reducer } = VIPParty

export const { setVIPGuests } = actions

export default reducer
