import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "redux/store"
import { TicketState } from "redux/tickets"
import { HomeShow } from "views/Home"

export interface CarouselState {
  lastActiveSlide: number
  activeSlide: number
  carouselCanAnimate: boolean
  shows: HomeShow[]
  carouselIsNavigating: boolean
  carouselItemWidth: number
  carouselContainerWidth: number
}

interface CarouselProps extends CarouselState {
  setCarouselState: React.Dispatch<React.SetStateAction<CarouselState>>
  navigateToSlide: (index: number) => void
}

const initialState = {
  lastActiveSlide: 0,
  activeSlide: 0,
  carouselCanAnimate: true,
  carouselIsNavigating: false,
  carouselItemWidth: 0,
  carouselContainerWidth: 0,
  shows: [],
}

const CarouselProviderContext = createContext<CarouselProps>({
  ...initialState,
  setCarouselState: () => {},
  navigateToSlide: () => {},
})

const useCarousel = () => {
  const context = useContext(CarouselProviderContext)
  if (context === undefined) {
    throw new Error("useCarousel must be used within a CarouselProvider")
  }
  return context
}

const CarouselProvider: React.FC<{ shows: HomeShow[] }> = ({ shows, ...rest }) => {
  const { tickets } = useSelector((state: RootState) => ({
    tickets: state.tickets,
  }))
  const [state, setCarouselState] = useState<CarouselState>({ ...initialState, shows })

  useEffect(() => {
    if (!tickets.loading) {
      const SHOWS_WITH_TICKET = shows.map((show) => ({
        ...show,
        isTicketPurchased: tickets.data.some((ticket: TicketState) => ticket.showId === show.id),
      }))
      setCarouselState((prev) => ({ ...prev, shows: SHOWS_WITH_TICKET }))
    }
  }, [tickets.loading])

  const navigateToSlide = (index: number) => {
    if (index < -1) {
      setCarouselState((prev) => ({
        ...prev,
        activeSlide: shows.length - 1,
        carouselCanAnimate: false,
        lastActiveSlide: prev.activeSlide,
      }))
    } else if (index >= shows.length + 1) {
      setCarouselState((prev) => ({
        ...prev,
        lastActiveSlide: prev.activeSlide,
        activeSlide: 0,
        carouselCanAnimate: false,
      }))
    } else {
      setCarouselState((prev) => ({
        ...prev,
        lastActiveSlide: prev.activeSlide,
        activeSlide: index,
      }))
    }
  }
  const value = useMemo(() => ({ ...state, setCarouselState, navigateToSlide }), [state])
  return <CarouselProviderContext.Provider value={value} {...rest} />
}

export { CarouselProvider, useCarousel }
