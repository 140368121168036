import styled from "styled-components/macro"
import { readableColor } from "polished"

const PreviewContainer = styled.div`
  padding: 16px 24px;
  width: 100%;
  background: ${({ theme }) => theme.elements.chatBackground};
  display: none;
  @media (max-width: 1111px) {
    display: flex;
    flex-direction: column;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.elements.chatText};
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  font-size: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
`

const ViewAll = styled.div`
  color: ${({ theme }) => theme.elements.chatText};
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  font-size: 11px;
  letter-spacing: 1px;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

const Avatar = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 16px;
`

const Username = styled.div`
  color: ${({ theme }) => theme.elements.chatText};
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 14px;
`

const Message = styled.div`
  color: ${({ theme }) => theme.elements.chatText};
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: 14px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Body = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
  cursor: pointer;
`

export { PreviewContainer, Title, ViewAll, Avatar, Username, Message, Header, Body }
