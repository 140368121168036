import styled from "styled-components/macro"

const Container = styled.div<{ showChatPanel: boolean }>`
  height: 100vh;
  transition: 0.2s ease width;
  width: ${({ showChatPanel }) => (showChatPanel ? "calc(100% - 300px)" : "100%")};
  display: flex;
  z-index: 0;
  justify-content: center;
  position: relative;
  text-align: center;
  overflow: hidden;
  position: relative;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 180px;
  padding: 40px 60px;
`
const ErrorTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 26px;
  padding-bottom: 8px;
`

const WSConnectionErrorMessage = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  background-color: ${({ theme }) => theme.colors.error};
  color: white;
  border-radius: 16px;
  padding: 8px;
  line-height: 1.2;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  font-size: 14px;
  text-align: center;
  button {
    appearance: none;
    text-decoration: underline;
    background: transparent;
    margin: 0;
    padding: 0;
    color: white;
    border: none;
  }
`

export { Container, InnerContainer, ErrorTitle, WSConnectionErrorMessage }
