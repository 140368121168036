import styled from "styled-components"
import { Error, Warning } from "@styled-icons/material-rounded"

export const ChatContainer = styled.div`
  display: flex;
  height: calc(100vh - 40px);
  width: 300px;
  position: relative;
`

export const CardContainer = styled.div``
export const VIPJoinContainer = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.elevation1};
  z-index: 400;
  display: flex;
  justify-content: center;
`
export const NameModalContainer = styled.div`
  z-index: 3000;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 740px) {
    width: 90%;
  }
`

export const NameModal = styled.div`
  width: 366px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const NameInput = styled.input`
  width: 100%;
  height: 48px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.elevation5};
  border: 0.5px solid ${({ theme }) => theme.colors.elevation5};
  border-radius: 4px;
  margin-bottom: 32px;
  padding: 5px 20px;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.elevation7};
  }
`
export const InputLabel = styled.div`
  width: 100%;
  font-size: 14px;
  ${({ theme }) => theme.fonts.TTNormsPro};
  color: ${({ theme }) => theme.colors.elevation5};
  margin-left: 32px;
`

export const WelcomeText = styled.div`
  font-size: 24px;
  ${({ theme }) => theme.fonts.TTNormsPro};
  color: ${({ theme }) => theme.colors.elevation7};
  margin-bottom: 32px;
`

export const ErrorMessage = styled.div`
  width: 100%;
  font-size: 16px;
  ${({ theme }) => theme.fonts.TTNormsPro};
  color: ${({ theme }) => theme.colors.error};
  margin-top: 12px;
  text-align: center;
`

export const JoinButton = styled.button<{ active: boolean }>`
  display: flex;
  ${({ theme }) => theme.fonts.TTNormsPro};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.elevation7 : theme.colors.elevation5};
  opacity: ${({ active }) => (!active ? "0.7" : "1")};
  border-radius: 20px;
  font-size: 14px;
  height: 40px;
  padding: 2px 6px;
  border: ${({ active, theme }) => (!active ? `1px solid ${theme.colors.elevation5}` : "none")};
  width: 100%;
  gap: 14px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: ${({ active }) => (active ? "pointer" : "default")};
    opacity: ${({ active }) => (active ? "0.9" : "0.7")};
  }
`

export const StyledChatButton = styled.button`
  height: 35px;
  display: flex;
  flex-direction: row;
  padding: 8px 14px;
  background-color: transparent;
  border: solid 2px #fff;
  gap: 10px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 740px) {
    display: none;
  }
  @media (max-height: 500px) {
    display: none;
  }
`
export const OptionsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
`

export const OptionsButton = styled.button`
  display: flex;
  flex-direction: row;
  padding: 2px 10px;
  background-color: ${({ theme }) => theme.colors.elevation3};
  border: none;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
export const HostAlertModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.elevation1};
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HostAlertModal = styled.div`
  width: 320px;
  background-color: ${({ theme }) => theme.colors.elevation3};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`
export const StyledError = styled(Error)`
  color: #ffe073;
  width: 40px;
  margin-bottom: 8px;
`
export const StyledWarning = styled(Warning)`
  color: #ff7575;
  width: 33px;
  margin-bottom: 8px;
`
export const AlertModalConfirmCta = styled.button`
  width: 100%;
  height: 48px;
  background-color: #fff;
  border: none;
  border-radius: 24px;
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const AlertModalCloseCta = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  ${({ theme }) => theme.fonts.TTNormsPro};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
export const AccessAlertContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const EntryContainer = styled.div`
  padding-top: 5vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
