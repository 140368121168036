import styled, { css } from "styled-components"
import { AddAPhoto } from "@styled-icons/material-sharp"
import { Modal } from "react-bootstrap"
import { rgba } from "polished"

export const CobrandSurveyContainer = styled(Modal)`
  .modal-dialog {
    .modal-content {
      border-radius: 24px;
      background-color: #333;
      background-clip: padding-box;
      border: none;
      color: #fff;
    }
    max-width: 586px;
    @media (max-width: 760px) {
      margin: 0;
      max-width: 100vw;
    }
  }

  &.highlight-incomplete {
    .incomplete {
      border-left: 4px solid red;
      padding-left: 16px;
    }
  }

  .error {
    border-left: 4px solid red;
    padding-left: 16px;
  }
`

export const CobrandSurveyHeaderFooter = styled.div`
  margin: 0px 24px 24px 24px;
`

export const CobrandSurveyLogo = styled.div`
  width: 200px;
  height: 120px;
  background: url(${({ theme }) => theme?.logos?.customHeaderLogo}) center center no-repeat;
  background-size: contain;
  margin: 24px auto;

  @media (min-width: 600px) {
    width: 400px;
    height: 200px;
  }
`

export const SurveyQuestion = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px 24px 24px;
`

const inputCss = css`
  background-color: ${({ theme }) => rgba(theme.elements.pageText, 0.1)};
  color: ${({ theme }) => theme.elements.pageText};
  ::placeholder {
    color: ${({ theme }) => rgba(theme.elements.pageText, 0.6)};
  }
  border-radius: 31px;
  border: none;
  height: 54px;
  outline: none;
  padding: 16px 24px;
  width: 100%;
  &:focus,
  &:active {
    box-shadow: 0 0 0 3px #878888;
    border-radius: 3px;
  }
`

export const SurveyQuestionInput = styled.input`
  ${inputCss}
`

export const SurveyQuestionTextarea = styled.textarea`
  ${inputCss}
  height: 200px;
`

export const SurveyQuestionLabel = styled.label`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  font-size: 14px;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
`

export const SurveyQuestionCheckboxContainer = styled.div`
  margin: 8px 0 0 24px;
  line-height: 24px;

  input[type="checkbox"] {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    vertical-align: middle;
    margin-right: 4px;
  }

  label {
    margin-bottom: 0;
    vertical-align: middle;
  }
`

export const SurveyFileInput = styled.input`
  display: none;
`

export const SurveyPhotoPreview = styled.div`
  ${inputCss}
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const SurveyPhotoImg = styled.img`
  max-width: 180px;
  max-height: 180px;
  border-radius: 21px;
`

export const SurveyPhotoIcon = styled(AddAPhoto)`
  width: 50px;
  height: 50px;
`

export const SurveyAdminLocked = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  z-index: 300;
`
