import styled from "styled-components/macro"
import { transparentize } from "polished"

interface ArtistProps {
  count: number
  active: boolean
}

const FanContainer = styled.div`
  width: 160px;
  max-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${({ theme }) => transparentize(0.4, theme.colors.elevation3)};
  position: absolute;
  right: 16px;
  bottom: 16px;
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

const HostContainer = styled.div<{
  active: boolean
  hidden: boolean
}>`
  width: 160px;
  height: ${({ hidden }) => (hidden ? "0px" : "auto")};
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 5px;
  background-color: ${({ theme }) => transparentize(0.4, theme.colors.elevation3)};
  border: ${({ active, theme }) =>
    `2px solid ${active ? theme.colors.primarySolid : "transparent"}`};
  video {
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`
const ArtistContainer = styled.div<ArtistProps>`
  flex: 1;
  display: flex;
  max-height: 100%;
  min-height: ${({ count }) => (count < 5 ? 50 : 33)}%;
  width: ${({ count }) => (count === 1 && 100) || (count === 3 && 33) || 50}%;
  position: relative;
  overflow: hidden;
  border: ${({ active, theme }) =>
    `2px solid ${active ? theme.colors.primarySolid : "transparent"}`};
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`

const HostTag = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 20px;
  width: 40px;
  z-index: 1000;
  background-color: ${({ theme }) => transparentize(0.6, theme.colors.elevation1)};
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.elevation6};
  font-family: ${({ theme }) => theme.fonts.TTNormsProExtraBold};
`

export { HostContainer, FanContainer, ArtistContainer, HostTag }
