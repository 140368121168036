import styled from "styled-components/macro"
import { BidState } from "components/NftCollection/Card/ForBid/ForBid"

export interface ContainerProps {
  disabled: boolean
  state: BidState
}

const CARD_HEIGHT = "580px"
const BORDER_HEIGHT = "0.75rem"

export const CardBorder = styled.div<{ state: BidState }>`
  height: calc(${CARD_HEIGHT});
  min-width: 300px;
  border-radius: 1rem 1rem 0.75rem 0.75rem;
  padding-bottom: ${BORDER_HEIGHT};
  background: ${(props) =>
    props.state === BidState.HIGH_BID
      ? "linear-gradient(155.46deg, #3FD27A 0%, #3FD2CA 100%)"
      : props.state === BidState.OUTBID
      ? "linear-gradient(155.46deg, #FF766D 0%, #FFB55E 100%)"
      : "#ffffff"};
`

export const Container = styled.div<ContainerProps>`
  height: calc(${CARD_HEIGHT} - (${BORDER_HEIGHT}));
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 52.25% 47.75%;
  grid-template-areas:
    "header"
    "main";
  background-color: #ffffff;
  border-radius: ${(props) => (props.state === BidState.NO_BID ? "1rem" : "1rem 1rem 0  0")};
  color: #000;
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.8 : 1)};
  hr {
    margin: 0.5rem 0;
  }
  small {
    color: #565b60;
    line-height: 0.75rem;
  }
`

export const CardImage = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: zoom-in;
  grid-area: header;
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 300px;
`
