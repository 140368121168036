import styled from "styled-components/macro"
import { readableColor } from "polished"
import { ArrowLeftShort } from "@styled-icons/bootstrap"

export const Title = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  color: ${({ theme }) => theme.elements.chatText};
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
  margin: 16px 0 16px 0;
  text-align: ${({ center }) => (center ? "center" : "left")};
  width: 100%;
  @media (max-width: 1111px) {
    font-size: 16px;
    line-height: 18px;
    margin: 8px 0 8px 0;
  }
`

export const SubTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ theme }) => theme.elements.chatText};
  font-size: 16px;
  line-height: 19px;
  text-align: ${({ center }) => (center ? "center" : "left")};
  margin-bottom: 24px;
  @media (max-width: 1111px) {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 8px;
  }
`

export const WarningMessage = styled.div`
  font-size: 12px;
  color: #fe532f;
`

export const BackArrow = styled(ArrowLeftShort)`
  cursor: pointer;
  height: 26px;
  width: 26px;
  fill: ${({ theme }) => theme.elements.chatText};
  &:hover,
  &:focus {
    opacity: 0.6;
  }
`

export const BackTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  color: ${({ theme }) => theme.elements.chatText};
  font-size: 16px;
  margin-left: 8px;
  display: inline;
`
