import Config from "config/config"
import { Magic } from "magic-sdk"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { updateUserMagicWallets } from "services/users"
import { trackWalletConnect } from "services/analytics"
import Web3 from "web3"

const { chainId, magicPublicKey } = Config.MAGIC
const { provider } = Config.ALCHEMY
/**
 * NOTE: when connecting to a testnet, Test API keys from the Magic dashboard must be used (Live API keys for eth mainnet)
 */

const customNodeOptions = {
  rpcUrl: provider,
  chainId,
}

// Setting network to Matic
export const magic = new Magic(magicPublicKey, {
  network: customNodeOptions,
})

magic.preload()

export const maticWeb3 = new Web3(magic.rpcProvider as any)

interface User {
  email: string
  uid: string
  magicWallets: string[]
}
interface StateSelector {
  user: User
}

export default function useMagic() {
  const {
    user: { email, uid, magicWallets = [] },
  } = useSelector<any, StateSelector>((state) => state)

  const [isConnecting, setIsConnecting] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [address, setAddress] = useState<string | null>(null)

  const connect = useCallback(async () => {
    setIsConnecting(true)
    try {
      await magic.auth.loginWithMagicLink({
        email,
      })
      const meta = await magic.user.getMetadata()

      if (!magicWallets.includes(meta.publicAddress as string)) {
        await updateUserMagicWallets(uid, [...magicWallets, meta.publicAddress])
        trackWalletConnect("magic")
      }

      setAddress(meta.publicAddress)
      setIsConnected(true)
    } catch (e) {
      console.error(e)
      setIsConnected(false)
    } finally {
      setIsConnecting(false)
    }
  }, [email])

  const disconnect = useCallback(async () => {
    await magic.user.logout()
    setIsConnected(false)
    setAddress(null)
  }, [])

  useEffect(() => {
    const checkLogin = async () => {
      setIsConnecting(true)
      if (magicWallets.length > 0) {
        setAddress(magicWallets[0])
      }
      try {
        const userLoggedIn = await magic.user.isLoggedIn()

        if (userLoggedIn) {
          const meta = await magic.user.getMetadata()
          if (meta.email !== email) {
            await disconnect()
            return
          }
          setAddress(meta.publicAddress)
          setIsConnected(userLoggedIn)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsConnecting(false)
      }
    }
    if (email) {
      checkLogin()
    }
  }, [email])

  return { connect, disconnect, isConnecting, isConnected, address }
}
