import Slider from "react-slick"
import styled from "styled-components/macro"

export const WaitingCarouselContainer = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 80px);
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
`
export const WaitingCarouselInner = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
`
export const StyledSlider = styled(Slider)`
  height: 100%;
  width: 347px;
  .slick-slide {
    padding-left: 10px;
  }

  .slick-next:before {
    content: "";
  }
  .slick-prev:before {
    content: "";
  }
  @media (max-width: 740px) {
    padding-bottom: 100px;
    width: 315px;
  }
`
export const SliderContainer = styled.div`
  padding-right: 8px;
  height: 100%;
`
