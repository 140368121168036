import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { auth as firebaseAuth, firestore } from "firebaseApp"
import { datadogRum } from "@datadog/browser-rum"
import { wsURL } from "services/utils"
import Loading from "components/shared/Loading/Loading"
import { setUserData } from "redux/user"
import { setAuthData } from "redux/auth"
import { setConnected } from "redux/websocket"
import { initWsClient } from "services/wsClient"

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const {
    auth: { externalLogin, uid, isPending },
    user: { isPending: userIsPending },
  } = useSelector((state) => state)

  const { result, token } = externalLogin

  useEffect(() => {
    const connectWebsocket = async () => {
      try {
        initWsClient(wsURL)
        dispatch(setConnected(true))
      } catch (error) {
        console.error("connectWebsocket: ", error.message)
      }
    }
    if (uid && !isPending) {
      connectWebsocket()
    }
  }, [uid, isPending])

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
      const tokenResult = await user?.getIdTokenResult(true)
      dispatch(
        setAuthData({
          uid: user?.uid,
          email: user?.email,
          isAdmin: tokenResult?.claims?.admins,
          authProvider: user?.providerData[0]?.providerId,
        }),
      )
      if (user) {
        // analytics.setUserId(user.uid)
        window.localStorage.setItem("userInfo", JSON.stringify(user))
        window.analytics.identify(user.uid)
        datadogRum.setUser({
          id: user?.uid,
          email: user?.email,
          name: user?.displayName,
        })
      }
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (!uid && result === "success") {
      firebaseAuth
        .signInWithCustomToken(token)
        .then((data) => {
          const { uid: newUid, displayName, email } = data
          dispatch(setAuthData({ uid: newUid, name: displayName, email, authProvider: "AXS" }))
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [result])

  useEffect(() => {
    let unsubscribe = () => null
    if (uid) {
      unsubscribe = firestore
        .collection("users")
        .doc(uid)
        .onSnapshot(async (snapshot) => {
          if (snapshot.exists) {
            const { createdOn, features = [], ...snapshotData } = snapshot.data()
            dispatch(
              setUserData({
                ...snapshotData,
                uid,
                features,
              }),
            )
          }
        })
    } else {
      dispatch(setUserData({}))
    }
    return () => unsubscribe()
  }, [uid])

  if (isPending || userIsPending) return <Loading />

  return children
}

export default AuthProvider
