import ImageLoader from "components/ImageLoader"
import styled from "styled-components/macro"
import { skeletonStyles, toRem } from "theme/utils"

type FigureProps = {
  fullWidth?: boolean
}

const Figure = styled.figure<FigureProps>`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => `${toRem(fullWidth ? 360 : 220)}`};
  height: 100%;
  padding: 0;
  margin: 0;
`

const ItemImageContainer = styled.div<{ spacing: number }>`
  ${skeletonStyles}
  overflow:hidden;
  border-radius: ${toRem(16)};
  margin-bottom: ${toRem(16)};
  padding: ${({ spacing }) => spacing}px;
  height: ${toRem(220)};
  @media (prefers-reduced-motion: no-preference) {
    transition: transform 320ms ${({ theme }) => theme.defaults.cubicBezier};
  }
`

const LinkContainer = styled.a`
  display: block;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
    ${ItemImageContainer} {
      transform: translate(0, -${toRem(10)});
    }
  }
`

const CommerceItemImage = styled(ImageLoader)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: transparent;
  object-fit: cover;
`

const FigureCaption = styled.figcaption`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h6 {
    /* hack to make sure we clip at 2 lines */
    --max-lines: 2;
    --lh: calc(1rem * 1.1);
    max-height: calc(var(--lh) * var(--max-lines));
    /* modern browser ellipsis */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`

export { LinkContainer, Figure, CommerceItemImage, FigureCaption, ItemImageContainer }
