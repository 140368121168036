/* eslint-disable import/prefer-default-export */
import styled from "styled-components/macro"
import { Timelapse } from "@styled-icons/material-rounded"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 145px;
`

export const Content = styled.div`
  display: grid;
  grid-gap: 0.4rem;
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
`

export const Label = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #565b60;
  font-size: 0.9rem;
  line-height: 16px;
  text-align: center;
  font-weight: 700;
  margin-bottom: -7px;
`

export const Icon = styled(Timelapse)`
  color: #565b60;
  width: 1.5rem;
  height: 1.5rem;
`
