import React, { useEffect } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { Container } from "./styled"
import AccountNav from "./AccountNav/AccountNav"
import EditProfile from "./EditProfile"
import Notifications from "./Notifications"
import ChangePassword from "./ChangePassword"
import Wallet from "./Wallet"

const Account = () => {
  const { auth } = useSelector((state) => state)
  const history = useHistory()

  if (!auth.isAuthenticated) {
    history.push("/")
  }

  useEffect(() => {
    window.analytics.page()
  }, [])

  return (
    <Container>
      <AccountNav />
      <Switch>
        <Route path="/account/edit-profile">
          <EditProfile />
        </Route>
        <Route path="/account/notifications">
          <Notifications />
        </Route>
        <Route path="/account/change-password">
          <ChangePassword />
        </Route>
        <Route path="/account/wallet">
          <Wallet />
        </Route>
        <Redirect from="/account" to="/account/edit-profile" />
      </Switch>
    </Container>
  )
}

export default Account
