import { css } from "styled-components/macro"
import { atMediaMin, toRem } from "./utils"

export const fallbackFonts =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"

export const fontFamilies = {
  TTNormsProLight: `
    font-family: "TTNormsPro-Light", ${fallbackFonts};
  `,
  TTNormsProRegular: `
    font-family: "TTNormsPro-Regular", ${fallbackFonts};
  `,
  TTNormsProNormal: `
    font-family: "TTNormsPro-Normal", ${fallbackFonts};
  `,
  TTNormsProMedium: `
    font-family: "TTNormsPro-Medium", ${fallbackFonts};
  `,
  TTNormsProDemiBold: `
    font-family: "TTNormsPro-DemiBold", ${fallbackFonts};
  `,
  TTNormsProBold: `
    font-family: "TTNormsPro-Bold", ${fallbackFonts};
  `,
  TTNormsProExtraBold: `
    font-family: "TTNormsPro-ExtraBold", ${fallbackFonts};
  `,
  TTNormsProBlack: `
    font-family: "TTNormsPro-Black", ${fallbackFonts};
  `,
  TTNormsProExtraBlack: `
    font-family: "TTNormsPro-ExtraBlack", ${fallbackFonts};
  `,
}

export const headingStyles = {
  h1: css`
    ${({ theme }) => theme.fonts.TTNormsProExtraBold};
    line-height: 1.1;
    letter-spacing: -0.5px;
    font-size: ${toRem(42 / 1.5)};
    ${atMediaMin("md")} {
      font-size: ${toRem(42 / 1.25)};
    }
    ${atMediaMin("lg")} {
      font-size: ${toRem(42)};
    }
  `,
  h2: css`
    ${({ theme }) => theme.fonts.TTNormsProBold};
    line-height: 1.1;
    letter-spacing: -0.75px;
    font-size: ${toRem(36 / 1.5)};
    ${atMediaMin("md")} {
      font-size: ${toRem(36 / 1.25)};
    }
    ${atMediaMin("lg")} {
      font-size: ${toRem(36)};
    }
  `,
  h3: css`
    ${({ theme }) => theme.fonts.TTNormsProBold};
    line-height: 1.1;
    letter-spacing: -0.5px;
    font-size: ${toRem(28 / 1.5)};
    ${atMediaMin("md")} {
      font-size: ${toRem(28 / 1.25)};
    }
    ${atMediaMin("lg")} {
      font-size: ${toRem(28)};
    }
  `,
  h4: css`
    ${({ theme }) => theme.fonts.TTNormsProBold};
    line-height: 1.1;
    letter-spacing: -0.5px;
    font-size: ${toRem(24 / 1.5)};
    ${atMediaMin("md")} {
      font-size: ${toRem(24 / 1.25)};
    }
    ${atMediaMin("lg")} {
      font-size: ${toRem(24)};
    }
  `,
  h5: css`
    ${({ theme }) => theme.fonts.TTNormsProBold};
    line-height: 1.1;
    letter-spacing: -0.5px;
    font-size: ${toRem(20 / 1.5)};
    ${atMediaMin("md")} {
      font-size: ${toRem(20 / 1.25)};
    }
    ${atMediaMin("lg")} {
      font-size: ${toRem(20)};
    }
  `,
  h6: css`
    ${({ theme }) => theme.fonts.TTNormsProBold};
    line-height: 1.1;
    letter-spacing: -0.5px;
    font-size: ${toRem(16)};
  `,
}

export const typeStyles = {
  body: css`
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    font-weight: 500;
    font-size: ${toRem(16)};
    line-height: 1.4;
  `,
  cap: css`
    ${({ theme }) => theme.fonts.TTNormsProBlack};
    font-size: ${toRem(16)};
    line-height: 1.1;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 800;
  `,
  middleCap: css`
    ${({ theme }) => theme.fonts.TTNormsProBold};
    font-size: ${toRem(16)};
    line-height: 1.125;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  `,
  label: css`
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    font-weight: 500;
    font-size: ${toRem(18)};
    line-height: 1.2;
  `,
  small: css`
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    font-size: 13px;
    line-height: 1.2;
    font-weight: 500;
  `,
  smallBold: css`
    ${({ theme }) => theme.fonts.TTNormsProBold};
    font-size: 13px;
    ${atMediaMin("md")} {
      font-size: 16px;
    }
    line-height: 1.2;
    font-weight: 700;
  `,
  smallItalic: css`
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    font-size: 13px;
    line-height: 1.2;
    font-weight: 500;
    font-style: italic;
  `,
  smallCap: css`
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    font-size: 13px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  `,
  smallCapBold: css`
    ${({ theme }) => theme.fonts.TTNormsProBold};
    font-size: 13px;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  `,
  normalTitle: css`
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    letter-spacing: 0.1px;
  `,
}
