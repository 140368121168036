import React from "react"
import { Container, Body, Header, Subheader } from "./OutdatedBrowser.styled"

const OutdatedBrowser = () => (
  <Container>
    <img src="/logo/PNG/Large/logo-white@3x.png" />
    <Body>
      <Header>Unsupported Browser</Header>
      <Subheader>
        You're using a browser that isn't supported by Mandolin, so we've recommended that you use
        <a href="https://www.google.com/chrome"> Chrome</a> or{" "}
        <a href="https://support.apple.com/downloads/safari">Safari</a> to give you the best
        experience.
      </Subheader>
    </Body>
  </Container>
)

export default OutdatedBrowser
