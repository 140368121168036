import { Network } from "opensea-js"

const DevConfig = {
  FIREBASE: {
    apiKey: "AIzaSyDzVcl7ZD4rP9cuymtoOwPXCL4_joEVJxo",
    authDomain: window.location.host,
    localhostAuthDomain: "mandolin-dev-281521.firebaseapp.com",
    databaseURL: "https://mandolin-dev-281521.firebaseio.com",
    projectId: "mandolin-dev-281521",
    storageBucket: "mandolin-dev-281521.appspot.com",
    messagingSenderId: "316595165859",
    appId: "1:316595165859:web:708cc640f22a0614fcbd16",
  },
  MUX: {
    muxDataEnvKey: "0peht48sril5360qke0boc0aa",
  },
  OPTIMIZELY: {
    optimizelyKey: "D3zeAgsNTh4RgHy89U3Tk",
  },
  ETHEREUM: {
    proxyApi: "https://mandolin-nft-proxy-staging.herokuapp.com",
    network: Network.Rinkeby,
    openseaApi: "https://rinkeby-api.opensea.io/api/v1",
    openseaApiKey: "0abe3a760e5240278f6bdeea7de5f7c8",
    provider: "wss://eth-rinkeby.alchemyapi.io/v2/lvrMD2fc5gdpNHgBWC_u59WUxY27gDr6",
    wethContractAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
  },
  STRAPI: {
    restURL: "https://content-dev-testing.mandolin.com",
  },
  API: {
    wsURL: "wss://ws.dev-jazz.mndl.in",
    restURL: "https://api.dev-jazz.mandolin.com",
  },
  MAGIC: {
    magicPublicKey: "pk_live_D33B4E388775C4ED",
    chainId: 80001,
  },
  ALCHEMY: {
    provider: "https://polygon-mumbai.g.alchemy.com/v2/HA75OM12AyYPEZ-3k520J2bGxU2Fp8kN",
  },
  IPFS: {
    gateway: "https://mandolin.mypinata.cloud/ipfs/",
  },
  MANDOLIN: {
    contractMap: "https://9364xtry97.execute-api.us-east-2.amazonaws.com/contract-map",
    openSeaAssetUrl: "https://testnets.opensea.io/assets/mumbai",
  },
  PRESENTER: {
    baseUrl: "https://present.dev.mandolin.com",
  },
}

export default DevConfig
