import React, { useState } from "react"
import { Close } from "styled-icons/material-rounded"

import ContentCarousel from "components/ContentCarousel"
import EventTile from "components/EventTile"
import CommerceItem from "components/CommerceItem"
import { Button } from "components/shared"
import { StoreIcon } from "components/Watch/VideoContainer/styled"

import { getSubdomain } from "services/utils"

import {
  FestivalContentContainer,
  ShowWrapper,
  MapCTAContainer,
  MapModalContainer,
  MapModalBody,
  GridItemWrapper,
  CloseButton,
} from "../ClientDetail.styled"
import { MandolinClientData } from "../ClientRouter"

export interface StrapiImage {
  width?: number
  height?: number
  url: string
  formats: {
    medium: {
      width?: number
      height?: number
      url: string
    }
  }
}

export enum festivalTileTypes {
  EVENT = "event",
  VENUE = "venue",
  ARTIST = "artist",
  VIP = "VIPExperience",
  CONTENT_COLLECTION = "contentCollection",
}

export enum layoutType {
  GRID = "navigation-items.grid",
  SCHEDULE = "navigation-items.schedule",
  MULTIDAYSTAGE = "navigation-items.multi-day-stage",
}

export enum AspectRatio {
  OneXOne = "onexone",
  SixteennXNine = "sixteenxnine",
}

export type GridListContents = {
  title: string
  description?: string
  image: StrapiImage
  entity: string
  entityType?: festivalTileTypes
  yCord?: number
  xCord?: number
  width?: number
  height?: number
  state: string
  webUrl: string
  order?: number
  aspectRatio?: AspectRatio
}

export type GridVariant = {
  includeIfEventIdExists: string
  excludeIfEventIdExists: string
  // eslint-disable-next-line camelcase
  festivals_grid_items: GridListContents[]
}

const formatUrl = (entity: string, entityType: festivalTileTypes, url?: string) => {
  if (url) return url

  switch (entityType) {
    case festivalTileTypes.ARTIST: {
      return `/artists/${entity}`
    }
    case festivalTileTypes.VENUE: {
      return `/venues/${entity}`
    }
    case festivalTileTypes.EVENT: {
      return `/events/${entity}`
    }
    default: {
      return "/"
    }
  }
}

const FestivalContent: React.FC<{ client: MandolinClientData }> = ({ client }) => {
  const [open, setOpen] = useState(false)

  const contentRenderer = (navigationItem: any) => {
    // eslint-disable-next-line no-underscore-dangle
    switch (navigationItem.__component) {
      case layoutType.GRID: {
        return (
          <FestivalContentContainer // eslint-disable-next-line no-underscore-dangle
            key={`${navigationItem.id}-${navigationItem.__component}`}
          >
            <ContentCarousel
              label={navigationItem.title}
              items={navigationItem.gridItems.sort(
                (a: any, b: any) => (Number(a.order) || 100) - (Number(b.order) || 100),
              )}
              itemRenderer={(item) => {
                return (
                  <GridItemWrapper>
                    <CommerceItem
                      fullWidth={item.aspectRatio && item.aspectRatio === "sixteenxnine"}
                      target={item.webUrl ? "_blank" : "_self"}
                      url={formatUrl(item?.entity, item?.entityType, item?.webUrl)}
                      image={item?.image?.formats?.small?.url || item?.image?.url || ""}
                      title={item?.title}
                      subtitle={item?.description}
                      onClick={() => {
                        window.analytics.track("Item Clicked", {
                          description: "Festival Item Clicked",
                          activityLocation: "Festival Home",
                          url: item?.webUrl,
                        })
                      }}
                    />
                  </GridItemWrapper>
                )
              }}
            />
          </FestivalContentContainer>
        )
      }
      case layoutType.SCHEDULE: {
        return (
          <FestivalContentContainer // eslint-disable-next-line no-underscore-dangle
            key={`${navigationItem.id}-${navigationItem.__component}`}
          >
            <ContentCarousel
              label={navigationItem?.title}
              items={navigationItem?.scheduleItems}
              itemRenderer={(item) => {
                return (
                  <ShowWrapper>
                    <EventTile
                      event={{
                        dateTime: item?.startTime,
                        id: item.entity,
                        toProps: formatUrl(item?.entity, item?.entityType, item?.webUrl),
                        cobrands: [getSubdomain()],
                        isTicketPurchased: false,
                        headerImg: item?.image?.formats?.small?.url || item?.image?.url || "",
                        name: item?.title,
                      }}
                    />
                  </ShowWrapper>
                )
              }}
            />
          </FestivalContentContainer>
        )
      }
      case layoutType.MULTIDAYSTAGE: {
        return (
          <FestivalContentContainer // eslint-disable-next-line no-underscore-dangle
            key={`${navigationItem.id}-${navigationItem.__component}`}
          >
            <ContentCarousel
              // eslint-disable-next-line camelcase
              label={navigationItem?.festival_day_item?.title}
              // eslint-disable-next-line camelcase
              items={navigationItem?.festival_day_item?.stages}
              itemRenderer={(item) => {
                return (
                  <ShowWrapper>
                    <EventTile
                      event={{
                        id: item?.eventId,
                        toProps: formatUrl(item?.eventId, festivalTileTypes.EVENT, item?.webUrl),
                        cobrands: [getSubdomain()],
                        isTicketPurchased: false,
                        headerImg:
                          item?.featuredImage?.formats?.small?.url ||
                          item?.featuredImage?.url ||
                          "",
                        name: item?.stageTitle,
                      }}
                    />
                  </ShowWrapper>
                )
              }}
            />
          </FestivalContentContainer>
        )
      }
      default: {
        return null
      }
    }
  }
  const FESTIVAL_MAP = client?.festivalData?.festivalMapWeb || client?.festivalData?.festivalMap
  return (
    <>
      {FESTIVAL_MAP?.url ? (
        <>
          <MapCTAContainer>
            <Button
              style={{ marginRight: 20 }}
              onClick={() => setOpen((prev) => !prev)}
              height="50px"
              width="200px"
              label="View Full Schedule"
            />
            {getSubdomain() !== "ivoted" && (
              <>
                <StoreIcon href="https://apps.apple.com/us/app/mandolin-live/id1541246796/">
                  <img alt="" src="/images/appStore.svg" />
                </StoreIcon>
                <StoreIcon href="https://play.google.com/store/apps/details?id=com.mandolin.mobile">
                  <img alt="" src="/images/playStore.svg" />
                </StoreIcon>
              </>
            )}
          </MapCTAContainer>
          <MapModalContainer
            onHide={() => setOpen(false)}
            centered
            show={open}
            id="mapModal"
            dialogClassName="map-modal"
          >
            <MapModalBody direction={open ? "in" : "out"}>
              <img src={FESTIVAL_MAP?.url} alt={FESTIVAL_MAP?.title} />
            </MapModalBody>
            <CloseButton onClick={() => setOpen(false)}>
              <Close color="white" />
            </CloseButton>
          </MapModalContainer>
        </>
      ) : null}
      {client?.festivalData?.navigationItems.map(contentRenderer)}
    </>
  )
}

export default React.memo(FestivalContent)
