/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import videojs from "video.js"
import "videojs-mux"
import "videojs-contrib-quality-levels"
import "videojs-hls-quality-selector"

import "video.js/dist/video-js.css"
import "@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css"
import "@silvermine/videojs-airplay/dist/silvermine-videojs-airplay.css"

import { VideoController } from "./styled"

import Config from "../../../config/config"
import { useThrottleFn } from "../../../hooks/useThrottle"
import { setShowUnmuteModal } from "../../../redux/show"
import {
  trackVideoAirplay,
  trackVideoChromecast,
  trackVideoFullscreen,
  trackVideoPause,
  trackVideoPlay,
  trackVideoSeeked,
  trackVideoVolumeChange,
} from "../../../services/analytics"

require("@silvermine/videojs-chromecast")(videojs)
require("@silvermine/videojs-airplay")(videojs)

const Video = ({ url }) => {
  const { id: showId } = useParams()
  const dispatch = useDispatch()
  const {
    auth: { uid },
    show: { name, state, venue: { name: venueName } },
  } = useSelector((store) => store)
  const [playerReady, setPlayerReady] = useState(false)
  const videoRef = useRef()
  const video = useRef()

  const loadQualityControls = (videoJsPlayer) => {
    videoJsPlayer.hlsQualitySelector({
      displayCurrentQuality: true,
    })
  }

  const onVolumeChange = useThrottleFn(() => {
    trackVideoVolumeChange(showId, name, state, venueName)
    window.localStorage.setItem("setVolume", video.current.volume())
  })

  const onSeeked = useThrottleFn(() => trackVideoSeeked(showId, name, state, venueName))

  useEffect(() => {
    const onAirPlay = () => trackVideoAirplay(showId, name, state, venueName)
    const onPlayLog = () => trackVideoPlay(showId, name, state, venueName)
    const onPauseLog = () => trackVideoPause(showId, name, state, venueName)
    const onFullscreen = () => {
      const isFullscreen = video.current.isFullscreen()
      if (isFullscreen) trackVideoFullscreen(showId, name, state, venueName)
    }

    if (playerReady && video.current) {
      video.current.on("play", onPlayLog)
      video.current.on("pause", onPauseLog)
      video.current.on("volumechange", onVolumeChange)
      video.current.on("seeked", onSeeked)
      video.current.on("fullscreenchange", onFullscreen)
    }

    if (videoRef.current) {
      videoRef.current.addEventListener("webkitcurrentplaybacktargetiswirelesschanged", onAirPlay)
    }

    return () => {
      if (video.current) {
        video.current.off("play", onPlayLog)
        video.current.off("pause", onPauseLog)
        video.current.off("volumechange", onVolumeChange)
        video.current.off("seeked", onSeeked)
        video.current.off("fullscreenchange", onFullscreen)
      }

      if (videoRef.current) {
        videoRef.current.removeEventListener(
          "webkitcurrentplaybacktargetiswirelesschanged",
          onAirPlay,
        )
      }
    }
  })

  useEffect(() => {
    if (url && videoRef.current && !video.current) {
      video.current = videojs(
        videoRef.current,
        {
          preload: "auto",
          autoPlay: true,
          liveui: true,
          fluid: false,
          fill: false,
          responsive: true,
          breakpoints: {
            large: 1583
          },
          controls: true,
          aspectRatio: "16:9",
          textTrackSettings: false,
          chromecast: {
            requestTitleFn: () => {
              trackVideoChromecast(showId, name, state, venueName)
              return `${name}`
            },
          },
          techOrder: ["chromecast", "html5"],
          html5: {
            hls: {
              overrideNative: !videojs.browser.IS_ANY_SAFARI,
              smoothQualityChange: true,
            },
            vhs: {
              overrideNative: !videojs.browser.IS_ANY_SAFARI,
            },
            nativeAudioTracks: false,
            nativeVideoTracks: false,
            nativeTextTracks: false,
          },
          plugins: {
            chromecast: {},
            airPlay: {},
            mux: {
              beaconDomain: "mandolin.com",
              debug: false,
              data: {
                page_type: "watchpage",
                env_key: Config.MUX.muxDataEnvKey,
                viewer_user_id: uid,
                player_name: "Mandolin Web Player",
                player_version: "1.0.0",
                player_init_time: Date.now(),
                video_id: showId,
                video_title: name,
                video_content_type: "event",
                video_language_code: "en",
              },
            },
          },
        },
        async () => {
          dispatch(setShowUnmuteModal(true))
          loadQualityControls(video.current)
          setPlayerReady(true)
          video.current.src(url)
        },
      )
    } else if (video.current && video.current.currentSrc() !== url) {
      video.current.src(url)
    }
  }, [url, videoRef, video])

  return (
    <VideoController data-video-js>
      <video
        ref={videoRef}
        id="video"
        controls
        muted
        autoPlay={true}
        webkit-playsinline="true"
        playsInline
        x-webkit-airplay="allow"
        className="video-js vjs-big-play-centered"
        preload="auto"
      >
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser
          that
          <a href="http://videojs.com/html5-video-support/" target="_blank" rel="noreferrer">
            supports HTML5 video
          </a>
        </p>
      </video>
    </VideoController>
  )
}

export default Video
