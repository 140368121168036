import { Network } from "opensea-js"

import merge from "lodash/merge"

import DevConfig from "./development"
import ProdConfig from "./production"
import DevDemoConfig from "./dev-demo"
import DevLofiConfig from "./dev-lofi"
import DevTestingConfig from "./dev-testing"
import StagingConfig from "./staging"

const environment = process.env.REACT_APP_MANDOLIN_ENV

const DefaultConfig = {
  OPTIMIZELY: {
    optimizelyKey: "",
  },
  ETHEREUM: {
    network: Network.Rinkeby,
    openseaApi: "localhost",
    openseaApiKey: "",
    provider: "ws://localhost",
    wethContractAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
  },
  API: {
    wsURL: "ws://localhost",
    restURL: "http://localhost",
    contentURL: "http://localhost:1337",
  },
  IMAGE_ACCESSOR: {
    baseUrl: "https://image-accessor-dev-testing.mandolin.com",
  },
  STRAPI: {
    restURL: "https://content-dev-testing.mandolin.com",
  },
  SDK: {
    /**
     * Default local cache maxAge for GET requests in seconds. It can be
     * overriden on individual SDK methods by passing an options object as the
     * last argument to SDK methods.
     *
     * https://github.com/RasCarlito/axios-cache-adapter#override-instance-config-with-per-request-options
     *
     * @example <caption>Disable cache for request</caption>
     * restClient.getVIPExperience(params, { cache: { ignoreCache: true } })
     *
     * @example <caption>Set cache to 10 seconds</caption>
     * restClient.getVIPExperience(params, { cache: { maxAge: 10 * 1000 } })
     */
    cacheMaxAgeS: 60,
  },
  MAGIC: {
    magicPublicKey: "pk_live_D33B4E388775C4ED",
    chainId: 80001,
  },
  ALCHEMY: {
    provider: "https://polygon-mumbai.g.alchemy.com/v2/HA75OM12AyYPEZ-3k520J2bGxU2Fp8kN",
  },
  IPFS: {
    gateway: "https://mandolin.mypinata.cloud/ipfs/",
  },
  MANDOLIN: {
    contractMap: "https://9364xtry97.execute-api.us-east-2.amazonaws.com/contract-map",
    openSeaAssetUrl: "https://testnets.opensea.io/assets/mumbai",
  },
  DATADOG: {
    applicationId: "316adf3b-ce04-487e-8fc7-dde19e474221",
    clientToken: "pubb40daf2f5d9151fc2d8eec4a8fb351f8",
    site: "datadoghq.com",
    service: "mandolin_app",
    version: process.env.REACT_APP_GIT_SHA,
    sampleRate: 10,
    replaySampleRate: 10,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    env: environment,
  },
  GIT_SHA: process.env.REACT_APP_GIT_SHA,
}

const configMap = {
  development: DevConfig,
  production: ProdConfig,
  "dev-testing": DevTestingConfig,
  "dev-demo": DevDemoConfig,
  "dev-lofi": DevLofiConfig,
  staging: StagingConfig,
}

const Config = merge(DefaultConfig, configMap[environment] ?? {})

const { host } = window.location
if (host.includes("localhost") && Config.FIREBASE?.localhostAuthDomain) {
  Config.FIREBASE.authDomain = Config.FIREBASE.localhostAuthDomain
}

delete Config.FIREBASE.localhostAuthDomain

export default Config
