import React from "react"
import styled, { useTheme } from "styled-components/macro"
import useComponentVisible from "hooks/useComponentVisible"
import { readableColor, rgba } from "polished"
import { Audiotrack } from "styled-icons/material-rounded"
import { Menu } from "styled-icons/remix-fill"
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { mandolinTheme, toRem } from "theme"
import { Button } from "components/shared"
import { auth as firebaseAuth } from "firebaseApp"
import { toggleAuthModal } from "redux/auth"
import Typography from "components/Typography"
import { isCobranded, imageNanoServiceAccessor } from "services/utils"
import {
  trackAccountDropdown,
  trackLoginCTA,
  trackMandolinLogo,
  trackMyKeepsCTA,
  trackMyTicketsCTA,
  trackSignOut,
} from "services/analytics"
import Border from "./Border"
import Logo from "./Logo.tsx"

const MobileContainer = styled.nav`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 72px;
  position: relative;
  user-select: none;
  z-index: 100;
  background-color: ${({ theme }) => theme.elements.headerBar};
`

const LogoWrapper = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
  cursor: pointer;
  display: flex;
  padding-left: 20px;
  img {
    display: block;
    width: 152px;
    @media (max-width: 600px) {
      width: 124px;
    }
  }
`

const MobileRightContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  a {
    color: ${({ theme }) =>
      theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
    padding: 0 40px;
    font-weight: bold;
    font-size: 16px;
  }
  svg {
    width: 50px;
  }
`

const DrawerContainer = styled.div`
  right: 0;
  top: 72px;
  height: auto;
  width: 100%;
  padding: 20px;
  position: fixed;
  z-index: 1000;
  background-color: ${({ theme }) => theme.elements.headerBar};
  display: flex;
  flex-direction: column;
  .mobile-link {
    color: ${({ theme }) =>
      theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
    display: block;
    cursor: pointer;
    background: transparent;
    border: none;
    text-align: left;
    padding: 0;
    margin: ${toRem(15)} 0;
    span,
    div {
      ${({ theme }) => theme.fonts.TTNormsProMedium};
      color: ${({ theme }) =>
        theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
    }
  }
  .signOut:focus,
  .signOut:hover {
    text-decoration: underline;
  }
  .logInButton {
    display: flex;
    border: 2px solid
      ${({ theme }) => theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
    span,
    div,
    svg {
      fill: ${({ theme }) =>
        theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
      color: ${({ theme }) =>
        theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
    }
  }
`

const UserInfo = styled(Link)`
  display: flex !important;
  align-items: center;
  margin-bottom: ${toRem(20)};
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: block;
  }
  div {
    padding-left: ${toRem(16)};
    color: ${({ theme }) =>
      theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
    span {
      display: block;
      color: ${({ theme }) =>
        theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
    }
  }
`

const MobileMenu = styled.button`
  cursor: pointer;
  background: transparent;
  color: ${({ theme }) => theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
  border: none;
  svg {
    width: 30px;
    color: ${({ theme }) =>
      theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
  }
`

export const Icon = styled(Audiotrack)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: ${({ theme }) => rgba(theme.elements.pageBackground || "#000000", 0.9)};
`

export const StyledButton = styled.button`
  appearance: none;
  border: none;
  padding: 0 16px;
  margin: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
  span,
  div {
    color: ${({ theme }) =>
      theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
  }
`

export const SignOutButton = styled.button`
  background: transparent;
  border: none;
  text-align: left;
  padding: 16px 20px;
  color: ${({ theme }) => theme.elements.headerBarText || readableColor(theme.elements.headerBar)};
  &:hover {
    text-decoration: underline;
  }
`

const MobileNav = () => {
  const { ref, triggerRef, isComponentVisible } = useComponentVisible(false)
  const { logos, elements } = useTheme()
  const { avatar, customHeaderLogo, brandLogo, mobileLogo } = logos
  const { user, auth } = useSelector((state) => state)
  const { isAdmin } = auth
  const dispatch = useDispatch()
  const history = useHistory()
  const { email, name, photoUrl } = user

  const hasUser = name && email

  const onLogoClicked = () => {
    trackMandolinLogo()
  }

  const onTicketsClicked = () => {
    trackMyTicketsCTA()
  }

  const onKeepsClicked = () => {
    trackMyKeepsCTA()
  }

  const onAccountClicked = () => {
    trackAccountDropdown()
  }

  const onLoginClicked = () => {
    trackLoginCTA()
    dispatch(toggleAuthModal())
  }

  const signOut = async () => {
    trackSignOut()
    await firebaseAuth.signOut()
    history.push("/")
    window.location.reload()
  }

  const to = isCobranded() ? { pathname: "https://mandolin.com" } : "/"
  const toTickets = isCobranded() ? { pathname: "https://mandolin.com/tickets" } : "/tickets"
  const toAccount = isCobranded() ? { pathname: "https://mandolin.com/account" } : "/account"
  const target = isCobranded() ? "_blank" : undefined

  const imageAssetSizeProps = {
    type: "assetWidth",
    value: "40",
  }

  return (
    <>
      {isComponentVisible && <Cover />}
      <MobileContainer role="navigation">
        <Logo
          to={to}
          target={target}
          customHeaderLogo={customHeaderLogo}
          brandLogo={brandLogo}
          lightBrandLogo={mobileLogo}
          isCobranded={isCobranded()}
          elements={elements}
        />
        <MobileRightContainer>
          <MobileMenu ref={triggerRef}>
            <Menu />
          </MobileMenu>
        </MobileRightContainer>
        {isComponentVisible ? (
          <DrawerContainer ref={ref}>
            {hasUser ? (
              <UserInfo to={toAccount} target={target} onClick={onAccountClicked}>
                <img
                  alt="User avatar icon"
                  src={
                    photoUrl
                      ? imageNanoServiceAccessor({ imageAssetUrl: photoUrl, imageAssetSizeProps })
                      : avatar
                  }
                />
                <div>
                  <Typography variant="h5" tag="span">
                    {name}
                  </Typography>
                  <Typography variant="smallItalic" tag="span">
                    {email}
                  </Typography>
                </div>
              </UserInfo>
            ) : (
              <Button
                onClick={onLoginClicked}
                fontSize="16px"
                height="40px"
                Icon={Icon}
                label="log in"
                letterSpacing="-0.5px"
                radius="100px"
                variant="outline"
                width="100%"
                className="logInButton"
                style={{ backgroundColor: "transparent" }}
              />
            )}
            {hasUser && (
              <>
                <Link
                  className="mobile-link"
                  to={toTickets}
                  target={target}
                  onClick={onTicketsClicked}
                >
                  <Typography data-testid="user_MyTickets" variant="h1" tag="span">
                    my tickets
                  </Typography>
                </Link>
                {hasUser && !isCobranded() && (
                  <Link
                    className="mobile-link"
                    to="/keeps"
                    target={target}
                    onClick={onKeepsClicked}
                  >
                    <Typography data-testid="user_MyKeeps" variant="h1" tag="span">
                      my keeps
                    </Typography>
                  </Link>
                )}
              </>
            )}
            <a
              className="mobile-link"
              href="https://partners.mandolin.com"
              alt="Mandolin Partners"
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="h1" tag="span" data-testid="user_Partners">
                for artists + venues
              </Typography>
            </a>
            <a
              className="mobile-link"
              href="https://help.mandolin.com"
              alt="Mandolin Support"
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="h1" tag="span" data-testid="user_Support">
                support
              </Typography>
            </a>
            {hasUser && (
              <SignOutButton className="mobile-link" onClick={signOut}>
                <Typography variant="h1" tag="span" data-testid="user_SignOut">
                  sign out
                </Typography>
              </SignOutButton>
            )}
            <Border bottom />
          </DrawerContainer>
        ) : (
          <Border bottom />
        )}
      </MobileContainer>
    </>
  )
}

export default MobileNav
