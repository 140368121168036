import React, { memo } from "react"
import {
  MessageContainer,
  MessageContent,
  SkeletonAvatar,
  SkeletonText,
  SkeletonUsername,
} from "../Chat.styled"

const SkeletonMessages = () => (
  <>
    {[...Array(40)].map((_, i) => (
      <MessageContainer key={i}>
        <SkeletonAvatar />
        <MessageContent>
          <SkeletonUsername />
          <SkeletonText />
        </MessageContent>
      </MessageContainer>
    ))}
  </>
)

export default memo(SkeletonMessages)
