import styled from "styled-components/macro"

const StyledContainer = styled.div`
  width: 100vw;
  height: ${props => props.height + 'px' || '100vh'};
  display: flex;
  flex-flow: column;
  overflow: hidden;
  font-size: 54w;
  color: #ffffff;
  @media (min-width: 768px) {
    flex-direction:row;
    font-size:2vw;
  }
  @media (min-width: 1024px) {
    flex-direction:row;
    font-size: 36px;
  }
`

export { StyledContainer }
