import { makeRestClient, DefaultApi, PresenterApi } from "@mandolin-dev/ts-sdk"
import { getToken, restURL } from "./utils"
import Config from "../config/config"

let restClient: DefaultApi
let presenterClient: PresenterApi
let restConnected = false

export const initRestClient = () => {
  if (!restConnected) {
    restClient =
      restClient ?? makeRestClient(restURL, getToken, {}, { cacheMaxAgeS: Config.SDK.cacheMaxAgeS })
    presenterClient = presenterClient ?? new PresenterApi({ accessToken: getToken }, restURL)
    restConnected = true
  }
}

export const getRestClient = () => {
  initRestClient()
  return { restConnected, restClient, presenterClient }
}
