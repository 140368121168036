import styled from "styled-components/macro";
import { Toast } from "react-bootstrap";

const StyledContainer = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 9999;
`;

const StyledToast = styled(Toast)`
  box-sizing: border-box;
  min-width: 298px;
  border: 1px solid #434650;
  border-radius: 8px;
  background-color: #383b45;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.5);
  padding: 0.7rem;
  .toast-header {
    background-color: #383b45;
    span {
      color: #ffffff;
    }
    strong {
      font-size: 16px;
      ${({theme}) => theme.fonts.TTNormsProBold};
      line-height: 19px;
      color: #ffffff;
      padding-left: 12px;
      margin-right: auto;
    }
    svg {
      height: 24px;
      width: 24px;
      fill: #f6ba00;
    }
  }
  .toast-body {
    padding: 0 3rem;
    color: #d7d7d9;
  }
`;

export { StyledContainer, StyledToast };
