import Skeleton from "components/Skeleton"
import Typography from "components/Typography"
import { throttle } from "lodash"
import React, { ReactElement, useRef, useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { NavigateNext, NavigateBefore } from "styled-icons/material-rounded"
import { EventSkeletonContainer } from "views/Home/styled"
import {
  Container,
  CarouselRow,
  CarouselItem,
  CarouselHeader,
  ArrowContainer,
  ArrowButton,
} from "./ContentCarousel.styled"

interface Props {
  label?: string
  items: any[]
  itemRenderer: (item: any, index: number) => ReactElement
  loading?: boolean
  customKey?: boolean
}

const ContentCarousel: React.FC<Props> = ({ label, items, itemRenderer, loading, customKey }) => {
  const [showBackArrow, setShowBackArrow] = useState(false)
  const [showForwardArrow, setShowForwardArrow] = useState(false)
  const lastScrollOffset = useRef<number>(0)
  const rowRef = useRef<HTMLUListElement>(null)
  const itemRef = useRef<HTMLLIElement>(null)
  const scrollType = useMediaQuery({ query: "(prefers-reduced-motion: no-preference)" })

  useEffect(() => {
    if (rowRef.current?.clientWidth && itemRef.current?.clientWidth) {
      setShowForwardArrow(items.length * itemRef.current.clientWidth > rowRef.current?.clientWidth)
    }
  }, [rowRef.current?.clientWidth, itemRef.current?.clientWidth])

  const scrollToOffset = (left: number) =>
    rowRef.current?.scroll({
      top: 0,
      left,
      behavior: scrollType ? "smooth" : "auto",
    })

  const calculateLeftOffset = (scrollForward: boolean = false) => {
    if (rowRef.current && itemRef.current) {
      const itemWidth = itemRef.current.clientWidth
      const numberToShift = Math.floor(rowRef.current.clientWidth / itemWidth)
      const rowScrollLeft = rowRef.current.scrollLeft
      if (scrollForward) {
        return itemWidth * Math.ceil((rowScrollLeft + itemWidth * numberToShift) / itemWidth)
      }
      return itemWidth * Math.ceil((rowScrollLeft - itemWidth * numberToShift) / itemWidth)
    }
    return 0
  }

  const scrollForward = throttle((scrollTo: boolean) => {
    if (scrollTo) scrollToOffset(calculateLeftOffset(true))
  }, 500)

  const scrollBackward = throttle((scrollTo: boolean) => {
    if (scrollTo) scrollToOffset(calculateLeftOffset())
  }, 500)

  const onScroll = (e: any) => {
    if (rowRef.current && itemRef.current) {
      const left = Math.ceil(rowRef.current.scrollLeft + rowRef.current.clientWidth)
      if (left >= rowRef.current.scrollWidth) {
        setShowForwardArrow(false)
      } else if (!showForwardArrow) {
        setShowForwardArrow(true)
      }
      if (left <= rowRef.current.clientWidth) {
        setShowBackArrow(false)
      } else if (!showBackArrow) {
        setShowBackArrow(true)
      }

      lastScrollOffset.current = e.target.scrollLeft
    }
  }

  const displayLabel = (): JSX.Element | JSX.Element[] => {
    const header = (
      <Typography aria-label={label} variant="h3">
        {label}
      </Typography>
    )

    if (loading) {
      return <Skeleton display>{header}</Skeleton>
    }

    if (label && !loading) {
      return header
    }

    return <div />
  }

  if (!items.length && !loading) return null

  return (
    <Container>
      <CarouselHeader>
        {displayLabel()}
        <ArrowContainer>
          <ArrowButton onClick={() => scrollBackward(true)} active={showBackArrow}>
            <NavigateBefore />
          </ArrowButton>
          <ArrowButton onClick={() => scrollForward(true)} active={showForwardArrow}>
            <NavigateNext />
          </ArrowButton>
        </ArrowContainer>
      </CarouselHeader>
      <CarouselRow ref={rowRef} onScroll={onScroll}>
        {!loading
          ? items.map((item, i) => {
              return (
                <CarouselItem
                  ref={i === 0 ? itemRef : undefined}
                  key={(customKey && `${item.id}-${i}`) || item.id || i}
                >
                  {itemRenderer(item, i)}
                </CarouselItem>
              )
            })
          : [1, 2, 3, 4, 5, 6].map((i) => {
              return (
                <Skeleton key={i} display={loading}>
                  <CarouselItem ref={i === 0 ? itemRef : undefined} key={i}>
                    <EventSkeletonContainer />
                  </CarouselItem>
                </Skeleton>
              )
            })}
      </CarouselRow>
    </Container>
  )
}

export default ContentCarousel
