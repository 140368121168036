
export interface ImageAssetSizeProps {
    type: ImageAssetSizeType,
    value: ImageAssetSizeValue,
  }

  export enum ImageAssetSizeType {
    ASSET_SIZE = "assetSize",
    ASSET_WIDTH = "assetWidth",
    ASSET_HEIGHT = "assetHeight"
  }

  export enum ImageAssetSizeValue {
    WIDTH_40 = "40",
    WIDTH_300 = "300",
    WIDTH_350 = "350",
    WIDTH_450 = "450",
    WIDTH_650 = "650",
    WIDTH_750 = "750",
    WIDTH_800 = "800",
    WIDTH_1200 = "1200",
    WIDTH_1400 = "1400",
    WIDTH_3850 = "3850",
  }