import styled from "styled-components"
import { mandolinTheme } from "theme"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  border-radius: 4px;
  position: relative;
`

export const ClaimedWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid ${mandolinTheme.colors.elevation7};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px 4px 0 0;
`

export const AssetWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  padding-bottom: 100%;
  border: 1px solid ${mandolinTheme.colors.elevation7};
  border-radius: 4px 4px 0 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px 4px 0 0;
  }
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px 4px 0 0;
  }
`

export const DataWrapper = styled.div`
  cursor: pointer;
`

export const Data = styled.div`
  padding: 1.5rem;
  background-color: ${mandolinTheme.colors.elevation7};
  color: ${mandolinTheme.colors.elevation1};
  ${mandolinTheme.fonts.TTNormsProNormal};
  position: relative;
  border-radius: 0 0 4px 4px;
  h1 {
    font-size: 1rem;
  }
  h2 {
    font-size: 0.775rem;
  }
  p {
    font-size: 0.65rem;
    margin: 0.5rem 0;
  }
  strong {
    ${mandolinTheme.fonts.TTNormsProBold};
  }
`
export const Wallet = styled.div`
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  img {
    width: 16px;
  }
`

export const CheckboxWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 400;
  padding: 0.65rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.32);
  background-color: ${mandolinTheme.colors.elevation7};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`
