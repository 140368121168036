import { configureStore } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import { combineReducers } from "redux"
import auth from "./auth"
import cobrands from "./cobrands"
import tickets from "./tickets"
import user from "./user"
import watchParty from "./watchParty"
import websocket from "./websocket"
import show from "./show"
import features from "./features"
import VIPParty from "./vip"

const preloadedState = {}

export const rootReducer = combineReducers({
  auth,
  cobrands,
  features,
  show,
  tickets,
  user,
  watchParty,
  websocket,
  VIPParty,
})

const store = configureStore({
  devTools: process.env.NODE_ENV === "development",
  reducer: rootReducer,
  preloadedState,
})

export default store

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
