import Typography from "components/Typography"
import React, { FC, useEffect, useState } from "react"
import {
  CompleteBorder,
  CompleteModalContainer,
  ModalInfoContainer,
  ModalCloseButton,
  ModalEmoji,
  ModalText,
  StyledClose,
  OnMicModalContainer,
} from "./VIPPartyComponents.styled"

export enum PartyModalTypes {
  HAND_RAISED = "handRaised",
  ON_MIC = "onmic",
  COMPLETE = "complete",
  TIME_UP = "timeUp",
}
interface PartyModalProps {
  type: PartyModalTypes
  close: () => void
  name?: string
}
const InPartyModal: FC<PartyModalProps> = ({ type, close = () => {}, name }) => {
  const [showOnMic, setShowOnMic] = useState<boolean>(type === PartyModalTypes.ON_MIC)
  const [showTimeUp, setShowTimeUp] = useState<boolean>(type === PartyModalTypes.TIME_UP)
  useEffect(() => {
    let timer: any
    if (showOnMic) {
      timer = setTimeout(() => setShowOnMic(false), 5000)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [showOnMic])

  useEffect(() => {
    let timer: any
    if (showTimeUp) {
      timer = setTimeout(() => setShowTimeUp(false), 3000)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [showTimeUp])

  const renderModal = () => {
    if (type === PartyModalTypes.COMPLETE) {
      return (
        <CompleteModalContainer>
          <CompleteBorder />
          <ModalInfoContainer>
            <ModalEmoji>😀</ModalEmoji>
            <ModalText>
              <Typography variant="h6" textColor="#000">
                Great Job!
              </Typography>
              <Typography textColor="#000">You can raise your hand again.</Typography>
            </ModalText>
            <ModalCloseButton onClick={close}>
              <StyledClose />
            </ModalCloseButton>
          </ModalInfoContainer>
        </CompleteModalContainer>
      )
    }
    if (type === PartyModalTypes.HAND_RAISED) {
      return (
        <CompleteModalContainer>
          <CompleteBorder />
          <ModalInfoContainer>
            <ModalEmoji>👋</ModalEmoji>
            <ModalText>
              <Typography variant="h6" textColor="#000">
                Stay Close!
              </Typography>
              <Typography textColor="#000">In case you are called on.</Typography>
            </ModalText>
            <ModalCloseButton onClick={close}>
              <StyledClose />
            </ModalCloseButton>
          </ModalInfoContainer>
        </CompleteModalContainer>
      )
    }
    if (type === PartyModalTypes.ON_MIC && showOnMic) {
      return (
        <OnMicModalContainer>
          <ModalEmoji>🎤</ModalEmoji>
          <Typography>{`Hey ${name}, the mic is yours!`}</Typography>
        </OnMicModalContainer>
      )
    }
    if (type === PartyModalTypes.TIME_UP && showTimeUp) {
      return (
        <OnMicModalContainer>
          <ModalEmoji>⏰</ModalEmoji>
          <Typography>{`Time to wrap it up ${name}.`}</Typography>
        </OnMicModalContainer>
      )
    }
    return null
  }

  return renderModal()
}

export default InPartyModal
