import React from "react"
import { SpinnerContainer, SpinnerElement } from "./Spinner.styled"

const Spinner = () => (
  <SpinnerContainer data-testid="spinner">
    <SpinnerElement />
    <SpinnerElement />
    <SpinnerElement />
    <SpinnerElement />
    <SpinnerElement />
  </SpinnerContainer>
)

export default Spinner
