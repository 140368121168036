import styled from "styled-components/macro"
import { Modal } from "react-bootstrap"

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 600px;
    @media (max-width: 760px) {
      margin: auto;
      max-width: 80vw;
    }
  }
`

export const ModalBody = styled.div`
  align-items: stretch;
  color: black;
  display: flex;
  flex-direction: row;
  height: 90vh;
  max-height: 600px;
  overflow: hidden;
  width: 100%;
  @media (max-width: 760px) {
    flex-direction: column;
    height: 464px;
    justify-content: center;
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @media (max-width: 760px) {
    width: 100%;
  }

  form {
    max-width: 336px;
    margin: 0 auto;
    width: 100%;
  }
  @media (max-width: 760px) {
    margin: 0;
    form {
      max-width: 64vw;
    }
  }

  .form-check {
    ${({ theme }) => theme.fonts.TTNormsProMedium};
    font-size: 14px;
    .input {
      height: 12px;
      width: 12px;
    }
    @media (max-width: 760px) {
      font-size: 12px;
      .input {
        height: 12px;
        width: 12px;
      }
    }
  }
`

export const Header = styled.h3`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 24px;
  letter-spacing: 0;
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: 760px) {
    margin-bottom: 16px;
  }
`

export const ErrorText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: #c5162e;
  font-size: 12px;
`

export const PhotoUploadPreview = styled.div`
  align-items: center;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-color: #0165f2;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 56px;
  justify-content: center;
  transition: 0.1s ease opacity;
  width: 56px;
  z-index: 1;
  @media (max-width: 760px) {
    height: 40px;
    width: 40px;
  }
`

export const PhotoUploadContainer = styled.div`
  margin-bottom: 30px;
  height: unset;
  input {
    display: none;
  }
  label {
    margin-bottom: 0;
    display: flex;
    position: relative;
    cursor: pointer;
    @media (max-width: 760px) {
      flex-direction: column;
      align-items: center;
      font-size: 14px;
    }
    span {
      color: #0165f2;
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      @media (max-width: 760px) {
        position: relative;
        margin-top: 8px;
      }
    }
    &:hover {
      span {
        text-decoration: underline;
      }
      opacity: 0.6;
    }
  }
`

export const FieldWrapper = styled.div`
  margin-bottom: 32px;
  width: 100%;
`

export const FieldInternal = styled.div`
  display: flex;
  position: relative;
  border-radius: 1px;
  background: #ffffff;
  border: 1px solid #ffffff;
  line-height: 22px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  margin: 0;
  padding: 0;
`

export const FieldBorder = styled.div`
  -webkit-box-direction: normal;
  background: ${({ error }) => (error ? "#c5162e" : "#dbdae3")};
  border-radius: 2px;
  color: ${({ error }) => (error ? "#c5162e" : "#6f7287")};
  display: block;
  letter-spacing: 0;
  margin: 0;
  padding: 1px;
  position: relative;
  transition: background 0.24s cubic-bezier(0.4, 0, 0.3, 1);
  width: 100%;
  &:hover {
    background: #a9a8b3;
  }
  &:active,
  &:focus,
  &:focus-within {
    background: #3659e3;
    color: #3659e3;
    padding: 2px;
    ${FieldInternal} {
      border: none;
      transition: none;
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex: 1 1 0%;
  min-width: 0;
`

export const InputLabelWrapper = styled.div`
  padding: 2px 12px 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 100%;
`

export const LabelElement = styled.label`
  font-size: 12px;
  line-height: 22px;
  transition: all 0.16s cubic-bezier(0.4, 0, 0.3, 1);
  white-space: nowrap;
  overflow: hidden;
  display: block;
  position: relative;
  pointer-events: none;
`

export const LabelContent = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 22px;
`

export const InputElement = styled.input`
  -webkit-box-direction: normal;
  -webkit-writing-mode: horizontal-tb !important;
  appearance: textfield;
  background: none;
  border: none;
  color: #39364f;
  display: inline-block;
  flex: 1;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 22px;
  min-height: 22px;
  min-width: 0;
  outline: none;
  padding: 18px 12px 6px;
  text-align: start;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  transition: padding 0.16s cubic-bezier(0.4, 0, 0.3, 1), color 0.4s cubic-bezier(0.4, 0, 0.3, 1);
  white-space: nowrap;
  word-spacing: normal;
  &:active,
  &:focus {
    outline-offset: 0;
  }
`
