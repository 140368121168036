import React, { FC, createContext, useState, useCallback } from "react"

interface ToastContextState {
  message: string
  type?: string
  visible: boolean
  error: boolean
  success: boolean
  info: boolean
}

const initialToast: ToastContextState = {
  message: "",
  visible: false,
  error: false,
  success: false,
  info: false,
}

export interface IToastContext {
  showToast: (arg0: object) => void
  hide: () => void
  toast: ToastContextState
}

export const ToastContext = createContext({})

export const ToastProvider: FC = ({ children }) => {
  const [toast, setToast] = useState(initialToast)

  const showToast = useCallback((args) => {
    setToast({ ...initialToast, visible: true, ...args })
  }, [])

  const hide = useCallback(() => {
    setToast({ ...toast, visible: false })
  }, [toast])

  return (
    <ToastContext.Provider
      value={{
        hide,
        showToast,
        toast,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}
