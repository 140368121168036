import React from "react"
import styled, { useTheme } from "styled-components/macro"
import { readableColor, transparentize } from "polished"
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { auth as firebaseAuth } from "firebaseApp"
import { atMediaMax, atMediaMin } from "theme"
import { toggleAuthModal } from "redux/auth"
import { isCobranded } from "services/utils"
import { sectionContainer } from "theme/spacing"
import { Facebook, Instagram, Twitter } from "styled-icons/boxicons-logos"

import Border from "../TopNav/Border"

const StyledFooter = styled.footer`
  ${sectionContainer};
  max-width: 100%;
  background: ${({ theme }) => theme.elements.footerBackground};
  height: auto;
  padding-top: 60px !important;
  padding-bottom: 60px !important;
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.elements.footerText};
  a {
    color: ${({ theme }) => theme.elements.footerText};
  }
`

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${atMediaMax("md")} {
    display: block;
  }
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  ${atMediaMax("md")} {
    display: block;
  }
`

const LogoWrapper = styled(Link)`
  padding-top: 4px;
  align-items: center;
  color: ${({ theme }) => theme.elements.footerText};
  cursor: pointer;
  display: flex;
  img {
    width: 100%;
    height: 100%;
    display: block;
    max-height: 42px;
    max-width: 152px;
    object-fit: contain;
    object-position: left center;
    @media (max-width: 600px) {
      max-height: 36px;
      max-width: 124px;
    }
  }
  ${atMediaMax("md")} {
    display: none;
  }
`

const MobileLogoWrapper = styled(Link)`
  display: none;
  ${atMediaMax("md")} {
    align-items: center;
    color: ${({ theme }) => theme.elements.footerText};
    cursor: pointer;
    display: flex;
    img {
      height: auto;
      width: auto;
      max-height: 51px;
      max-width: 152px;
    }
  }
`

const LegalLinks = styled.div`
  display: flex;
  opacity: 0.6;
  font-size: 18px;
  a {
    margin-left: 16px;
    color: ${({ theme }) => theme.elements.footerText};
  }
  ${atMediaMax("md")} {
    display: block;
    margin-top: 80px;
    a {
      margin-left: 0;
      display: block;
      margin-top: 30px;
      text-transform: uppercase;
    }
  }
`
const UserLinks = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  a {
    margin-left: 16px;
    color: ${({ theme }) => theme.elements.footerText};
    ${atMediaMax("md")} {
      margin-left: 0;
      display: block;
      margin-top: 20px;
    }
  }
  ${atMediaMax("md")} {
    display: block;
    margin-top: 60px;
  }
`

const LogInLink = styled.button`
  appearance: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  span {
    color: ${({ theme }) => theme.elements.footerText};
    margin-left: 16px;
    ${atMediaMax("md")} {
      margin-left: 0;
      margin-top: 40px;
    }
  }
  ${atMediaMax("md")} {
    display: block;
    margin-top: 20px;
  }
`

const SocialLinks = styled.div`
  display: flex;
  margin: 20px 0;
  justify-content: flex-start;
  ${atMediaMin("md")} {
    margin: 0;
    justify-content: flex-end;
  }
  a {
    margin: 0 16px;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background-color: ${({ theme }) => transparentize(0.5, theme.elements.footerText)};
    color: ${({ theme }) => theme.elements.footerBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
    &:last-of-type {
      margin-right: 0px;
    }
    &:first-of-type {
      margin-left: 0px;
    }
    svg {
      width: 32px;
      height: 32px;
    }
  }
`

const InfoWrapper = styled.div``

const ContactInfoDesktop = styled.div`
  margin-top: 24px;
  display: block;
  p:first-child {
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    color: ${({ theme }) => theme.elements.footerText};
  }
  ${atMediaMax("md")} {
    display: none;
  }
`

const ContactInfoMobile = styled.div`
  display: none;
  ${atMediaMax("md")} {
    display: block;
    p:first-child {
      color: rgba(255, 255, 255, 0.6);
      text-transform: uppercase;
      color: ${({ theme }) => theme.elements.footerText};
    }
  }
`
const EmailText = styled.a`
  color: #fff;

  &:hover {
    color: #fff;
  }
`

const Footer = () => {
  const { elements } = useTheme()
  const {
    user: { name, email },
    auth,
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const { isAdmin } = auth

  const signOut = async () => {
    window.analytics.track("Account Signed Out", {
      userType: "authenticated",
      activityLocation: "Home",
    })
    await firebaseAuth.signOut()
    history.push("/")
    window.location.reload()
  }

  const hasUser = name && email

  const lightLogo = readableColor(elements.footerText) === "#000"

  const to = "/"
  const toTickets = "/tickets"

  const ContactInfo = () => (
    <>
      <EmailText href="mailto:info@mandolin.com">info@mandolin.com</EmailText>
      <br />
      <span>
        1075 Broad Ripple Ave #374
        <br />
        Indianapolis, IN 46220-2034
      </span>
    </>
  )

  return (
    <StyledFooter>
      <Border top />
      <div>
        <TopWrapper>
          <InfoWrapper>
            <LogoWrapper to={to}>
              <img
                alt="Mandolin logo"
                src={lightLogo ? "/logo/SVG/logo-white.svg" : "/logo/SVG/logo-black.svg"}
                data-testid="footerLogo"
              />
            </LogoWrapper>
            <MobileLogoWrapper to="/">
              <img
                alt="Mandolin logo"
                src={
                  lightLogo
                    ? "/logo/PNG/Large/mark-white@3x.png"
                    : "/logo/PNG/Large/mark-black@3x.png"
                }
                data-testid="footerLogo"
              />
            </MobileLogoWrapper>
            <ContactInfoDesktop>
              <ContactInfo />
            </ContactInfoDesktop>
          </InfoWrapper>
          <UserLinks>
            <Link to={toTickets}>my tickets</Link>
            {!isCobranded() && <Link to="/keeps">my keeps</Link>}
            <a href="https://partners.mandolin.com" target="_blank" rel="noreferrer">
              for artists+venues
            </a>
            {!hasUser ? (
              <LogInLink onClick={() => dispatch(toggleAuthModal())}>
                <span>log in</span>
              </LogInLink>
            ) : (
              <LogInLink onClick={signOut}>
                <span>log out</span>
              </LogInLink>
            )}
          </UserLinks>
        </TopWrapper>
        <BottomWrapper>
          <ContactInfoMobile>
            <ContactInfo />
          </ContactInfoMobile>
          <LegalLinks>
            <span>&copy; 2022 Mandolin Software Inc.</span>
            <a rel="noreferrer" target="_blank" href="https://partners.mandolin.com/about-us">
              About Our Company
            </a>
            <a rel="noreferrer" target="_blank" href="https://boards.greenhouse.io/mandolin50">
              Careers
            </a>
            <a rel="noreferrer" target="_blank" href="https://partners.mandolin.com/privacy-policy">
              Privacy Policy
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://partners.mandolin.com/terms-of-service"
            >
              Terms + conditions
            </a>
          </LegalLinks>
          <SocialLinks>
            <a href="//www.facebook.com/watchmandolin" rel="noreferrer" target="_blank">
              <Facebook />
            </a>
            <a href="https://twitter.com/mandolinlive" rel="noreferrer" target="_blank">
              <Twitter />
            </a>
            <a href="https://www.instagram.com/watchmandolin/" rel="noreferrer" target="_blank">
              <Instagram />
            </a>
          </SocialLinks>
        </BottomWrapper>
      </div>
    </StyledFooter>
  )
}

export default Footer
