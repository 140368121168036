import styled from "styled-components/macro";
import { readableColor } from "polished";

const ListContainer = styled.ol`
  width: 100%;
  height: 100%;
`;

const ListItem = styled.li`
  padding: 0 1rem;
  color: ${({ theme }) => theme.colors.elevation6};
  ${({theme}) => theme.fonts.TTNormsProMedium};
  font-size: 14px;
  line-height: 34px;
`;

export { ListContainer, ListItem };
