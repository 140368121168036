/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import { IContentSectionItemType } from "@mandolin-dev/ts-sdk"
import axios from "axios"
import { CustomContentQuery, CustomContentQueryEnum } from "hooks/useCustomContent"
import Config from "config/config"

export interface CustomContent {
  id: string
  title: string
  description: string
  image: StrapiImage
  rows: CustomContentRow[]
}

export interface StrapiImage {
  width?: number
  height?: number
  url: string
  formats: {
    medium: {
      width?: number
      height?: number
      url: string
    }
  }
}

export interface CustomContentRow {
  id: string
  layout: CustomLayoutType
  title: string
  items: CustomContentRowItem[]
  image: StrapiImage
}

export enum CustomLayoutType {
  FULL_WIDTH = "fullWidth",
  GRID = "grid",
  HORIZONTAL = "horizontal",
}

export interface CustomContentRowItem extends CustomContentRowInfo {
  id: string
  __component?: ComponentType
  // eslint-disable-next-line camelcase
  content_collection?: CustomContentRowInfo
}

interface CustomContentRowInfo {
  id: string
  title: string
  url: string
  price: string
  itemUrl: string | undefined | null
  productId: string | undefined | null
  storeUrl: string | undefined | null
  storefrontAccessToken: string | undefined | null
  image: StrapiImage
  entityType?: CustomTileTypes
  entityId?: string
  contentType: IContentSectionItemType
}

export enum CustomTileTypes {
  EVENT = "event",
  VENUE = "venue",
  ARTIST = "artist",
  VIP = "VIPExperience",
  CONTENT_COLLECTION = "contentCollection",
}

export enum ComponentType {
  COMMERCE_ITEM = "content-collections.commerce-item",
  CONTENT_COLLECTION = "content-collections.content-collection",
  ENTITY = "content-collections.entity",
}

const customContentQueryBuilder = (eventId: string, options?: CustomContentQuery): string => {
  let query = `${Config.STRAPI.restURL}/content-collections`
  const defaultUrl = `${query}/${eventId}`

  if (!options) {
    return defaultUrl
  }

  if (options.associatedEvent) {
    query += `/${CustomContentQueryEnum.ASSOCIATED_EVENT}=${eventId}`
  }

  return query
}

export const getStrapiContent = async (
  eventId: string,
  options?: CustomContentQuery,
): Promise<CustomContent | CustomContent[] | undefined> => {
  try {
    const requestUrl = customContentQueryBuilder(eventId, options)
    const response = await axios.get(requestUrl)
    return response.data
  } catch (error) {
    console.log(error)
  }

  return undefined
}

/**
 * Strapi will include an extra property in their response for
 * content collections. This property contains the necessary
 * data to render the components, so this makes sure it's mapped
 * properly.
 */
const formatItems = (row: CustomContentRow): CustomContentRow => {
  const items = row.items.map((item) => {
    if (item.__component === ComponentType.CONTENT_COLLECTION && item.content_collection) {
      return {
        __component: ComponentType.CONTENT_COLLECTION,
        ...item.content_collection,
      }
    }
    return item
  })

  return {
    ...row,
    items,
  }
}

export const filterContentSections = (
  content: CustomContent | undefined,
): CustomContent | undefined => {
  const contentSections: CustomContentRow[] =
    content && content.rows ? content.rows.map(formatItems) : []
  return content ? { ...content, rows: contentSections } : undefined
}

export const formatCustomContentUrl = (
  entityId: string | undefined,
  entityType: CustomTileTypes | undefined,
  url?: string,
  fromWatch?: boolean,
) => {
  if (url) return url

  switch (entityType) {
    case CustomTileTypes.ARTIST: {
      return `/artists/${entityId}`
    }
    case CustomTileTypes.VENUE: {
      return `/venues/${entityId}`
    }
    case CustomTileTypes.EVENT: {
      if (fromWatch) {
        return `/watch/${entityId}`
      }
      return `/events/${entityId}`
    }
    default: {
      return "/"
    }
  }
}
