import styled from "styled-components"
import { rgba } from "polished"
import { Add } from "styled-icons/fluentui-system-filled/"
import Typography from "components/Typography"
import { Button } from "components/shared"
import { sectionContainer } from "theme/spacing"

const Container = styled.div`
  ${sectionContainer};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 35px;
  margin-top: 40px;
  min-height: 75vh;
  @media (max-width: 740px) {
    flex-direction: column;
    margin-top: 0px;
  }
`

const BodyContainer = styled.div`
  color: ${({ theme }) => theme.elements.pageText};
  display: flex;
  flex-direction: column;
  max-width: 600px;
  flex: 1;
  @media (max-width: 740px) {
    padding: 0 2rem;
    padding-top: 2rem;
    margin: auto;
  }
`

const WalletBodyContainer = styled.div`
  color: ${({ theme }) => theme.elements.pageText};
  display: flex;
  flex-direction: column;
  max-width: 600px;
  flex: 1;

  @media (max-width: 740px) {
    padding-top: 2rem;
  }
`

const BodyForm = styled.form`
  display: flex;
  flex-direction: row;
  min-height: 75vh;
  @media (max-width: 740px) {
    flex-direction: column;
  }
`
const BodyFormLeft = styled.div`
  width: 366px;
  @media (max-width: 740px) {
    width: 100%;
  }
`

const BodyFormRight = styled.div`
  margin-left: 34px;
  @media (max-width: 740px) {
    margin: 48px 0 0 0;
  }
`

const BodyHeader = styled(Typography).attrs({
  variant: "h4",
  gutterBottom: "lg",
})``

const BodySubheader = styled(Typography).attrs({
  variant: "h6",
})``

const BodyField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  &.last {
    margin: 0;
  }
`

const BodyInput = styled.input`
  background-color: ${({ theme }) => rgba(theme.elements.pageText, 0.1)};
  color: ${({ theme }) => theme.elements.pageText};
  ::placeholder {
    color: ${({ theme }) => rgba(theme.elements.pageText, 0.6)};
  }
  border-radius: 31px;
  border: none;
  height: 54px;
  outline: none;
  padding: 16px 24px;
  width: 100%;
  &:focus,
  &:active {
    box-shadow: 0 0 0 3px #878888;
    border-radius: 3px;
  }
`

const BodyLabel = styled(Typography).attrs({
  tag: "label",
  variant: "smallCap",
  gutterBottom: "xs",
})``

const Divider = styled.div`
  background: rgba(255, 255, 255, 0.2);
  height: 3px;
  margin-bottom: 48px;
  margin-top: 48px;
  width: 100%;
`

export const Address = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const BodyText = styled(Typography).attrs({
  variant: "body",
  gutterBottom: "sm",
})``

const BodySubText = styled(Typography).attrs({
  variant: "small",
})``

const FieldSubtext = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: 14px;
  letter-spacing: 0.01em;
  margin-top: 8px;
`

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
`

const AvatarImage = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 28px;
  border-radius: 100%;
`

const AvatarControls = styled.div`
  button {
    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
`

const AddAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  gap: 1rem;
`

const Icon = styled(Add)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`

const ConnectButton = styled(Button)`
  background-color: transparent;
  border: 1px solid #ffffff;

  div {
    color: #ffffff !important;
  }
  svg {
    fill: ${({ theme }) => theme.colors.elevation8};
    color: ${({ theme }) => theme.colors.elevation8};
  }
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.elevation3};
  padding: 2rem;

  p {
    margin: 0;
  }

  a {
    color: white;
  }
`
const ValidationContainer = styled.div`
  margin-bottom: 48px;
  margin-top: -30px;
`

export {
  AddAddressWrapper,
  Icon,
  InfoBox,
  BodyContainer,
  BodyForm,
  BodyFormLeft,
  BodyFormRight,
  BodyField,
  BodyHeader,
  BodyInput,
  BodyLabel,
  BodySubheader,
  BodyText,
  BodySubText,
  Container,
  Divider,
  FieldSubtext,
  AvatarContainer,
  AvatarImage,
  AvatarControls,
  ConnectButton,
  WalletBodyContainer,
  ValidationContainer,
}
