import { Spinner } from "components/shared"
import { useRestClient } from "hooks"
import React, { useEffect, useState } from "react"
import { Audio, Image, Video } from "./Asset.styled"

interface AssetProps {
  url: string
  preview?: string
  modalAsset?: boolean
  muted?: boolean
}

const audioTypes = ["audio/ogg", "audio/mpeg", "audio/mp3"]
const videoTypes = ["video/ogg", "video/mp4", "video/webm"]

const Asset = React.forwardRef<null, AssetProps>(({ url, preview, modalAsset, muted }, ref) => {
  const [contentType, setContentType] = useState<string>("")
  const [loading, setLoading] = useState(false)
  const { restClient } = useRestClient()

  useEffect(() => {
    try {
      setLoading(true)
      restClient
        .getAssetContentType({ nftAssetContentTypeRequest: { url } })
        .then(({ data }) => setContentType(data.contentType || ""))
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  if (loading) {
    return <Spinner />
  }

  if (modalAsset) {
    if (audioTypes.includes(contentType)) {
      return (
        <>
          <Image src={preview || "/images/pattern4.png"} alt="" />
          <Audio controls controlsList="nodownload" ref={ref} muted={muted}>
            <source type={contentType} src={url} />
            Your browser does not support the audio tag.
          </Audio>
        </>
      )
    }

    if (videoTypes.includes(contentType)) {
      return (
        <Video controls controlsList="nodownload" ref={ref} muted={muted}>
          <source type={contentType} src={url} />
          Your browser does not support the video tag.
        </Video>
      )
    }
    return <Image ref={ref} src={url} alt="" />
  }

  if (preview) {
    return <Image ref={ref} src={preview} alt="" />
  }

  if (videoTypes.includes(contentType)) {
    return (
      <Video controls controlsList="nodownload" ref={ref} muted={muted}>
        <source type={contentType} src={url} />
        Your browser does not support the video tag.
      </Video>
    )
  }

  if (audioTypes.includes(contentType)) {
    return (
      <>
        <Image src={preview || "/images/pattern4.png"} alt="" />
        <Audio controls controlsList="nodownload" ref={ref} muted={muted}>
          <source type={contentType} src={url} />
          Your browser does not support the audio tag.
        </Audio>
      </>
    )
  }

  return <Image ref={ref} src={url} alt="" />
})

export default Asset
