import styled, { css } from "styled-components"
import { atMediaMin, toRem } from "theme/utils"

const RemindMeButtonContainer = styled.div`
  .remindMeCTA {
    display: inline-flex;
    padding: 0 ${toRem(16)};
    ${atMediaMin("md")} {
      padding: 0 ${toRem(24)};
    }
    svg {
      width: ${toRem(20)};
      margin-right: ${toRem(8)};
    }
  }
`

const RemindAlert = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 15px;
  gap: 8px;
`

export { RemindMeButtonContainer, RemindAlert }