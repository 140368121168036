import React from "react"
import { isIE } from "react-device-detect"
import "bootstrap/dist/css/bootstrap.min.css"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components/macro"
import store from "redux/store"
import { GlobalStyle, mandolinTheme } from "theme"

import useCobranding from "hooks/useCobranding"

import AuthProvider from "providers/AuthProvider"
import TicketProvider from "providers/TicketProvider"
import WalletProvider from "providers/WalletProvider"
import { ToastProvider } from "providers/ToastContext"

import ToastConsumer from "components/ToastConsumer/ToastConsumer"
import CookieConsent from "components/CookieConsent"
import { Loading, OutdatedBrowser } from "components/shared"

import Goodbye from "components/shared/Goodbye/Goodbye"
import ErrorBoundary from "./ErrorBoundary"
import Routes from "./Routes"

const WithTheme = ({ children }) => {
  const { theme, loading } = useCobranding()
  if (loading) return <Loading />
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

const WithCobrandSurvey = ({ children }) => {
  const { theme, loading } = useCobranding()
  if (loading) return <Loading />
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

const App = () => {
  window.addEventListener("beforeinstallprompt", (e) => {
    // Stash the event so it can be triggered using deferredPrompt.prompt()
    const deferredPrompt = e
  })

  if (isIE) return <OutdatedBrowser />

  return (
    <Provider store={store}>
      <ThemeProvider theme={mandolinTheme}>
        <ErrorBoundary>
          <ToastProvider>
            <AuthProvider>
              <WalletProvider>
                <TicketProvider>
                  <WithTheme>
                    <GlobalStyle />
                    <Goodbye />

                    {/* <Routes /> */}
                  </WithTheme>
                  <ToastConsumer />
                </TicketProvider>
              </WalletProvider>
            </AuthProvider>
          </ToastProvider>
        </ErrorBoundary>
        <CookieConsent />
      </ThemeProvider>
    </Provider>
  )
}

export default App
