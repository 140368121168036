import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

const NavContainer = styled.nav`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  padding-bottom: 90px;
  z-index: 1000;
  @media (${({ theme }) => theme.breakpoint}) {
    padding: 32px 16px;
  }
`

const NavItems = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  .active {
    border-bottom: 3px solid #ff0000;
  }
`

const NavItem = styled(NavLink).attrs({ activeClassName: 'active' })`
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  margin-right: 32px;
  transition: opacity ${({ theme }) => theme.transition};
  text-decoration: none;
  &:hover {
    color: #ffffff;
    opacity: 0.75;
    text-decoration: none;
  }
`

const NavLogos = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const NavLogo = styled.img.attrs({ width: '200px' })`
  height: 60px;
  object-fit: contain;
`

const NavBrand = styled.img.attrs({ height: '40px', width: '124px' })`
  object-fit: contain;
  position: absolute;
  top: 40px;
  left: 60px;
`

const NavTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #ffffff;
  padding-top: 10px;
`

const NavSubTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #ffffff;
  padding-top: 8px;
  padding-right: 8px;
`

const ShowOnMobile = styled.div`
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  @media (${({ theme }) => theme.breakpoint}) {
    display: flex;
  }
`

const ShowOnDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (${({ theme }) => theme.breakpoint}) {
    display: none;
  }
`

export {
  NavContainer,
  NavItems,
  NavItem,
  NavLogo,
  NavBrand,
  NavLogos,
  NavTitle,
  NavSubTitle,
  ShowOnDesktop,
  ShowOnMobile
}
