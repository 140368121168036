/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from "styled-components/macro"

export const fadeIn = keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
`

export const Content = styled.div`
  padding: 1.5rem 1.5rem 0.75rem 1.5rem;
  color: #0d1115;
  grid-area: main;
  display: grid;
  grid-template-rows: 4rem auto;
  hr {
    border: 1px solid #eff2f3;
    width: 100%;
  }
`
export const CardTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold};
  color: ${({ theme }) => theme.colors.elevation1};
  font-size: 1.25rem;
  line-height: 1.63rem;
  letter-spacing: -1px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardSubtitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #0d1115;
  margin: 0;
  line-height: 1.13rem;
  font-size: 0.88rem;
`

export const Button = styled.button`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  background-color: ${({ theme }) => theme.colors.elevation1};
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.elevation1};
  color: ${({ theme }) => theme.colors.elevation8};
  font-size: 14px;
  height: 32px;
  letter-spacing: -0.2px;
  width: 100%;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ErrorText = styled.p`
  color: #ea3274;
`
export const Error = styled.div`
  text-align: justify;
  line-height: 0.85rem;
  color: #e7392b;
  width: 100%;
  font-weight: 600;
  font-size: 0.75rem;
`

export const VictoryText = styled.p`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #3fd27a;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`

export const LoserText = styled.p`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #e7392b;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`

export const NormalText = styled.p`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #161b20;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`

export const DismissText = styled.p`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #006efb;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`

export const NotForSaleText = styled.p`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #565b60;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: zoom-in;
`
