import React, { FC } from "react"
import { useSelector } from "react-redux"
import { RootState } from "redux/store"
import { NftTokenDTO } from "@mandolin-dev/ts-sdk"
import useMagic from "hooks/useMagic"
import { NoKeepsWrapper, MagicSignIn, CollectibleGrid } from "views/MyKeeps/styles"
import { useWallet } from "hooks"
import { NftModel } from "views/MyKeeps"
import { trackNftViewed } from "services/analytics"
import { Spinner } from "components/shared"
import Card from "../Card"
import NoKeeps from "../NoKeeps"

const Claimed: FC<any> = ({ collectibles, tokens, setIsModalOpen, setNftModel, loading }) => {
  const {
    user: { magicWallets },
  } = useSelector<any, any>((state: RootState) => state)

  const {
    address: mandolinAddress,
    connect: magicConnect,
    isConnecting: magicIsConnecting,
  } = useMagic()
  const { address: metamaskAddress } = useWallet()

  const handleOpenModal = (nft: NftModel) => {
    setIsModalOpen(true)
    setNftModel(nft)
    trackNftViewed(nft.id)
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      {magicWallets?.length && !mandolinAddress && (
        <NoKeepsWrapper>
          <MagicSignIn
            label="connect with mandolin"
            width="200px"
            height="35px"
            onClick={() => magicConnect()}
            variant="primary"
            Icon={() => <img src="/images/mando-wallet.svg" alt="" />}
            isLoading={magicIsConnecting}
            disabled={magicIsConnecting}
          />
        </NoKeepsWrapper>
      )}
      {(mandolinAddress || metamaskAddress) && collectibles && collectibles.length === 0 && (
        <NoKeepsWrapper>
          <NoKeeps />
        </NoKeepsWrapper>
      )}
      <CollectibleGrid>
        {collectibles &&
          tokens &&
          collectibles.map((asset: any) => {
            const token = tokens.find(
              (t: NftTokenDTO) => t.contractAddress === asset.contract.address,
            ) as NftTokenDTO
            return (
              <Card
                key={`${asset.contract.address}-${asset.tokenId}`}
                onClickOpenModal={handleOpenModal}
                nftData={{
                  id: token?.id,
                  title: asset.name,
                  author: asset.author,
                  date: asset.date,
                  description: asset.description,
                  edition: asset.edition,
                  asset: asset.asset,
                  preview: asset.preview,
                  number: asset.number,
                  totalSupply: asset.totalSupply,
                  attributes: asset.attributes,
                  contractAddress: asset.contract.address,
                  tokenId: asset.tokenId,
                  wallet:
                    asset.contract.owner === mandolinAddress
                      ? "/images/mando-wallet.svg"
                      : "/images/metamask-wallet.svg",
                }}
              />
            )
          })}
      </CollectibleGrid>
    </>
  )
}

export default Claimed
