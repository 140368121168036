import React, { useEffect, useState } from "react"
import moment from "moment"

import { getManagedShows } from "services/shows"

import { Button } from "components/shared"

import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Login } from "styled-icons/material"
import {
  StyledContainer,
  StyledShowCard,
  StyledImage,
  StyledTitle,
  StyledVenue,
  StyledDate,
  StyledPublished,
  StyledLabel,
  StyledButtons,
  StyledA,
  StyledButtonContainer,
} from "./styled"
import { getRestClient } from "../../services/restClient.ts"
import Config from "../../config/config"

const ManageShows = () => {
  const [shows, setShows] = useState(null)
  const [tabs, setTabs] = useState(0)
  const history = useHistory()

  const { restClient, restConnected } = getRestClient()

  const {
    user: { features, isManager },
  } = useSelector((state) => state)

  const hasPresenterTools = features?.includes("hasPresenterTools")

  useEffect(() => {
    if (hasPresenterTools) {
      window.location.href = "https://present.mandolin.com"
    } else if (isManager) {
      window.analytics.page("Manage Shows")
    } else {
      history.push("/")
      window.location.reload()
    }
  }, [hasPresenterTools])

  useEffect(() => {
    const getData = async () => {
      const { managedShowResults } = await getManagedShows(restClient)
      setShows(managedShowResults)
    }
    if (restConnected) {
      getData()
    }
  }, [restConnected])

  const viewButtonClicked = (show, watch = true) => {
    window.analytics.track("Item Clicked", {
      userType: "authenticated",
      activityLocation: "Manage Shows",
      description: watch ? "Watch Button" : "Studio Button",
      showState: show?.state,
      showName: show?.name,
      showId: show?.id,
      showType: show?.type,
    })
  }

  const renderShow = (show) => {
    return (
      <StyledShowCard key={show.id}>
        <StyledImage background={show?.headerImg} />
        <StyledLabel>Show</StyledLabel>
        <StyledTitle>{show?.name}</StyledTitle>
        <StyledLabel>Venue</StyledLabel>
        <StyledVenue>{show?.venue?.name}</StyledVenue>
        <StyledLabel>Date</StyledLabel>
        <StyledDate>{moment(show?.dateTime).format("MMM D, YYYY @ h:mma")}</StyledDate>
        <StyledLabel>Published</StyledLabel>
        <StyledPublished>{show?.type}</StyledPublished>
        <StyledButtons>
          <Button
            label="Watch"
            height="40px"
            width="48%"
            to={`/watch/${show.id}`}
            onClick={() => viewButtonClicked(show)}
          />
          <Button
            label="Studio"
            height="40px"
            width="48%"
            to={`/studio/${show.id}`}
            variant="secondary"
            onClick={() => viewButtonClicked(show, false)}
          />
        </StyledButtons>
      </StyledShowCard>
    )
  }

  return (
    <StyledContainer>
      <h1>Mandolin Studio</h1>
      <h4>Now, you can access all your Mandolin tools and analytics via Mandolin Live+ Studio!</h4>
      <h4>
        Visit Mandolin Studio by clicking below and entering your Mandolin Presenter credentials.
      </h4>
      <StyledButtonContainer>
        <StyledA href={Config.PRESENTER.baseUrl} target="_blank" rel="noreferrer">
          <Login />
          <span>Enter Mandolin Studio</span>
        </StyledA>
      </StyledButtonContainer>
    </StyledContainer>
  )
}

export default React.memo(ManageShows)
