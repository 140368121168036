import Typography from "components/Typography"
import React, { FC } from "react"
import {
  HostAlertModalContainer,
  HostAlertModal,
  StyledError,
  StyledWarning,
  AlertModalConfirmCta,
  AlertModalCloseCta,
} from "../VIPParty.styled"

export enum HostAlertTypes {
  REVOKE = "revoke",
  REMOVE = "remove",
}

interface AlertProps {
  type: HostAlertTypes
}

const UserExitModal: FC<AlertProps> = ({ type }) => {
  const renderText = () => {
    if (type === HostAlertTypes.REVOKE) {
      return "The host has removed you from this event because of a technical problem."
    }
    return "The host has revoked your access to this event. If you believe this was done in error you can contact support below."
  }
  return (
    <HostAlertModalContainer>
      <HostAlertModal>
        {type === HostAlertTypes.REVOKE ? <StyledError /> : <StyledWarning />}
        <Typography gutterBottom="lg" textAlign="center">
          {renderText()}
        </Typography>
        <AlertModalConfirmCta
          onClick={() => {
            if (type === HostAlertTypes.REVOKE) {
              window.location.reload()
            }
            console.log("contact support action goes here.")
          }}
        >
          {type === HostAlertTypes.REVOKE ? "try rejoining" : "contact support"}
        </AlertModalConfirmCta>
        <AlertModalCloseCta
          onClick={() => {
            window.location.href = "https://mandolin.com/tickets"
          }}
        >
          close event
        </AlertModalCloseCta>
      </HostAlertModal>
    </HostAlertModalContainer>
  )
}

export default UserExitModal
