import React from "react"
import { useParams, Link } from "react-router-dom"

import styled from "styled-components/macro"
import { useSelector } from "react-redux"
import { NavWrapper, NavBody } from "./styled"

import Merch from "./Merch"
import Sponsors from "./Sponsors"
import Donations from "./Donations"
import UpcomingShows from "../UpcomingShows"

const BackButton = styled(Link)`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 16px;
  color: white;
  line-height: 36px;
  background: #e05a5a;
  border-radius: 8px;
  width: 30%;
  height: 36px;
  position: fixed;
  bottom: 26px;
  left: 50%;
  transform: translate(-50%);
  min-width: 212px;
  text-decoration: none;
  text-align: center;
  display: none;

  @media (max-width: 1111px) {
    display: block;
  }

  &:hover {
    color: white;
    text-decoration: underline;
  }
`

const MobileUpcomingShows = styled.div`
  height: 250px;
  width: 100%;
  display: none;

  @media (max-width: 1111px) {
    display: flex;
  }
`

const TabView = ({ venueName, upcomingShows, showMobileChat }) => {
  const { id: showId } = useParams()
  const { show } = useSelector((state) => state)
  const { name, merch = [], sponsors = [], donations = [], state } = show
  const showSponsors = sponsors?.length > 0
  const showMerch = merch?.length > 0
  const showDonations = donations.length > 0

  const passedShow = {
    showId,
    showName: name,
    showState: state,
    venueName,
  }

  return (
    <NavWrapper showMobileChat={showMobileChat}>
      <NavBody>
        {showSponsors && <Sponsors sponsors={sponsors} show={passedShow} />}
        {showMerch && <Merch merch={merch} show={passedShow} />}
        {showDonations && <Donations donations={donations} show={passedShow} />}
        <MobileUpcomingShows>
          <UpcomingShows venueName={venueName} upcomingShows={upcomingShows} />
        </MobileUpcomingShows>
      </NavBody>
      <BackButton
        to="/schedule"
        onClick={() => {
          window.analytics.track("Item Clicked", {
            userType: "authenticated",
            activityLocation: "Festival Watch",
            description: "Back to Schedule Button",
            showName: name,
            showId,
            showState: state,
            venueName,
          })
        }}
      >
        Back to full schedule
      </BackButton>
    </NavWrapper>
  )
}

export default TabView
