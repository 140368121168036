/* eslint-disable camelcase */
import React, { FC, useState, useEffect } from "react"
import { DailyParticipant } from "@daily-co/daily-js"
import { VIPExperienceParticipantStatus } from "@mandolin-dev/ts-sdk"
import useVIPParty from "hooks/useVIPParty"
import {
  getParticipantStatus,
  getParticipantType,
  participantSpeakerStatus,
  toggleHandRaised,
} from "services/VIP"
import {
  Participant,
  ArtistVideoContainer,
  AlertBanner,
  AlertText,
  CompleteCard,
  CardTitle,
  CompleteCardText,
  SubmitBtn,
  InPartyBottomBar,
  ArtistSpeakerContainer,
  InPartyContainer,
  LayoutTypeButton,
  StyledGridIcon,
  StyledIndividualIcon,
  LayoutOptionContainer,
  MainSpeakersContainer,
  HandRaiseContainer,
  OffMicContainer,
} from "./styled"
import HostTile from "../components/Tiles/HostTile"
import ArtistTile from "../components/Tiles/ArtistTile"
import ParticipantTile from "../components/Tiles/ParticipantTile"
import OnMicParticipantTile from "../components/Tiles/OnMicParticipantTile"
import LocalParticipantTile from "../components/Tiles/LocalParticipantTile"
import RaiseHand from "../components/RaiseHand"
import InPartyModal, { PartyModalTypes } from "../components/InPartyModal"

export enum LayoutType {
  GRID = "grid",
  ONE = "one-on-one",
}

export enum ParticipantStatus {
  HAND_RAISED = "h",
  SPEAKING = "s",
  IN_PARTY = "",
}

export type ParticipantsInCall = {
  onmic: DailyParticipant[]
  fans: DailyParticipant[]
  hosts: DailyParticipant[]
  artists: DailyParticipant[]
}

export const DefaultParticipants: ParticipantsInCall = {
  onmic: [],
  fans: [],
  hosts: [],
  artists: [],
}

const InParty: FC<{
  id: string
  roomUrl: string
  userName?: string
  guestState: VIPExperienceParticipantStatus
  videoOn: boolean
  audioOn: boolean
  volume: boolean
}> = ({ id, roomUrl, userName, guestState, videoOn, audioOn, volume }) => {
  const [layoutType, setLayoutType] = useState<LayoutType>(LayoutType.GRID)
  const {
    participants,
    activeSpeaker,
    networkScore,
    dailyEventError,
    localParticipant,
    updateParticipantName,
    mute,
    video,
    toggleVolume,
    toggleLayout,
    timeUp,
    resetTimeUpAlert,
  } = useVIPParty(roomUrl, userName)
  const onmic: DailyParticipant[] = []
  const fans: DailyParticipant[] = []
  const artists: DailyParticipant[] = []
  const hosts: DailyParticipant[] = []
  const [handRaised, setHandRaised] = useState<boolean>(false)
  const [speakerID, setSpeakerID] = useState<string>()
  const [autoLayout, setAutoLayout] = useState<boolean>(true)
  const [showHandRaisedModal, setShowHandRaisedModal] = useState<boolean>(false)
  const [hasBeenOnMic, setHasBeenOnMic] = useState<boolean>(false)
  const [participantsInCall, setParticipantsInCall] = useState<ParticipantsInCall>(
    DefaultParticipants,
  )

  useEffect(() => {
    mute(audioOn)
  }, [audioOn])

  useEffect(() => {
    video(videoOn)
  }, [videoOn])

  useEffect(() => {
    toggleVolume(volume)
  }, [volume])

  useEffect(() => {
    participants.forEach((p: DailyParticipant) => {
      const type = getParticipantType(p.user_name)
      if (type === "participant") {
        const status = getParticipantStatus(p.user_name)
        if (status === ParticipantStatus.SPEAKING) {
          onmic.push(p)
        } else {
          fans.push(p)
        }
      }
      if (type === "artist") {
        artists.push(p)
      }
      if (type === "host") {
        hosts.push(p)
      }
    })

    const fansWithMaxVids = fans.sort((a, b) => Number(b.video) - Number(a.video)).slice(0, 17)
    setParticipantsInCall({
      onmic,
      artists,
      fans: fansWithMaxVids,
      hosts,
    })
  }, [participants])

  useEffect(() => {
    if (localParticipant) {
      if (guestState === VIPExperienceParticipantStatus.OnMic) {
        setHasBeenOnMic(true)
        setHandRaised(false)
        setShowHandRaisedModal(false)
        video(true)
        mute(true)
        participantSpeakerStatus(
          // eslint-disable-next-line camelcase
          localParticipant?.user_name,
          ParticipantStatus.SPEAKING,
          updateParticipantName,
        )
      }

      if (guestState === VIPExperienceParticipantStatus.HandRaised) {
        participantSpeakerStatus(
          // eslint-disable-next-line camelcase
          localParticipant?.user_name || "",
          ParticipantStatus.HAND_RAISED,
          updateParticipantName,
        )
        mute(false)
      }

      if (guestState === VIPExperienceParticipantStatus.InParty) {
        resetTimeUpAlert()
        participantSpeakerStatus(
          // eslint-disable-next-line camelcase
          localParticipant?.user_name || "",
          ParticipantStatus.IN_PARTY,
          updateParticipantName,
        )
        mute(false)
      }
    }
  }, [guestState])
  useEffect(() => {
    if (networkScore && networkScore !== "good" && autoLayout) {
      setLayoutType(LayoutType.ONE)
      toggleLayout(LayoutType.ONE)
      setAutoLayout(false)
    }
  }, [networkScore])

  useEffect(() => {
    const speakerInfo = participants.find((p) => {
      return p.session_id === activeSpeaker
    })
    // eslint-disable-next-line camelcase
    setSpeakerID(speakerInfo?.session_id)
  }, [activeSpeaker, participants])

  return (
    <>
      {dailyEventError ? (
        <div style={{ marginTop: 40 }}>
          <CompleteCard>
            <CardTitle>Meeting Error</CardTitle>
            <CompleteCardText>
              Please try refreshing your browser. If the issue continues please contact support.
            </CompleteCardText>
            <SubmitBtn
              style={{ width: "60%" }}
              onClick={() => {
                window.location.reload()
              }}
            >
              refresh
            </SubmitBtn>
          </CompleteCard>
        </div>
      ) : (
        <InPartyContainer>
          <LayoutOptionContainer>
            <LayoutTypeButton
              onClick={() => {
                setLayoutType(LayoutType.GRID)
                toggleLayout(LayoutType.GRID)
              }}
              active={layoutType === LayoutType.GRID}
              color="#B6B3AE"
            >
              <StyledGridIcon active={layoutType === LayoutType.GRID} />
            </LayoutTypeButton>
            <LayoutTypeButton
              onClick={() => {
                setLayoutType(LayoutType.ONE)
                toggleLayout(LayoutType.ONE)
              }}
              active={layoutType === LayoutType.ONE}
              color="#B6B3AE"
            >
              <StyledIndividualIcon active={layoutType === LayoutType.ONE} />
            </LayoutTypeButton>
          </LayoutOptionContainer>
          <ArtistSpeakerContainer
            grid={layoutType === LayoutType.GRID}
            onmic={guestState === VIPExperienceParticipantStatus.OnMic}
          >
            {participantsInCall.artists.length > 0 ? (
              <ArtistVideoContainer count={participantsInCall.artists.length}>
                {participantsInCall.artists.map((a) => (
                  <ArtistTile
                    activeSpeaker={speakerID}
                    key={`${a.joined_at}-${a.user_id}`}
                    artist={a}
                    artistCount={participantsInCall.artists.length}
                  />
                ))}
              </ArtistVideoContainer>
            ) : null}
            <MainSpeakersContainer
              grid={layoutType === LayoutType.GRID}
              onmic={guestState === VIPExperienceParticipantStatus.OnMic}
            >
              {localParticipant && (
                <LocalParticipantTile
                  activeSpeakerId=""
                  key={`${localParticipant.joined_at}-${localParticipant.user_id}`}
                  participant={localParticipant}
                  onmic={guestState === VIPExperienceParticipantStatus.OnMic}
                  grid={layoutType === LayoutType.GRID}
                />
              )}
              {guestState !== VIPExperienceParticipantStatus.OnMic &&
              participantsInCall.onmic.length > 0 ? (
                <OnMicParticipantTile
                  key={`${participantsInCall.onmic[0].joined_at}-${participantsInCall.onmic[0].user_id}`}
                  participant={participantsInCall.onmic[0]}
                  grid={layoutType === LayoutType.GRID}
                />
              ) : null}
              {participantsInCall.hosts.length > 0 &&
                participantsInCall.hosts.map((h) => (
                  <HostTile
                    activeSpeaker={participantsInCall.onmic.length === 0}
                    key={`${h.joined_at}-${h.user_id}`}
                    host={h}
                    hidden={
                      participantsInCall.onmic.length > 0 || participantsInCall.hosts.indexOf(h) > 0
                    }
                  />
                ))}
            </MainSpeakersContainer>
          </ArtistSpeakerContainer>
          <>
            {participantsInCall.fans.length > 0 && layoutType === LayoutType.GRID && (
              <Participant className="participantSection">
                {participantsInCall.fans.map((p) => (
                  <ParticipantTile key={`${p.joined_at}-${p.user_id}`} participant={p} />
                ))}
              </Participant>
            )}
            {networkScore && networkScore !== "good" && (
              <AlertBanner>
                <AlertText>your connection is unstable</AlertText>
              </AlertBanner>
            )}
            <InPartyBottomBar>
              {guestState !== VIPExperienceParticipantStatus.OnMic ? (
                <>
                  <HandRaiseContainer>
                    <RaiseHand
                      handRaised={handRaised}
                      disabled={false}
                      toggleStatus={() => {
                        setHandRaised(!handRaised)
                        setHasBeenOnMic(false)
                        setShowHandRaisedModal(!handRaised)
                        toggleHandRaised(
                          id,
                          handRaised
                            ? VIPExperienceParticipantStatus.HandRaised
                            : VIPExperienceParticipantStatus.InParty,
                        )
                      }}
                    />
                  </HandRaiseContainer>
                  <OffMicContainer>
                    {showHandRaisedModal && (
                      <InPartyModal
                        type={PartyModalTypes.HAND_RAISED}
                        close={() => {
                          setShowHandRaisedModal(false)
                        }}
                      />
                    )}
                    {hasBeenOnMic && (
                      <InPartyModal
                        type={PartyModalTypes.COMPLETE}
                        close={() => {
                          setHasBeenOnMic(false)
                        }}
                      />
                    )}
                  </OffMicContainer>
                </>
              ) : (
                <>
                  {timeUp && (
                    <InPartyModal type={PartyModalTypes.TIME_UP} close={() => {}} name={userName} />
                  )}
                  <InPartyModal type={PartyModalTypes.ON_MIC} close={() => {}} name={userName} />
                </>
              )}
            </InPartyBottomBar>
          </>
        </InPartyContainer>
      )}
    </>
  )
}

export default InParty
