import styled from "styled-components/macro"
import { Button, ButtonGroup, Card, ListGroup, Form, Modal } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { ContentCopy } from "styled-icons/material-rounded"

export const StyledCodeCopy = styled(ContentCopy)`
  color: white;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 1em;
`

const StyledContent = styled.div`
  background-color: #101112;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  position: relative;
  .content-area {
    flex: 1;
    position: relative;
  }
`

const StyledContentInner = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    padding-bottom: 60px;
  }
  .reaction-holder-container {
    bottom: 10px;
    right: 10px;
  }
`

const StyledButton = styled(Button)`
  background-color: #363636;
  color: #fff;
  border: none;
  border-radius: 0px;
  font-size: 14px;
  padding: 8px;
  &.active {
    background-color: #c39300;
  }
  @media (min-width: 1024px) {
    border-radius: 8px;
  }
`

const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;
  @media (min-width: 1024px) {
    padding: 10px;
    max-width: 500px;
    margin: 0 auto;
    position: absolute;
    top: 12px;
    right: 12px;
  }
`

const StyledConfigContainer = styled.div`
  background-color: #101112;
  color: #ffffff;
  height: 100%;
  padding: 20px;
  overflow: auto;
`
const StyledPanel = styled(Card)`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.maxwidth || "500px"};
  background-color: #363636;
  font-size: 16px;
  p {
    margin-bottom: 0;
  }
  .data-table-extensions > .data-table-extensions-filter > .filter-text {
    color: white;
  }
`

const StyledLink = styled(NavLink)`
  background-color: #363636;
  color: #fff;
  border: none;
  border-radius: 0px;
  font-size: 14px;
  padding: 8px;
  text-align: center;
  width: 100%;
  &.active {
    background-color: #c39300;
  }
  &:hover {
    background-color: #c39300;
    color: #000000;
    text-decoration: none;
  }
  @media (min-width: 1024px) {
    border-radius: 0px;
  }
`

const StyledListGroup = styled(ListGroup)`
  background-color: rgba(0, 0, 0, 0.25);
  .list-group-item {
    background-color: transparent;
  }
`

const StyledHeader = styled.header`
  padding: 20px;
  h3 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
  }
  h4 {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-top: 4px;
  }
  @media (min-width: 1024px) {
    padding: 20px 0;
    padding-right: 60%;
  }
`

const StyledSection = styled.section`
  padding: 30px 0;
  header {
    padding: 0px;
  }
  .rdt_Table {
    text-transform: uppercase;
    letter-spacing: 1.71px;
    font-size: 12px;
    font-weight: 300;
    &Header {
      display: none;
    }
    &Col {
      color: #cacaca;
      padding: 0px;
    }
    &Cell {
      padding: 0px;
    }
  }
  .rdt_Pagination {
    justify-content: flex-start;
    padding: 0px;
  }
  .data-table-extensions {
    font-size: 16px;
    padding: 0px;
    margin-top: 20px;
    &-action {
      button {
        color: #000000 !important;
      }
      & > button {
        filter: grayscale(100%) brightness(2);
        &:after {
          filter: brightness(0.5);
        }
        &.drop,
        &:hover {
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
    }
    &-filter {
      .icon {
        display: none;
      }
      input.filter-text {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.05);
        border: 1px solid transparent;
        height: 48px;
        padding: 0 16px;
        &:hover {
          border-color: rgba(255, 111, 40, 0.3);
          outline: 2px solid rgba(255, 111, 40, 0.3);
        }
        &:focus {
          border-color: rgba(255, 111, 40, 1);
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
  @media (min-width: 768px) {
    padding: 20px 0 40px;
  }
`

const StyledToolbar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h3 {
    font-size: 24px;
    margin: 0;
    font-weight: 400;
    @media (min-width: 768px) {
    }
  }
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`

const StyledStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px;
  @media (min-width: 768px) {
    margin: 0 -10px;
  }
`

const StyledStat = styled.div`
  padding: 15px 10px 20px;
  text-align: center;
  margin: 5px;
  background-color: #161616;
  flex: 1;
  min-width: calc(50% - 10px);
  span {
    display: block;
    &:first-of-type {
      font-size: 12vw;
      @media (min-width: 768px) {
        font-size: 48px;
      }
    }
    &:last-of-type {
      font-size: 3.25vw;
      letter-spacing: 2px;
      opacity: 0.6;
      text-transform: uppercase;
      font-weight: 300;
      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 1024px) {
    margin: 10px;
    min-width: calc(30% - 20px);
    padding: 30px 20px 40px;
  }
`

const StyledModal = styled(Modal)`
  color: #000000;
  ${({ theme }) => theme.fonts.TTNormsProRegular}
  h2 {
    font-size: 24px;
    margin: 0;
  }
`

const StyledLabel = styled(Form.Label)`
  font-size: 14px;
  ${({ theme }) => theme.fonts.TTNormsProBold}
`

const StyledFormControl = styled(Form.Control)`
  min-height: 48px;
  border-radius: 0px;
  border: 2px solid ${({ theme }) => theme.colors.elevation1};
  padding: 0 16px;
  outline: none;
  &:hover {
    border-color: rgba(255, 111, 40, 0.5);
  }
  &:focus {
    border-color: #ff6f28;
    outline: none;
  }
`

const AdditionalAngleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  top: 0;
`

const AdditionalAngleItem = styled.div`
  padding: 10px 0;
`

const StyledFormText = styled(Form.Text)``

const CardContainer = styled.div`
  margin: 10px 0;
`

const VideoTypeText = styled(StyledPanel.Text)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
`

const PreviewTabContainer = styled.div`
  .nav-item {
    color: #fff;
    border: none;
    border-radius: 0px;
    font-size: 14px;
    padding: 8px;
    text-align: center;
    &.active {
      background-color: #c39300;
      color: #fff;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    &:hover {
      background-color: #c39300;
      color: #000000;
      text-decoration: none;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    @media (min-width: 1024px) {
      border-radius: 0px;
    }
  }
`

const PreviewVideoContainer = styled.div`
  height: 90%;
  width: 90%;
`

export {
  StyledContent,
  StyledButton,
  StyledButtonGroup,
  StyledContentInner,
  StyledConfigContainer,
  StyledPanel,
  StyledLink,
  StyledListGroup,
  StyledHeader,
  StyledToolbar,
  StyledSection,
  StyledStats,
  StyledStat,
  StyledModal,
  StyledLabel,
  StyledFormControl,
  StyledFormText,
  AdditionalAngleHeader,
  AdditionalAngleItem,
  CardContainer,
  VideoTypeText,
  PreviewTabContainer,
  PreviewVideoContainer,
}
