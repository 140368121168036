import React from "react"
import jazzicon from "@metamask/jazzicon"
import styled from "styled-components"
import parse from "html-react-parser"

const size = 64
const sizeSmall = 48

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${size}px;
  height: ${size}px;
  overflow: hidden;
  flex-shrink: 0;
  @media (max-width: 740px) {
    width: ${sizeSmall}px;
    height: ${sizeSmall}px;
  }
`

export default function Jazzicon({ address }) {
  const addr = address.slice(2, 10)
  const seed = parseInt(addr, 16)

  const element = jazzicon(size, seed)

  return <Wrapper>{parse(element.outerHTML)}</Wrapper>
}
