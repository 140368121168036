import { invert, transparentize } from "polished"
import React, { useState } from "react"
import styled from "styled-components"

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {}

const StyledImage = styled.img<{ loaded: boolean }>`
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity 0.6s ${({ theme }) => theme.defaults.cubicBezier};
  will-change: opacity;
  background-color: ${({ theme }) => transparentize(0.8, invert(theme.elements.pageBackground))};
  border-radius: 16px;
`

const ImageLoader: React.FC<Props> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  return <StyledImage loading="lazy" onLoad={() => setLoaded(true)} loaded={loaded} {...props} />
}

export default ImageLoader
