import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"
import "firebase/functions"
import "firebase/storage"
import "firebase/remote-config"

import Config from "./config/config"

firebase.initializeApp({
  apiKey: Config.FIREBASE.apiKey,
  authDomain: Config.FIREBASE.authDomain,
  databaseURL: Config.FIREBASE.databaseURL,
  projectId: Config.FIREBASE.projectId,
  storageBucket: Config.FIREBASE.storageBucket,
  messagingSenderId: Config.FIREBASE.messagingSenderId,
  appId: Config.FIREBASE.appId,
})

export const auth = firebase.auth()
export const database = firebase.database()
export const firestore = firebase.firestore()
export const storage = firebase.storage()
export const functions = firebase.functions()
export const firebaseRoot = firebase
export const remoteConfig = firebase.remoteConfig()

remoteConfig.defaultConfig = {
  stream_chat_ws_read_url: Config.API.wsURL,
}

if (process.env.REACT_APP_DOCKER == "true") {
  functions.useFunctionsEmulator("http://localhost:5001")
}

export default firebase
