import styled from "styled-components"
import { mandolinTheme } from "theme"

export interface ButtonProps {
  active?: boolean
}

export const Button = styled.button<ButtonProps>`
  ${mandolinTheme.fonts.TTNormsProBold};
  font-size: 0.75rem;
  background: ${({ active }) => (active ? mandolinTheme.colors.elevation7 : "transparent")};
  color: ${({ active }) =>
    active ? mandolinTheme.colors.elevation3 : mandolinTheme.colors.elevation7};
  border: 1px solid ${mandolinTheme.colors.elevation7};
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    ${mandolinTheme.fonts.TTNormsProBold};
    text-transform: uppercase;
    font-size: 1rem;
    background: transparent;
    color: ${({ active }) => (active ? mandolinTheme.colors.elevation7 : "#6e7073")};
    border: none;
    ${({ active }) => active && `border-bottom: 2px solid ${mandolinTheme.colors.elevation7};`}
    padding: 0;
    border-radius: 0;
  }
`
