import { Network } from "opensea-js"

const ProdConfig = {
  FIREBASE: {
    apiKey: "AIzaSyBppOevpuhuSCZNhhciC2HfjHM8FXYF-Z0",
    authDomain: window.location.host,
    localhostAuthDomain: "mandolin-75659.firebaseapp.com",
    databaseURL: "https://mandolin-75659.firebaseio.com",
    projectId: "mandolin-75659",
    storageBucket: "mandolin-75659.appspot.com",
    messagingSenderId: "942819696195",
    appId: "1:942819696195:web:d82728e8bf02a3b7667ef6",
  },
  MUX: {
    muxDataEnvKey: "7daj2h2b9cap889ql72tvp46d",
  },
  OPTIMIZELY: {
    optimizelyKey: "MiixyxhYh2SfR7Yw1ZheB",
  },
  ETHEREUM: {
    proxyApi: "https://mandolin-nft-proxy-production.herokuapp.com",
    network: Network.Main,
    openseaApi: "https://api.opensea.io/api/v1",
    openseaApiKey: "5f1cdd73cb764f5aac389ce005a1b68d",
    provider: "wss://eth-mainnet.alchemyapi.io/v2/9o2_h8l8AQjogTLFvHsusYlq7WxaT9w9",
    wethContractAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  },
  STRAPI: {
    restURL: "https://content.mandolin.com",
  },
  API: {
    wsURL: "wss://ws.mndl.in",
    restURL: "https://api.mandolin.com",
    contentURL: "https://content.mandolin.com",
  },
  IMAGE_ACCESSOR: {
    baseUrl: "https://image-accessor.mandolin.com",
  },
  MAGIC: {
    magicPublicKey: "pk_live_33854CF2FD68750B",
    chainId: 137,
  },
  ALCHEMY: {
    provider: "https://polygon-mainnet.g.alchemy.com/v2/VkneCiQC3ad9GZLZdkGYILyOVzFh-fLS",
  },
  IPFS: {
    gateway: "https://mandolin.mypinata.cloud/ipfs/",
  },
  MANDOLIN: {
    contractMap: "https://ryelqd4fkc.execute-api.us-east-2.amazonaws.com/contract-map",
    openSeaAssetUrl: "https://opensea.io/assets/matic",
  },
  PRESENTER: {
    baseUrl: "https://present.mandolin.com",
  },
  PAGE_CONTENT: {
    homepage: "9i79w7Ivpg6yMnq0yBes",
  },
}

export default ProdConfig
