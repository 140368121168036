import styled from "styled-components/macro"
import { readableColor } from "polished"
import { SentimentVerySatisfied } from "@styled-icons/material-rounded"

export const Title = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  color: ${({ theme }) => theme.elements.chatText};
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
  margin: 16px 0;
  text-align: center;
  @media (max-width: 1111px) {
    font-size: 16px;
    line-height: 18px;
    width: 232px;
  }
`

export const SubTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ theme }) => theme.colors.elevation6};
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  @media (max-width: 1111px) {
    width: 232px;
  }
`

export const SmileIcon = styled(SentimentVerySatisfied)`
  height: 24px;
  width: 24px;
  fill: #ffffff;
  margin: 0 4px;
  margin-bottom: 4px;
`
