import styled from "styled-components/macro"
import { skeletonStyles } from "theme/utils"

const Container = styled.div`
  div,
  img,
  span,
  h1,
  h2,
  h3,
  h4 {
    margin: 5px;
    padding: 0;
    ${skeletonStyles}
  }

  img {
    visibility: hidden;
  }

  span,
  h1,
  h2,
  h3,
  h4 {
    overflow: hidden;
    color: transparent;
  }
`

export default Container
