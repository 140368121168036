import React, { useCallback, useRef, useEffect } from "react"
import debounce from "lodash/debounce"
import { useMediaQuery } from "react-responsive"
import { useReducedMotion } from "framer-motion"

import FeaturedEvent from "components/FeaturedEvent"

import { useCarousel } from "./CarouselProvider"

import { CarouselItem, CarouselTrack, ClickCover } from "./FeaturedCarousel.styled"
import { HomeShow } from "../../index"

interface CarouselControllerProps {
  trackClickEvent?: (showId: string, showName: string) => void
}

const CarouselController: React.FC<CarouselControllerProps> = ({
  trackClickEvent = () => null,
}) => {
  const {
    lastActiveSlide,
    activeSlide,
    carouselCanAnimate,
    carouselIsNavigating,
    shows,
    carouselContainerWidth,
    carouselItemWidth,
    setCarouselState,
    navigateToSlide,
  } = useCarousel()
  const lastScrollOffset = useRef<number>(0)
  const cardElement = useRef<HTMLLIElement>(null)
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" })
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const shouldReduceMotion = useReducedMotion()

  const CARD_WIDTH = cardElement.current?.clientWidth || 0

  useEffect(() => {
    setCarouselState((prev) => ({ ...prev, carouselItemWidth: CARD_WIDTH }))
  }, [CARD_WIDTH])

  const setActiveBlurHashColor = debounce(
    (newActiveSlide: number, currentScrollPos: number, currentCardWidth: number) => {
      const currentPos = currentScrollPos / currentCardWidth
      // when we're dragging we need to determine if we're closer to the next card or the previous to determine what blurhash color appears in the background behind the carousel
      if (currentPos > newActiveSlide + 0.5) {
        setCarouselState((prev) => ({ ...prev, activeSlide: Math.ceil(currentPos) }))
      } else if (currentPos < newActiveSlide - 0.5) {
        setCarouselState((prev) => ({ ...prev, activeSlide: Math.floor(currentPos) }))
      }
    },
    100,
  )

  const onScroll = (e: React.UIEvent<HTMLUListElement>) => {
    setActiveBlurHashColor(
      activeSlide,
      e.currentTarget.scrollLeft,
      cardElement.current?.clientWidth || 0,
    )
    lastScrollOffset.current = e.currentTarget.scrollLeft
  }

  const renderExtraNext = (): HomeShow[] => {
    // render extra cards for infinite looping
    const output: HomeShow[] = []
    if (shows.length > 2) {
      for (let index = 0; index < 2; index += 1) {
        output.push(shows[index])
      }
    }
    return output
  }

  const renderExtraPrev = (): HomeShow[] => {
    // render extra cards for infinite looping
    const output: HomeShow[] = []
    if (shows.length > 2) {
      for (let index = 0; index < 2; index += 1) {
        output.push(shows[shows.length - 1 - index])
      }
    }
    return output.reverse()
  }

  const TRACK_WIDTH = useCallback(() => {
    const BASE_WIDTH = carouselItemWidth * shows.length
    const PADDED_WIDTH = shows.length > 2 ? carouselItemWidth * 4 : 0

    return BASE_WIDTH + PADDED_WIDTH
  }, [shows, carouselItemWidth])

  const POSITION_OFFSET = useCallback(() => {
    const BASE_OFFSET =
      carouselContainerWidth / 2 +
      (carouselItemWidth / 2) * -1 +
      carouselItemWidth * activeSlide * -1
    const PADDED_OFFSET = shows.length > 2 ? carouselItemWidth * -2 : 0
    if (isPortrait && isTabletOrMobile) {
      return 0
    }
    return BASE_OFFSET + PADDED_OFFSET
  }, [activeSlide, shows, carouselItemWidth])

  const onAnimationComplete = () => {
    if (!carouselCanAnimate) {
      setCarouselState((prev) => ({
        ...prev,
        carouselIsNavigating: false,
        carouselCanAnimate: true,
        activeSlide: lastActiveSlide > activeSlide ? 1 : shows.length - 2,
      }))
    }
  }

  const carouselItemRenderer = (
    show: HomeShow,
    index: number,
    isDuplicate = false,
    duplicateIndex = 0,
  ) => {
    return (
      <CarouselItem
        ref={index === 0 && !isDuplicate ? cardElement : null}
        aria-hidden={isDuplicate ? "true" : "false"}
        active={activeSlide === index || (isDuplicate && activeSlide === duplicateIndex)}
        key={`${show.id}${isDuplicate ? "-duplicate" : ""}`}
        animate={carouselCanAnimate}
        className={`${isDuplicate ? "duplicate" : ""}`}
      >
        <FeaturedEvent
          isTicketPurchased={show.isTicketPurchased}
          buttonProps={
            isDuplicate
              ? {
                  tabIndex: -1,
                }
              : {}
          }
          onFocus={() => {
            if (!isDuplicate) {
              setCarouselState((prev) => ({
                ...prev,
                activeSlide: index,
              }))
            }
          }}
          show={show}
          trackClickEvent={() => trackClickEvent(show.id, show.name)}
        />
        <ClickCover
          onClick={() => {
            if (!carouselIsNavigating) {
              navigateToSlide(index)
            }
          }}
          visible={activeSlide !== index}
        />
      </CarouselItem>
    )
  }

  const renderContent = () => (
    <>
      {renderExtraPrev().map((show, i) =>
        carouselItemRenderer(show, i - 2, true, shows.length - 2 + i),
      )}
      {shows.map((show, i) => carouselItemRenderer(show, i))}
      {renderExtraNext().map((show, i) => carouselItemRenderer(show, shows.length + i, true, i))}
    </>
  )

  return (
    <>
      <CarouselTrack
        trackWidth={TRACK_WIDTH()}
        onScroll={onScroll}
        initial={{ opacity: 0.25 }}
        animate={{
          x: POSITION_OFFSET(),
          opacity: 1,
        }}
        transition={{
          ease: [0.4, 0, 0.2, 1],
          duration: !shouldReduceMotion && carouselCanAnimate ? undefined : 0,
        }}
        onAnimationComplete={onAnimationComplete}
      >
        {renderContent()}
      </CarouselTrack>
    </>
  )
}

export default React.memo(CarouselController)
