import React, { useState, FC } from "react"
import { VIPExperienceParticipantStatus } from "@mandolin-dev/ts-sdk"
import StreamChat from "components/Watch/Chat/StreamChat"

import { Tabs, Tab } from "./Tabs"
import Fans from "./Fans"

export enum SidebarTabs {
  chat,
  fans,
}

interface SidebarProps {
  showId: string
  guestState: VIPExperienceParticipantStatus | undefined
}

const Sidebar: FC<SidebarProps> = ({ showId, guestState }) => {
  const [activeTab, setActiveTab] = useState(SidebarTabs.chat)
  const inParty =
    guestState === VIPExperienceParticipantStatus.HandRaised ||
    guestState === VIPExperienceParticipantStatus.InParty ||
    guestState === VIPExperienceParticipantStatus.OnMic

  return (
    <Tabs activeTab={activeTab} onTabChange={setActiveTab}>
      <Tab id={SidebarTabs.chat} label="Chat">
        <div style={{ height: "100%", display: "flex" }}>
          <StreamChat showId={showId} />
        </div>
      </Tab>
      {inParty ? (
        <Tab id={SidebarTabs.fans} label="Fans">
          <Fans />
        </Tab>
      ) : null}
    </Tabs>
  )
}

export default Sidebar
