import React from "react"
import styled from "styled-components/macro"
import { readableColor } from "polished"
import { isCobranded } from "services/utils"

const Anchor = styled.a`
  display: flex;
  margin: 0 auto;
  padding: 24px 0 24px 0;
  width: 108px;
  svg {
    path {
      fill: ${({ theme }) => readableColor(theme.elements.pageBackground)};
    }
  }
`

const PoweredBy = () =>
  isCobranded() ? (
    <Anchor href="https://www.mandolin.com/" rel="noopener noreferrer" target="_blank">
      <svg viewBox="0 0 904 200">
        <path d="M291 197h-27v-80h31l19 26 19-26h29v80h-27v-21l1-22-21 28h-3l-22-28 1 22v21zm197-33v33h-28v-80h26l27 33v-33h28v80h-26l-27-33zm266-47v55h26v25h-54v-80h28zm32 0h28v80h-28v-80zm66 47v33h-28v-80h26l26 33v-33h28v80h-26l-26-33zm-178-50c-26 0-47 19-47 43s21 43 47 43c25 0 46-19 46-43s-21-43-46-43zm0 57c-9 0-16-6-16-14s7-14 16-14c8 0 15 6 15 14s-7 14-15 14zm-96-54h-29v80h30c24 0 44-9 44-40s-21-40-45-40zm4 54h-6v-28h6c8 0 15 4 15 14 0 11-6 14-15 14zm-158-54h-25l-32 80h29l3-8h24l3 8h29l-31-80zm-20 52l7-23 8 23h-15zM271 16h24l8 1a18 18 0 0112 18l-2 7a18 18 0 01-10 10l-8 2h-12v18h-12V16zm23 26c3 0 5 0 6-2 2-1 2-3 2-5s0-4-2-6l-6-2h-11v15h11zM340 73a21 21 0 01-20-13 20 20 0 010-15 21 21 0 0120-13 20 20 0 0114 6 20 20 0 016 14 20 20 0 01-6 15 20 20 0 01-14 6zm0-10l3-1 4-2 2-3 1-5a11 11 0 00-3-7 10 10 0 00-7-3l-4 1a10 10 0 00-6 5v9a10 10 0 0010 6zM363 33h11l8 23 8-23h8l8 23 8-23h11l-14 39h-9l-8-23-8 23h-9l-14-39zM448 73a21 21 0 01-19-13 20 20 0 010-15 21 21 0 0119-13l8 2a19 19 0 0111 20 59 59 0 010 2h-28c0 2 1 4 3 5 2 2 4 2 7 2a14 14 0 008-2l3-2 5 7-4 4-5 2-8 1zm8-25l-3-4c-1-2-3-2-5-2-3 0-5 0-6 2l-3 4h17zM476 33h10v5h1l3-3 3-2 5-1h1v12a28 28 0 00-3-1c-3 0-5 1-7 3s-3 4-3 8v18h-10V33zM524 73a21 21 0 01-20-13 20 20 0 010-15 21 21 0 0119-13l8 2a19 19 0 0112 20 59 59 0 01-1 2h-28l3 5c2 2 4 2 7 2a14 14 0 008-2l3-2 5 7-4 4-5 2-7 1zm8-25l-4-4c-1-2-3-2-5-2-3 0-4 0-6 2l-3 4h18zM566 73a16 16 0 01-12-6l-4-6-2-9 2-8 4-6 5-4a15 15 0 0119 4h1V16h11v56h-11v-6h-1c0 2-1 3-3 4l-4 2-5 1zm3-10l4-1 3-2 2-3 1-5a11 11 0 00-3-7 10 10 0 00-7-3l-4 1-3 2a10 10 0 00-3 7l1 5a10 10 0 009 6zM641 73a14 14 0 01-9-3l-3-4h-1v6h-10V16h10v22h1a14 14 0 017-5l5-1 7 2 5 4 4 6 2 8-2 9-4 6-5 4-7 2zm-3-10l4-1 3-2 2-3 1-5a11 11 0 00-3-7 10 10 0 00-7-3l-4 1a10 10 0 00-5 5l-1 4 1 5a10 10 0 009 6zM672 89a25 25 0 01-6-1h-2V78h5l4-1 3-3v-2l-15-39h12l9 26 9-26h12l-16 42-6 10c-3 3-6 4-9 4zM169 200l-69-59L0 54v146h169zM106 81l-22 19 116 100V0l-94 81z" />
      </svg>
    </Anchor>
  ) : null

export default PoweredBy
