import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { EllipsisHorizontal } from '@styled-icons/ionicons-sharp';
import { useTheme } from "styled-components/macro"
import SkeletonMessage from './SkeletonMessage';
import {
  Avatar,
  Message,
  MessageContent,
  MessageHead,
  MessageUsername,
  MessageMenuButton,
  MessageTimestamp,
  MessageBody,
} from './Chat.styled';
import { CHAT_PERMISSIONS } from '../../../services/chat';
const ADMIN_PERMISSIONS = [CHAT_PERMISSIONS.deleteAnyMessage, CHAT_PERMISSIONS.removeMember];

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const Messages = (props) => {
  const {
    messages,
    chatIsConnected,
    participants,
    permissions,
    banUser,
    deleteMessage
  } = props.chatContext;
  const { logos: { avatar } } = useTheme()

  const confirmDeleteMessage = useCallback(
    (message) => {
      if (window.confirm('Are you sure you want to delete this message?')) {
        deleteMessage(message);
      }
    },
    [deleteMessage]
  );

  const confirmBanUser = useCallback(
    (message) => {
      if (window.confirm('Are you sure you want to ban this user?')) {
        banUser(message);
      }
    },
    [banUser]
  );

  const hasAdminPermission = permissions.filter((permission) => ADMIN_PERMISSIONS.includes(permission))?.length !== 0;
  const hasDeletePermission = permissions.includes(CHAT_PERMISSIONS.deleteAnyMessage);
  const hasRemovePermission = permissions.includes(CHAT_PERMISSIONS.removeMember);
  return (
    <div className="messages">
      {!chatIsConnected && [...Array(40)].map((_, i) => <SkeletonMessage key={i} />)}
      {chatIsConnected &&
        messages.map((message) => (
          <Message key={message.sid}>
            <Avatar>
              <img alt="avatar" src={participants[message.author]?.photoUrl || avatar} />
            </Avatar>
            <MessageContent>
              <MessageHead>
                <MessageUsername>{participants[message.author]?.name}</MessageUsername>
                <MessageTimestamp>{message.dateCreated.toLocaleTimeString()}</MessageTimestamp>
                {hasAdminPermission && (
                  <MessageMenuButton>
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle}>
                        <EllipsisHorizontal size="36px" color="#565962" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {hasDeletePermission && (
                          <Dropdown.Item onClick={() => confirmDeleteMessage(message)}>
                            <span style={{color: 'black'}}>Delete this message</span>
                          </Dropdown.Item>
                        )}
                        {hasRemovePermission && (
                          <Dropdown.Item onClick={() => confirmBanUser(message.author)}>
                            <span style={{color: 'black'}}>Ban user from chat</span>
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </MessageMenuButton>
                )}
              </MessageHead>
              <MessageBody>{message.body}</MessageBody>
            </MessageContent>
          </Message>
        ))}
    </div>
  );
};

export default Messages;
