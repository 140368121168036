import { GeneralChat } from "@mandolin-dev/chat-sdk"
import { WsActions } from "@mandolin-dev/websocket-sdk"
import React, { useRef, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { auth as fbauth } from "firebaseApp"
import { useCobranding, useRestClient } from "hooks"
import { mix, invert } from "polished"
import { getWebsocketClient } from "services/wsClient"
import { trackChatMessageSend } from "services/analytics"
import { RootState } from "redux/store"

import { ChatTheme } from "../../../theme"

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 24px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const StreamChat = ({ showId }: { showId: string }) => {
  const wsActions = useRef<WsActions>(getWebsocketClient().wsActions)
  const [isManager, setIsManager] = useState<boolean | undefined>()
  const { restClient } = useRestClient()
  const { auth, user, show } = useSelector((state: RootState) => state)
  const cobrand = useCobranding(show.cobrands[0] as any)

  useEffect(() => {
    const initIsManager = async () => {
      const userDocLoaded = user?.uid !== undefined

      if (!auth.isAuthenticated) {
        // unauthenticated user is not a show manager
        setIsManager(false)
      } else if (userDocLoaded && user?.isManager === true) {
        // authenticated user with isManager flag
        // check if user can access managerGetShow for this show they
        // are a manager
        try {
          await restClient.managerGetShow({
            showId,
          })
          setIsManager(true)
        } catch (_e) {
          setIsManager(false)
        }
      } else if (userDocLoaded) {
        // authenticated user, check for admins on jwt claims
        const token = await fbauth.currentUser?.getIdTokenResult(true)
        setIsManager(token?.claims.admins === true)
      }
    }

    if (restClient && !auth.isPending && isManager === undefined) {
      initIsManager()
    }
  }, [auth, showId, isManager, user, restClient])

  if (restClient && wsActions && auth && user && user.name && isManager !== undefined) {
    return (
      <div style={{ flex: 1, position: "relative" }}>
        <GeneralChat
          className="general-chat"
          eventId={showId}
          restClient={restClient}
          wsActions={wsActions.current}
          user={{
            id: auth?.uid || "",
            name: user?.name,
            avatar: user?.photoUrl || "",
            userIsManager: isManager,
          }}
          theme={
            {
              colors: {
                ...ChatTheme.colors,
                primaryText: cobrand.theme.elements.chatText,
                secondaryText: cobrand.theme.elements.chatHeaderAccent,
                primaryHeaderText: cobrand.theme.elements.chatHeaderText,
                headerBackground: cobrand.theme.elements.chatHeaderBackground,
                secondaryHeaderText: cobrand.theme.elements.chatHeaderAccent,
                background: cobrand.theme.elements.chatBackground,
                border: cobrand.theme.elements.chatText,
                highlight: cobrand.theme.elements.chatHeaderBackground,
                icons: cobrand.theme.elements.chatText,
                inputText: cobrand.theme.elements.chatText,
                inputBorder: mix(
                  0.8,
                  cobrand.theme.elements.chatBackground,
                  invert(cobrand.theme.elements.chatBackground),
                ),
              },
              fonts: {
                ...ChatTheme.fonts,
              },
              scale: {
                ...ChatTheme.scale,
              },
            } as any
          }
          eventCallback={(e) => {
            switch (e) {
              case "messageSent": {
                trackChatMessageSend(
                  showId,
                  show.state,
                  show.name,
                  auth.uid ? "authenticated" : "unauthenticated",
                  show.venue?.name,
                  true,
                )
                break
              }
              default: {
                break
              }
            }
          }}
        />
      </div>
    )
  }

  if (user && !user.name && isManager !== undefined) {
    return <LoadingContainer>You need to set a user name in your profile to chat</LoadingContainer>
  }

  return <LoadingContainer>Loading Chat</LoadingContainer>
}

export default StreamChat
