import { auth } from "../firebaseApp"
import Config from "../config/config"

const WHITELISTED_SUBDOMAINS = [
  "localhost",
  "dev-demo",
  "dev-lofi",
  "dev-jazz",
  "dev-testing",
  "festival-demo",
  "watch",
  "admin",
  "admin-testing",
]

const DEV_DOMAINS = ["dev-testing", "dev-jazz", "dev-lofi", "dev-demo", "demo"]

const ADMIN_DOMAINS = {
  production: "https://admin.mandolin.com",
  development: "https://mandolin-admin-jazz.web.app",
  "dev-demo": "https://mandolin-admin-demo.web.app",
  "dev-lofi": "https://mandolin-admin-lofi.web.app",
  "dev-testing": "https://admin-testing.mandolin.com",
}

export const getAdminDomain = () => ADMIN_DOMAINS[process.env.REACT_APP_MANDOLIN_ENV]

const domain = window.location.hostname
// Dev tool for setting a cobrand from localStorage
const getLocalCobrand = () => window.localStorage.getItem("mndlin_cobrand")

export const overrideDomain = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get("cobrand")
}

export const getSubdomain = () => {
  const subdomain = domain.split(".")[0]
  return overrideDomain() || getLocalCobrand() || subdomain
}

export const isDevDomain =
  DEV_DOMAINS.includes(getSubdomain()) || window.location.hostname === "localhost"
export const isFestival = getSubdomain() === "festival-demo"
export const isWolftrap = getSubdomain() === "wolftrap"

export const getSld = () => {
  const sld = domain.split(".")[1]
  return sld
}

export const isCobranded = () => {
  const subdomain = getSubdomain()
  const sld = getSld()
  return (
    overrideDomain() ||
    getLocalCobrand() ||
    (sld === "mandolin" && !WHITELISTED_SUBDOMAINS.includes(subdomain))
  )
}

export const retryPromise = async (fn, retriesLeft = 1, interval = 1000) => {
  try {
    const val = await fn()
    return val
  } catch (error) {
    if (retriesLeft) {
      await new Promise((r) => setTimeout(r, interval))
      return retryPromise(fn, retriesLeft - 1, interval)
    }
  }
}

export const getToken = async () => auth.currentUser?.getIdToken(true) || ""

export const isDevEnv =
  process.env.REACT_APP_MANDOLIN_ENV === "development" ||
  process.env.REACT_APP_MANDOLIN_ENV === "dev-testing" ||
  process.env.REACT_APP_MANDOLIN_ENV === "dev-demo" ||
  process.env.REACT_APP_MANDOLIN_ENV === "dev-lofi"

export const { wsURL } = Config.API
export const { restURL } = Config.API

export const readPhoto = async (photo) => {
  const canvas = document.createElement("canvas")
  const img = document.createElement("img")

  // create img element from File object
  img.src = await new Promise((resolve) => {
    const reader = new window.FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.readAsDataURL(photo)
  })
  await new Promise((resolve) => {
    img.onload = resolve
  })

  // draw image in canvas element
  canvas.width = img.width
  canvas.height = img.height
  canvas.getContext("2d").drawImage(img, 0, 0, canvas.width, canvas.height)

  return canvas
}

export const scaleCanvas = (canvas, scale) => {
  const scaledCanvas = document.createElement("canvas")
  scaledCanvas.width = canvas.width * scale
  scaledCanvas.height = canvas.height * scale

  scaledCanvas.getContext("2d").drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height)

  return scaledCanvas
}

export const optimizePhoto = async (photo) => {
  const MAX_WIDTH = 1000
  const QUALITY = 0.7
  let canvas = await readPhoto(photo)

  while (canvas.width >= 2 * MAX_WIDTH) {
    canvas = scaleCanvas(canvas, 0.5)
  }

  if (canvas.width > MAX_WIDTH) {
    canvas = scaleCanvas(canvas, MAX_WIDTH / canvas.width)
  }

  return new Promise((resolve) => {
    canvas.toBlob(resolve, "image/jpeg", QUALITY)
  })
}

export const isUrl = (url) => {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return regexp.test(url)
}

export const imageNanoServiceAccessor = ({ imageAssetUrl, imageAssetSizeProps }) => {
  // Return the imageAssetUrl as is if it is not a url (local file path) that is also firebase hosted
  if (
    !isUrl(imageAssetUrl) ||
    !String(imageAssetUrl).includes("firebase") ||
    window.location.hostname === "localhost"
  ) {
    return imageAssetUrl
  }
  const encodedUri = encodeURIComponent(imageAssetUrl)
  const { baseUrl } = Config.IMAGE_ACCESSOR

  return `${baseUrl}/api/access?assetUrl=${encodedUri}&${imageAssetSizeProps.type}=${imageAssetSizeProps.value}&redirect=1`
}

export const showZendesk = () => window.zE.activate()
