import styled, { css } from "styled-components/macro"
import { Link } from "react-router-dom"
import { Error, Close } from "@styled-icons/material-rounded"
import { readableColor, rgba } from "polished"
import { Button } from "../shared"

const buttonStyles = css`
  color ${({ theme }) => theme.colors.elevation1};
  background: ${({ theme }) => theme.colors.elevation8};
  padding: 9px 16px;
  border-radius: 32px;
  margin: 32px auto 0 auto;
  outline: none;
  border: none;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color ${({ theme }) => theme.colors.elevation1};
    background: ${({ theme }) => theme.colors.elevation7};
  }
`

export const ClaimCodeButton = styled(Button)`
  background-color: ${({ theme }) => rgba(readableColor(theme.colors.elevation1), 0.6)};

  &:not(:disabled):hover {
    background-color: ${({ theme }) => rgba(readableColor(theme.colors.elevation1), 0.8)};
    opacity: 1;
  }
`

export const CloseButton = styled(Close)`
  cursor: pointer;
  position: absolute;
  top: 29px;
  right: 29px;
  width: 20px;
  height: 20px;
`

export const TicketsButton = styled(Link)`
  ${buttonStyles}
`

export const ErrorIcon = styled(Error)``

export const ModalBackdrop = styled.div`
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 72px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 98;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 48px 64px;
  z-index: 99;
  text-align: left;
  align-items: flex-start;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  color ${({ theme }) => theme.colors.elevation8};
  background: ${({ theme }) => theme.colors.elevation2};
  border-radius: 16px;
  width: 600px;
  max-height: 90%;

  > svg {
    cursor: pointer;
    margin-left: auto;
  }

  > button.claim-button {
    ${buttonStyles}
    width: auto;
    margin-top: 12px;
    height: auto;
  }

  @media (max-width: 600px) {
    width: 360px;
    padding: 10px 20px;
  }
`

export const ClaimCodesList = styled.div`
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const ModalTitle = styled.div`
  ${({ theme }) => theme.fonts.bold}
  font-size: 28px;
  line-height: 32px;
`

export const ModalText = styled.p`
  font-size: 16px;
  line-height: 22px;
`

export const ModalWarning = styled.p`
  color: #ffe073;
`

export const ModalSubText = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin: 24px auto 0 auto;
`

export const InputContainer = styled.div`
  background: ${({ theme }) => theme.colors.elevation3};
  border-radius: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  margin: 24px auto 0 auto;
  width: 100%;

  .claimError {
    cursor: pointer;
    color: #FF7575;
  }

  .buttonClear {
    cursor: pointer;
    margin-left: auto;
  }

  > input {
    outline: none;
    border: none;
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    width: 100%;
    color ${({ theme }) => theme.colors.elevation8};
    background: ${({ theme }) => theme.colors.elevation3};

    ::placeholder {
      background: ${({ theme }) => theme.colors.elevation3};
    }
  }
`

export const ShowHeading = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin: 0 auto 8px auto;
`

export const ShowTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin: 0 auto 9px auto;
`

export const ShowTime = styled.div`
  font-size: 14px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  margin: 0 auto;
`

export const ClaimCodesInputWrapper = styled.div`
  background: ${({ theme }) => theme.colors.elevation3};
  border-radius: 36px;
  border: 1px solid ${({ theme }) => theme.colors.elevation3};
  display: flex;
  width: 100%;
  margin: 8px 0;
  justify-content: space-between;
`

export const CopyButton = styled.button`
  background-color: #fff;
  border-radius: 10px;
  border: none;
  color: ${({ theme }) => theme.colors.elevation3};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  margin: 10px 14px auto auto;
  padding: 0 10px;
  vertical-align: middle;
`

export const CopiedButton = styled(CopyButton)`
  background-color: #53ff98;
`

export const ClaimCodesInput = styled.input`
  background: transparent;
  border-radius: 36px;
  border: none;
  color: ${({ theme }) => theme.colors.elevation8};
  height: 40px;
  margin-left: 14px;
  outline: none;
  width: 70%;
  font-size: 14px;
`
