/* eslint-disable import/prefer-default-export */
import styled from "styled-components/macro"

export const CollectionWrapper = styled.section`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, 300px);
  margin: 48px 16px;
  place-content: center;
`
