import React from "react"
import { LoadingContainer } from "./Loading.styled"

const Loading = () => {
  return (
    <LoadingContainer>
      <img alt="Page load icon" src="/logo/PNG/Large/mark-white@3x.png" />
    </LoadingContainer>
  )
}

export default Loading
