import styled from "styled-components/macro"
import { NavLink } from "react-router-dom"
import { Close } from "@styled-icons/material-sharp"

const CloseIcon = styled(Close)`
  height: 36px;
  width: 36px;
  line-height: 100%;
  fill: #404040;
  cursor: pointer;
  transition: opacity ${({ theme }) => theme.transition};
  &:hover {
    opacity: 0.75;
  }
`
const DrawerBody = styled.div`
  right: 0;
  height: 100%;
  background: #ffffff;
  color: #111112;
  transform: ${(props) => (props.open ? "translateX(0%)" : "translateX(100%)")};
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
    box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
`

const DrawerContainer = styled.div`
  width: ${(props) => (props.open ? "100%" : "0")};
  right: 0;
  top: 0;
  height: 100%;
  position: fixed;
  z-index: 1000;
  transition: ${(props) =>
    props.open
      ? "transform .3s cubic-bezier(.7,.3,.1,1),-webkit-transform .3s cubic-bezier(.7,.3,.1,1)"
      : "transform .3s cubic-bezier(.7,.3,.1,1),height 0s ease .3s,width 0s ease .3s,-webkit-transform .3s cubic-bezier(.7,.3,.1,1)"};
  margin: 0;
  padding: 0;
  display: block;
  z-index: 1000;
`

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 16px;
  margin-bottom: 32px;
`

const DrawerOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
`

const DrawerLink = styled(NavLink).attrs({ activeClassName: "active" })`
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  color: #000000;
  cursor: pointer;
  font-size: 32px;
  letter-spacing: -0.02em;
  line-height: 100%;
  text-decoration: none;
  width: 140px;
  margin-bottom: 24px;
  transition: opacity ${({ theme }) => theme.transition};
  &:hover {
    opacity: 0.75;
    text-decoration: none;
    color: #000000;
  }
`

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const LogoImg = styled.img.attrs({ height: "48px", width: "160px" })`
  object-fit: contain;
`

const LogoBrand = styled.img.attrs({ height: "48px", width: "124px" })`
  object-fit: contain;
  position: absolute;
  top: 32px;
  left: 54px;
`

const MenuIconContainer = styled.div`
  align-items: center;
  background: #0000ff;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-left: 16px;
  width: 40px;
  transition: background ${({ theme }) => theme.transition};
  &:hover {
    background: #000080;
  }
  svg {
    fill: white;
    height: 24px;
    width: 24px;
  }
`

export {
  CloseIcon,
  DrawerBody,
  DrawerContainer,
  DrawerHeader,
  DrawerOptions,
  DrawerLink,
  Logos,
  LogoImg,
  MenuIconContainer,
  LogoBrand,
}
