import { createWebsocketClientInstance, WsActions } from "@mandolin-dev/websocket-sdk"
import { getToken, wsURL } from "./utils"
import { getRemoteConfig } from "./remoteConfig"

const connectedClients: Record<string, WsActions> = {}

export const initWsClient = (url = wsURL) => {
  if (!connectedClients[url]) {
    connectedClients[url] = createWebsocketClientInstance(url, getToken, {
      batchingEnabled: true,
    })
  }
  return connectedClients[url]
}

export const getWebsocketClient = (url = wsURL) => {
  const wsActions = connectedClients[url]
  return {
    wsActions,
  }
}

export const getStreamChatWsClient = async () => {
  const config = await getRemoteConfig()
  const url = config.getValue("stream_chat_ws_read_url").asString()
  return initWsClient(url)
}
