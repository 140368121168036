import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { atMediaMin } from "theme/utils"

const NavItem = styled(NavLink).attrs({ activeClassName: "active" })`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  border-radius: 29px;
  align-items: center;
  color: ${({ theme }) => theme.elements.pageText};
  display: flex;
  font-size: 13px;
  height: 36px;
  padding: 8px;
  transition: 0.1s ease all;
  opacity: 0.5;
  margin-bottom: 32px;
  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    @media (max-width: 740px) {
      display: none;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.elements.pageText};
    opacity: 0.7;
    text-decoration: none;
  }
  @media (max-width: 740px) {
    height: 60px;
    text-align: center;
    margin: 0;
  }
`

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  padding-right: 20px;
  @media (max-width: 740px) {
    padding: 0px;
    border-bottom: 1px solid #dcdcdc;
    flex-direction: row;
    justify-content: space-between;
    width: unset;
    position: sticky;
    top: 0;
    background: ${({ theme }) => theme.elements.pageBackground};
    z-index: 2;
    margin: 0;
  }
  span {
    padding: 4px 8px;
    border-radius: 100px;
  }
  .active {
    span {
      color: ${({ theme }) => theme.elements.pageText};
      background-color: ${({ theme }) => theme.colors.elevation3};
      opacity: 1;
      ${atMediaMin("md")} {
        background-color: transparent;
      }
    }
    ${atMediaMin("md")} {
      background-color: ${({ theme }) => theme.colors.elevation3};
    }
  }
`

export { NavContainer, NavItem }
