import { Button } from "components/shared"
import { BaseButton } from "components/shared/Button/Button.styled"
import styled from "styled-components"
import { mandolinTheme } from "theme"

interface TabProps {
  visible: boolean
}

export const Tab = styled.div<TabProps>`
  display: ${({ visible }) => (visible ? "block" : "none")};
`

export const TabWrapper = styled.div`
  display: flex;
  gap: 2rem;
  padding: 3rem 0;
`

export const Container = styled.div`
  min-height: 80vh;
  padding: 0 1rem;

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    padding: 0 3.75rem 3.75rem 3.75rem;
  }
`

export const CollectibleGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: 100%;
  }
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    & > div {
      width: 49%;
    }
    gap: 1%;
  }

  @media only screen and (min-width: ${mandolinTheme.breakpoints.lg}px) {
    & > div {
      width: 24.25%;
    }
    gap: 1%;
  }
`

export const ClaimWrapper = styled.div`
  position: fixed;
  bottom: 0.75rem;
  right: 2rem;
  z-index: 400;

  h1 {
    display: none;
  }

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    position: unset;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    h1 {
      display: block;
      ${mandolinTheme.fonts.TTNormsProBold};
      font-size: 1.5rem;
      color: ${mandolinTheme.colors.elevation7};
      margin: 0;
    }
  }
`

export const ClaimDropdownWrapper = styled.div`
  position: relative;
`

export const ClaimDropdown = styled.div`
  animation: fadeIn 0.14s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: absolute;
  top: -100px;
  left: -40px;
  width: 232px;
  height: 90px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  z-index: 1000;
`

export const ClaimDropdownContent = styled.div`
  background: ${mandolinTheme.colors.elevation7};
  border-radius: 0.25rem;
  border-radius: 4px;
  box-shadow: 0 1px 17px 0 rgba(40, 44, 53, 0.1), 0 2px 4px 0 rgba(40, 44, 53, 0.1);

  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
export const ClaimDropdownButton = styled(BaseButton)<{ disabled: boolean }>`
  width: unset;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 0.5rem;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "pointer" : "not-allowed")};
  }
`

export const ClaimButton = styled(Button)`
  background: transparent;
  display: flex;
  align-items: center;
  img {
    margin-right: 0.5rem;
  }
`

export const MagicSignIn = styled(Button)`
  display: flex;
  align-items: center;
  img {
    margin-right: 0.5rem;
  }
`

export const GradientBox = styled.div`
  display: none;

  @media only screen and (min-width: ${mandolinTheme.breakpoints.lg}px) {
    background: linear-gradient(
        88.1deg,
        rgba(252, 86, 139, 0.5) -23.9%,
        rgba(251, 147, 147, 0.5) 6.64%,
        rgba(252, 80, 120, 0.5) 27.53%,
        rgba(239, 176, 191, 0.5) 50.03%,
        rgba(186, 143, 220, 0.5) 77.36%,
        rgba(37, 167, 238, 0.5) 100.66%,
        rgba(80, 202, 255, 0.5) 130.4%
      ),
      #ffffff;

    min-height: 212px;
    margin: 1.5rem 0;

    border-radius: 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    ${mandolinTheme.fonts.TTNormsProNormal};
    font-size: 1.5rem;
    color: ${mandolinTheme.colors.elevation3};
  }
`

export const NoKeepsWrapper = styled.div`
  padding: 2rem;
`
