import { transparentize } from "polished"
import styled from "styled-components/macro"

export const InputName = styled.input`
  -webkit-box-direction: normal;
  -webkit-writing-mode: horizontal-tb !important;
  appearance: textfield;
  background-color: rgba(255, 255, 255);
  border: none;
  display: inline-block;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.colors.elevation5};
  border-radius: 4px;
  height: 48px;
  letter-spacing: normal;
  outline: none;
  padding: 18px 15px 6px;
  text-align: start;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  white-space: nowrap;
  word-spacing: normal;
  &:active,
  &:focus {
    outline-offset: 0;
    border: 1px solid ${({ theme }) => theme.colors.primarySolid};
  }
`
export const InputContainer = styled.div`
  z-index: 300;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  max-width: 420px;
  padding: 20px 30px;
  background-color: ${({ theme }) => theme.colors.elevation7};
  @media (min-width: 768px) {
    padding: 40px 60px;
  }
`
export const InputLabel = styled.label`
  ${({ theme }) => theme.fonts.TTNormsProLight};
  position: relative;
  color: ${({ theme }) => theme.colors.elevation4};
  font-size: 12px;
  right: 35%;
  top: 30px;
`
export const InputText = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
export const InfoText = styled.div`
  padding-top: 24px;
  font-size: 12px;
  text-align: start;
  color: ${({ theme }) => transparentize(0.2, theme.colors.elevation2)};
`
