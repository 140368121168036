import styled from "styled-components"

const ContentContainer = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`
const SuccessContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  svg {
    margin-bottom: 20px;
  }
`
const Logo = styled.img`
  max-width: 180px;
  margin: 2rem;
  display: block;
`
const Headline = styled.div`
  font-size: 36px;
  line-height: 1.1;
  margin-bottom: 20px;
  ${({ theme: { fonts } }) => fonts.TTNormsProBold}
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.warning};
`


export { ContentContainer, SuccessContainer, Logo, Headline, ErrorMessage  }
