import "@ungap/global-this"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import { datadogRum } from "@datadog/browser-rum"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import Config from "./config/config"

datadogRum.init(Config.DATADOG)

datadogRum.startSessionReplayRecording()

ReactDOM.hydrate(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
