import React, { useRef, useState } from "react"
import Modal from "react-modal"
import { CloseOutline, ExternalLinkOutline } from "styled-icons/evaicons-outline/"
import { VolumeUp, VolumeOff } from "styled-icons/evaicons-solid/"
import { OpenInFull } from "styled-icons/material-rounded/"
import { CloseFullscreen } from "styled-icons/material-sharp"
import { assetURL } from "../service"
import Asset from "./Asset"
import Config from "../../../config/config"
import "./NftModal.css"
import {
  AssetWrapper,
  Attribute,
  Attributes,
  ButtonWrapper,
  CloseButton,
  Data,
  DataWrapper,
  ModalContent,
  modalStyles,
  RoundedButton,
} from "./NftModal.styled"

interface NftModalModel {
  asset: string
  preview?: string
  title: string
  author: string
  description: string
  date: string
  edition: number
  number?: number
  totalSupply?: number
  wallet?: string
  attributes?: {
    trait_type: string
    value: string
  }[]
  contractAddress?: string
  tokenId?: number
}

interface NftModalProps {
  nftModel: NftModalModel | undefined
  isModalOpen: boolean
  onRequestClose: () => void
}

Modal.setAppElement("#root")

export default function NftModal(props: NftModalProps) {
  const { nftModel, isModalOpen, onRequestClose } = props

  const [fullscreen, setFullscreen] = useState(false)
  const [muted, setMuted] = useState(false)

  const assetRef = useRef<any>(null)

  const toggleMuted = () => {
    if (assetRef.current && assetRef.current.muted !== undefined) {
      assetRef.current.muted = !assetRef.current.muted
      setMuted(assetRef.current.muted)
    }
  }

  const handleOnCloseModal = () => {
    onRequestClose()
  }
  return (
    <Modal isOpen={isModalOpen} onRequestClose={handleOnCloseModal} style={modalStyles}>
      <ModalContent fullscreen={fullscreen}>
        <CloseButton type="button" onClick={onRequestClose}>
          <CloseOutline size="25" color="black" />
        </CloseButton>
        {nftModel && (
          <>
            <AssetWrapper fullscreen={fullscreen}>
              <Asset
                ref={assetRef}
                url={assetURL(nftModel.asset)}
                preview={nftModel.preview && assetURL(nftModel.preview)}
                muted={muted}
                modalAsset
              />
              <ButtonWrapper>
                <RoundedButton type="button" onClick={toggleMuted}>
                  {muted ? <VolumeOff size="18" /> : <VolumeUp size="18" />}
                </RoundedButton>
                <RoundedButton
                  type="button"
                  fullscreen={fullscreen}
                  onClick={() => setFullscreen(!fullscreen)}
                >
                  {fullscreen ? <CloseFullscreen size="18" /> : <OpenInFull size="18" />}
                </RoundedButton>
              </ButtonWrapper>
            </AssetWrapper>
            <DataWrapper>
              <Data>
                <h1>{nftModel.title}</h1>
                <h2>by {nftModel.author}</h2>
                <p>{nftModel.description}</p>
                <p>
                  <strong>{nftModel.date}</strong>
                  <br />
                  <strong>{nftModel.edition && `Edition ${nftModel.edition}`}</strong>
                  <br />
                  <strong>
                    {nftModel.number &&
                      nftModel.totalSupply &&
                      `${nftModel.number}/${nftModel.totalSupply}`}
                  </strong>
                  <br />
                  {nftModel.contractAddress && nftModel.tokenId && (
                    <strong>
                      <a
                        href={`${Config.MANDOLIN.openSeaAssetUrl}/${nftModel.contractAddress}/${nftModel.tokenId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                      <ExternalLinkOutline size="16" />&nbsp;
                         View on Opensea
                      </a>
                    </strong>
                  )}
                </p>
              </Data>
              {nftModel.attributes && (
                <Attributes>
                  {nftModel.attributes.map((attr) => (
                    <Attribute key={`${attr.trait_type}${attr.value}`}>
                      <code>{attr.trait_type}</code>
                      <div>{attr.value}</div>
                    </Attribute>
                  ))}
                </Attributes>
              )}
            </DataWrapper>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
