import React, { useRef, useState, useEffect } from "react"
import { ArtistDTO, VenueDTO } from "@mandolin-dev/ts-sdk"
import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons"
import Typography from "components/Typography"
import {
  Arrow,
  ArrowContainer,
  CarouselTitleContainer,
  EmptyArrowContainer,
  ClientCarouselRow,
  ClientCarouselRowContainer,
  CarouselSection,
} from "components/ClientCarousel/ClientCarousel.styled"
import ContentSection from "components/ContentSection"
import Skeleton from "components/Skeleton"
import ClientCarouselItem, { ClientCarouselData } from "./ClientCarouselItem"

export enum ClientType {
  Artists = "Artists",
  Venues = "Venues",
}

interface ClientCarouselProps {
  dataType: ClientType
  data: ArtistDTO[] | VenueDTO[]
  itemWidthWithPaddingDesktop: number
  itemWidthWithPaddingMobile: number
  loading?: boolean
}

const MIN_DESKTOP_SCREEN_WIDTH = 600

const ClientCarousel: React.FC<ClientCarouselProps> = ({
  dataType,
  data,
  itemWidthWithPaddingDesktop,
  itemWidthWithPaddingMobile,
  loading,
}) => {
  const shouldHideArrows = window.innerWidth <= MIN_DESKTOP_SCREEN_WIDTH

  const [showBackArrow, setShowBackArrow] = useState(false)
  const [showForwardArrow, setShowForwardArrow] = useState(false)

  const rowRef = useRef<any>()
  const lastScrollOffset = useRef<Element>()
  const adjustedItemWidth = useRef(
    window.innerWidth <= MIN_DESKTOP_SCREEN_WIDTH
      ? itemWidthWithPaddingMobile
      : itemWidthWithPaddingDesktop,
  ).current

  const castedData = data as ClientCarouselData[]
  const renderedData = castedData.filter((d: ClientCarouselData) => d.avatar)

  useEffect(() => {
    const isEnoughItemsToScroll = adjustedItemWidth * data.length > window.innerWidth
    setShowForwardArrow(isEnoughItemsToScroll)
  }, [data])

  const scrollForward = (scrollTo: React.MouseEvent<SVGSVGElement> | boolean) => {
    setShowBackArrow(true)
    const numberToShift = Math.floor(window.innerWidth / adjustedItemWidth)

    if (rowRef.current) {
      const left = rowRef.current.scrollLeft + adjustedItemWidth * (numberToShift - 2)
      const scrolledOffset = scrollTo
        ? left + window.innerWidth
        : rowRef.current.scrollLeft + window.innerWidth

      if (scrolledOffset >= rowRef.current.scrollWidth) {
        setShowForwardArrow(false)
      }

      if (scrollTo) {
        rowRef.current.scroll({
          top: 0,
          left,
          behavior: "smooth",
        })
      }
    }
  }

  const scrollBackward = (scrollTo: React.MouseEvent<SVGSVGElement> | boolean) => {
    setShowForwardArrow(true)
    const numberToShift = Math.floor(window.innerWidth / adjustedItemWidth)

    if (rowRef.current) {
      const left = rowRef.current.scrollLeft - adjustedItemWidth * (numberToShift - 2)
      const scrolledOffset = scrollTo ? left : rowRef.current.scrollLeft

      if (scrolledOffset <= 0) {
        setShowBackArrow(false)
      }

      if (scrollTo) {
        rowRef.current.scroll({
          top: 0,
          left,
          behavior: "smooth",
        })
      }
    }
  }

  const onScroll = (e: any) => {
    if (lastScrollOffset.current && e.target.scrollLeft > lastScrollOffset.current) {
      scrollForward(false)
    } else {
      scrollBackward(false)
    }

    lastScrollOffset.current = e.target.scrollLeft
  }

  if (!renderedData.length && !loading) return null

  return (
    <CarouselSection>
      <ContentSection>
        <Skeleton display={loading}>
          <CarouselTitleContainer>
            <Typography data-testid="clientCarouselTitle" variant="h2" gutterBottom="sm">
              {dataType}
            </Typography>
            <ArrowContainer>
              {showBackArrow && !shouldHideArrows ? (
                <Arrow>
                  <ArrowLeftIcon onClick={scrollBackward} />
                </Arrow>
              ) : (
                <EmptyArrowContainer />
              )}
              {showForwardArrow && !shouldHideArrows ? (
                <Arrow>
                  <ArrowRightIcon onClick={scrollForward} />
                </Arrow>
              ) : (
                <EmptyArrowContainer />
              )}
            </ArrowContainer>
          </CarouselTitleContainer>
        </Skeleton>
        <ClientCarouselRowContainer>
          <ClientCarouselRow onScroll={onScroll} ref={rowRef}>
            {!loading
              ? renderedData.map((d: ClientCarouselData) => {
                  return (
                    <ClientCarouselItem
                      name={d.name}
                      avatar={d.avatar}
                      key={d.id}
                      dataType={dataType}
                      dataTypeId={d.id}
                    />
                  )
                })
              : [1, 2, 3, 4].map((i) => {
                  return <ClientCarouselItem loading key={i} />
                })}
          </ClientCarouselRow>
        </ClientCarouselRowContainer>
      </ContentSection>
    </CarouselSection>
  )
}

export default ClientCarousel
