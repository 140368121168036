import styled from "styled-components/macro"
import { rgba } from "polished"

const FieldWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

const LabelContent = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 22px;
  color: #000;
`
export const Underline = styled.div`
  background-color: #000;
  bottom: 0px;
  height: 2px;
  left: 0px;
  margin: 0;
  position: absolute;
  right: 0px;
  transition: background-color 0.24s cubic-bezier(0.4, 0, 0.3, 1);
`

const InputElement = styled.input`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  -webkit-box-direction: normal;
  -webkit-writing-mode: horizontal-tb !important;
  color: ${({ error }) => (error ? "#c5162e" : "#6f7287")};
  appearance: textfield;
  background: ${({ error }) => (error ? "#c5162e" : "#ffffff")};
  border: 1px solid #cbcbcb;
  border-radius: 44px;
  color: ${({ theme, disabled }) => (disabled ? "#6f7287" : "#000000")};
  transition: padding 0.16s cubic-bezier(0.4, 0, 0.3, 1), color 0.4s cubic-bezier(0.4, 0, 0.3, 1);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 40px;
  letter-spacing: 0.2px;
  max-width: 336px;
  width: 100%;
  padding: 24px 16px;

  ::placeholder {
    color: #ccc;

  &:active,
  &:focus {
    outline: none;
    & + ${Underline} {
      background-color: ${({ theme }) => theme.colors.confirmation};
    }
  }
`

const FieldError = styled.div`
  padding-top: 4px;
`
const FieldErrorMessage = styled.aside`
  color: #c5162e;
  font-size: 0.75rem;
  line-height: 1rem;
`

export { FieldWrapper, LabelContent, InputElement, FieldErrorMessage, FieldError }
