import styled from "styled-components/macro"
import { Timelapse } from "@styled-icons/material-rounded"

export const Container = styled.div`
  text-align: center;
  font-size: 0.9rem;
  line-height: 1rem;
`
export const Icon = styled(Timelapse)`
  color: #565b60;
  width: 1rem;
  height: 1rem;
  margin-right: 0.2rem;
`
