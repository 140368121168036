import React from "react"
import { ConsentManagerBuilder } from "@segment/consent-manager"
import { Container, ConsentOptions, StyledButton, CookiesButton } from "./styled"
import { isDevDomain } from "services/utils"
import { isEmpty } from "lodash"

const CookieConsent = () => {
  var writeKey = isDevDomain
    ? "fPdnB0EFThl8aJCzQEAyvA4mplS2nIEv"
    : "O11xdCBeG3vndMQb9FJmemX3gwO72iRS"
  return (
    <ConsentManagerBuilder writeKey={writeKey}>
      {({ saveConsent, preferences }) => (
        <>
          <Container hide={!isEmpty(preferences)}>
            <span>
              We use cookies (and other similar technologies) to collect data to improve your
              experience on our site. By using our website, you’re agreeing to the collection of
              data as described in our{" "}
              <a href="https://partners.mandolin.com/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              .
            </span>
            <ConsentOptions>
              <StyledButton type="button" onClick={() => saveConsent(true, false)}>
                Accept All
              </StyledButton>

              <StyledButton type="button" onClick={() => saveConsent(false, true)}>
                Deny All
              </StyledButton>
              <StyledButton
                type="button"
                onClick={() => (window as any).consentManager.openConsentManager()}
              >
                Let Me Choose
              </StyledButton>
            </ConsentOptions>
          </Container>
        </>
      )}
    </ConsentManagerBuilder>
  )
}

export default CookieConsent
