import styled from "styled-components"
import { atMediaMin, toRem } from "theme/utils"

const HomeContainer = styled.div`
  padding-bottom: 20px;
  ${atMediaMin("md")} {
    padding-bottom: 40px;
  }
  ${atMediaMin("lg")} {
    padding-bottom: 60px;
  }
  ${atMediaMin("lg")} {
    padding-bottom: 80px;
  }
`

const EventContainer = styled.div`
  width: 65vw;

  ${atMediaMin("md")} {
    width: ${toRem(310)};
  }
`
const EventSkeletonContainer = styled.div`
  width: 65vw;
  height: 65vw;
  margin-right: 32px !important;

  ${atMediaMin("md")} {
    width: ${toRem(310)};
    height: ${toRem(310)};
  }
`
const SectionSpacer = styled.div`
  margin: ${toRem(20)} 0;
  ${atMediaMin("md")} {
    margin: ${toRem(40)} 0;
  }
  ${atMediaMin("lg")} {
    margin: ${toRem(60)} 0;
  }

  ${atMediaMin("lg")} {
    margin: ${toRem(80)} 0;
  }
`
const StickyNavOffsetContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`

export {
  EventContainer,
  EventSkeletonContainer,
  SectionSpacer,
  HomeContainer,
  StickyNavOffsetContainer,
}
