import React from "react"
import Container from "./AnimatedCardContainer.styled"

interface AnimatedCardContainerProps {
  children: JSX.Element | JSX.Element[]
  to?: any
  hideVertAnimation?: boolean
  loading?: boolean
  target?: string
  onClick?: () => void
}

const AnimatedCardContainer: React.FC<AnimatedCardContainerProps> = ({
  children,
  to,
  hideVertAnimation,
  loading,
  target,
  onClick,
}) => {
  return hideVertAnimation || !to || loading ? (
    <>{children}</>
  ) : (
    <Container to={to} target={target} onClick={onClick}>
      {children}
    </Container>
  )
}

export default AnimatedCardContainer
