import styled from "styled-components/macro";
import { Modal } from "react-bootstrap";

const ModalContainer = styled(Modal)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  .modal-dialog {
    width: 500px;
  }
  .modal-content {
    border: none;
    padding: 50px;
    border-radius: 0;
  }
  .modal-body {
    padding: 0px;
    color: #000000;
  }
  .image-top {
    background: #b9b9b9;
    padding: 30px;

    img {
      width: 100%;
    }
  }
  .greetings {
    color: #b9b9b9;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 16px;
  }
  .title {
    font-size: 41px;
    font-weight: bold;
    letter-spacing: 2.1px;
    line-height: 41px;
    @media (max-width: 767px) {
      font-size: 30px;
    }
  }
  .desc {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  @media (max-width: 767px) {
    padding: 15px;

    .modal-content {
      padding: 20px;
    }
  }
`;

export { ModalContainer };
