import { createContext } from "react"

export const AuthModalContext = createContext({})

export const initialState = {
  form: {
    email: "",
    emailIsVerified: false,
    locale: null,
    marketingOptIn: false,
    name: "",
    password: "",
    profilePic: undefined,
    zipCode: "",
    wolftrapID: "",
  },
  view: { state: "SignUpOrLogin", direction: "in" },
  promptPasswordLogin: false,
  error: false,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case "setPromptPasswordLogin":
      return {
        ...state,
        promptPasswordLogin: true,
      }
    case "setFormValue":
      return {
        ...state,
        form: { ...state.form, ...action.payload },
        ...(Object.keys(action.payload).includes("email") && { promptPasswordLogin: false }),
        ...(Object.keys(action.payload).includes(state.error.field) && { error: false }),
      }
    case "setError":
      return {
        ...state,
        error: action.payload,
      }
    case "toSignupWithEmail":
      return {
        ...state,
        view: { state: "SignupWithEmail", direction: "in" },
      }
    case "toCreateProfile":
      return {
        ...state,
        form: { ...state.form, ...action.payload },
        view: { state: "CreateProfile", direction: "in" },
      }
    case "toWolftrapID":
      return {
        ...state,
        view: { state: "WolftrapID", direction: "in" },
      }
    case "backToSignUpOrLogin":
      return {
        ...state,
        form: { ...state.form, password: "" },
        view: { state: "SignUpOrLogin", direction: "out" },
      }
    case "noAccount":
      return {
        ...state,
        form: { ...state.form, email: "", password: "" },
        view: { state: "SignUpOrLogin", direction: "out" },
      }
    default:
      throw new Error()
  }
}
