import { Styles } from "react-modal"
import styled from "styled-components"
import { mandolinTheme } from "theme"

interface FullscreenToggleProps {
  fullscreen?: boolean
}

export const ModalContent = styled.div<FullscreenToggleProps>`
  background-color: ${({ theme }) => theme.colors.elevation7};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    margin: ${({ fullscreen }) => (fullscreen ? "0" : "3rem")};
  }
`

export const CloseButton = styled.button`
  color: black;
  position: absolute;
  right: 1.3rem;
  top: 1.3rem;
  border: 0;
  background: transparent;
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
`
export const modalStyles: Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 400,
  },
  content: {
    padding: 0,
    inset: 0,
    width: "100%",
    height: "100%",
    background: "transparent",
  },
}

export const AssetWrapper = styled.div<FullscreenToggleProps>`
  position: ${({ fullscreen }) => (fullscreen ? "absolute" : "relative")};
  width: ${({ fullscreen }) => (fullscreen ? "100%" : "unset")};
  max-width: ${({ fullscreen }) => (fullscreen ? "unset%" : "800px")};
  min-height: ${({ fullscreen }) => (fullscreen ? "100vh" : "unset")};
  margin: ${({ fullscreen }) => (fullscreen ? "0" : "5rem 0")};
  background: ${({ fullscreen, theme }) => (fullscreen ? theme.colors.elevation1 : "unset")};
  padding: ${({ fullscreen }) => (fullscreen ? "6rem 2.5rem" : "unset")};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    padding: ${({ fullscreen }) => (fullscreen ? "6rem 4rem" : "unset")};
  }

  img {
    box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.32);
    width: 100%;
    max-width: ${({ fullscreen }) => (fullscreen ? "100%" : "800px")};
    height: 100%;
    max-height: ${({ fullscreen }) => (fullscreen ? "100%" : "536px")};
    object-fit: cover;
    border-radius: 4px 4px 0 0;
  }

  video {
    &:focus {
      outline: black;
    }
    border-radius: 4px 4px 0 0;
    box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.32);
  }
`

export const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.5rem;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.elevation8};

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    flex-direction: row;
    padding: 4rem;
  }
`

export const Data = styled.div`
  ${mandolinTheme.fonts.TTNormsProNormal};
  color: ${mandolinTheme.colors.elevation1};
  width: 100%;
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.125rem;
    margin: 1rem 0;
  }
  strong {
    ${mandolinTheme.fonts.TTNormsProBold};
  }

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    width: 45%;
  }
`

export const Attributes = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${mandolinTheme.fonts.TTNormsProNormal};
  color: ${mandolinTheme.colors.elevation1};

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    width: 45%;
  }
`
export const Attribute = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;

  ${mandolinTheme.fonts.TTNormsProNormal};
  color: ${mandolinTheme.colors.elevation4};
  font-size: 1.125rem;

  padding: 0.5rem;

  border: 1px solid ${mandolinTheme.colors.elevation5};
  border-radius: 4px;

  code {
    ${mandolinTheme.fonts.TTNormsProNormal};
    text-transform: uppercase;
    color: ${mandolinTheme.colors.elevation5};
    font-size: 0.75rem;
  }

  @media only screen and (min-width: ${mandolinTheme.breakpoints.md}px) {
    flex-direction: row;
    align-items: center;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const RoundedButton = styled.button<FullscreenToggleProps>`
  position: ${({ fullscreen }) => (fullscreen ? "absolute" : "unset")};
  right: 1.5rem;
  top: 1.5rem;
  background-color: ${({ theme, fullscreen }) =>
    fullscreen ? "rgba(255, 255, 255, 0.2)" : theme.colors.elevation8};
  color: ${({ theme, fullscreen }) =>
    fullscreen ? theme.colors.elevation7 : theme.colors.elevation5};
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
