import React, { createContext, useState } from "react"

interface WalletStoreProps {
  children: React.Component
}

export interface WalletContextProps {
  address: string
  setAddress: React.Dispatch<React.SetStateAction<string>>
  balance: number
  setBalance: React.Dispatch<React.SetStateAction<number>>
}

export const WalletContext = createContext<WalletContextProps | undefined>(undefined)

export default function WallerProvider({ children }: WalletStoreProps) {
  const [address, setAddress] = useState<string>("")
  const [balance, setBalance] = useState<number>(0)

  const store = { address, setAddress, balance, setBalance }

  return <WalletContext.Provider value={store}>{children}</WalletContext.Provider>
}
