import styled, { css } from "styled-components"
import { atMediaMin } from "theme/utils"

export const CountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
`

export const Count = styled.div`
  flex: 1;
  margin: 16px 0;
  ${atMediaMin("md")} {
    flex: 0;
    padding-right: 20px;
    text-align: left;
  }
  ${atMediaMin("xl")} {
    flex: 0;
    padding-right: 40px;
  }
  span {
    font-size: 32px !important;
    ${atMediaMin("md")} {
      text-align: left;
      font-size: 38px !important;
    }
    ${atMediaMin("xl")} {
      font-size: 60px !important;
    }
  }
  div {
    margin-top: 4px;
    opacity: 0.5;
  }
`
