import Reactions from "components/Watch/Reactions"
import { Reaction } from "components/Watch/Reactions/styled"
import useQuery from "hooks/useQuery"
import useWindowDimensions from "hooks/useWindowDimensions"
import useZendeskHide from "hooks/useZendeskHide"
import React from "react"
import styled, { css, keyframes } from "styled-components"

const flash = keyframes`
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity:0.75;
  }

  60% {
    opacity:1;
  }

  70% {
    opacity:0.75;
  }

  /* 80% {
    opacity:1;
  } */

  100% {
    opacity: 0;
  }
`

const slideDown = keyframes`
0% {
  transform: translate(300%,-300%);
}
100% {
  transform: translate(0%,0%);
}
`

const Container = styled.div<{
  width: number
  height: number
}>`
  background: linear-gradient(#353535, #1c1c1c);
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  overflow: hidden;
  position: relative;
  ${Reaction} {
    animation-name: ${slideDown};
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    > .reactionY {
      animation-name: ${flash} !important;
      animation-duration: 1s !important;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      font-size: 30px;
      width: 30px;
      height: 30px;
      animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1) !important;
    }
  }
`
const SmokeyBoi = styled.video`
  position: absolute;
  top: 0%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -5%);
`
const logoBase = css`
  position: absolute;
  left: 50%;
  width: 80%;
  transform: translate(-50%);
`
const LogoBottom = styled.img`
  bottom: 2%;
  ${logoBase}
`
const LogoTop = styled.img`
  top: 2%;
  ${logoBase}
`
const BoundingContainer = styled.div`
  width: 160px;
  height: 480px;
  position: relative;
  overflow: hidden;
  & > div {
    width: 100%;
    height: 100%;
    bottom: 0;
    overflow: hidden;
  }
`

const Uproar = () => {
  const { width, height } = useWindowDimensions()
  const params = useQuery()
  useZendeskHide()

  return (
    <Container width={width} height={height}>
      <BoundingContainer>
        {Array.from(new Set(params.get("e")?.split(","))).map((id) => (
          <Reactions
            key={id}
            showId={id}
            customReactions={[
              {
                src: "⚡",
                type: "img",
              },
            ]}
            hideControls
            customOffsetX={[0, 160]}
            customOffsetY={[0, 480]}
            invertOffsetY
            animationDelay={[0, 3]}
            alwaysFocused
            localReactions={1000}
          />
        ))}
        <SmokeyBoi
          loop
          muted
          autoPlay
          src="https://firebasestorage.googleapis.com/v0/b/mandolin-75659.appspot.com/o/vibes%2F1052889161-4k_1_2.mp4?alt=media&token=cc9704f5-aa6d-4261-8805-4e184183408f"
        />
        <LogoBottom src="https://firebasestorage.googleapis.com/v0/b/mandolin-75659.appspot.com/o/vibes%2FSmokeUpLockUp.png?alt=media&token=b856896b-aafd-4ed6-a870-edf2c9fa06a2" />
        <LogoTop src="https://firebasestorage.googleapis.com/v0/b/mandolin-75659.appspot.com/o/vibes%2FSmokeUpLockUp.png?alt=media&token=b856896b-aafd-4ed6-a870-edf2c9fa06a2" />
      </BoundingContainer>
    </Container>
  )
}

export default Uproar
