import styled from "styled-components/macro"

export const TabContainer = styled.div`
  width: 300px;
  height: 55px;
  line-height: 55px;
  background-color: #0d1115;
  padding: 0px 20px;
  font-size: 12px;
`
export const TabButton = styled.span`
  transition: background-color 0.5s ease;
  transition: color 0.5s ease;
  background-color: transparent;
  color: #6a6a6a;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 10px 5px 12px;
  border-radius: 12px;
  cursor: pointer;
  letter-spacing: 2px;

  &:hover {
    color: white;
  }

  &.active {
    background-color: #303537;
    color: white;
    cursor: unset;
  }
`
export const TabContent = styled.div`
  height: 100%;
  background-color: #1b1f24;
  color: white;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
`
export const FanContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px 30px 50px 15px;
  overflow-y: scroll;
`
