import styled from "styled-components/macro"
import { Tabs, Tab } from "react-bootstrap"
import { rgba, readableColor } from "polished"
import { atMediaMin } from "../../theme"

const StyledContainer = styled.div`
  padding: 20px;
  max-width: 1440px;
  & > h1 {
    font-size: 34px;
    letter-spacing: -0.3px;
    ${({ theme }) => theme.fonts.TTNormsProExtraBold};
    flex: 0 0 100%;
  }
  @media (min-width: 1024px) {
    padding: 40px;
  }
  @media (min-width: 1440px) {
    padding: 60px;
  }
`

const StyledTabs = styled(Tabs)`
  border: none;
  margin-bottom: 24px;
  .nav-item {
    background-color: transparent !important;
    border: none !important;
    color: ${({ theme }) => rgba(theme.elements.pageText, 0.5)};
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: 3px solid transparent !important;
    letter-spacing: 1px;
    padding: 16px 0 0 0 !important;
    margin-right: 32px;
    &:hover,
    &:focus {
      border-bottom-color: ${({ theme }) => rgba(theme.elements.pageText, 0.5)};
    }
    &.active {
      color: ${({ theme }) => theme.elements.pageText} !important;
      border-bottom-color: ${({ theme }) => theme.elements.pageText} !important;
    }
    @media (min-width: 768px) {
      margin-bottom: 36px;
      font-size: 16px;
    }
  }
`

const StyledTab = styled(Tab)``

const StyledShowCard = styled.div`
  width: 100%;
  margin-bottom: ${({ isPlaceholder }) => (isPlaceholder ? "0px" : "20px")};
  ${({ isPlaceholder }) =>
    isPlaceholder
      ? ``
      : `
display:grid;
grid-template-columns: 40% 60%;
grid-auto-flow: row;
padding: 5px;
align-items:center;
`};
  @media (min-width: 768px) {
    margin-bottom: ${({ isPlaceholder }) => (isPlaceholder ? "0px" : "10px")};
    padding: 5px;
    grid-column-gap: 16px;
    grid-template-columns: 0fr 2fr 2fr 2fr 1.5fr 1.5fr;
    grid-auto-flow: row;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1.5fr 2fr 2fr 2fr 2fr 2fr;
  }
  @media (min-width: 1440px) {
    grid-column-gap: 24px;
  }
`

const StyledTableHeader = styled.div`
  display: none;
  width: 100%;
  @media (min-width: 768px) {
    display: grid;
    padding: 5px;
    grid-column-gap: 16px;
    grid-template-columns: 0fr 2fr 2fr 2fr 1.5fr 1.5fr;
    grid-auto-flow: row;
    margin-bottom: 8px;
    div {
      font-size: 14px !important;
      opacity: 0.5;
      text-transform: uppercase;
    }
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1.5fr 2fr 2fr 2fr 2fr 2fr;
  }
  @media (min-width: 1440px) {
    grid-column-gap: 24px;
  }
`

const StyledImage = styled.div`
  border-radius: 16px;
  width: 100%;
  height: 50vw;
  background-image: url("${({ background }) => background}");
  background-position: center;
  background-size: cover;
  grid-column: span 2;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    width: 100%;
    height: 110px;
    grid-column: 1;
    margin-bottom: 0px;
  }
`

const StyledButtons = styled.div`
  justify-content: space-between;
  grid-column: span 2;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: 768px) {
    grid-column: 6;
    margin: 0px;
  }
  @media (min-width: 1024px) {
    flex-wrap: none;
  }
`

const StyledButtonContainer = styled.div`
  flex: 0 0 100%;
  margin-top: 16px;
  ${atMediaMin("md")} {
    display: flex;
    justify-content: flex-start;
  }
  a {
    margin: 8px auto 0;
    ${atMediaMin("md")} {
      margin: 0;
    }
  }
  svg {
    width: 24px;
    margin-right: 16px;
  }
`

const StyledBase = styled.div`
  margin: 5px 0;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  grid-column: 2;
  @media (min-width: 1024px) {
    margin: 0px;
  }
`
const StyledTitle = styled(StyledBase)`
  text-transform: none;
  @media (min-width: 768px) {
    grid-column: 2;
    font-size: 16px;
    font-weight: 500;
  }
`
const StyledVenue = styled(StyledBase)`
  @media (min-width: 768px) {
    grid-column: 3;
  }
`
const StyledDate = styled(StyledBase)`
  @media (min-width: 768px) {
    grid-column: 4;
  }
`

const StyledPublished = styled(StyledBase)`
  @media (min-width: 768px) {
    grid-column: 5;
  }
`
const StyledLabel = styled(StyledBase)`
  opacity: 0.5;
  grid-column: 1;
  @media (min-width: 768px) {
    display: none;
  }
`

const StyledA = styled.a`
  color: ${({ theme }) => theme.elements.buttonText} !important;
  fill: ${({ theme }) => theme.elements.buttonText} !important;
  background-color: ${({ theme }) => theme.elements.buttonBackground};
  align-self: flex-start;
  justify-self: flex-start;
  padding: 8px 16px 8px 8px;
  border-radius: 100px;
  text-decoration: none !important;
  &:hover {
    opacity: 0.85;
  }
  svg {
    width: 24px;
    margin-right: 16px;
  }
`

export {
  StyledContainer,
  StyledShowCard,
  StyledImage,
  StyledTabs,
  StyledTab,
  StyledTitle,
  StyledBase,
  StyledVenue,
  StyledDate,
  StyledPublished,
  StyledLabel,
  StyledTableHeader,
  StyledButtons,
  StyledA,
  StyledButtonContainer,
}
