import AnimatedCardContainer from "components/AnimatedCardContainer"
import Skeleton from "components/Skeleton"
import React from "react"
import { ClientType } from "./ClientCarousel"
import {
  ClientCarouselImageContainer,
  ClientCarouselItemContainer,
  ClientCarouselItemImage,
  ClientCarouselShowName,
} from "./ClientCarousel.styled"
import { imageNanoServiceAccessor } from "services/utils"
import { ImageAssetSizeProps, ImageAssetSizeType, ImageAssetSizeValue } from "types/ImageAssets"

export interface ClientCarouselData {
  id?: string
  name?: string
  avatar?: string
  loading?: boolean
  dataType?: ClientType
  dataTypeId?: string
  cobrand?: string
  trackClickEvent?: () => void
}

const ClientCarouselItem: React.FC<ClientCarouselData> = ({
  name,
  avatar,
  loading,
  dataType,
  dataTypeId,
  cobrand,
  trackClickEvent = () => null,
}) => {
  const target = cobrand ? "_blank" : undefined
  const defaultAvatar = `${process.env.PUBLIC_URL}/images/avatar-default.svg`

  // Explicitly checking dataType here in case UI changes
  const getNavigationUrl = () => {
    const cobrandURL = `https://${cobrand}.mandolin.com/`
    switch (dataType) {
      case ClientType.Artists:
        return cobrand ? { pathname: cobrandURL } : `artists/${dataTypeId}`
      case ClientType.Venues:
        return cobrand ? { pathname: cobrandURL } : `venues/${dataTypeId}`
      default:
        return ""
    }
  }

  const imageAssetSizeProps: ImageAssetSizeProps = {
    type: ImageAssetSizeType.ASSET_WIDTH,
    value: ImageAssetSizeValue.WIDTH_350,
  }

  return (
    <ClientCarouselItemContainer data-testid="clientCarouselItem">
      <Skeleton display={loading}>
        <AnimatedCardContainer
          to={getNavigationUrl()}
          loading={loading}
          target={target}
          onClick={trackClickEvent}
        >
          <ClientCarouselImageContainer>
            <ClientCarouselItemImage src={avatar? imageNanoServiceAccessor({ imageAssetUrl: avatar, imageAssetSizeProps }) : defaultAvatar} />
          </ClientCarouselImageContainer>
        </AnimatedCardContainer>
      </Skeleton>
      <Skeleton display={loading}>
        <ClientCarouselShowName variant="smallBold">{name}</ClientCarouselShowName>
      </Skeleton>
    </ClientCarouselItemContainer>
  )
}

export default ClientCarouselItem
