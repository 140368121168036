import React from "react"
import styled from "styled-components/macro"
import { rgba } from "polished"

const StyledPrivacyTerms = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: ${({ theme }) => rgba(theme.colors.elevation8, 0.75)};
  display: block;
  font-size: 13px;
  line-height: 17px;
  margin: 24px auto 0 auto;
  text-align: center;
  width: 256px;
  a {
    color: ${({ theme }) => rgba(theme.colors.primarySolid, 0.75)};
  }
`

const PrivacyTerms = () => (
  <StyledPrivacyTerms data-testid="signIn_consent_links">
    {`By continuing, I agree to the `}
    <a
      target="_blank"
      href="https://partners.mandolin.com/terms-of-service"
      rel="noopener noreferrer"
    >
      Terms of Service
    </a>
    {` and `}
    <a
      target="_blank"
      href="https://partners.mandolin.com/privacy-policy"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>
    .
  </StyledPrivacyTerms>
)

export default PrivacyTerms
