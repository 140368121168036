import firebase from "firebase/app"
import { firestore, storage, functions } from "../firebaseApp"
import { getSubdomain, optimizePhoto } from "./utils"
import { trackAccountCreationConversion } from "../analytics/google.ts"

const appleAuthProvider = new firebase.auth.OAuthProvider("apple.com")
appleAuthProvider.addScope("email")
appleAuthProvider.addScope("name")

const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
googleAuthProvider.addScope("profile email https://www.googleapis.com/auth/userinfo.profile")

const facebookAuthProvider = new firebase.auth.FacebookAuthProvider()
facebookAuthProvider.addScope("")

const emailAuthProvider = new firebase.auth.EmailAuthProvider()

export const AUTH_REQUIRES_RECENT_LOGIN = "auth/requires-recent-login"
export const AUTH_USER_MISMATCH = "auth/user-mismatch"
export const AUTH_EMAIL_LOGIN = "auth/invalid-oauth-provider"
export const AUTH_EMAIL_IN_USE = "auth/email-already-in-use"

export const firebaseErrorMessages = {
  "auth/email-already-in-use": "This email address is already in use",
  "auth/weak-password": "Please enter a stronger password",
  "auth/invalid-email": "Please enter a valid email address",
  "auth/auth-domain-config-required": "This domain has not configured auth",
  "auth/operation-not-supported-in-this-environment":
    "The operation is not supported in this environment",
  "auth/unauthorized-domain": "The operation is unauthorized in this domain",
  "auth/user-disabled": "This account has been disabled",
  "auth/user-not-found": "Invalid Credentials",
  "auth/wrong-password": "Invalid Credentials",
}

export const authWithApple = async () => {
  window.analytics.track("Logged in", { provider: "Apple", authDomainOrigin: getSubdomain() })
  try {
    return await firebase.auth().signInWithRedirect(appleAuthProvider)
  } catch ({ code }) {
    throw new Error(firebaseErrorMessages[code] || `Error: ${code}`)
  }
}

export const authWithGoogle = async () => {
  window.analytics.track("Logged in", { provider: "Google", authDomainOrigin: getSubdomain() })
  try {
    return await firebase.auth().signInWithRedirect(googleAuthProvider)
  } catch ({ code }) {
    throw new Error(firebaseErrorMessages[code] || `Error: ${code}`)
  }
}

const getPhotoUrl = async (file, uid) => {
  const optimzedPhoto = await optimizePhoto(file)
  const avatarRef = storage.ref().child(`userPhotos/${uid}/${file.name || "avatar"}`)
  const uploadTaskSnapshot = await avatarRef.put(optimzedPhoto)
  const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()
  return downloadURL
}

export const authWithFacebook = async () => {
  window.analytics.track("Logged in", { provider: "Facebook", authDomainOrigin: getSubdomain() })
  try {
    return await firebase.auth().signInWithRedirect(facebookAuthProvider)
  } catch ({ code }) {
    throw new Error(firebaseErrorMessages[code] || `Error: ${code}`)
  }
}

export const authEmailExists = async (email) => {
  try {
    const response = await firebase.auth().fetchSignInMethodsForEmail(email.toLowerCase())
    if (response[0] === "facebook.com") return await authWithFacebook()
    if (response[0] === "google.com") return await authWithGoogle()
    return Boolean(response[0] === "password")
  } catch ({ code }) {
    throw new Error(firebaseErrorMessages[code] || `Error: ${code}`)
  }
}

export const getAuthProvider = (providerId) => {
  switch (providerId) {
    case "google.com":
      return googleAuthProvider
    case "facebook.com":
      return facebookAuthProvider
    case "password":
      return emailAuthProvider
    default:
      return null
  }
}

export const reauthWithPopup = async () => {
  return new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser
    const provider = user.providerData.find((prov) => prov.email === user.email)
    const authProvider = getAuthProvider(provider.providerId)

    user
      .reauthenticateWithPopup(authProvider)
      .then(() => {
        resolve()
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}

export const reauthWithCredentials = async (email, password) => {
  return new Promise((resolve, reject) => {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password)

    firebase
      .auth()
      .currentUser.reauthenticateWithCredential(credential)
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getRedirectAuthData = async ({ additionalUserInfo, credential }) => {
  if (!credential || !additionalUserInfo?.isNewUser) return false
  // trackAccountCreationConversion()
  const { profile, providerId } = additionalUserInfo
  const {
    verified_email: emailIsVerified = null,
    picture = null,
    email = null,
    locale = null,
    name = null,
  } = profile
  const isFacebook = providerId === "facebook.com" && !picture?.data?.is_silhouette
  const isGoogle = providerId === "google.com"
  const photoSrc = isGoogle ? picture : isFacebook ? picture?.data?.url : undefined
  const profilePic = photoSrc ? await fetch(photoSrc).then((res) => res.blob()) : ""
  return { email, emailIsVerified, locale, name, profilePic }
}

export const signUpWithEmail = async (email, password) => {
  // analytics.logEvent("user_signup", { provider: "Email" })
  // trackAccountCreationConversion()
  try {
    return await firebase.auth().createUserWithEmailAndPassword(email.toLowerCase(), password)
  } catch ({ code }) {
    throw new Error(firebaseErrorMessages[code] || `Error: ${code}`)
  }
}

export const signInWithEmail = async (email, password) => {
  window.analytics.track("Logged in", { provider: "password", authDomainOrigin: getSubdomain() })
  try {
    return await firebase.auth().signInWithEmailAndPassword(email.toLowerCase(), password)
  } catch ({ code }) {
    throw new Error(firebaseErrorMessages[code] || `Error: ${code}`)
  }
}

export const sendPasswordResetEmail = async (email) => {
  const callable = functions.httpsCallable("sendPasswordReset")
  const res = await callable({
    email,
    callbackUrl: window.location.origin,
  })
  return res
}

export const sendNoonChorusPasswordResetEmail = async (email) => {
  const callable = functions.httpsCallable("sendPasswordReset")
  const res = await callable({
    email,
    noonChorus: true,
    callbackUrl: window.location.origin,
  })
  return res
}

export const getUserProfile = async (uid) => {
  return firestore.collection("userProfiles").doc(uid).get()
}

export const updateProfile = async ({
  uid,
  profilePic,
  name,
  zipCode,
  marketingOptIn,
  dateOfBirth,
  firstName,
  lastName,
  gaVoter,
  phoneNumber,
  authDomainOrigin,
  wolftrapID,
}) => {
  const photoUrl =
    profilePic && typeof profilePic !== "string" ? await getPhotoUrl(profilePic, uid) : profilePic
  const userRef = firestore.collection("users").doc(uid)
  await userRef.update({
    name,
    profileUpdated: true,
    ...(photoUrl && { photoUrl }),
    ...(typeof zipCode !== "undefined" && { zipCode }),
    ...(typeof marketingOptIn !== "undefined" && { marketingOptIn }),
    ...(typeof dateOfBirth !== "undefined" && { dateOfBirth }),
    ...(typeof firstName !== "undefined" && { firstName }),
    ...(typeof lastName !== "undefined" && { lastName }),
    ...(typeof phoneNumber !== "undefined" && { phoneNumber }),
    ...(typeof authDomainOrigin !== "undefined" && { authDomainOrigin }),
    ...(typeof gaVoter !== "undefined" && { gaVoter }),
    ...(typeof wolftrapID !== "undefined" && { wolftrapID }),
  })
  const profileRef = firestore.collection("userProfiles").doc(uid)
  await profileRef.update({
    name,
    ...(photoUrl && { photoUrl }),
  })
}

export const updateUserReminders = async ({
  uid,
  showReminders,
  newShowAnnouncements,
  marketingOptIn,
}) => {
  const userRef = firestore.collection("users").doc(uid)

  await userRef.update({
    ...(typeof newShowAnnouncements !== "undefined" && { newShowAnnouncements }),
    ...(typeof marketingOptIn !== "undefined" && { marketingOptIn }),
    ...(typeof showReminders !== "undefined" && { showReminders: { emailNotifications: true } }),
  })
}

export const updateUserWallets = async (uid, wallets) => {
  const userRef = firestore.collection("users").doc(uid)
  const doc = await userRef.get()
  if (!doc.exists) return
  return await userRef.update({ wallets })
}

export const updateUserMagicWallets = async (uid, magicWallets) => {
  const userRef = firestore.collection("users").doc(uid)
  const doc = await userRef.get()
  if (!doc.exists) return
  return await userRef.update({ magicWallets })
}

export const updatePassword = (attributes) => {
  return new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser

    if (attributes.new !== attributes.confirm) {
      return reject({ code: "New passwords did not match." })
    }

    const credential = firebase.auth.EmailAuthProvider.credential(user.email, attributes.current)

    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        user
          .updatePassword(attributes.new)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteUser = async () => {
  try {
    await firebase.auth().currentUser.delete()
    localStorage.removeItem("userInfo")
  } catch (error) {
    console.log(error)
    throw error
  }
}
