import styled from "styled-components/macro"
import { ContentPaste, Smartphone } from "@styled-icons/material-rounded"

export const CopyIcon = styled(ContentPaste)`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`

export const PhoneIcon = styled(Smartphone)`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`

export const Container = styled.div`
  margin: 48px auto 0 auto;
  @media (max-width: 1111px) {
    margin: 8px auto 8px auto;
    button {
      height: 36px;
      width: 224px;
    }
  }
`

export const WatchPartyRoomKey = styled.input`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  background-image: ${({ theme }) => theme.colors.primaryGradient};
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  outline: none;
  border: none;

  &:active,
  &:focus {
    outline: none;
    border: none;
  }

  @media (max-width: 1111px) {
    margin: 0;
  }
`
