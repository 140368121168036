import styled from "styled-components"

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
`

export const ContentBorder = styled.div`
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.colors.elevation4};
  padding-top: 1.5rem;
  width: 100%;
  max-width: 688px;
`

export const Content = styled.div`
  padding: 3rem 2rem;
  background-color: ${({ theme }) => theme.colors.elevation3};
  border-radius: 1.5rem;
  font-size: 18px;
  h1 {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.elevation8};
  }
  hr {
    width: 4.5rem;
    border: ${({ theme }) => `1px solid ${theme.colors.elevation4};`};
  }
`

export const Mando = styled.img`
  z-index: 10;
  margin-bottom: -1rem;
`
