import ImageLoader from "components/ImageLoader"
import { invert, lighten, rgba, saturate, transparentize } from "polished"
import styled, { css } from "styled-components"
import { sectionContainer } from "theme/spacing"
import { atMediaMin, skeletonStyles, toRem } from "theme/utils"

const sectionSpacing = css`
  margin-top: ${toRem(40)};
  margin-bottom: ${toRem(40)};
  ${atMediaMin("md")} {
    margin-top: ${toRem(60)};
    margin-bottom: ${toRem(60)};
  }
`
const Container = styled.main`
  min-height: 75vh;
  padding-bottom: 40px;
`
const BlurContainer = styled.main<{ blur: boolean }>`
  min-height: 75vh;
  padding-bottom: 40px;
  filter: ${({ blur }) => blur && "blur(5px)"};
`

const BackButton = styled.button`
  margin: 0 var(--horizontal-margin);
  cursor: pointer;
  border-radius: ${toRem(16)};
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.elements.pageText};
  width: ${toRem(32)};
  height: ${toRem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(20)};
  border-radius: ${toRem(32)};
  transition: box-shadow 0.25s ${({ theme }) => theme.defaults.cubicBezier};
  will-change: box-shadow;
  position: absolute;
  left: 0;
  bottom: calc(100%);
  transform: scale(1) translateZ(0);
  :hover,
  :focus {
    box-shadow: 0 0 0 ${toRem(4)} ${({ theme }) => transparentize(0.55, theme.elements.pageText)};
    svg {
      transform: scale(1.2) translateZ(0);
    }
  }
  svg {
    width: ${toRem(20)};
    height: ${toRem(20)};
    color: ${({ theme }) => theme.elements.pageBackground};
    transform: scale(1) translateZ(0);
    transition: transform 0.25s ${({ theme }) => theme.defaults.cubicBezier};
    will-change: transform;
    overflow: visible;
  }
`
const ErrorContainer = styled.section`
  ${sectionContainer}
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  a {
    color: ${({ theme }) => theme.elements.pageTextAccent};
  }
`
const HeaderImageContainer = styled.div`
  position: relative;
`
const HeaderImage = styled.div`
  position: relative;
  padding-bottom: 60%;
  overflow: hidden;
  background-color: ${({ theme }) => rgba(invert(theme.elements.pageBackground), 0.075)};
  border-radius: ${toRem(16)};
  margin-bottom: ${toRem(26)};
  ${atMediaMin("md")} {
    border-radius: ${toRem(32)};
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: ${toRem(16)};
    ${atMediaMin("md")} {
      border-radius: ${toRem(32)};
    }
  }
`

const HeaderContent = styled.div`
  .venueName {
    opacity: 0.6;
    margin: ${toRem(2)} 0 ${toRem(16)};
  }
  .buyTicketsCTA {
    display: inline-flex;
    padding: 0 ${toRem(16)};
    ${atMediaMin("md")} {
      padding: 0 ${toRem(24)};
    }
    svg {
      width: ${toRem(20)};
      margin-right: ${toRem(8)};
    }
  }
`
const HeaderInnerContainer = styled.div`
  position: relative;
  ${sectionContainer}
  ${sectionSpacing}
  ${atMediaMin("lg")} {
    display: flex;
    ${HeaderImageContainer} {
      align-self: flex-start;
      flex: 1.1;
      max-width: ${toRem(600)};
      ${HeaderImage} {
        margin: 0;
      }
    }
    ${HeaderContent} {
      padding-left: ${toRem(52)};
      align-self: center;
      flex: 1;
    }
  }
`
const HeaderContainer = styled.section<{
  eventLoading: boolean
  gradientColor?: string
}>`
  padding-top: ${toRem(30)};
  ${atMediaMin("md")} {
    padding-top: ${toRem(60)};
  }
  transition: background 0.25s ${({ theme }) => theme.defaults.cubicBezier};
  will-change: background;
  background: ${({ gradientColor, theme }) =>
    css`linear-gradient(0deg,${transparentize(
      1,
      theme.elements.pageBackground,
    )} 30%, ${transparentize(
      0.9,
      gradientColor || lighten(0.2, saturate(0.5, theme.elements.pageBackground)),
    )})`};

  ${({ eventLoading }) =>
    eventLoading
      ? css`
          ${HeaderContent} {
            h1,
            h4,
            > div {
              color: rgba(0, 0, 0, 0);
              ${skeletonStyles}
              border-radius: 4px;
              &:before {
                content: "---";
              }
            }
            h1 {
              width: 90%;
            }
            h4 {
              width: 60%;
            }
            > div {
              width: 50%;
            }
          }
        `
      : ``};
  &:before {
    content: "";
    display: block;
  }
`
const DescriptionContainer = styled.section`
  ${sectionContainer}
  ${sectionSpacing}
  span {
    display: block;
    flex: 1.1;
    max-width: ${toRem(600)};
  }
  ${atMediaMin("lg")} {
    display: flex;
    div {
      flex: 1;
    }
  }
`
const DescriptionSkeleton = styled.div`
  div {
    display: block;
    ${skeletonStyles}
    border-radius: 4px;
    color: rgba(0, 0, 0, 0);
    height: 1rem;
    margin-bottom: ${toRem(6.4)};
    &:nth-child(1) {
      width: 98%;
    }
    &:nth-child(2) {
      width: 95%;
    }
    &:last-of-type {
      width: 60%;
    }
  }
`
const ContentSection = styled.div`
  ${sectionSpacing}
`
const EventContainer = styled.div`
  width: 80vw;
  max-width: ${toRem(386)};
`
const CobrandLogo = styled(ImageLoader)`
  background-color: transparent;
  max-width: 280px;
  max-height: 100px;
  display: block;
  margin-bottom: 16px;
  border-radius: 0px;
`

const MultiButtonContainer = styled.div`
  flex-direction: row;
  display: flex;
  gap: 10px;
`

const RemindAlert = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-left: 10px;
`
const ViewScheduleCta = styled.button`
  background: ${({ theme }) => theme.elements.buttonBackground || theme.colors.primaryGradient};
  height: 50px;
  width: 160px;
  color: ${({ theme }) => theme.elements.starterButtonText || theme.elements.buttonText};
  border: none;
  border-radius: 25px;
  font-size: 12px;
  line-height: 24px;
  padding-left: 6px;
  padding-right: 6px;
`
const LogInText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: ${toRem(13)};
`

const HelperContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  align-items: baseline;
  
`
const HelperCta = styled.button`
  border: none;
  background: none;
  color: white;
`

export {
  BlurContainer,
  Container,
  ErrorContainer,
  HeaderContainer,
  HeaderInnerContainer,
  HeaderImage,
  HeaderContent,
  HeaderImageContainer,
  DescriptionContainer,
  DescriptionSkeleton,
  ContentSection,
  EventContainer,
  BackButton,
  CobrandLogo,
  MultiButtonContainer,
  RemindAlert,
  ViewScheduleCta,
  HelperContainer,
  HelperCta,
  LogInText,
}
