import {
  ArtistDTO,
  DefaultApi,
  ShowDetailDTO,
  ShowDTO,
  ShowIncludes,
  SortOrder,
  TicketStatus,
  VenueDTO,
} from "@mandolin-dev/ts-sdk"
import { useRestClient } from "hooks"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { RootState } from "redux/store"
import axios, { AxiosResponse } from "axios"
import Config from "config/config"
import dayjs from "dayjs"
import { firestore } from "firebaseApp"
import omit from "lodash/omit"

import { HomeShow } from ".."

export interface HomeShowSection {
  label: string
  type: Type
  dataType: DataType
  client?: VenueDTO
  data: HomeShow[] | ArtistDTO[] | VenueDTO[]
  bannerInfo?: FeaturedBannerDTO
  isFeatured?: boolean
}

export type FeaturedBannerDTO = {
  image: any
  title: string
  cobrand?: string
  eventId: string
  type: DataType
  webUrl: string
  description?: string
  startTime: string
  endTime?: string
  ctaBackgroundColor: string
  ctaTextColor: string
  backgroundColor: string
  textColor: string
}

export enum DataType {
  Events = "events",
  Venues = "venues",
  Artists = "artists",
  Banner = "banner",
  Strip = "strip",
}

export enum Type {
  Featured = "featured",
  Horizontal = "horizontal",
  Grid = "grid",
  Banner = "banner",
  Strip = "strip",
}

type CustomPageSection = {
  sections: {
    events: {
      label: string
      value: string
    }[]
    id: string
    index: number
    label: string
    hideDates?: boolean
  }[]
  title: string
}

const getFeaturedBanner = async () => {
  try {
    const response = await axios.get(`${Config.STRAPI.restURL}/featured-banners`)
    const featuredBanners = response.data
    const filteredBanners = featuredBanners.filter((banner: FeaturedBannerDTO) =>
      dayjs().isBefore(dayjs(banner.endTime)),
    )
    filteredBanners.sort((a: FeaturedBannerDTO, b: FeaturedBannerDTO) => {
      return new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    })
    return filteredBanners[0]
  } catch (error) {
    console.log(error)
    return null
  }
}

const getFeaturedStrip = async () => {
  try {
    const response = await axios.get(`${Config.STRAPI.restURL}/featured-strips`)
    const featuredBanners = response.data
    const filteredBanners = featuredBanners.filter((banner: FeaturedBannerDTO) =>
      dayjs().isBefore(dayjs(banner.endTime)),
    )
    filteredBanners.sort((a: FeaturedBannerDTO, b: FeaturedBannerDTO) => {
      return new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    })
    return filteredBanners[0]
  } catch (error) {
    console.log(error)
    return null
  }
}

const getCustomHomeSections = async (restClient: DefaultApi) => {
  const id = Config.PAGE_CONTENT.homepage
  try {
    const doc = await firestore.collection("pages").doc(id).get()
    if (doc.exists) {
      const data: CustomPageSection = doc.data() as CustomPageSection
      const sections: HomeShowSection[] = []
      const promises: Promise<AxiosResponse<ShowDetailDTO>>[] = []
      const showsToReturn: {
        [id: string]: any
      } = {}
      data.sections.forEach((section) => {
        section.events.forEach((evt) => {
          promises.push(restClient.getShow({ showId: evt.value }))
        })
      })
      const res = await Promise.all(promises)
      res.forEach((show) => {
        showsToReturn[show.data.id] = show.data
      })

      data.sections.forEach((section) => {
        const shows: HomeShow[] = section.events.map((ev) =>
          section?.hideDates
            ? omit(showsToReturn[ev.value], ["dateTime"])
            : showsToReturn[ev.value],
        )
        sections.push({
          label: section.label,
          type: Type.Horizontal,
          dataType: DataType.Events,
          data: shows,
        })
      })

      return sections
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

const useHomeContent = () => {
  const [state, setState] = useState<{
    sectionItems: HomeShowSection[]
    loading: boolean
  }>({
    loading: true,
    sectionItems: [],
  })
  const [queryStarted, setQueryStarted] = useState(false)
  const { tickets, auth } = useSelector((reduxState: RootState) => ({
    tickets: reduxState.tickets,
    auth: reduxState.auth,
  }))
  const { restClient } = useRestClient()

  const userHasTicket = (show: HomeShow) =>
    tickets.data.filter((t) => show.id === t.showId && t.status !== TicketStatus.Revoked).length > 0

  const getUpcomingShows = async () => {
    try {
      const isToday = (date: string) => {
        const formattedDate = new Date(date)
        const today = new Date()
        return (
          formattedDate.getDate() === today.getDate() &&
          formattedDate.getMonth() === today.getMonth() &&
          formattedDate.getFullYear() === today.getFullYear()
        )
      }
      const response = await restClient.listShows({
        limit: 200,
        sortOrder: SortOrder.Asc,
        include: [ShowIncludes.Venue],
        excludePast: true,
      })

      const data = response.data.data.map((show) => ({
        ...show,
        isTicketPurchased: userHasTicket(show),
        venue: response.data.included?.venue?.filter((v) => v.id === show.venueId)[0],
      }))

      const {
        data: { data: featuredArtists },
      } = await restClient.listArtists({
        limit: 50,
        isFeatured: true,
      })

      const {
        data: { data: featuredVenues },
      } = await restClient.listVenues({
        limit: 50,
        isFeatured: true,
      })
      const venuePromises: Promise<any>[] = []
      const venuesWithNoShows: string[] = []

      featuredVenues.forEach((venue) => {
        venuePromises.push(
          venue?.cobrand
            ? restClient.listShows({ limit: 100, excludePast: true, cobrand: venue.cobrand })
            : restClient.listShows({ limit: 100, excludePast: true, venueId: venue.id }),
        )
      })

      const venueEvents = await Promise.all(venuePromises)
      const combinedShowDataForFeaturedVenues: string[] = []
      venueEvents.forEach((v, i) => {
        if (!v.data.data.length) {
          venuesWithNoShows.push(featuredVenues[i].id)
        }
        v.data.data.forEach((s: ShowDTO) => combinedShowDataForFeaturedVenues.push(s.id))
      })

      let filteredData: ShowDTO[] = data.filter(
        (show) => !combinedShowDataForFeaturedVenues.includes(show.id),
      )

      // find VIP events
      const VIPShows = filteredData.filter((show) => show.external_event !== undefined)
      filteredData = filteredData.filter((show) => !show.external_event)

      // set featured shows
      const featured: ShowDTO[] = data.filter((show) => show.isFeatured)
      filteredData = filteredData.filter((show) => !show.isFeatured)

      // set shows tonight
      const showsTonight = filteredData.filter((show) => isToday(show.dateTime))
      if (showsTonight.length) {
        filteredData = filteredData.filter((show) => !isToday(show.dateTime))
      }

      // get shows this week
      const filterDate = new Date()
      filterDate.setDate(filterDate.getDate() + 6)

      const isInRange = (date: Date) => {
        return new Date(date) <= filterDate
      }
      const showsThisWeek = filteredData.filter((show) => {
        return isInRange(new Date(show.dateTime))
      })

      // list rest
      filteredData = filteredData.filter((show) => !isInRange(new Date(show.dateTime)))

      // Strapi data
      const featuredBanner = await getFeaturedBanner()
      const featuredStrip = await getFeaturedStrip()

      // Firestore Custom Home Section Experiment
      const customSections = await getCustomHomeSections(restClient)

      const dataToReturn: HomeShowSection[] = []
      if (featured.length) {
        dataToReturn.push({
          label: "Featured events",
          type: Type.Featured,
          dataType: DataType.Events,
          data: featured,
        })
      }
      if (featuredBanner) {
        dataToReturn.push({
          label: "banners",
          type: Type.Banner,
          dataType: DataType.Banner,
          data: [],
          bannerInfo: featuredBanner,
        })
      }
      if (featuredStrip) {
        dataToReturn.push({
          label: "strips",
          type: Type.Strip,
          dataType: DataType.Strip,
          data: [],
          bannerInfo: featuredStrip,
        })
      }
      if ([...showsTonight, ...showsThisWeek].length) {
        dataToReturn.push({
          label: "Happening soon",
          type: Type.Horizontal,
          dataType: DataType.Events,
          data: [...showsTonight, ...showsThisWeek],
          isFeatured: true,
        })
      }
      if (customSections?.length) {
        customSections.forEach((sec) => dataToReturn.push(sec))
      }
      if (featuredArtists.length) {
        dataToReturn.push({
          label: "Artists",
          type: Type.Horizontal,
          dataType: DataType.Artists,
          data: featuredArtists,
        })
      }
      if (VIPShows.length) {
        dataToReturn.push({
          label: "VIP events",
          type: Type.Horizontal,
          dataType: DataType.Events,
          data: VIPShows,
        })
      }
      // featuredVenues
      // venueEvents
      featuredVenues.forEach((venue, i) => {
        if (!venuesWithNoShows.includes(venue.id)) {
          dataToReturn.push({
            label: `Events from ${venue.name}`,
            type: Type.Horizontal,
            dataType: DataType.Events,
            data: venueEvents[i].data.data.map((e: HomeShow) => ({
              ...e,
              isTicketPurchased: userHasTicket(e),
              venue,
            })),
          })
        }
      })
      if (filteredData.length) {
        dataToReturn.push({
          label: "Upcoming Events",
          type: Type.Grid,
          dataType: DataType.Events,
          data,
        })
      }
      setState({ loading: false, sectionItems: dataToReturn })
    } catch (error) {
      throw new Error("Unable to fetch recent data")
    }
  }

  useEffect(() => {
    if (restClient && !tickets.loading && !auth.isPending && !queryStarted) {
      setQueryStarted(true)
      getUpcomingShows()
    }
  }, [restClient, tickets, auth.isPending, queryStarted])

  return state
}

export default useHomeContent
