import { rgba } from "polished"
import { NavLink } from "react-router-dom"
import styled, { css, keyframes } from "styled-components/macro"

export const UpcomingShowOuter = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

export const UpcomingHeader = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.01em;
  margin: 24px;

  @media (max-width: 1111px) {
    font-size: 16px;
    margin: 16px;
    line-height: 20px;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 260px;
  height: 100%;
  position: relative;

  @media (max-width: 1111px) {
    height: 200px;
    max-width: 1111px;
    min-width: 0px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-direction: row;
    padding-bottom: 0;
  }
`

export const StartingSoon = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 0 5px;
  color: white;
  border-radius: 4px;
  background-color: ${({ live }) => (live ? "#73D689" : "#6992FE")};
  font-size: 14px;
  height: 20px;
  line-height: 22px;
`

export const PastShow = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 0 5px;
  color: white;
  border-radius: 4px;
  background-color: gray;
  font-size: 14px;
  height: 20px;
  line-height: 22px;
`

export const ShowTime = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
`

export const ShowName = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 16px;
  color: #ffffff;
`

const skeletonAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`

const skeletonStyles = css`
  background: linear-gradient(
    90deg,
    ${rgba("#58586A", 0.2)},
    ${rgba("#58586A", 0.4)},
    ${rgba("#58586A", 0.2)}
  );
  background-size: 300%;
  animation: ${skeletonAnimation} 1.2s ease-in infinite;
`

export const SkeletonShow = styled.div`
  background: rgba(255, 255, 255, 0.15);
  width: 280px;
  height: 112px;
  border-radius: 4px;
  margin: 16px auto;
  padding: 16px;
  transition: all 0.3s;
  flex-shrink: 0;

  @media (max-width: 1111px) {
    margin: 16px;
  }

  ${skeletonStyles};
`

export const InnerShow = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.3s;

  &:hover {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1));
  }
`

export const UpcomingShow = styled(NavLink)`
  margin: 16px;
  width: 250px;
  height: 200px;
  border-radius: 4px;
  margin: 16px auto;
  transition: all 0.3s;
  flex-shrink: 0;
  background-image: url("${(props) => props.img}");
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  @media (max-width: 1111px) {
    width: 200px;
    margin: 10px;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    ${ShowName} {
      text-decoration: underline;
      color: #ffffff;
    }

    ${ShowTime} {
      text-decoration: none;
    }
  }
`

export const Back = styled(NavLink)`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 16px;
  color: white;
  line-height: 36px;
  background: #e05a5a;
  border-radius: 8px;
  width: 344px;
  height: 36px;
  position: fixed;
  bottom: 8px;
  right: 8px;
  text-decoration: none;
  text-align: center;

  @media (max-width: 1111px) {
    display: none;
  }

  &:hover {
    color: white;
    text-decoration: underline;
  }
`

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0;
  transition: 0.2s ease width;
  width: ${(props) => (props.showDesktopChat ? "360px" : "0px")};

  @media (max-width: 1111px) {
    width: 100%;
    position: absolute;
    top: calc(40vh + 40px);
    height: calc(60vh - 40px);
    display: ${(props) => (props.showMobileChat ? "block" : "none")};
  }
`

export const Box = styled.div`
  background: ${({ theme }) => theme.colors.elevation2};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`
