import styled from "styled-components/macro"

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  z-index: 98;
  position: fixed;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.6);
`

export const ModalContainer = styled.div`
  display: ${({ hideModal }) => (hideModal ? "none" : "block")};
  position: fixed;
  z-index: 99;
  margin: auto;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top: solid 16px #2b74df;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.6);

  > div {
    width: 100%;
  }

  @media (max-width: 1111px) {
    width: 100%;
  }

  #axsid_main_container {
    padding: 30px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    color: rgba(255, 255, 255, 0.6);

    .main-header {
      margin-top: 20px;
      color: white;

      span {
        display: none;
      }

      &:after {
        color: white;
        content: "Sign up/in with AXS Tickets to view your livestream.";
        font-size: 32px;
      }
    }

    input {
      border-radius: 2px;
    }

    button {
      border-radius: 4px;
      background: #0f3364;
    }

    label {
      margin-bottom: 0;
    }

    .axsid-nav-item {
      border-bottom: none;

      a {
        color: #2b74df;
        opacity: 0.6;
      }

      &::after {
        border-bottom: 5px solid #2b74df;
        opacity: 0.6;
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
      }
    }

    .axsid-nav-item--active {
      a {
        color: #2b74df;
        opacity: 1;
      }

      &::after {
        border-bottom: 5px solid #2b74df;
        opacity: 1;
      }
    }
  }
`

export default ModalContainer
