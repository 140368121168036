import React, { FC, useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import moment from "moment"
import { useRestClient, useQuery } from "hooks"
import {
  ClaimCodeButton,
  CloseButton,
  ErrorIcon,
  ModalBackdrop,
  ModalContainer,
  ModalTitle,
  ModalText,
  ModalSubText,
  InputContainer,
  ShowHeading,
  ShowTitle,
  ShowTime,
  TicketsButton,
} from "./styled"

const claimCodeSize = 20
const claimCodeAlphabet = "23456789ABCDEFGHJKLMNPQRSTUVWXYZ"
const claimCodeRegex = new RegExp(`^[${claimCodeAlphabet}]{${claimCodeSize}}\$`, "i")
const claimCodeURIRegex = new RegExp(
  `.*claim_code=(?<code>[${claimCodeAlphabet}]{${claimCodeSize}}).*`,
  "i",
)

const validateClaimCode = (code: string) => Boolean(code.match(claimCodeRegex))

const formatShowDate = (dateStr: string) => moment(dateStr).format("ddd, MMM D [AT] h:mm A")

interface ClaimCodeModalProps {
  buttonText?: string
  onTicketClaim?: () => void
  className?: string
}

const ClaimCodeModal: FC<ClaimCodeModalProps> = ({
  buttonText = "Enter a claim code",
  onTicketClaim,
  className,
}) => {
  const query = useQuery()
  const { restClient } = useRestClient()
  const [showModal, setShowModal] = useState(false)
  const [claimCode, setClaimCode] = useState("")
  const [error, setError] = useState(false)
  const [errorIcon, setErrorIcon] = useState(false)
  const [show, setShow] = useState<any>(null)

  useEffect(() => {
    // check for a claim code in the query string
    // if we have one show the modal with that code
    const code = query.get("claim_code")

    setClaimCode(code || "")

    if (code) {
      setShowModal(true)
    }
  }, [])

  useEffect(() => {
    setErrorIcon(false)

    if (claimCode) {
      // handle error case where user pastes a link containing
      // a claim code into the claim code box
      const uriMatch = claimCode.match(claimCodeURIRegex)

      if (uriMatch?.groups) {
        setClaimCode(uriMatch.groups.code)
      } else if (!claimCode.match(claimCodeRegex)) {
        setErrorIcon(true)
      }
    }
  }, [claimCode])

  const onCloseModal = () => {
    setError(false)
    setErrorIcon(false)
    setClaimCode("")
    setShowModal(false)
    setShow(null)
  }

  const submitClaimCode = async () => {
    if (!validateClaimCode(claimCode)) {
      setError(true)
      return
    }

    try {
      const { data } = await restClient.claimByCode({ claimByCodeRequestDTO: { claimCode } })

      if ("message" in data) {
        setError(true)
      } else {
        if (onTicketClaim) onTicketClaim()
        setShow(data)
        setError(false)
      }
    } catch (e) {
      setError(true)
    }
  }

  return (
    <>
      <ClaimCodeButton
        label={buttonText}
        type="button"
        variant="secondary"
        className={className}
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <ModalBackdrop className={className}>
          <ModalContainer>
            <CloseButton onClick={() => onCloseModal()} />
            {show && (
              <>
                <ShowHeading>You've claimed a ticket to</ShowHeading>
                <ShowTitle>{show.name}</ShowTitle>
                <ShowTime>{formatShowDate(show.dateTime)}</ShowTime>
                <TicketsButton to="/tickets" onClick={onCloseModal}>
                  go to your tickets
                </TicketsButton>
              </>
            )}
            {!show && (
              <>
                <ModalTitle>Claim a Ticket</ModalTitle>
                <ModalText>
                  {error
                    ? "The code isn’t valid. Please try again or use the Help button below to contact support."
                    : "To claim a ticket, enter the claim code below."}
                </ModalText>

                <InputContainer>
                  {errorIcon && <ErrorIcon className="claimError" size={16} />}
                  <input
                    placeholder="Enter your claim code here"
                    value={claimCode}
                    onChange={(e) => setClaimCode(e.target.value)}
                    type="text"
                  />
                </InputContainer>
                <button type="button" onClick={() => submitClaimCode()} className="claim-button">
                  claim this ticket
                </button>
                <ModalSubText>
                  Need help? Look for the Help button in the lower left corner.
                </ModalSubText>
              </>
            )}
          </ModalContainer>
        </ModalBackdrop>
      )}
    </>
  )
}

export default ClaimCodeModal
