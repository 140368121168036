import React, { useEffect, useReducer, createContext } from "react"
import { ESTtoLocalDate, ESTtoLocalTime, fetchGridData } from "./services"
export const ShowsContext = createContext({})

const initialState = {
  loading: true,
  genres: [],
  filters: [],
  shows: [],
  stages: [
    {
      title: "#iVoted HeadCount Stage",
      subtitle: "Special Performances, presented by Ben & Jerry's",
    },
    {
      title: "#iVoted Partners Stage",
      subtitle: "Presented by: Come Hear NC, Gritty in Pink, PETA & Gamers.Vote",
    },
    {
      title: "Desert Stage",
      subtitle: "Artists from or trending in AZ & NV, presented by Tenner & Associates, Inc.",
    },
  ],
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setShowData":
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case "setFilters":
      return {
        ...state,
        filters: action.payload,
      }
    default:
      throw new Error()
  }
}

const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const fetchData = async () => {
      const showsData = await fetchGridData()
      const shows = showsData.map((show) => ({
        ...show,
        startTime: ESTtoLocalTime(show.startTime),
        dateTime: ESTtoLocalDate(show.startTime),
      }))
      const allGenres = [...new Set(shows.map(({ filters }) => [...filters]).flat())]
      const payload = { shows: shows, genres: allGenres }
      dispatch({ type: "setShowData", payload })
    }
    fetchData()
  }, [])
  return <ShowsContext.Provider value={[state, dispatch]}>{children}</ShowsContext.Provider>
}

export default DataProvider
