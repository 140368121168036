import React from "react"
import { Link } from "react-router-dom"
import { isCobranded } from "../../../services/utils"

import { LoadingSpinner, BaseButton, BaseLabel } from "./Button.styled"

const Button = ({ variant = "primary", Icon = null, ...rest }) => {
  const { href, ...props } = rest
  const { disabled, label, to, isLoading, fontSize, letterSpacing } = props
  const isAnchor = Boolean(href && !disabled)
  // eslint-disable-next-line no-nested-ternary
  const element = isAnchor ? "a" : to ? Link : "button"
  const isCobrand = isCobranded()

  /* Temporary custom color for firefly to safely meet deadline until we can support linear gradients */
  const isFireflyOutline = isCobrand === "firefly" && variant === "outline"

  const buttonProps = {
    ...props,
    disabled: disabled || isLoading,
    as: element,
    $variant: isCobrand && !isFireflyOutline ? "cobrand" : variant,
    cobrand: isCobrand,
    ...(isAnchor && {
      href,
      rel: "noopener noreferrer",
    }),
  }

  const labelProps = {
    fontSize,
    letterSpacing,
    cobrand: isCobrand,
  }

  return (
    <BaseButton {...buttonProps}>
      {Icon && !isLoading && <Icon />}
      {isLoading && <LoadingSpinner />}
      <BaseLabel {...labelProps}>{label}</BaseLabel>
    </BaseButton>
  )
}

export default Button
