import React from "react"
import styled, { useTheme } from "styled-components/macro"
import { useSelector, useDispatch } from "react-redux"
import { toggleAuthModal } from "redux/auth"
import { readableColor, lighten, saturate, transparentize, darken } from "polished"
import { Link, useHistory } from "react-router-dom"
import { Audiotrack, Menu } from "styled-icons/material-rounded"
import { atMediaMax } from "theme"
import { sectionContainer } from "theme/spacing"
import useComponentVisible from "hooks/useComponentVisible"
import {
  trackAccountDropdown,
  trackEditAccountLink,
  trackLoginCTA,
  trackManageShows,
  trackMyKeepsCTA,
  trackMyTicketsCTA,
  trackSignOut,
} from "services/analytics"
import { getAdminDomain, isCobranded, imageNanoServiceAccessor, getSubdomain } from "services/utils"
import { Button } from "components/shared"
import { auth as firebaseAuth } from "firebaseApp"
import Border from "./Border"
import Logo from "./Logo.tsx"

const getHeaderBarTextColor = (theme) => {
  return (
    theme.elements.headerBarText || readableColor(transparentize(0.2, theme.elements.headerBar))
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
  height: 72px;
  position: relative;
  user-select: none;
  z-index: 100;
`

const RightContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  a {
    color: ${({ theme }) => getHeaderBarTextColor(theme)};
    padding: 0 20px;
    font-size: 16px;
  }
  ${atMediaMax("md")} {
    display: none;
  }
`

const TopContainer = styled.div`
  ${sectionContainer};
  align-items: center;
  display: flex;
  height: 72px;
  justify-content: space-between;
  position: relative;
  width: 100%;
  z-index: 10;
  max-width: 100%;

  .login {
    background-color: transparent;
    border: 1px solid ${({ theme }) => readableColor(transparentize(0.2, theme.elements.headerBar))};
    border-color: ${({ theme }) => getHeaderBarTextColor(theme)};
    div {
      color: ${({ theme }) => getHeaderBarTextColor(theme)};
    }
    svg {
      fill: ${({ theme }) => getHeaderBarTextColor(theme)};
      color: ${({ theme }) => getHeaderBarTextColor(theme)};
    }
  }
`

const AvatarContainer = styled.button`
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.6;
  }
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
`

const NavDropdown = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProSemiBold};
  animation: fadeIn 0.25s cubic-bezier(0.4, 0, 0.3, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-color: ${({ theme }) =>
    readableColor(theme.elements.headerBar) === "#000"
      ? lighten(0.05, saturate(0.05, theme.elements.headerBar))
      : darken(0.1, saturate(0.05, theme.elements.headerBar))};
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  text-rendering: optimizeLegibility;
  top: 100%;
  padding-bottom: 8px;
  width: 208px;
  z-index: -1;

  & > a,
  & > div:not(:first-of-type) {
    padding: 16px 20px;
    align-items: center;
    color: ${({ theme }) => theme.elements.headerBarText};
    cursor: pointer;
    display: flex;
    font-size: 16px;
    width: 100%;
    height: 48px;
    &:hover {
      text-decoration: underline;
    }
  }
  ${atMediaMax("md")} {
    width: 100%;
    z-index: 100;
  }
`

const UserInfo = styled.button`
  color: ${({ theme }) => theme.elements.headerBarText};
  display: flex;
  background: transparent;
  border: none;
  flex-direction: column;
  height: 80px;
  padding: 32px 20px 0px 20px;
  overflow: hidden;
  white-space: nowrap;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const Name = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: unset;
`

const Email = styled.div`
  width: 150px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  height: unset;
  div {
    margin: 0px;
    padding: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
  }
`

export const Icon = styled(Audiotrack)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`

export const MenuIcon = styled(Menu)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`

export const StyledButton = styled.button`
  appearance: none;
  border: none;
  padding: 0 20px;
  margin: 0 20px 0 0;
  background-color: transparent;
  color: ${({ theme }) => getHeaderBarTextColor(theme)};
  &:hover {
    text-decoration: underline;
  }
`

export const SignOutButton = styled.button`
  background: transparent;
  border: none;
  text-align: left;
  padding: 16px 20px;
  color: ${({ theme }) => theme.elements.headerBarText};
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const Nav = () => {
  const { ref, triggerRef, isComponentVisible } = useComponentVisible(false)
  const { logos, elements } = useTheme()
  const { avatar, customHeaderLogo, brandLogo, mobileLogo } = logos
  const { auth, user, cobrands } = useSelector((state) => state)
  const dispatch = useDispatch()
  const { isAdmin } = auth
  const { uid, email, name, photoUrl, isManager } = user
  const history = useHistory()

  const hasUser = name && email

  const onTicketsClicked = () => {
    trackMyTicketsCTA()
  }

  const onKeepsClicked = () => {
    trackMyKeepsCTA()
  }

  const onLoginClicked = () => {
    trackLoginCTA()
    dispatch(toggleAuthModal())
  }

  const onDropdown = () => {
    if (!isComponentVisible) {
      trackAccountDropdown()
    }
  }

  const onManageShowsClicked = () => {
    trackManageShows()
  }

  const signOut = async () => {
    trackSignOut()
    await firebaseAuth.signOut()
    history.push("/")
    window.location.reload()
  }

  const to = "/"
  const toTickets = "/tickets"
  const toAccount = "/account"

  const imageAssetSizeProps = {
    type: "assetWidth",
    value: "40",
  }

  return (
    <StyledNav>
      <TopContainer>
        <Logo
          to={to}
          customHeaderLogo={customHeaderLogo}
          brandDestination="/"
          brandLogo={brandLogo}
          lightBrandLogo={mobileLogo}
          isCobranded={isCobranded()}
          elements={elements}
        />
        <RightContainer>
          {hasUser && (
            <Link to={toTickets} onClick={onTicketsClicked}>
              <span data-testid="user_MyTickets">my tickets</span>
            </Link>
          )}
          {hasUser && !isCobranded() && (
            <Link to="/keeps" onClick={onKeepsClicked}>
              <span data-testid="user_MyKeeps">my keeps</span>
            </Link>
          )}
          <a
            href="https://partners.mandolin.com"
            alt="Mandolin Partners"
            target="_blank"
            rel="noreferrer"
          >
            <span data-testid="user_Partners">for artists + venues</span>
          </a>
          <a
            href="https://help.mandolin.com"
            alt="Mandolin Support"
            target="_blank"
            rel="noreferrer"
          >
            <span data-testid="user_Support">support</span>
          </a>
          {!hasUser && (
            <Button
              className="login"
              onClick={onLoginClicked}
              fontSize="16px"
              height="40px"
              Icon={Icon}
              label="log in"
              letterSpacing="-0.5px"
              radius="100px"
              variant="outline"
              width="138px"
            />
          )}
          {hasUser && (
            <AvatarContainer
              ref={triggerRef}
              onClick={onDropdown}
              data-testid="TopNav_Dropdown_Button"
            >
              <img
                alt="User avatar icon"
                src={
                  photoUrl
                    ? imageNanoServiceAccessor({ imageAssetUrl: photoUrl, imageAssetSizeProps })
                    : avatar
                }
              />
            </AvatarContainer>
          )}
        </RightContainer>
        {isComponentVisible && (
          <NavDropdown ref={ref}>
            <UserInfo>
              {hasUser && (
                <>
                  <Name>{name}</Name>
                  <Email>{email}</Email>
                </>
              )}
              {!hasUser && <Email>{uid}</Email>}
            </UserInfo>
            <Link to={toAccount} onClick={trackEditAccountLink}>
              <span data-testid="user_EditProfile">Edit Profile</span>
            </Link>
            <Link to="/account/change-password">
              <span data-testid="user_ChangePassword">Change Password</span>
            </Link>
            {isAdmin && (
              <a target="_blank" rel="noopener noreferrer" href={getAdminDomain()}>
                <span data-testid="user_Admin">Admin</span>
              </a>
            )}
            {isManager && (
              <Link to="/account/manage-shows" onClick={onManageShowsClicked}>
                <span data-testid="user_ManageShows">Manage Shows</span>
              </Link>
            )}
            <SignOutButton onClick={signOut}>
              <span data-testid="user_SignOut">Sign Out</span>
            </SignOutButton>
          </NavDropdown>
        )}
      </TopContainer>
      <Border bottom />
    </StyledNav>
  )
}

export default Nav
