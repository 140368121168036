import styled from "styled-components/macro"

const Avatar = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: opacity ${({ theme }) => theme.transition};
  width: 40px;
  ${({ theme }) => theme.skeletonLoad};
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
  &:hover {
    opacity: 0.75;
  }
`

const UserContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
`

const NavDropdown = styled.div`
  animation: fadeIn 0.14s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background: #151529;
  border-radius: 0.25rem;
  border-radius: 4px;
  box-shadow: 0 1px 17px 0 rgba(40, 44, 53, 0.1), 0 2px 4px 0 rgba(40, 44, 53, 0.1);
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  position: absolute;
  right: 0px;
  top: 45px;
  width: 190px;
  z-index: 1000;
  a,
  div {
    ${({ theme }) => theme.fonts.TTNormsProMedium};
    color: #ffffff;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 36px;
    letter-spacing: 0.01em;
    margin-right: 0;
    padding: 1.5rem 1rem;
    width: 100%;
    &:hover {
      text-decoration: none;
      background: #111122;
    }
  }
`

export { UserContainer, Avatar, NavDropdown }
