import styled from "styled-components/macro"
import { ExpandMore } from "@styled-icons/material-sharp"

const DropdownContainer = styled.div`
  position: relative;
  @media (${({ theme }) => theme.breakpoint}) {
    margin-left: auto;
  }
`

const DropdownTrigger = styled.div`
  width: 104px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: opacity ${({ theme }) => theme.transition};
  &:hover {
    opacity: 0.75;
  }
`

const DropdownIcon = styled(ExpandMore)`
  height: 24px;
  width: 24px;
  fill: #ffffff;
`

const DropdownTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 100%;
`

const DropdownElement = styled.div`
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation: fadeIn 0.14s linear;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  width: 350px;
  height: 415px;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 1;
`

const DropdownElementInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: relative;
`

const DropdownHeader = styled.div`
  color: #111112;
  font-size: 16px;
  border-bottom: 1px solid #b7b7b7;
  height: 36px;
  display: flex;
  margin: 8px 16px;
  label {
    margin: 0;
    padding: 0;
    flex-grow: 1;
  }
`

const DropdownBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: #111112;
  height: 100%;
  overflow: hidden;
  margin: 0 16px;
`
const CheckboxLabel = styled.label`
  cursor: pointer;
  padding: 6px;
  align-items: center;
  position: relative;
  display: flex;
  flex: 0 1 24px;
  margin-bottom: 8px;
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    width: 1px;
    height: 1px;
    box-sizing: border-box;
    padding: 0;
    overflow: visible;
  }
  span {
    ${({ theme }) => theme.fonts.TTNormsProNormal};
    font-size: 14px;
    padding-left: 4px;
    padding-top: 1px;
  }
`

const IconContainer = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export {
  CheckboxLabel,
  DropdownBody,
  DropdownContainer,
  DropdownElement,
  DropdownHeader,
  DropdownIcon,
  DropdownTitle,
  DropdownTrigger,
  IconContainer,
  DropdownElementInner,
}
