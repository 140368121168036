import firebase from "firebase/app"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface UserState extends firebase.firestore.DocumentData {
  channelSids: { [key: string]: string }
  email?: string
  isManager?: boolean
  marketingOptIn: boolean
  name?: string
  profileUpdated?: boolean
  zipCode?: string
  uid?: string
  watchPartyInvites?: any
  photoUrl?: string | null
  features?: Array<string>
  wallets?: Array<string>
  magicWallets?: Array<string>
  wolftrapID?: string
  isPending: boolean
}

export const userInitialState: UserState = {
  channelSids: {},
  marketingOptIn: true,
  photoUrl: null,
  isManager: false,
  features: [],
  wallets: [],
  magicWallets: [],
  isPending: true,
}

const user = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setUserData(_, action: PayloadAction<UserState>) {
      return { ...action.payload, isPending: false }
    },
  },
})

const { actions, reducer } = user

export const { setUserData } = actions

export default reducer
