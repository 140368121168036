import { firestore, functions } from "../firebaseApp"

export const getShowById = async (showId) => {
  const show = await firestore.collection("showProfiles").doc(showId).get()
  return show
}

export const changeShowState = async (showId, showState, restClient) => {
  try {
    return await restClient.managerChangeShowState({
      id: showId,
      managerChangeShowStateRequestDTO: { state: showState },
    })
  } catch (error) {
    console.log(error)
    return "error"
  }
}

export const mapArtistData = (artistData) => {
  let artistDataMap = new Map()
  artistData.forEach((artist) => {
    artistDataMap.set(artist.id, artist)
  })
  return artistDataMap
}

export const mapVenueData = (venueData) => {
  let venueDataMap = new Map()
  venueData.forEach((venue) => {
    venueDataMap.set(venue.id, venue)
  })
  return venueDataMap
}

export const getManagedShows = async (restClient) => {
  let managedShowResults = {}
  let loadingShows = true
  try {
    const futureShowsRes = await getFutureManagedShows(restClient)
    const pastShowsRes = await getPastManagedShows(restClient)

    futureShowsRes.showsData.map((futureShow) => {
      const venue = futureShowsRes.venuesMap.get(futureShow.venueId)
      const artist = futureShowsRes.artistsMap.get(futureShow.artistId)
      futureShow.venue = venue
      futureShow.artist = artist
      return futureShow
    })

    pastShowsRes.showsData.map((pastShow) => {
      const venue = pastShowsRes.venuesMap.get(pastShow.venueId)
      const artist = pastShowsRes.artistsMap.get(pastShow.artistId)
      pastShow.venue = venue
      pastShow.artist = artist
      return pastShow
    })

    managedShowResults.upcoming = futureShowsRes.showsData
    managedShowResults.past = pastShowsRes.showsData

    if (!futureShowsRes.loadingShows && !pastShowsRes.loadingShows) {
      loadingShows = false
    }

  } catch (error) {
    console.log(error)
  }
  return { managedShowResults, loadingShows }
}

export const getFutureManagedShows = async (restClient) => {
  // future shows include shows that occurred today
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const afterDate = today.toISOString()

  let next = true
  let startAfter
  let showDataFetched = []
  let artistDataFetched = []
  let venueDataFetched = []
  let showsData = []
  let artistsMap = {}
  let venuesMap = {}
  let loadingShows = true
  try {
    while (next) {
      const showsRef = await restClient.managerListManagedShows({
        limit: 1000,
        startAfter,
        afterDate,
        include: ["venue", "artist"],
      })
      const showsArray = showsRef.data.data
      const artistsArray = showsRef.data.included.artist
      const venuesArray = showsRef.data.included.venue
      const nextLink = showsRef.data.links.next
      showDataFetched = [...showDataFetched, ...showsArray]
      artistDataFetched = [...artistDataFetched, ...artistsArray]
      venueDataFetched = [...venueDataFetched, ...venuesArray]
      if (!nextLink) {
        next = false
        showsData = showDataFetched
        artistsMap = mapArtistData(artistDataFetched)
        venuesMap = mapVenueData(venueDataFetched)
        loadingShows = false
      } else {
        const urlParams = new URLSearchParams(nextLink.split("?")[1])
        startAfter = urlParams.get("startAfter")
      }
    }
  } catch (e) {
    loadingShows = false
    throw new Error(`getFutureManagedShows Error: ${e}`)
  }

  return { showsData, loadingShows, artistsMap, venuesMap }
}

export const getPastManagedShows = async (restClient) => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  yesterday.setHours(23, 59, 59, 999)
  const beforeDate = yesterday.toISOString()
  let next = true
  let startAfter
  let showDataFetched = []
  let artistDataFetched = []
  let venueDataFetched = []
  let showsData = []
  let artistsMap = {}
  let venuesMap = {}
  let loadingShows = true
  try {
    while (next) {
      const showsRef = await restClient.managerListManagedShows({
        limit: 1000,
        startAfter,
        beforeDate,
        include: ["venue", "artist"],
      })
      const showsArray = showsRef.data.data
      const artistsArray = showsRef.data.included.artist
      const venuesArray = showsRef.data.included.venue
      const nextLink = showsRef.data.links.next
      showDataFetched = [...showDataFetched, ...showsArray]
      artistDataFetched = [...artistDataFetched, ...artistsArray]
      venueDataFetched = [...venueDataFetched, ...venuesArray]
      if (!nextLink) {
        next = false
        showsData = showDataFetched
        artistsMap = mapArtistData(artistDataFetched)
        venuesMap = mapVenueData(venueDataFetched)
        loadingShows = false
      } else {
        const urlParams = new URLSearchParams(nextLink.split("?")[1])
        startAfter = urlParams.get("startAfter")
      }
    }
  } catch (e) {
    loadingShows = false
    throw new Error(`getPastManagedShows Error: ${e}`)
  }

  return { showsData, loadingShows, artistsMap, venuesMap }
}

export const checkShowManager = async (showId, isAdmin, restClient) => {
  if (isAdmin) {
    return true
  }
  const showManager = await restClient.managerGetShow({showId})
  return showManager
}