import React from "react"
import styled from "styled-components/macro"

const StyledDivider = styled.div`
  align-items: center;
  display: flex;
  height: 29px;
  margin: 32px auto;
  width: 100%;
  @media (max-width: 660px) {
    margin: 16px auto;
  }
  div {
    background-color: ${({ theme }) => theme.colors.elevation6};
    flex-grow: 1;
    height: 1px;
  }
  span {
    color: ${({ theme }) => theme.colors.elevation6};
    font-size: 13px;
    letter-spacing: 1.5px;
    line-height: 17px;
    margin: 0 24px;
    text-align: center;
    text-transform: uppercase;
    width: 64px;
  }
`

const Divider = () => (
  <StyledDivider>
    <div />
    <span>or</span>
    <div />
  </StyledDivider>
)

export default Divider
