import React from "react"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

import { getSubdomain, imageNanoServiceAccessor } from "services/utils"

import { HomeShow } from "views/Home"
import TicketBadge from "components/TicketBadge"
import {
  FeaturedEventContentHash,
  FeaturedEventButton,
  FeaturedEventContainer,
  FeaturedEventContent,
  FeaturedEventImage,
  FeaturedEventImageContainer,
  FeaturedEventDate,
} from "./FeaturedEvent.styled"

import { ImageAssetSizeProps, ImageAssetSizeType, ImageAssetSizeValue } from "types/ImageAssets"

dayjs.extend(LocalizedFormat)

interface Props {
  show: HomeShow
  onFocus?: (e: React.FocusEvent<HTMLAnchorElement>) => void
  buttonProps?: {
    tabIndex?: number
  }
  isTicketPurchased?: boolean
  trackClickEvent?: () => void
}

const FeaturedEvent: React.FC<Props> = ({
  show,
  onFocus,
  buttonProps = {},
  trackClickEvent = () => null,
}) => {
  const [localShow] = React.useState(show)
  let toProps: any = `/events/${localShow?.id}`
  const needsToRedirect = show?.cobrands?.length && getSubdomain() !== show?.cobrands[0]
  if (needsToRedirect) {
    toProps = { pathname: `https://${show?.cobrands[0]}.mandolin.com/events/${localShow?.id}` }
  }

  const imageAssetSizeProps: ImageAssetSizeProps = {
    type: ImageAssetSizeType.ASSET_WIDTH,
    value: ImageAssetSizeValue.WIDTH_1400,
  }

  return (
    <FeaturedEventContainer>
      <FeaturedEventButton
        {...buttonProps}
        onFocus={onFocus}
        aria-label={`${localShow?.name}, ${dayjs(localShow?.dateTime)
          .format("ddd, MMM D [at] LT")
          .toString()}`}
        to={toProps}
        target={needsToRedirect ? "_blank" : undefined}
        onClick={trackClickEvent}
      >
        <FeaturedEventImageContainer>
          <FeaturedEventImage alt={`Header image for ${localShow?.name}`} src={imageNanoServiceAccessor({ imageAssetUrl:show?.headerImg, imageAssetSizeProps })} />
        </FeaturedEventImageContainer>
        <FeaturedEventContent>
          <FeaturedEventDate>
            {dayjs(localShow?.dateTime).format("ddd, MMM D [at] LT").toString()}
          </FeaturedEventDate>
          <h3>{localShow?.name}</h3>
          <FeaturedEventContentHash width={98} height={98} hash="AEO2?U-W}SQ[" />
        </FeaturedEventContent>
        <TicketBadge display={show.isTicketPurchased} />
      </FeaturedEventButton>
    </FeaturedEventContainer>
  )
}

export default React.memo(FeaturedEvent)
