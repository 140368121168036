import styled, { css, keyframes } from "styled-components/macro"
import { transparentize, lighten, darken, readableColor, opacify, rgba } from "polished"

const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0;
  transition: 0.2s ease width;
  width: ${(props) => (props.showDesktopChat ? "300px" : "0px")};

  @media (max-width: 1111px) {
    width: 100%;
    position: absolute;
    top: 0;
    padding-top: calc(56.25% + 40px);
    display: ${(props) => (props.showMobileChat ? "block" : "none")};
  }
`

const MessageMenuButton = styled.span`
  position: absolute;
  top: 4px;
  right: 0;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
`

const MessageContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: stretch;
  padding-right: 40px;
  min-height: 38px;
  min-width: 200px;
  position: relative;
  transition: 0.3s;
`

const MessageSettings = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold}
  ${({ isComponentVisible, hasAdminPermission }) =>
    hasAdminPermission && isComponentVisible ? "display: flex" : "display: none"};
  transition: 0.3s;
  width: 200px;
  background-color: ${({ theme }) => theme.colors.elevation3};
  flex-direction: column;
  position: absolute;
  right: 100px;
  top: 35px;
  z-index: 3;
  border: 1px solid rgb(84, 84, 84);

  > button {
    padding: 10px 16px;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.elevation3};
    outline: none;
    border: none;
    color: white;
    text-align: left;
    transition: 0.3s;

    &:hover {
      background: rgba(174, 174, 174, 0.2);
    }
  }
`

const MessageContent = styled.div`
  color: ${({ theme }) => theme.elements.chatText};
  position: relative;
  width: 100%;
`

const MessageUsername = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.2px;
`

const Avatar = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -1px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
`

const messageAdminStyles = css`
  ${MessageUsername} {
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.3s;
  }

  ${Avatar} {
    cursor: pointer;
    transition-property: box-shadow;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    border-radius: 50%;
  }

  &:hover {
    ${MessageUsername} {
      text-decoration: underline;
    }

    ${Avatar} {
      box-shadow: 0 0 0 2px ${({ theme }) => readableColor(theme.elements.chatBackground)};
    }
  }
`

const getAdminStyles = (hasAdminPermission) => {
  if (hasAdminPermission) {
    return messageAdminStyles
  }
  return null
}

const MessageHead = styled.div`
  padding-left: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  ${({ hasAdminPermission }) => getAdminStyles(hasAdminPermission)}
`

const MessageBody = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProRegular};
  font-size: 12px;
  letter-spacing: 0.01em;
  padding-left: 50px;
  display: inline-block;
`

const MessageTimestamp = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProRegular};
  font-size: 10px;
  letter-spacing: -0.2px;
`

const skeletonAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`
const skeletonStyles = css`
  ${({ theme }) => {
    const { chatBackground } = theme.elements
    const isLight = readableColor(chatBackground) === "#000"
    const shade = isLight ? darken(0.15, chatBackground) : lighten(0.15, chatBackground)
    return `background: linear-gradient(90deg, ${rgba(shade, 0.2)}, ${rgba(shade, 0.4)}, ${rgba(
      shade,
      0.2,
    )})`
  }};
  background-size: 400% 100%;
  animation: ${skeletonAnimation} 1.3s ease infinite;
`

const SkeletonAvatar = styled.div`
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  border-radius: 22px;
  margin-right: 1rem;
  ${skeletonStyles};
`

const SkeletonUsername = styled.span`
  display: block;
  width: 200px;
  height: 14px;
  ${skeletonStyles};
`

const SkeletonText = styled.div`
  width: 100%;
  height: 14px;
  margin-top: 6px;
  ${skeletonStyles};
`

const ChatArea = styled.div`
  margin-bottom: 80px;
  flex-grow: 1;
  max-width: 100%;
  overflow: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const ChatBox = styled.div`
  background: ${({ theme }) => theme.elements.chatBackground};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const ScrollTrigger = styled.div`
  display: block;
  background: ${({ theme }) => theme.elements.buttonBackground || theme.colors.primarySolid};
  border-radius: 50%;
  position: absolute;
  padding: 4px;
  left: 50%;
  margin-left: -30px;
  bottom: 80px;
  z-index: 1;
  cursor: pointer;
  @media (min-width: 1111px) {
    display: ${(props) => (props.showDesktopChat ? "block" : "none!important")};
  }
  svg {
    width: 27px;
    height: 27px;
    opacity: 1;
  }
`

const DisabledMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 26px;
  padding: 40px;
  line-height: 1.2;
  ${({ theme }) => theme.fonts.TTNormsProBold}
  color: ${({ theme }) => theme.elements.chatText};
  background-color: ${({ theme }) => opacify(0.1, theme.elements.chatBackground)};
  position: relative;
  span {
    position: relative;
    z-index: 1;
  }
  &:after {
    content: "";
    background-image: url("/images/blur.png");
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 100%;
    background-position: bottom left;
    opacity: 0.5;
  }
`

const StyledHideChatButton = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 12px;
  background-color: #fff;
  color: #000;
  text-transform: uppercase;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  display: none;
  &:hover,
  &:focus {
    background-color: #f2f2f2;
  }
  @media (min-width: 1024px) {
    display: block;
  }
`

export {
  Avatar,
  ChatArea,
  ChatBox,
  Container,
  DisabledMessage,
  MessageContainer,
  MessageBody,
  MessageContent,
  MessageHead,
  MessageMenuButton,
  MessageSettings,
  MessageTimestamp,
  MessageUsername,
  ScrollTrigger,
  SkeletonAvatar,
  SkeletonText,
  SkeletonUsername,
  StyledHideChatButton,
}
