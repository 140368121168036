import React, { memo, useContext } from "react"
import { AuthModalContext } from "components/AuthModal/context"
import Typography from "components/Typography"
import {
  FieldError,
  FieldErrorMessage,
  FieldWrapper,
  InputElement,
  LabelContent,
} from "./InputField.styled"

const InputField = ({ label, labelColor, formKey, type = "text", disabled, trim, testid }) => {
  const [state, dispatch] = useContext(AuthModalContext)
  const { form, error } = state
  const hasError = error.field === formKey
  return (
    <FieldWrapper>
      <Typography tag="label" variant="smallCap" htmlFor={formKey} textColor={labelColor}>
        <LabelContent>{label}</LabelContent>
      </Typography>
      <InputElement
        type={type}
        id={formKey}
        data-testid={testid}
        disabled={disabled}
        value={form[formKey]}
        onChange={({ target }) => {
          const value = trim ? target.value.trim() : target.value
          dispatch({ type: "setFormValue", payload: { [formKey]: value } })
        }}
      />

      <FieldError>{hasError && <FieldErrorMessage>{error.message}</FieldErrorMessage>}</FieldError>
    </FieldWrapper>
  )
}

export default memo(InputField)
