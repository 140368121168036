import styled from "styled-components"
import { Button } from "components/shared"
import { sectionContainer } from "theme/spacing"
import { toRem } from "theme"

export const Wrapper = styled.div``

export const Background = styled.div`
  position: fixed;
  top: 72px;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: calc(100vh - 72px);
  background-color: rgba(0, 0, 0, 0.5);
`

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  top: 72px;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: calc(100vh - 72px);
`

export const Container = styled.section`
  ${sectionContainer}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  a {
    color: ${({ theme }) => theme.elements.mobileTextAccent};
  }
`
export const InfoContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: ${toRem(36)};
`

export const Icon = styled.img`
  width: ${toRem(80)};
  margin-bottom: ${toRem(24)};
  @media (max-width: 760px) {
    width: ${toRem(60)};
  }
`

export const MainInfo = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: ${toRem(48)};
  @media (max-width: 760px) {
    font-size: ${toRem(24)};
  }
  line-height: 1.09;
  letter-spacing: -0.5px;
`

export const StyledButton = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
    margin: auto 4px;
  }
  margin-bottom: ${toRem(19)};
`
