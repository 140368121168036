import React from "react"
import styled, { css } from "styled-components/macro"
import { rgba } from "polished"
import { atMediaMax } from "theme"
import Nav from "./Nav"
import MobileNav from "./MobileNav"

const sharedStyles = css`
  position: sticky;
  top: 0;
  z-index: 200;
  backdrop-filter: blur(10px);
  background-color: ${({ theme }) => rgba(theme.elements.headerBar, 0.85)};
`

const ShowOnMobile = styled.div`
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => rgba(theme.elements.headerBar, 0.9)};
  position: relative;
  z-index: 100;
  ${sharedStyles}
  ${atMediaMax("md")} {
    display: flex;
  }
`

const ShowOnDesktop = styled.div`
  width: 100%;
  ${sharedStyles}
  ${atMediaMax("md")} {
    display: none;
  }
`

const TopNav = () => {
  return (
    <>
      <ShowOnDesktop>
        <Nav />
      </ShowOnDesktop>
      <ShowOnMobile>
        <MobileNav />
      </ShowOnMobile>
    </>
  )
}

export default TopNav
