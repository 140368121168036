/* eslint-disable import/prefer-default-export */
import styled from "styled-components/macro"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 145px;
`

export const Icon = styled.div`
  color: #565b60;
`
