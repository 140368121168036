import React, { useState, useEffect } from "react"
import Webcam from "react-webcam"
import { useDispatch } from "react-redux"
import { updateShowData } from "redux/show"
import { useParams } from "react-router-dom"
import { ShowDetailDTO, ShowState, VIPExperienceParticipantStatus } from "@mandolin-dev/ts-sdk"
import { useRestClient, useZendeskHide } from "hooks"
import useVIPParticipant from "hooks/useVIPParticipant"
import useVIPAllowJoiningSubscription from "hooks/useVIPAllowJoiningSubscription"
import { updateVIPInfo, enterVIPExperience } from "services/VIP"
import { checkForSurveyResponse } from "services/survey"
import SurveyModal from "components/SurveyModal"
import { showMobileView } from "services/mobileUtils"
import { InnerContainer, WSConnectionErrorMessage } from "./styled"
import InCall from "./StatusTypes/InCall"
import Waiting from "../shared/statusTypes/Waiting"
import ReadyCheck from "./StatusTypes/ReadyCheck"
import Ready from "./StatusTypes/Ready"
import Complete from "./StatusTypes/Complete"
import Backdrop from "./components/Backdrop"
import VIPEntry from "../shared/VIPEntry"

import { EntryContainer, CamContainer } from "./StatusTypes/styled"
import { PermissionText, SubmitBtn, Badge, Title } from "../shared/SharedStyles"

import VIPLayout from "../shared/VIPLayout/VIPLayout"
import NoTicket from "../shared/statusTypes/NoTicket"
import EventEnded from "../shared/statusTypes/EventEnded"

const LINK_PREFIX = `${
  process.env.REACT_APP_MANDOLIN_ENV !== "production"
    ? `https://mandolinapp.page.link/?link=https://dev-testing.mandolin.com`
    : `https://mandolin.page.link/?link=https://mandolin.com`
}/vip`
const DYNAMIC_LINK = `${
  process.env.REACT_APP_MANDOLIN_ENV !== "production"
    ? `&apn=com.mandolin.mobile.debug&isi=1541246796&ibi=com.mandolin.mobile.debug&efr=1`
    : `&apn=com.mandolin.mobile&isi=1541246796&ibi=com.mandolin.mobile&efr=1`
}`

const PROD_VIP_SURVEY_ID = "a0eb92da-3619-4c7d-9e98-bdf8376ee3b5"
const VIP = () => {
  useZendeskHide()
  const { id: showId } = useParams<{ id: string }>()
  const [show, setShow] = useState<ShowDetailDTO>()
  const [participantId, setParticipantId] = useState("")
  const { restClient } = useRestClient()
  const [camOn, setCamOn] = useState(false)
  const [micOn, setMicOn] = useState(false)
  const [showSurvey, setShowSurvey] = useState(false)
  const {
    guestStatus,
    roomUrl,
    position,
    fanName,
    issueConnectingToEvent,
    userHasTicket,
    showState,
  } = useVIPParticipant(showId, participantId)

  const vipState = useVIPAllowJoiningSubscription(show, userHasTicket)

  const dispatch = useDispatch()
  useEffect(() => {
    restClient.getShow({ showId }).then(({ data }) => {
      setShow(data)
      dispatch(updateShowData(data))
    })
  }, [restClient, showId])

  const enterMeet = async (name: string) => {
    const eventUserId = await enterVIPExperience(showId || "", `${name}`)
    setParticipantId(eventUserId)
  }

  useEffect(() => {
    if (show?.surveyEntry || process.env.REACT_APP_MANDOLIN_ENV === "production") {
      const getSurveyData = async () => {
        const info = await checkForSurveyResponse(show?.id || "")
        setShowSurvey(!info)
      }
      getSurveyData()
    }
  }, [show])

  const updateInfo = (name: string, value: any) => {
    updateVIPInfo(participantId || "", name, value)
  }
  const renderState = () => {
    if (showState === ShowState.Postshow) return <EventEnded />
    if (!userHasTicket) return <NoTicket show={show} />
    if (showMobileView())
      return (
        <InnerContainer>
          <PermissionText>Please use the Mandolin Mobile App to join this event.</PermissionText>
          <SubmitBtn
            onClick={() => {
              window.location.href = `${LINK_PREFIX}/${showId}/${DYNAMIC_LINK}`
            }}
          >
            Use Mobile App
          </SubmitBtn>
        </InnerContainer>
      )
    if (!fanName)
      return (
        <>
          <Badge />
          <Title>{show?.name || ""}</Title>
          <VIPEntry onEnterMeet={enterMeet} />
        </>
      )
    if (
      !vipState.vipEvent?.allowJoining ||
      showState === "preshow" ||
      guestStatus === VIPExperienceParticipantStatus.Waiting
    ) {
      return <Waiting position={position} showInfo={show} partyState={showState} />
    }
    if (guestStatus === VIPExperienceParticipantStatus.ReadyCheck) {
      return (
        <ReadyCheck
          camOn={camOn}
          micOn={micOn}
          toggleCam={() => {
            updateInfo("videoEnabled", !camOn)
            setCamOn(!camOn)
          }}
          toggleMic={() => {
            updateInfo("audioEnabled", !micOn)
            setMicOn(!micOn)
          }}
          updateStatus={() => {
            updateInfo("status", VIPExperienceParticipantStatus.Ready)
          }}
        />
      )
    }
    if (
      guestStatus === VIPExperienceParticipantStatus.Ready ||
      guestStatus === VIPExperienceParticipantStatus.UpNext
    ) {
      return <Ready />
    }
    if (guestStatus === VIPExperienceParticipantStatus.InCall) {
      return (
        <InCall
          headerImg={show?.headerImg || ""}
          roomUrl={roomUrl}
          id={participantId}
          userName={fanName}
          camOn={camOn}
          micOn={micOn}
        />
      )
    }
    if (guestStatus === VIPExperienceParticipantStatus.Complete) {
      return showSurvey ? (
        <SurveyModal
          finish={() => {
            setShowSurvey(false)
          }}
          eventId={showId}
          surveyId={
            process.env.REACT_APP_MANDOLIN_ENV === "production"
              ? PROD_VIP_SURVEY_ID
              : show?.surveyEntry?.csatSurveyId || ""
          }
          shownOn="VIP Experience"
        />
      ) : (
        <Complete />
      )
    }
    return null
  }

  const renderScreen = () => {
    if (guestStatus === "inCall") {
      return <>{renderState()}</>
    }
    return (
      <>
        <Backdrop img={show?.headerImg || ""} />
        <div style={{ flex: 1, position: "relative" }}>
          <EntryContainer>{renderState()}</EntryContainer>
          {guestStatus && guestStatus !== "complete" && (
            <CamContainer>
              {camOn && (
                <Webcam
                  audio={micOn}
                  width="100%"
                  style={{
                    borderRadius: 5,
                    overflow: "hidden",
                    objectFit: "contain",
                    display: "block",
                  }}
                />
              )}
            </CamContainer>
          )}
        </div>
      </>
    )
  }
  return (
    <VIPLayout
      showId={showId}
      renderContent={
        issueConnectingToEvent ? (
          <WSConnectionErrorMessage>
            We&nbsp;re having an issue connecting to the event. Refresh the page or &nbsp;
            <button
              onClick={() => {
                ;(window as any)?.zE?.show()
              }}
              type="button"
            >
              reach out to support
            </button>
          </WSConnectionErrorMessage>
        ) : (
          renderScreen()
        )
      }
      cam={camOn}
      mic={micOn}
      toggleVideo={setCamOn}
      toggleMic={setMicOn}
      guestState={guestStatus as any}
    />
  )
}

export default VIP
