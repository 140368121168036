import { ExternalEventType, ShowDetailDTO, ShowState } from "@mandolin-dev/ts-sdk"
import React, { FC, useState, useEffect } from "react"
import Carousel from "./Carousel"
import CountdownTimer from "../../CountdownTimer"
import { WaitingCarouselContainer, WaitingCarouselInner } from "./Waiting.styled"

interface WaitingProps {
  showInfo?: ShowDetailDTO | undefined
  position?: number
  partyState: ShowState
}

export type VIPCardInfo = {
  id: number
  type: string
  headline?: string
  text?: string
  showInfo?: ShowDetailDTO
  icon?: string
  background?: any
  remoteImage?: string
  localImage?: string
  color?: string
  date?: string
  ctaLink?: string
}

const STOCK_CARDS: {
  [key: string]: VIPCardInfo[]
} = {
  [ExternalEventType.One]: [
    {
      id: 1,
      type: "Title",
      headline: "",
      remoteImage: "",
      text: "",
    },
    {
      id: 2,
      type: "Position",
      headline: "",
      remoteImage: "",
      text: "",
    },
    {
      id: 3,
      type: "Announce",
      headline: "Stay close",
      icon: "🤚",
      text: "Keep your computer nearby and don’t close the webpage.",
      localImage: "/images/pattern1.png",
      color: "#FF7575",
    },
    {
      id: 4,
      type: "Announce",
      headline: "Ready?",
      icon: "😎",
      text: "We’ll ask you if you’re ready a few minutes before it’s time to enter your meet.",
      localImage: "/images/pattern3.png",
      color: "#B02FFF",
    },
    {
      id: 5,
      type: "Announce",
      headline: "Smile!",
      icon: "😊",
      text:
        "During your meet we’ll take a photo of you and the artist. All you need to do is smile, we’ll take care of the rest.",
      localImage: "/images/pattern2.png",
      color: "#2FA8FF",
    },
    {
      id: 6,
      type: "Announce",
      headline: "Is this thing on?",
      icon: "🎤",
      text:
        "Make sure you’re well lit, centered in the frame, and close to your mic so the artist can see you and hear you.",
      localImage: "/images/pattern4.png",
      color: "#33AF56",
    },
  ],
  [ExternalEventType.Party]: [
    {
      id: 1,
      type: "Title",
      headline: "",
      remoteImage: "",
      text: "",
    },
    {
      id: 2,
      type: "BasicHelp",
      headline: "Stay Close!",
      icon: "🤚",
      text: `When you raise your hand, the host may call on you at anytime. \n\nPlease note that we can’t guarantee everyone will get a chance to speak.`,
      color: "#006EFB",
    },
    {
      id: 3,
      type: "ImageHelp",
      headline: "When you are watching:",
      text: `You’ll see the artist, the fan currently speaking, and your own video.`,
      localImage: "/images/imageHelp1.png",
      color: "#fff",
    },
    {
      id: 4,
      type: "ImageHelp",
      headline: "When you are speaking",
      text: `We’ll notify you and focus on the artist and your own video.`,
      localImage: "/images/imageHelp2.png",
      color: "#fff",
    },
  ],
  [ExternalEventType.Zoom]: [],
}
const Waiting: FC<WaitingProps> = ({ showInfo, partyState, position }) => {
  // showInfo?.external_event?.type
  const [slides, setSlides] = useState<VIPCardInfo[]>([])

  useEffect(() => {
    const slidesToSet: VIPCardInfo[] = []
    showInfo?.contentSections?.forEach((section, index) => {
      const items = section.sectionItems
      items.forEach((item) => {
        if (item.isFeatured) {
          slidesToSet.push({
            id: index,
            type: "commerceItem",
            headline: item.title,
            text: item.price,
            remoteImage: item.photoURL,
            ctaLink: item.url,
          })
        }
      })
    })
    setSlides([
      // eslint-disable-next-line camelcase
      ...(showInfo?.external_event?.type ? STOCK_CARDS[showInfo?.external_event?.type] : []),
      ...slidesToSet,
    ])
  }, [showInfo])

  return (
    <WaitingCarouselContainer>
      {showInfo && (
        <WaitingCarouselInner>
          <CountdownTimer showInfo={showInfo} showState={partyState} loading={false} />
          <Carousel position={position} cards={slides} showInfo={showInfo} />
        </WaitingCarouselInner>
      )}
    </WaitingCarouselContainer>
  )
}

export default Waiting
