import styled, { css } from "styled-components/macro"
import { readableColor, transparentize } from "polished"

export const WatchPartyHeaderContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.elements.chatHeaderBackground};
  display: flex;
  justify-content: space-between;
  min-height: 64px;
  padding: 16px;
  border-radius: 0 0 16px 16px;
  position: relative;
  cursor: pointer;
`

const headerNameStyle = css`
  margin: 0;
`

export const MemberCountContainer = styled.div`
  transition: 0.25s cubic-bezier(0.4, 0, 0.3, 1);
  display: flex;
  height: 16px;
  padding-right: 24px;
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  font-size: 13px;
  margin: auto 0;
  color: ${({ theme }) => theme.elements.chatHeaderText};
  svg {
    height: 2;
    width: 16px;
    fill: ${({ theme }) => theme.elements.chatHeaderText};
    margin-right: 8px;
  }
`

export const WatchPartyName = styled.input`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  width: 190px;
  background-color: ${({ theme }) => theme.elements.chatHeaderBackground};
  color: ${({ theme }) => theme.elements.chatHeaderText};
  outline: none;
  border: none;
  padding-left: 10px;
  margin: 5px;
  transition: 0.3s;
  ${({ headerName }) => headerName && headerNameStyle};

  &:active,
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.elements.chatBackground};
    outline: none;
  }

  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => transparentize(0.3, theme.elements.chatBackground)};
    border-radius: 1px;
  }
`

export const EmptyStateShareMessage = styled.div`
  color: ${({ theme }) => theme.elements.chatText};
  display: flex;
  flex-direction: column;
  margin: 30px 20px;
  padding: 10px;
  text-align: center;
  justify-content: space-between;

  > p {
    font-size: 14px;
  }
`

export const PanelContainer = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: 32px;
  height: ${({ top }) => (top ? "auto" : "100%")};
  justify-content: center;
  @media (max-width: 1111px) {
    padding: 16px;
  }
`
