import styled from "styled-components/macro"

const NavWrapper = styled.div`
  display: flex;
  flex-drection: row;
  min-height: 500px;
  height: 100%;
  margin: 0 3rem;
  padding-top: 40px;
  @media (max-width: 1111px) {
    flex-direction: column;
    margin: 0;
    padding-top: 0;
    display: ${(props) => (props.showMobileChat ? "none" : "block")};
  }
`

const NavTabs = styled.div`
  height: 100%;
  width: 90px;
  margin-right: 90px;
  display: flex;
  justify-content: unset;
  flex-direction: column;
  padding: 0;

  @media (max-width: 1111px) {
    flex-direction: row;
    justify-content: space-evenly;
    height: 50px;
    width: 100%;
    padding: 0 2rem;
    margin-right: 0;
    border-bottom: 1px solid rgb(54, 54, 54);
  }
`

const TabTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  letter-spacing: 0.01em;
  font-size: 12px;
`

const TabBorder = styled.div`
  height: 2px;
  background: ${({ theme }) => theme.colors.elevation8};
  width: 100%;
  position: absolute;
  bottom: -1px;
`

const NavTab = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  ${TabTitle} {
    color: ${({ active, theme }) =>
      active ? theme.colors.elevation8 : theme.colors.elevation6};
  }

  ${TabBorder} {
    opacity: ${(props) => (props.active ? 1 : 0)};
  }

  &:hover {
    ${TabTitle} {
      opacity: ${(props) => (props.active ? 1 : 0.7)};
    }
  }

  margin-bottom: 35px;
  @media (max-width: 1111px) {
    margin-bottom: 0;
  }
`

const NavBody = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 1111px) {
    height: 100%;
    padding: 1rem 2rem;
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 250px;
`

const InnerContainer = styled.div`
  border-radius: 4px;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.3s;

  &:hover {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
  }
`

const Container = styled.a`
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  border-radius: 4px;
  flex-shrink: 0;
  background-image: url("${(props) => props.img}");
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 10px;

  &:hover {
    color: unset;
    text-decoration: none;
  }
`

const Items = styled.div`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-direction: row;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export {
  Items,
  Container,
  InnerContainer,
  RowContainer,
  NavWrapper,
  NavTabs,
  NavTab,
  TabTitle,
  TabBorder,
  NavBody,
}
