import { createGlobalStyle } from "styled-components/macro"

export default createGlobalStyle`
  html,
  body, 
  #root {
    ${({ theme }) => theme.fonts.TTNormsProMedium};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background: ${({ theme }) => theme.elements.pageBackground};
    color: ${({ theme }) => theme.elements.pageText};
    min-height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 16px;
  }
`
