import styled from "styled-components/macro"

const StyledSidebar = styled.div`
  width: 100%;
  display: flex;
  flex-direction:column;
  position: relative;
  z-index: 1;
  background-color: #101112;
  @media(min-width: 768px) {
    width: 36%;
    max-width: 360px;
  }
  h2 {
    font-weight: 400;
    color: #b9b9b9;
    letter-spacing: 1px;
  }
  .top {
    padding: 20px;
    @media(min-width: 768px) {
      flex: 1;
    }
    @media(min-width: 1024px) {
      padding: 40px;
    }
  }
  .logo {
    width: 170px;
    position: relative;
    @media (min-width: 1024px) {
      width: 255px;
    }
    img {
      display: block;
      width: 100%;
    }
    .studio-text {
      ${({ theme }) => theme.fonts.TTNormsProExtraBold};
      font-size: 9px;
      letter-spacing: 3px;
      position: absolute;
      right: -4px;
      @media (min-width: 1024px) {
        font-size: 14px;
      }
    }
  }
`
const StyledStats = styled.div`
  display: flex;
  justify-content: space-between;
  @media(min-width: 1024px) {
    flex-direction: column;
    flex: 1;
  }
`

const StyledStat = styled.div`
  flex: 0 0 48%;
  padding-top:20px;
  @media(min-width: 1024px) {
    padding-top:40px;
  }
  h2 {
    font-size: 0.6em;
  }
  h3 {
    font-size: 1.25em;
    margin: 0;
  }
`

const StyledControls = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  background-color: rgba(16, 17, 18, 0.7);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  @media(min-width: 1024px) {
    padding: 40px;
    display:block;
  }
  h2 {
    font-size: 0.6em;
    margin-bottom: 0.5rem;
    width: 100%;
  }
  h3 {
    font-size: 1.25em;
    width: 100%;
  }
`

const StyledButton = styled.button.attrs({ role: "button" })`
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  color: #ffffff;
  width: calc(50% - 5px);
  display: block;
  border: none;
  border-radius: 8px;
  height: 50px;
  font-size: 18px;
  padding: 0px;
  line-height: 2;
  transition: color, background-color, border-color 200ms ease 0ms;
  letter-spacing: 1px;
  & + button {
    margin-left: 10px;
    @media(min-width: 768px) {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
  @media(min-width: 768px) {
    width: 100%;
  }
  &.start {
    background-color: #088723;
    &:hover {
      background-color: #077d21;
    }
  }
  &.end {
    background-color: rgb(238, 0, 0);
    &:hover {
      background-color: rgba(238, 0, 0, 0.85);
    }
  }
  &.standby {
    background-color: transparent;
    border: 2px solid #e0a800;
    color: #e0a800;
    &:hover {
      background-color: rgb(220,220,220,.05);
      border-color: #e0a800;
      color: #e0a800;
    }
  }
  &.preshow {
    background-color: transparent;
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    &:hover {
      background-color: rgb(220, 220, 220, .09);
      border-color: #FFFFFF;
      color: #FFFFFF;
    }
  }
`

export { StyledSidebar, StyledStats, StyledStat, StyledControls, StyledButton }
