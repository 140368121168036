import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { Share } from "@styled-icons/material"
import { lighten, transparentize } from "polished"
import { useSelector } from "react-redux"
import {
  ArtistName,
  ExpandText,
  ArtistMessage,
  Avatar,
  Body,
  Container,
  TitleImage,
  PresentedBy,
} from "./ShowInfo.styled"

const MAX_CHAR = 300

const ShareButton = styled.button`
  white-space: nowrap;
  transition: all 0.2s;
  background-color: ${({ copied }) => (copied ? "#73D689" : transparentize(0.1, "white"))};
  width: ${({ copied }) => (copied ? "196px" : "96px")};
  color: #070716;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 0;
  height: 24px;
  line-height: 24px;
  padding-left: 6px;
  padding-right: 6px;

  @media (max-width: 1111px) {
    top: 20px;
  }

  &:hover {
    outline: 0;
    background-color: ${({ copied }) => (copied ? "#73D689" : lighten(0.2, "#73D689"))};
  }

  &:active,
  &:focus {
    outline: 0;
  }

  > svg {
    margin-left: 8px;
    margin-bottom: 2px;
  }
`

const ShareInput = styled.input`
  color: #070716;
  background: #070716;
  border: none;
  position: absolute;
  left: -9999px;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
`

const ShowInfo = () => {
  const { id: showId } = useParams()
  const urlRef = useRef(null)
  const [urlCopied, setUrlCopied] = useState(false)
  const { show } = useSelector((state) => state)

  const {
    name: showName,
    headline,
    description,
    venue: { avatar, name, state } = {},
    titleImage,
    headerImg,
    cobrands,
    venue: { name: venueName },
  } = show
  const showAvatar = avatar || headerImg

  const subdomain = window.location.host.split(".")[0]
  const showTitleImage = titleImage && cobrands.includes(subdomain)
  const presentedBy = `${name}`

  const copyURL = (e) => {
    urlRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    setUrlCopied(true)
    window.analytics.track("Item Clicked", {
      userType: "authenticated",
      activityLocation: "Festival Watch",
      description: "Share URL Clicked",
      showId,
      showName: name,
      showState: state,
      venueName,
    })
  }

  useEffect(() => {
    if (urlCopied) {
      setTimeout(() => setUrlCopied(false), 2000)
    }
  }, [urlCopied])

  const shareURL =
    subdomain === "dev-testing"
      ? `https://dev-testing.mandolin.com/watch/${showId}`
      : `https://ivoted.mndl.in/s/${showId}`

  return (
    <Container>
      {showAvatar && <Avatar src={showAvatar} withInfo={!showTitleImage} />}
      {showTitleImage ? (
        <TitleImage data-testid="ShowInfo_TitleImage" src={titleImage} />
      ) : (
        <Body>
          <PresentedBy>{presentedBy}</PresentedBy>
          <ArtistName>{showName}</ArtistName>
          <ArtistMessage>{headline}</ArtistMessage>
          <ArtistMessage>{description}</ArtistMessage>
        </Body>
      )}
      {document.queryCommandSupported("copy") && (
        <ShareButton onClick={copyURL} copied={urlCopied}>
          {urlCopied ? (
            "Link copied to clipboard!"
          ) : (
            <>
              SHARE
              <Share size={12} />
            </>
          )}
        </ShareButton>
      )}
      <ShareInput type="text" readOnly value={shareURL} ref={urlRef} />
    </Container>
  )
}

export default ShowInfo
