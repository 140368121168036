import React, { FC, ReactElement } from "react"
import { UserState } from "redux/user"
import DateQuestion from "./SurveyQuestions/DateQuestion"
import MultiLineTextQuestion from "./SurveyQuestions/MultiLineTextQuestion"
import MultipleChoiceQuestion from "./SurveyQuestions/MultipleChoiceQuestion"
import SingleLineTextQuestion from "./SurveyQuestions/SingleLineTextQuestion"
import PhotoUpload from "./SurveyQuestions/PhotoUpload"

export enum SurveyQuestionType {
  singleLineText,
  date,
  multiLineText,
  multipleChoice,
  photoUpload,
}

export const SurveyQuestionComponents: { [key: number]: FC<SurveyQuestionProps> } = {
  [SurveyQuestionType.singleLineText]: SingleLineTextQuestion,
  [SurveyQuestionType.date]: DateQuestion,
  [SurveyQuestionType.multipleChoice]: MultipleChoiceQuestion,
  [SurveyQuestionType.multiLineText]: MultiLineTextQuestion,
  [SurveyQuestionType.photoUpload]: PhotoUpload,
}

export interface SurveyQuestion {
  id: string
  label: ReactElement
  type: SurveyQuestionType
  required?: boolean
  options?: string[]
  default?: (user?: UserState) => string
}

export interface Survey {
  requireAuth: boolean
  requireAdmin: boolean
  header?: ReactElement
  footer?: ReactElement
  buttonText: string
  questions: SurveyQuestion[]
}

export interface SurveyResponse {
  [questionId: string]: string | File
}

export interface SurveyQuestionProps {
  question: SurveyQuestion
  value: string | File
  setSurveyResponse: React.Dispatch<React.SetStateAction<SurveyResponse>>
}
