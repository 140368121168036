import Config from "config/config"
import { OpenSeaPort } from "opensea-js"
import Web3 from "web3"

const { ethereum } = window as any
const provider = ethereum ?? new Web3.providers.HttpProvider(Config.ETHEREUM.provider)

export default new OpenSeaPort(provider, {
  apiKey: Config.ETHEREUM.openseaApiKey,
  networkName: Config.ETHEREUM.network,
})
