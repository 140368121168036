import styled from "styled-components"
import { atMediaMin, toRem } from "theme"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  padding-bottom: ${toRem(16)};

  ${atMediaMin("md")} {
    padding-bottom: ${toRem(32)};
  }

  h3 {
    color: ${({ theme }) => theme.elements.pageTextAccent}!important;
  }
`

const GridItemWrapper = styled.div`
  padding-top: 8px;
`

export { Container, ContentContainer, GridItemWrapper }
