import styled from "styled-components/macro"

export const OptionFooterContainer = styled.div<{ showChatPanel: boolean }>`
  background: ${({ theme }) => theme.elements.vipFooterBackground};
  max-width: 100%;
  display: flex;
  height: 80px;
  transition: 0.2s width ease;
  width: ${({ showChatPanel }) => (showChatPanel ? "calc(100% - 300px)" : "100%")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  z-index: 200;
  position: relative;
`
export const CallControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
export const CallControlsButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #303537;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`
export const ToggleChatContainer = styled.div`
  position: absolute;
  right: 10px;
  @media (max-width: 740px) {
    width: 32%;
    line-height: 1;
  }
`
export const IsMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const VIPOptionsMenu = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 10px;
  background-color: #1b1f24;
`
export const StyledOptionsButton = styled.button`
  display: flex;
  flex-direction: row;
  padding: 8px 14px;
  background-color: transparent;
  border: none;
  gap: 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
