import styled from "styled-components"
import { sectionContainer } from "theme/spacing"
import { atMediaMin, toRem } from "theme/utils"

const Container = styled.section`
  ${sectionContainer}
`

const CarouselHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ArrowButton = styled.button.attrs({ type: "button", tabIndex: -1 })<{ active: boolean }>`
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.5;
  width: 32px;
  height: 32px;
  display: block;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.elements.pageText};
  display: flex;
  visibility: ${({ active }) => (active ? "visible" : "hidden")};
  justify-content: center;
  align-items: center;
  svg {
    display: block;
    width: 80%;
  }
  &:hover {
    opacity: 1;
  }
`

const ArrowContainer = styled.div`
  color: ${({ theme }) => theme.colors.elevation8};
  display: none;
  ${atMediaMin("md")} {
    display: flex;
  }
`

const CarouselRow = styled.ul`
  display: flex;
  overflow-x: auto;
  margin: 0 calc(-1 * var(--horizontal-margin));
  padding: ${toRem(8)} 0 0 0;
  list-style: none;
  position: relative;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    min-width: var(--horizontal-margin);
  }
`

const CarouselItem = styled.li`
  padding-right: ${toRem(16)};
  ${atMediaMin("md")} {
    padding-right: ${toRem(24)};
  }
  ${atMediaMin("lg")} {
    padding-right: ${toRem(32)};
  }
  &:last-of-type {
    padding-right: 0px;
  }
`

export { Container, CarouselRow, CarouselItem, CarouselHeader, ArrowContainer, ArrowButton }
