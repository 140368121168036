import React, { useEffect, Component } from "react"
import styled from "styled-components/macro"
import Rollbar from "rollbar"
import Config from "./config/config"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ErrorBoundaryCatch = ({ rollbar, error }) => {
  useEffect(() => {
    const personData = window?.localStorage?.userInfo
    rollbar.configure({
      ignoredMessages: [
        "ResizeObserver loop completed with undelivered notifications.",
        "(unknown): ResizeObserver loop limit exceeded",
        "ResizeObserver loop limit exceeded",
        "Script error.",
        "Failed to fetch",
        "TypeError: Failed to fetch",
      ],
      payload: {
        environment: process.env.REACT_APP_MANDOLIN_ENV,
        person: personData ? JSON.parse(personData) : {},
        client: {
          javascript: {
            code_version: Config.GIT_SHA,
            source_map_enabled: true,
          },
        },
      },
    })
  }, [window?.localStorage?.userInfo])

  return error ? (
    <Container>
      <h1>Something went wrong.</h1>
      <p>{error.message}</p>
    </Container>
  ) : null
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
      rollbar: new Rollbar({
        accessToken: "03d32661dde4471b965e422f83dfc542",
        captureUncaught: true,
        captureUnhandledRejections: true,
      }),
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.state.rollbar.error(error)
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    return (
      <>
        <ErrorBoundaryCatch rollbar={this.state.rollbar} error={this.state.error} />
        {!this.state.error && this.props.children}
      </>
    )
  }
}
