import styled from "styled-components/macro"

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${({ theme }) => theme.colors.background};
  justify-content: center;
  align-items: center;
  padding: 10px;
`
export const TimeSection = styled.div`
  flex-direction: column;
  height: 28px;
  width: 60px;
  justify-content: center;
  align-items: center;
`
export const EntryHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`
export const TimeSectonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const CountdownContainer = styled.div`
  flex-direction: row;
  display: flex;
`
