import styled, { css } from "styled-components/macro"
import { isMobile } from "react-device-detect"
import { readableColor, transparentize, tint, rgba } from "polished"
import { Button } from "../../shared"
import { ClaimCodeModal, ModalBackdrop, InputContainer } from "../../ClaimCodes"

const WatchPageClaimCodeModal = styled(ClaimCodeModal)`
  &${ModalBackdrop} {
    width: 100%;
    height: 100%;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  ${InputContainer} {
    margin: 0 auto;
  }
`

const EnterClaimCodeButton = styled(Button)`
  background-color: ${({ theme }) => rgba(readableColor(theme.colors.elevation1), 0.6)};

  &:not(:disabled):hover {
    background-color: ${({ theme }) => rgba(readableColor(theme.colors.elevation1), 0.8)};
    opacity: 1;
  }
`

const BlurredBackground = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => props.src});
  opacity: 0.5;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  transform: scale(1);
`

const Counter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  h2 {
    ${({ theme }) => theme.fonts.TTNormsProExtraBold};
    font-size: 42px;
    letter-spacing: 2px;
  }
  h3 {
    ${({ theme }) => theme.fonts.TTNormsProExtraBold};
    font-size: 16px;
    letter-spacing: 2px;
  }
  @media (max-width: 500px) {
    h2 {
      font-size: 24px;
      letter-spacing: 2px;
    }
    h3 {
      font-size: 12px;
      letter-spacing: 0.2px;
    }
  }
`

const VideoController = styled.div`
  width: 100%;
  height: 100%;

  .video-js {
    height: 0;
    max-width: 100%;
    padding-top: 56.25% !important;
    width: 100%;
    @media (max-width: 1111px) {
      width: 100%;
      height: 100%;
      padding-top: 0;
      max-width: none;
    }
    &.vjs-layout-x-large {
      padding-top: 90vh;
    }
    .vjs-tech-chromecast {
      &-title {
        padding: 0 8vw;
      }
    }
    .vjs-chrome-cast google-cast-launcher {
      display: block;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      --disconnected-color: #fff;
    }
    .vjs-chrome-cast .cast_caf_state_d {
      fill: #fff !important;
    }
    .vjs-chromecast-button .vjs-icon-placeholder,
    .vjs-airplay-button .vjs-icon-placeholder {
      width: 50%;
      height: 50%;
    }

    .vjs-menu-button-popup {
      .vjs-menu {
        width: 7em;
        left: -1.5em;
        ul {
          border-radius: 4px;
          font-size: 11px;
        }
      }
    }
    .vjs-big-play-button {
      border-radius: 1em;
      width: 2em;
      height: 2em;
      margin-top: -1em;
      margin-left: -1em;
      line-height: 2em;
      font-size: 3em;
      border-width: 0px;
      background-color: ${transparentize(0.1, "#ffffff")};
      color: ${readableColor("#ffffff")};
      z-index: 10;
    }
    .vjs-control-bar {
      background-color: transparent;
      z-index: 3;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0px;
        opacity: 0.8;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4990371148459384) 0%,
          rgba(0, 0, 0, 0.25253851540616246) 25%,
          rgba(0, 0, 0, 0.09567577030812324) 75%,
          rgba(0, 0, 0, 0) 100%
        );
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
      }
      @media (min-width: 1111px) {
        font-size: 12px;
      }
      .vjs-control {
        z-index: 1;
        color: ${({ theme }) =>
          tint(0.95, theme.elements.buttonBackground || theme.colors.primarySolid)};
        &.vjs-seek-to-live-control {
          justify-content: center;
          align-items: center;
          &.vjs-at-live-edge {
            .vjs-seek-to-live-text {
              background-color: ${({ theme }) =>
                theme.elements.buttonBackground || theme.colors.primarySolid};
              color: ${({ theme }) =>
                readableColor(theme.elements.buttonBackground || theme.colors.primarySolid)};
            }
          }
          .vjs-seek-to-live-text {
            background-color: ${({ theme }) => theme.colors.elevation6};
            color: ${({ theme }) => readableColor(theme.colors.elevation6)};
            border-radius: 4px;
            padding: 4px 3px 3px 4px;
            display: inline-block;
            font-size: 11px;
            height: auto;
            line-height: 1.2;
            letter-spacing: 1px;
            font-weight: 600;
            text-align: center;
            position: relative;
            display: block;
          }
          .vjs-icon-placeholder {
            display: none;
          }
        }
        &.vjs-play-control,
        &.vjs-picture-in-picture-control,
        &.vjs-fullscreen-control {
          width: 3em;
        }
      }
      .vjs-play-progress.vjs-slider-bar {
        background-color: ${({ theme }) =>
          theme.elements.buttonBackground || theme.colors.primarySolid};
        color: ${({ theme }) => theme.elements.buttonBackground || theme.colors.primarySolid};
        border-radius: 100px;
        .vjs-time-tooltip {
          background-color: ${({ theme }) =>
            tint(0.9, theme.elements.buttonBackground || theme.colors.primarySolid)};
          padding: 6px;
          line-height: 1.1;
        }
        &:before {
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
        }
      }
      .vjs-slider {
        background-color: ${transparentize(0.7, tint(0.7, "#ffffff"))};
        border-radius: 100px;
      }
      .vjs-mouse-display {
        background-color: ${({ theme }) => transparentize(0.25, theme.colors.elevation1)};
      }
      .vjs-live-control {
        align-items: center;
      }
      .vjs-live-display {
        ${({ theme }) => theme.fonts.TTNormsProBold};
        background-color: ${({ theme }) =>
          theme.elements.buttonBackground || theme.colors.primarySolid};
        color: ${({ theme }) => theme.elements.buttonText};
        border-radius: 4px;
        padding: 4px 3px 3px 4px;
        display: inline-block;
        font-size: 11px;
        height: auto;
        line-height: 1.2;
        opacity: 0.8;
        letter-spacing: 1px;
        text-align: center;
        position: relative;
        display: block;
      }
    }
  }
`

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 0px;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 0;
  border-image-source: ${({ theme }) => theme.colors.primaryGradient};
  border-image-slice: 2;
  border-style: none none solid;
  border-width: 0px 0px 2px;
  ${({ theme }) =>
    theme.elements.watchPageAccent &&
    css`
      border: none;
      border-bottom: 2px solid ${theme.elements.watchPageAccent};
    `}
  > div {
    ${({ isLive }) => !isLive && "padding: 0 2rem"};
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    color: ${({ theme }) => readableColor(theme.elements.pageBackground)} !important;
    h4 {
      z-index: 1;
      @media (max-width: 500px) {
        font-size: 16px;
        margin: 0;
      }
    }
  }

  a,
  button {
    margin-top: 16px;
  }

  ${EnterClaimCodeButton} {
    margin-top: 40px;
  }

  @media (max-width: 500px) {
    a,
    button {
      div {
        font-size: 14px;
      }
    }
  }
`

const Countdown = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-evenly;
  z-index: 1;
  @media (max-width: 500px) {
    margin-bottom: 8px;
    max-width: 304px;
  }
`

const Header = styled.div`
  color: color: ${({ theme }) => theme.elements.pageText};
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  font-size: 30px;
  letter-spacing: 3px;
  margin-bottom: 30px;
  z-index: 1;
  text-transform: uppercase;
  @media (max-width: 500px) {
    font-size: 16px;
    letter-spacing: 2px;
    margin-bottom: 16px;
  }
`

const Notice = styled.div`
  color: ${({ theme }) => theme.colors.elevation6};
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: ${isMobile ? 10 : 16}px;
  letter-spacing: 1px;
  margin-bottom: 10px;
`

const Logo = styled.img`
  height: auto;
  left: 20px;
  max-height: 60px;
  max-width: 140px;
  object-fit: contain;
  object-position: left;
  position: absolute;
  top: 20px;
  width: auto;
  z-index: 99;

  @media (max-width: 1111px) {
    display: none;
  }
`

const Title = styled.div`
  color: rgb(255, 255, 255);
  ${({ theme }) => theme.fonts.TTNormsProBold};
  letter-spacing: 0.01em;
  font-size: 32px;
  z-index: 1;
`

const Subtitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold};
  font-size: 16px;
  text-align: center;
  width: 332px;
  margin-top: 10px;
  margin-bottom: 0;
  z-index: 1;
  @media (max-width: 500px) {
    width: 100%;
    max-width: 304px;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 8px;
  }
`

const Text = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold};
  font-size: 13px;
  margin-top: 16px;
  z-index: 1;
  a {
    ${({ theme }) => theme.fonts.TTNormsProDemiBold};
  }
  @media (max-width: 500px) {
    font-size: 10px;
    margin-top: 8px;
  }
`

const AdminText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold};
  font-size: 16px;
  z-index: 1;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 8px;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    font-size: 10px;
    margin-top: 8px;
  }
`

const UnticketedCountdown = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  display: flex;
  justify-content: space-evenly;
  max-width: 500px;
  width: 100%;
  z-index: 1;
  @media (max-width: 500px) {
    max-width: 304px;
  }
  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    h2 {
      font-size: 40px;
      letter-spacing: 3.89px;
      margin-bottom: 1px;
    }
    h3 {
      font-size: 13px;
      letter-spacing: 2px;
    }
    @media (max-width: 500px) {
      h2 {
        font-size: 24px;
        letter-spacing: 2px;
      }
      h3 {
        font-size: 12px;
        letter-spacing: 0.2px;
      }
    }
  }
`

const AppStoreContainer = styled.div`
  display: flex;
  max-width: 50%;
  flex-direction: row;
  gap: ${isMobile ? 4 : 20}px;
  z-index: 10;
`
const NativeVIPInfoContainer = styled.div`
  justify-content: center;
  padding-top: ${isMobile ? 10 : 30}px;
  z-index: 10;
`
const StoreIcon = styled.a`
  padding: 5px;

  img {
    height: ${isMobile ? 20 : 50}px;
  }
`

export {
  AdminText,
  AppStoreContainer,
  BlurredBackground,
  Container,
  Countdown,
  Counter,
  EnterClaimCodeButton,
  Header,
  Logo,
  NativeVIPInfoContainer,
  Notice,
  StoreIcon,
  Subtitle,
  Text,
  Title,
  UnticketedCountdown,
  VideoController,
  WatchPageClaimCodeModal,
}
