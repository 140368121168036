/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { getSubdomain } from "services/utils"
import { isMobile } from "react-device-detect"
import { ShowState } from "@mandolin-dev/ts-sdk"
import { functions } from "../firebaseApp"

export const claimTicket = async (id) => {
  const claimTicketRequest = functions.httpsCallable("claimTicket")
  const result = await claimTicketRequest({ ticketId: id })
  return result
}

export const createTicketForShow = async (showId, email, name, sendEmail) => {
  const createTicket = functions.httpsCallable("createTicketForShow")
  try {
    const result = await createTicket({ showId, email, name, sendEmail })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const createTicketForPublicShow = async (showId, email, name, wallet) => {
  const createTicket = functions.httpsCallable("createTicketForPublicShow")
  try {
    return await createTicket({ showId, email, name, wallet })
  } catch (error) {
    console.log(error.message)
  }
}

export const sendMintPurchaseEmail = async (email) => {
  const sendEmail = functions.httpsCallable("sendMintPurchaseEmail")
  try {
    return await sendEmail({ recipient: email })
  } catch (error) {
    console.log(error.message)
  }
}

export const reassignTicketForShow = async (showId, ticketId, newEmail) => {
  const reassignTicket = functions.httpsCallable("reassignTicketForShow")
  try {
    const result = await reassignTicket({ showId, ticketId, newEmail })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getShowTicketStats = async (showId, restClient) => {
  let next = true
  let startAfter
  let dataFetched = []
  let ticketsData = []
  let loadingTickets = true
  try {
    while (next) {
      // eslint-disable-next-line no-await-in-loop
      const ticketsRef = await restClient.presenterListShowTicketStats({
        limit: 10000,
        startAfter,
        showId,
      })
      const ticketsArray = ticketsRef.data.data
      const nextLink = ticketsRef.data.links.next
      dataFetched = [...dataFetched, ...ticketsArray]
      if (!nextLink) {
        next = false
        ticketsData = dataFetched
        loadingTickets = false
      } else {
        const urlParams = new URLSearchParams(nextLink.split("?")[1])
        startAfter = urlParams.get("startAfter")
      }
    }
  } catch (e) {
    loadingTickets = false
    throw new Error(`getShowTickets Error: ${e}`)
  }

  const totalCount = ticketsData.length
  const claimedCount = ticketsData.filter(({ status }) => status === "claimed").length

  return { ticketsData, loadingTickets, totalCount, claimedCount }
}

export const displayEventVIPOneEntry = (vipEvent, show) =>
  show?.external_event?.stream_type !== "livestream" &&
  vipEvent?.allowJoining &&
  show?.state === ShowState.Live

const externalUrl = (show, vipEvent, isNativeVIP, isParty) => {
  if (show?.external_event?.stream_type === "livestream") {
    return { pathname: `/watch/${show.id}` }
  }

  if (isNativeVIP && !displayEventVIPOneEntry(vipEvent, show)) {
    return { pathname: `/watch/${show.id}` }
  }
  if (isParty && !isMobile && displayEventVIPOneEntry(vipEvent, show)) {
    return { pathname: `/vip-party/${show.id}` }
  }

  if (isNativeVIP && !isMobile && displayEventVIPOneEntry(vipEvent, show)) {
    return { pathname: `/vip/${show.id}` }
  }

  if (show?.external_event) {
    return { pathname: `${show.external_event.url}` }
  }

  return undefined
}

const showCobrandUrl = (show) => {
  return show?.cobrands.length && getSubdomain() !== show?.cobrands[0]
    ? { pathname: `//${show?.cobrands[0]}.mandolin.com/watch/${show?.id}` }
    : undefined
}

const mandolinUrl = (show) => `/watch/${show?.id}`
const eventUrl = (show) => `/events/${show?.id}`

export const toEventUrl = (show, vipEvent, isNativeVIP, isParty, isonDemandEvent) =>
  // eslint-disable-next-line no-nested-ternary
  isMobile && displayEventVIPOneEntry(vipEvent, show)
    ? undefined
    : show?.state === ShowState.Postshow || isonDemandEvent
    ? eventUrl(show)
    : externalUrl(show, vipEvent, isNativeVIP, isParty) || showCobrandUrl(show) || mandolinUrl(show)

export const eventTarget = (show) =>
  show?.cobrands.length && getSubdomain() !== show?.cobrands[0] ? "_blank" : undefined
