/* eslint-disable import/prefer-default-export */
import styled from "styled-components/macro"
import { SentimentVerySatisfied } from "@styled-icons/material-rounded"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 145px;
`

export const Content = styled.div`
  display: grid;
  grid-gap: 0.4rem;
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
`

export const Label = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  background: -webkit-linear-gradient(155.46deg, #3fd27a 0%, #3fd2ca 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 0.9rem;
  line-height: 16px;
  text-align: center;
  font-weight: 700;
`

export const Icon = styled(SentimentVerySatisfied)`
  color: #3fd27a;
  width: 2.5rem;
  height: 2.5rem;
`
