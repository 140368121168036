import React from "react"
import { GoodbyeContainer } from "./Goodbye.styled"

const Goodbye = () => {
  return (
    <GoodbyeContainer>
      <img alt="Page load icon" src="/logo/PNG/Large/mark-white@3x.png" />
      <p>
        Hi there! Sadly, as of Monday April 17th, 2023, Mandolin will no longer be offering the
        digital fan experiences you’ve come to love. We’d like to sincerely thank every fan that
        supported their favorite artist’s livestream, digital meet & greet and fan page on our
        platform; doing so helped create a more authentic ecosystem for fans and artists alike.
      </p>
      <p>
        While Mandolin can no longer be the platform to support these special kinds of experiences,
        we sincerely believe in the power of fans to uplift the entire music community for the
        better. We know you came here for a livestream, but please consider redirecting the money
        and attention you would have paid to see a stream here to buying your favorite artist’s
        latest vinyl, merch or tour ticket on their channels of choice!
      </p>
      <p>
        If you’re here to request ticket refunds or pull down items from your Mandolin Wallet, check
        the email you used for your Mandolin username for more information on how to complete that
        process. You can always reach out to us at
{" "}
        <a href="mailto:help@mandolin.com">help@mandolin.com</a> as well.
        <br />
        <br />
        <p>
          Thank you for three incredible years!
          <br />
          <br />
          -Team Mandolin
        </p>
      </p>
    </GoodbyeContainer>
  )
}

export default Goodbye
