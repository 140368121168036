import { ReactionTunnel } from "components/Watch/Reactions/styled"
import { motion } from "framer-motion"
import styled from "styled-components"

export const VibesContainer = styled.div<{
  width: number
  height: number
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background: linear-gradient(#353535, #1c1c1c);
  position: relative;
  overflow: hidden;
  display: flex;
  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(28, 28, 28, 0) 0%,
      rgba(41, 41, 41, 0) 51%,
      rgba(53, 53, 53, 0.5) 100%
    );
    z-index: 5;
  }
`
export const ReactionContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  top: 0;
  ${ReactionTunnel} {
    bottom: 0;
    .reactionY {
      font-size: 60px;
      line-height: 1;
      width: 60px;
      height: 60px;
    }
  }
`
export const ShowVibeContainer = styled.div`
  position: relative;
  padding: 24px;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  overflow: hidden;
  flex: 1;
`

export const ChatItem = styled(motion.div)`
  margin-bottom: 40px;
  position: relative;
  margin-left: 80px;
  border-radius: 24px;
  padding: 20px 24px;
  background-color: #394249;
  span {
    color: #fff;
    font-size: 24px;
    line-height: 1.5;
  }
`
export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  .name {
    font-weight: 600;
  }
  .time {
    opacity: 0.5;
  }
`
export const ChatTitle = styled.div``

export const ChatContent = styled.div``

export const VibeAvatar = styled.div<{ bg?: string }>`
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center;
  position: absolute;
  left: -80px;
  top: 0;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.25);
`
