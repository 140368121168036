import { Button } from "components/shared"
import Typography from "components/Typography"
import React from "react"
import styled from "styled-components"

interface ErrorMessage {
  message: string
  buttonLabel: string
  onClick: () => void
}

const ErrorContainer = styled.section`
  ${({ theme }) => theme.sectionContainer}
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  z-index: 20;
  h3 {
    margin-bottom: 24px;
  }
  a {
    color: ${({ theme }) => theme.elements.pageTextAccent};
  }
`

const ErrorMessage = ({ message, buttonLabel, onClick }: ErrorMessage) => {
  return (
    <ErrorContainer>
      <Typography variant="h3">{message}</Typography>
      <Button
        letterSpacing="-0.5px"
        radius="100px"
        variant="outline"
        label={buttonLabel}
        height="40px"
        width="150px"
        onClick={onClick}
      />
    </ErrorContainer>
  )
}

export default ErrorMessage
