import React, { FC, SyntheticEvent, useRef } from "react"
import {
  SurveyFileInput,
  SurveyPhotoIcon,
  SurveyPhotoImg,
  SurveyPhotoPreview,
} from "../CobrandSurvey.styled"
import { SurveyQuestionProps } from "../CobrandSurvey.types"

const PhotoUpload: FC<SurveyQuestionProps> = ({ question, value, setSurveyResponse }) => {
  const fileInputRef = useRef<any>()

  const handlePhotoChange = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    if (target.files) {
      const image = target.files[0]
      setSurveyResponse((curr: any) => ({
        ...curr,
        [question.id]: image,
      }))
    }
  }

  return (
    <>
      <SurveyFileInput ref={fileInputRef} type="file" onChange={handlePhotoChange} />
      <SurveyPhotoPreview onClick={() => fileInputRef.current.click()}>
        {value && <SurveyPhotoImg src={URL.createObjectURL(value as File)} />}
        {!value && <SurveyPhotoIcon />}
      </SurveyPhotoPreview>
    </>
  )
}

export default PhotoUpload
