import React, { FC } from "react"
import { SurveyQuestionInput } from "../CobrandSurvey.styled"
import { SurveyQuestionProps } from "../CobrandSurvey.types"

const SingleLineTextQuestion: FC<SurveyQuestionProps> = ({
  question,
  value,
  setSurveyResponse,
}) => (
  <SurveyQuestionInput
    type="text"
    value={value as string}
    onChange={(e) => {
      const value = e.target.value
      setSurveyResponse((curr: any) => ({
        ...curr,
        [question.id]: value,
      }))
    }}
  />
)

export default SingleLineTextQuestion
