import styled from "styled-components/macro"

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 60px;
  text-align: center;
  align-items: center;
`
export const ErrorTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 26px;
  padding-bottom: 8px;
`
export const PermissionText = styled.div<{ small?: boolean }>`
  z-index: 20;
  font-size: ${({ small }) => (small ? `14px` : `18px`)};
`
export const CtaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`
export const PurchaseCta = styled.span`
  cursor: pointer;
`
export const CtaBox = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.elevation4};
  flex: 1;
  padding: 16px;
  color: white !important;
  text-decoration: none !important;
  transition: all 320ms ease;
  &:hover {
    border-color: ${({ theme }) => theme.colors.elevation5};
    background-color: rgba(255, 255, 255, 0.05);
    span {
      text-decoration: underline;
    }
  }
`
export const OrBox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  opacity: 0.4;
`
