import { Close } from "@styled-icons/material/"
import styled from "styled-components/macro"

export const ModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1024px) {
    align-items: flex-start;
  }
`

export const ModalCard = styled.div`
  width: 100%;
  max-width: 1233px;
  height: 100%;
  max-height: 600px;
  display: flex;
  position: relative;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    max-height: 100vh;
    overflow-x: scroll;
  }
`

export const ModalImage = styled.div`
  background-color: ${({ theme }) => theme.colors.elevation1};
  border-radius: 2rem 0 0 2rem;
  width: 66%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 100%;
    border-radius: 0;
  }
`

export const ModalInfo = styled.div`
  background-color: ${({ theme }) => theme.colors.elevation8};
  border-radius: 0 2rem 2rem 0;
  width: 33%;
  padding: 5rem 2.5rem;
  @media (max-width: 1024px) {
    width: 100%;
    border-radius: 0;
    padding: 2.5rem;
  }
`

export const CardImage = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  @media (max-width: 1024px) {
    min-height: 300px;
  }
`

export const CloseWindow = styled(Close)`
  position: absolute;
  width: 32px;
  height: 32px;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.elevation5};

  &:hover {
    color: #dcdcdc;
  }
`

export const WhiteBoard = styled.div`
  position: absolute;
  width: 1233px;
  height: 600px;
  background: #ffffff;
  border-radius: 24px;
`

export const BlackImage = styled.div`
  position: absolute;
  width: 800px;
  height: 600px;
  background: ${({ theme }) => theme.colors.elevation1};
`

export const TitleNft = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold};
  color: ${({ theme }) => theme.colors.elevation1};
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -1px;
`

export const Content = styled.div`
  position: absolute;
  width: 433px;
  height: 478px;
  left: 800px;
  top: 48px;
  padding: 2rem;
`

export const CollectionName = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: ${({ theme }) => theme.colors.elevation1};
  line-height: 1rem;
  font-size: 0.9rem;
  padding-top: 0.3rem;
`
export const Description = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: ${({ theme }) => theme.colors.elevation1};
  line-height: 1.13rem;
  font-size: 0.9rem;
  padding-top: 1rem;
`
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.elevation4};
  line-height: 1.2rem;
  font-size: 1rem;
  padding-top: 0.1rem;
`

export const SmallText = styled.div`
  color: ${({ theme }) => theme.colors.elevation4};
  line-height: 1.1rem;
  font-size: 0.8rem;
  padding-top: 0.3rem;
`

export const TextWrapper = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  padding-top: 1rem;
`

export const VideoAsset = styled.video`
  width: 100%;
  height: 100%;
  &:focus {
    outline: black;
  }
`

export const OpenSeaLink = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  line-height: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  align-self: end;
  margin-bottom: 0.2rem;
  a {
    color: ${({ theme }) => theme.colors.primarySolid};
  }
`
