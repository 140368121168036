import styled from "styled-components"
import { Link } from "react-router-dom"

const Container = styled<any>(Link)`
  margin-top: 10px;
  position: relative;
  transition: transform 0.2s ease-in-out;
  will-change: transform;
  display: block;
  &:hover {
    transform: translate3d(0, -6px, 0);
    text-decoration: none;
  }
  @media (prefers-reduced-motion) {
    transition: none !important;
  }
`

export default Container
