import styled from "styled-components/macro"
import { readableColor } from "polished"
import { Add, CancelPresentation } from "@styled-icons/material-rounded"

export const LeaveIcon = styled(CancelPresentation)`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`

export const InviteIcon = styled(Add)`
  height: 16px;
  width: 16px;
`

export const Avatar = styled.div`
  top: 0;
  left: 0;
  margin-right: 9px;

  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
`

export const MemberUsername = styled.span`
  color: ${({ theme }) => theme.elements.chatText};
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.2px;
  display: inline-flex;
  align-items: center;
`

export const PartyListContainer = styled.div`
  margin-left: 20px;
  margin-bottom: 20px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const ParticipantContainer = styled.div`
  display: flex;
  margin-bottom: 9px;
`

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
  button {
    font-size: 14px;
    line-height: 16px;
  }
  @media (max-width: 1111px) {
    margin-top: 0;
    margin-bottom: 8px;
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.elements.chatText};
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
  @media (max-width: 1111px) {
    font-size: 16px;
    line-height: 18px;
  }
`

export const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  button {
    margin-top: 12px;
    @media (max-width: 1111px) {
      margin-top: 0;
      height: 36px;
      width: 224px;
    }
  }
`
