import styled from "styled-components"
import { readableColor } from "polished"
import { atMediaMin } from "theme/utils"
import Typography from "components/Typography"
import { sectionContainer } from "theme/spacing"

const CarouselSection = styled.div`
  ${sectionContainer}
`

const CarouselTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ArrowContainer = styled.div`
  display: flex;
`

const Arrow = styled.div`
  padding: 8px;
  cursor: pointer;
`

const EmptyArrowContainer = styled.div`
  width: 28px;
  height: 28px;
`

const ClientCarouselRowContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`

const ClientCarouselRow = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  &:after {
    content: "";
    display: block;
    position: relative;
    min-width: 12px;
  }
`

const ClientCarouselItemContainer = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80px;

  ${atMediaMin("md")} {
    width: 120px;
  }

  ${atMediaMin("lg")} {
    width: 160px;
  }
`

const ClientCarouselImageContainer = styled.div`
  height: 80px;
  width: 80px;
  margin-bottom: 8px;

  ${atMediaMin("md")} {
    height: 120px;
    width: 120px;
    margin-bottom: 16px;
  }

  ${atMediaMin("lg")} {
    height: 160px;
    width: 160px;
    margin-bottom: 16px;
  }
`

const ClientCarouselItemImage = styled.img`
  border-radius: 50%;
  margin-bottom: 16px;
  object-fit: cover;
  height: 80px;
  width: 80px;

  ${atMediaMin("md")} {
    height: 120px;
    width: 120px;
  }

  ${atMediaMin("lg")} {
    height: 160px;
    width: 160px;
  }
`

const ClientCarouselShowName = styled(Typography)`
  /* hack to make sure we clip at 2 lines */
  --max-lines: 2;
  --lh: calc(6px * 1.2);

  ${atMediaMin("md")} {
    --lh: calc(10px * 1.2);
  }

  max-height: calc(var(--lh) * var(--max-lines));
  /* modern browser ellipsis */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`

const SectionTitle = styled.div`
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.3px;
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  color: ${({ theme }) => readableColor(theme.colors.elevation1)};
  margin-left: 30px;
  font-size: 24px;

  ${atMediaMin("md")} {
    font-size: 30px;
  }

  ${atMediaMin("lg")} {
    font-size: 36px;
  }
`

export {
  Arrow,
  ArrowContainer,
  CarouselSection,
  CarouselTitleContainer,
  EmptyArrowContainer,
  ClientCarouselRow,
  ClientCarouselRowContainer,
  ClientCarouselImageContainer,
  ClientCarouselItemContainer,
  ClientCarouselItemImage,
  ClientCarouselShowName,
  SectionTitle,
}
