import { useCallback, useEffect, useState, useMemo } from "react"
import Daily, { DailyParticipant, DailyEvent } from "@daily-co/daily-js"

export enum DailyEventCode {
  CAMERA_ERROR = "camera-error",
  CAMERA_STARTED = "started-camera",
  GENERAL_ERROR = "error",
  JOINED_MEETING = "joined-meeting",
  JOINING_MEETING = "joining-meeting",
  LOADED = "loaded",
  LOAD_ATTEMPT_ERROR = "load-attempt-failed",
  NETWORK_CONNECTION = "network-connection",
  PARTICIPANT_JOINED = "participant-joined",
  PARTICIPANT_LEFT = "participant-left",
  PARTICIPANT_UPDATED = "participant-updated",
  ACTIVE_SPEAKER_CHANGE = "active-speaker-change",
  APP_MESSAGE = "app-message",
}

const PARTICPANT_EVENTS: DailyEvent[] = [
  DailyEventCode.PARTICIPANT_JOINED,
  DailyEventCode.PARTICIPANT_LEFT,
  DailyEventCode.PARTICIPANT_UPDATED,
  DailyEventCode.APP_MESSAGE,
]

type DailyEventError = {
  id: string
  message: string
  severity: 1 | 2
}

const useVIPMeet = (micState: boolean, camState: boolean, roomUrl: string, name?: string) => {
  const [participants, setParticipants] = useState<DailyParticipant[]>([])
  const [dailyEventError, setDailyEventError] = useState<string | null>(null)
  const [networkScore, setNetworkScore] = useState("")
  const dailyCall = useMemo(() => Daily.createCallObject(), [])
  const [activeSpeaker, setActiveSpeaker] = useState<string>()
  const [photoTaken, setPhotoTaken] = useState<boolean>(false)
  const createCall = async () => {
    const url = roomUrl.split("?t=")
    await dailyCall.join({
      url: url[0],
      token: url[1],
    })
    dailyCall.setLocalAudio(micState)
    dailyCall.setLocalVideo(camState)
  }

  useEffect(() => {
    if (roomUrl && name) {
      createCall()
    }
  }, [dailyCall, roomUrl, name])

  const logDailyEvent = (dailyCode: DailyEventCode, errorEvent: any) => {
    console.log(`[${dailyCode}]: `, errorEvent)
  }

  const mute = (mic: boolean) => {
    dailyCall.setLocalAudio(mic)
  }

  const video = (cam: boolean) => {
    dailyCall.setLocalVideo(cam)
  }

  const leaveMeet = () => {
    dailyCall.leave()
  }

  const mapEnumToArray = (enumeration: any): DailyEvent[] =>
    Object.keys(enumeration).map((key) => enumeration[key])

  useEffect(() => {
    const handleNetworkCondition = async () => {
      try {
        const condition = await dailyCall.getNetworkStats()
        setNetworkScore(condition.threshold)
      } catch (error) {
        console.log(error?.message)
      }
    }
    const interval = setInterval(handleNetworkCondition, 5000)

    return () => clearInterval(interval)
  }, [dailyCall])

  // Enter call on render

  // Iterate through the partipants in call from dailyCall object
  const handleNewParticipantsState = useCallback(() => {
    const participantList = Object.values(dailyCall.participants())
    if (participantList.length > 0) {
      // Add participants from daily to state
      setParticipants(participantList)
    }
  }, [dailyCall])

  const handleEventEmitters = useCallback(() => {
    dailyCall
      .on(DailyEventCode.CAMERA_ERROR, (cameraErrorEvent) => {
        logDailyEvent(DailyEventCode.CAMERA_ERROR, cameraErrorEvent)
      })
      .on(DailyEventCode.GENERAL_ERROR, (errorEvent) => {
        logDailyEvent(DailyEventCode.GENERAL_ERROR, errorEvent)
        setDailyEventError(errorEvent?.errorMsg || "Error")
      })
      .on(DailyEventCode.LOAD_ATTEMPT_ERROR, (loadAttemptFailedEvent) => {
        logDailyEvent(DailyEventCode.LOAD_ATTEMPT_ERROR, loadAttemptFailedEvent)
      })
      .on(DailyEventCode.NETWORK_CONNECTION, (networkConnectionEvent) => {
        logDailyEvent(DailyEventCode.NETWORK_CONNECTION, networkConnectionEvent)
      })
      .on(DailyEventCode.JOINING_MEETING, () => {
        setDailyEventError(null)
      })
      .on(DailyEventCode.JOINED_MEETING, () => {
        dailyCall.setUserName(`participant,${name}`)
        setDailyEventError(null)
      })
      .on(DailyEventCode.ACTIVE_SPEAKER_CHANGE, (meet) => {
        setActiveSpeaker(meet?.activeSpeaker.peerId)
      })
      .on(DailyEventCode.PARTICIPANT_LEFT, () => {})
  }, [dailyCall])

  const cleanupParticipants = useCallback(() => {
    PARTICPANT_EVENTS.forEach((event) => {
      dailyCall.off(event, handleNewParticipantsState)
    })
  }, [dailyCall, handleNewParticipantsState])

  const cleanupEvents = useCallback(() => {
    const eventValues = mapEnumToArray(DailyEventCode)
    eventValues.forEach((eventValue: DailyEvent) => {
      dailyCall.off(eventValue, handleEventEmitters)
    })
  }, [dailyCall, handleEventEmitters])

  const endCall = async () => {
    setParticipants([])
    await dailyCall.destroy()
  }

  // Participant states
  useEffect(() => {
    if (!dailyCall) return
    // Add new participants
    handleNewParticipantsState()
    // Handle event changes
    PARTICPANT_EVENTS.forEach((event) => {
      dailyCall.on(event, handleNewParticipantsState)
    })

    handleEventEmitters()
    // eslint-disable-next-line consistent-return
    return () => {
      cleanupEvents()
      cleanupParticipants()
      endCall()
    }
  }, [dailyCall, cleanupParticipants, handleNewParticipantsState])
  const handleAppMessage = (event: any) => {
    if (event?.data === "Photo taken") {
      setPhotoTaken(true)
    }
  }

  const resetPhotoTaken = () => {
    setPhotoTaken(false)
  }

  useEffect(() => {
    // Handles app messages from Daily
    dailyCall.on(DailyEventCode.APP_MESSAGE, handleAppMessage)

    return () => {
      dailyCall.off(DailyEventCode.APP_MESSAGE, handleAppMessage)
    }
  }, [dailyCall])
  return {
    participants,
    networkScore,
    mute,
    video,
    leaveMeet,
    activeSpeaker,
    dailyEventError,
    photoTaken,
    resetPhotoTaken,
  }
}

export default useVIPMeet
