import React from "react"
import { Survey, SurveyQuestionType } from "./CobrandSurvey.types"

const surveyConfigs: { [key: string]: Survey } = {
  ivoted: {
    requireAuth: true,
    requireAdmin: false,
    header: (
      <div>
        <p>Welcome to #iVoted Festival!</p>

        <p>
          Access the event below with a selfie from outside your polling place or at home with your
          blank and unmarked ballot. Underage? Access #iVoted Festival by letting us know what
          election you will be 18 for and why you’re excited to vote. Non-citizen or ineligible to
          vote? Access #iVoted Festival below by letting us know which artist you&apos;re most
          excited to check out!
        </p>

        <p>
          #iVoted Festival also re-broadcasts for 24 hours following Election Day in case you are
          voting last minute or stuck in line voting. This also ensures you’re able to check out ALL
          of the artists you want! AND gives you time to spread the word. Share this link to invite
          your friends and celebrate voting together. Happy Election Day!
        </p>
      </div>
    ),
    buttonText: "Continue to #iVoted",
    questions: [
      {
        id: "email",
        label: <>Email Address</>,
        type: SurveyQuestionType.singleLineText,
        required: true,
        default: (user) => user?.email || "",
      },
      {
        id: "first_name",
        label: <>First Name</>,
        type: SurveyQuestionType.singleLineText,
        required: true,
        default: (user) => {
          const nameParts = user?.name?.split(/\s+/)
          if (nameParts) return nameParts[0]
          return ""
        },
      },
      {
        id: "last_name",
        label: <>Last Name</>,
        type: SurveyQuestionType.singleLineText,
        required: true,
        default: (user) => {
          const nameParts = user?.name?.split(/\s+/)
          if (nameParts && nameParts.length > 1) return nameParts[nameParts.length - 1]
          return ""
        },
      },
      {
        id: "birthdate",
        label: <>Birthdate</>,
        type: SurveyQuestionType.date,
        required: true,
      },
      {
        id: "zip_code",
        label: <>Zip Code</>,
        type: SurveyQuestionType.singleLineText,
        required: true,
        default: (user) => user?.zipCode || "",
      },
      {
        id: "voting_history",
        label: <>Did you vote in the 2018, 2020, and 2022 elections? Check all that apply.</>,
        type: SurveyQuestionType.multipleChoice,
        options: ["2018", "2020", "2022"],
      },
      {
        id: "selfie",
        label: (
          <>
            Upload a photo from outside your nearest polling place or at home with a blank and
            unmarked ballot below.
            <br />
            <br />
            In AZ you must be 75 feet from your polling place; in TX you must be 100 feet from your
            polling place. Don't know how far these distances are? Walk a block! Each state or local
            jurisdiction may have their own rules, please make sure to comply with any applicable
            restrictions at your polling place.
            <br />
            <br />
            By submitting your photo, you grant your permission for your likeness to be included in
            Project I Voted, Inc. social media posts, websites, and/or promotional materials without
            compensation, credit, or other consideration.
          </>
        ),
        type: SurveyQuestionType.photoUpload,
      },
      {
        id: "notes",
        label: (
          <>
            Under 18? Let us know what election you will be 18 for and why you're excited to vote.
            <br />
            <br />
            Not a U.S. Resident or ineligible to vote? Access #iVoted Festival 2022 by letting us
            know which artist you’re most excited to check out.
          </>
        ),
        type: SurveyQuestionType.multiLineText,
      },
    ],
  },
}

export default surveyConfigs
