import styled from "styled-components/macro"

const WatchContainer = styled.div`
  justify-content: flex-end;
  padding-top: 0;
  width: 100%;
  position: absolute;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  color: #ffffff;
`

const WatchBody = styled.div`
  background: #070716;
  position: relative;
  transition: 0.2s ease width;
  width: ${(props) => (props.showDesktopChat ? "calc(100% - 360px)" : "100%")};
  @media (max-width: 1111px) {
    width: 100%;
  }
`

const ChatToggle = styled.div`
  height: 24px;
  width: 24px;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  svg {
    color: #ffffff;
    height: 20px;
    width: 20px;
    transition: 0.1s ease transform;
    transform: ${(props) => (props.showDesktopChat ? "rotate(0deg)" : "rotate(180deg)")};
  }
  @media (max-width: 1111px) {
    display: none;
  }
`

export { WatchContainer, WatchBody, ChatToggle }
