import styled from "styled-components/macro"
import { Close } from "@styled-icons/material/"
import { Smile } from "@styled-icons/boxicons-regular"
import { darken, readableColor } from "polished"

const InputOuter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  padding: 24px;
`

const InputContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.elements.chatText};
  border-radius: 20px;
  display: flex;
  transition: 0.2s border-color ease;
  position: relative;

  &:focus-within {
    border-color: ${({ theme }) => theme.elements.buttonBackground || theme.colors.primarySolid};
  }

  @media (min-width: 1111px) {
    display: ${(props) => (props.showDesktopChat ? "flex" : "none")};
  }
`

const Input = styled.input`
  width: calc(100% - 75px);
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.elements.chatText};
  font-size: 12px;
  padding: 10px 20px;

  &::placeholder {
    color: ${({ theme }) => theme.elements.chatText};
    opacity: 0.6;
  }

  &:active,
  &:focus {
    outline: none;
  }
`

const SubmitIcon = styled.svg`
  position: absolute;
  right: 20px;
  fill: ${({ theme }) => theme.elements.buttonBackground || theme.colors.primarySolid};
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: ${(props) => (props.hasText ? "pointer" : "normal")};
  height: 22px;
  width: 22px;
`

const ProfaneContainer = styled.div`
  text-align: center;
  padding: 13px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.error};
  position: relative;
`
const ProfaneHeader = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  color: ${({ theme }) => theme.elements.chatText};
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 4px;
`

const ProfaneMessage = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProRegular};
  color: ${({ theme }) => theme.elements.chatText};
  font-size: 14px;
  line-height: 21px;
`

const ProfaneClear = styled.button`
  ${({ theme }) => theme.fonts.TTNormsProRegular};
  color: ${({ theme }) => theme.elements.buttonBackground || theme.colors.primarySolid};
  border: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 5px;
  outline: 0;
  background: none;
  transition: 0.3s;

  &:focus,
  &:hover,
  &:active {
    outline: 0;
    border: none;
    text-decoration: underline;
  }
`

const CloseWindow = styled(Close)`
  position: absolute;
  left: 15px;
  top: 15px;
  width: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.elements.buttonText};
`

const EmojiContainer = styled.div`
  span {
    .emoji-mart {
      bottom: 40px;
      display: block;
      position: absolute;
      right: 0px;
      width: 338px;
      z-index: 2;
    }
  }
`

const EmojiIcon = styled(Smile)`
  position: absolute;
  right: 50px;
  height: 22px;
  width: 22px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: ${(props) => (props.isBannedFromChatroom ? "normal" : "pointer")};
  fill: ${({ theme }) => theme.elements.chatText};
  @media (max-width: 1111px) {
    display: none;
  }
`

export {
  CloseWindow,
  EmojiContainer,
  EmojiIcon,
  ProfaneHeader,
  ProfaneMessage,
  ProfaneClear,
  ProfaneContainer,
  InputContainer,
  Input,
  SubmitIcon,
  InputOuter,
}
