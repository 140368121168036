import styled from "styled-components/macro"
import { rgba, invert } from "polished"
import { Button } from "components/shared"
import Typography from "components/Typography"
import { atMediaMin } from "theme"
import { atMediaMax } from "theme/utils"
import { Login } from "styled-icons/material-rounded"
import { Link } from "react-router-dom"
import { sectionContainer, sectionSpacing } from "theme/spacing"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  ${sectionContainer};
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 100%;
  ${atMediaMin("md")} {
    padding-top: ${sectionSpacing.xs}px;
    padding-bottom: ${sectionSpacing.xs}px;
  }
  background-color: ${({ theme }) => rgba(invert(theme.elements.pageBackground), 0.05)};
`

const ArtistImageContainer = styled.div`
  height: 100%;
  min-width: 96px;
  border-radius: 16px;
  margin-right: 16px;
  overflow: hidden;

  ${atMediaMin("sm")} {
    min-width: 156px;
    max-width: 156px;
  }

  ${atMediaMin("md")} {
    min-width: 262px;
    max-width: 262px;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ArtistImage = styled.img`
  height: 100%;
`

const EnterShowLink = styled(Link)`
  text-decoration: none !important;
`

const EnterShowButton = styled(Button)`
  width: 132px;
  font-size: 12px;

  ${atMediaMax("sm")} {
    padding: 16px;
    height: 0;
  }

  ${atMediaMin("md")} {
    width: 160px;
    font-size: 16px;
  }
`

const ButtonIcon = styled(Login)`
  margin-right: 8px;
  height: 16px;
  width: 16px;
`

const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50vw;

  ${atMediaMin("md")} {
    max-width: 1000px;
    overflow: hidden;
  }
`

const LiveShowText = styled(Typography)`
  font-size: 10px;

  ${atMediaMin("sm")} {
    font-size: 13px;
  }
`

const ShowText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${atMediaMax("sm")} {
    font-size: 12px;
  }
`

export {
  Container,
  ArtistImageContainer,
  ArtistImage,
  BannerTextContainer,
  EnterShowLink,
  EnterShowButton,
  ButtonIcon,
  LiveShowText,
  ShowText,
}
