import styled, { css } from "styled-components/macro"
import Typography from "components/Typography"
import { atMediaMin } from "theme/utils"
import { Link } from "react-router-dom"
import { readableColor } from "polished"
import ImageLoader from "components/ImageLoader"

type TicketProps = {
  featured?: boolean | number
  cobrand?: any
}

type CobrandHeaderProps = {
  headerColor: string
}

enum TicketWidth {
  MOBILE = "220px",
  TABLET = "240px",
  FEATURED_MOBILE = "220px",
  FEATURED_TABLET = "320px",
}

const BACKGROUND_COLOR = css`
  ${({ theme }) =>
    readableColor(theme.colors.elevation7, "rgba(255,255,255,0.7)", "rgba(0,0,0,0.2)", false)}
`

const LinkContainer = styled(Link)<TicketProps>`
  display: block;
  clip-path: url(#ticketNotches);
  transition: transform 0.2s ease-in-out;
  will-change: transform;
  width: 100%;
  min-width: 100%;
  &:hover,
  &:focus {
    transform: translate3d(0, -6px, 0);
    text-decoration: none;
  }
  @media (prefers-reduced-motion) {
    transition: none !important;
  }

  ${atMediaMin("md")} {
    width: ${({ featured }) => (featured ? TicketWidth.FEATURED_TABLET : TicketWidth.TABLET)};
    min-width: ${({ featured }) => (featured ? TicketWidth.FEATURED_TABLET : TicketWidth.TABLET)};
  }
`

const TicketContainer = styled.figure`
  display: flex;
  flex-direction: column;
`

const HeaderPhotoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: rgba(196, 196, 196, 0.2);
`

const CobrandHeader = styled.div<CobrandHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ headerColor }) => headerColor};
  height: 50px;
`

const CobrandHeaderImage = styled.img`
  height: 30px;
  margin-top: 5px;
`

const HeaderPhoto = styled(ImageLoader)<TicketProps>`
  height: ${({ cobrand }) => (cobrand ? 160 : 190)}px;
  border-top-right-radius: ${({ cobrand }) => (cobrand ? "0" : "12")}px;
  border-top-left-radius: ${({ cobrand }) => (cobrand ? "0" : "12")}px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  object-fit: cover;

  ${atMediaMin("md")} {
    height: ${({ featured, cobrand }) => {
      if (featured && !cobrand) {
        return "220px"
      }

      if (featured && cobrand) {
        return "170px"
      }

      if (!featured && cobrand) {
        return "140px"
      }

      return "190px"
    }};
  }
`

const MandolinLogoContainer = styled.div<TicketProps>`
  position: absolute;
  top: 10px;
  right: 15px;

  ${atMediaMin("md")} {
    top: ${({ featured }) => (featured ? "25px" : "20px")};
    right: 20px;
  }
`

const InvisibleDivider = styled.div`
  width: 100%;
`

const VIPTagContainer = styled.div<TicketProps>`
  position: absolute;
  border-radius: 50%;
  margin-top: -20px;
  margin-left: 18px;

  ${atMediaMin("md")} {
    margin-left: 20px;
  }
`

const ShowDetailContainer = styled.figcaption<TicketProps>`
  display: flex;
  flex-direction: column;
  background-color: ${BACKGROUND_COLOR};
  height: 170px;
  padding: 28px 18px;
  padding-bottom: 14px;

  ${atMediaMin("md")} {
    height: ${({ featured }) => (featured ? "230px" : "166px")};
    padding: 30px 20px 20px;
    h3 {
      font-size: 24px !important;
    }
  }
`

const ShowTitle = styled(Typography)<TicketProps>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #000000;

  ${atMediaMin("md")} {
    font-size: ${({ featured }) => (featured ? "2rem" : "1.75rem")};
  }
`

const VenueTitle = styled(Typography)<TicketProps>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #000000;

  ${atMediaMin("md")} {
    font-size: ${({ featured }) => (featured ? "1rem" : "0.75rem")};
  }
`

const TicketDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
`

const TicketDivider = styled.div`
  background-color: ${BACKGROUND_COLOR};
  border-width: 0.5px;
  border-style: dashed;
  border-color: black;
  display: flex;
  justify-content: space-between;
  height: 0;
`

const TicketBottom = styled.div<TicketProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: ${({ cobrand }) => (cobrand ? "66" : "61")}px;
  background-color: ${BACKGROUND_COLOR};
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  ${atMediaMin("md")} {
    height: ${({ featured }) => (featured ? "61px" : "60px")};
    padding: ${({ featured }) => (featured ? "0px 16px" : "16px")};
  }
`

const TicketStubText = styled.figcaption`
  display: flex;
  flex-direction: column;
`

const TicketStubLabel = styled(Typography)`
  color: ${({ theme }) => readableColor(theme.colors.elevation7, "#646567", "#394249", false)};
  font-size: 0.65rem;
  letter-spacing: 0.5px;

  ${atMediaMin("md")} {
    font-size: 0.75rem;
  }
`

const TicketStubDateTime = styled(Typography)`
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  line-height: 1.2;

  ${atMediaMin("md")} {
    font-size: 0.75rem;
  }
`

const TransferContainer = styled.div`
  transition: 0.3s;
  align-items: center;
  position: relative;
  display: flex;
  color: #000000;
  opacity: 0.5;

  &:hover {
    svg {
      right: -20px;
    }
  }

  svg {
    transition: 0.3s;
    position: absolute;
    right: -16px;
    color: #000000;
  }
`

const TicketCodeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-right: 8px;
`

const TicketCount = styled.div`
  align-items: center;
  color: #000000;
  display: flex;
  font-size: 14px;
  letter-spacing: 0.16em;
  opacity: 0.5;
  text-transform: uppercase;
`

export {
  CobrandHeader,
  CobrandHeaderImage,
  HeaderPhoto,
  HeaderPhotoContainer,
  InvisibleDivider,
  LinkContainer,
  MandolinLogoContainer,
  ShowDetailContainer,
  ShowTitle,
  TicketBottom,
  TicketCodeContainer,
  TicketContainer,
  TicketCount,
  TicketDetails,
  TicketDivider,
  TicketStubDateTime,
  TicketStubLabel,
  TicketStubText,
  TransferContainer,
  VIPTagContainer,
  VenueTitle,
}
