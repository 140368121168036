import styled from "styled-components/macro"

export const VIPLayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  background: Linear-gradient(to right, #2f3b40, #35383e, #3d3636, #403133, #3f3b3c);
  overflow: hidden;
  position: relative;
`
export const VIPMainPanel = styled.div<{ showChatPanel: boolean }>`
  display: flex;
  transition: 0.2s width ease;
  @media (min-width: 740px) {
    width: ${({ showChatPanel }) => (showChatPanel ? "calc(100% - 300px)" : "100%")};
  }
  position: relative;
  flex: 1;
`
export const VIPContentContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
`
export const VIPSidePanel = styled.div<{ showChatPanel: boolean }>`
  display: flex;
  flex-direction: column;
  transition: 0.2s all ease;
  width: 300px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(${({ showChatPanel }) => (showChatPanel ? "0" : "100%")});
  z-index: 10;
  @media (max-width: 740px) {
    display: none;
  }
`
