import styled from "styled-components/macro"

const Container = styled.a`
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.img});
  background-color: rgba(22, 22, 22, 0.5);
  background-blend-mode: ${({ hasTextOverlay }) => (hasTextOverlay ? "overlay" : "unset")};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 10px;
  &:hover {
    color: unset;
    text-decoration: none;
  }
`
const Text = styled.div`
  color: rgb(255, 255, 255);
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
`

const Items = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
`

const ShopifyContainer = styled.div`
  position: relative;
  .shopify-buy-button {
    height: 100%;
    width: 100%;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
  }
  .shopify-product {
    height: inherit;
    width: inherit;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
`

export { Container, Text, Items, ShopifyContainer }
