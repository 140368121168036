import styled from "styled-components/macro"
import { sectionSpacing } from "theme/spacing"
import { toRem, atMediaMin } from "theme/utils"

const NavTabs = styled.div`
  height: 100%;
  width: 90px;
  margin-right: 90px;
  display: flex;
  justify-content: unset;
  flex-direction: column;
  padding: 0;

  @media (max-width: 1111px) {
    flex-direction: row;
    justify-content: space-evenly;
    height: 50px;
    width: 100%;
    padding: 0 2rem;
    margin-right: 0;
    border-bottom: 1px solid rgb(54, 54, 54);
  }
`

const TabTitle = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 12px;
  letter-spacing: 0.01em;
`

const TabBorder = styled.div`
  height: 2px;
  background: ${({ theme }) => theme.elements.pageText};
  width: 100%;
  position: absolute;
  bottom: -1px;
`

const NavTab = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  ${TabTitle} {
    color: ${({ theme }) => theme.elements.pageText};
    opacity: ${({ active }) => active ? 1 : 0.5};
  }

  ${TabBorder} {
    opacity: ${({ active }) => active ? 1 : 0};
  }

  &:hover {
    ${TabTitle} {
      opacity: ${({ active }) => active ? 1 : 0.7};
    }
  }

  margin-bottom: 35px;
  @media (max-width: 1111px) {
    margin-bottom: 0;
  }
`

const NavBody = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  @media (max-width: 1111px) {
    height: 100%;
    padding: 1rem 2rem;
  }
`

const GenericSectionContainer = styled.div`
  padding-bottom: ${toRem(60)};
  max-width: 100% !important;
  display: ${({ showMobileChat }) => (showMobileChat ? "none" : "block")};
  section {
    margin: 0;
    max-width: 100%;
  }
  ${atMediaMin("1112px")} {
    padding-bottom: ${toRem(80)};
    display: block;
  }
`

export { NavTabs, NavTab, TabTitle, TabBorder, NavBody, GenericSectionContainer }
