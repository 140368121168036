/* eslint-disable no-param-reassign  */
import { createSlice } from "@reduxjs/toolkit"
import { firestore, functions } from "../firebaseApp"
import { trackWPCreate, trackWPStart } from "../services/analytics"
import { setCurrentTab } from "./show"

export const CONTEXTS = {
  CHAT: 0,
  SMS_INVITE: 1,
  SETTINGS: 2,
  INVITE_METHODS: 3,
}

const initialState = {
  hasWatchParty: false,
  id: null,
  roomKey: 0,
  watchPartyInvites: [],
  warning: null,
  isLoading: false,
  name: "",
  removedFromWatchParty: false,
  panelContext: CONTEXTS.CHAT,
}

const watchParty = createSlice({
  name: "watchParty",
  initialState,
  reducers: {
    setHasWatchParty(state, action) {
      return {
        ...state,
        ...action.payload,
        hasWatchParty: true,
      }
    },
    joinedWatchParty(state, action) {
      state.hasWatchParty = true
      state.roomKey = action.payload.roomKey
      state.name = action.payload.name
    },
    setRoomKey(state, action) {
      state.roomKey = action.payload
    },
    setChatroomId(state, action) {
      state.id = action.payload
    },
    addInvites(state, action) {
      state.watchPartyInvites = [...state.watchPartyInvites, ...action.payload]
    },
    setWarning(state, action) {
      state.warning = action.payload
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setWatchPartyName(state, action) {
      state.name = action.payload
    },
    setLeftWatchParty(state) {
      state.hasWatchParty = false
      state.roomKey = 0
      state.id = 0
      state.name = ""
    },
    removedFromWatchParty(state) {
      state.hasWatchParty = false
      state.removedFromWatchParty = true
      state.roomKey = 0
      state.id = 0
    },
    setRemovedFromWatchParty(state, action) {
      state.removedFromWatchParty = action.payload
    },
    setContext(state, action) {
      state.panelContext = action.payload
    },
  },
})

const { actions, reducer } = watchParty

export const {
  joinedWatchParty,
  setLeftWatchParty,
  setIsLoading,
  setRoomKey,
  setChatroomId,
  addInvites,
  setWarning,
  setWatchPartyName,
  setHasWatchParty,
  removedFromWatchParty,
  setRemovedFromWatchParty,
  setContext,
} = actions

export const setPanelContext = (context) => async (dispatch, state) => {
  dispatch(setContext(context))
}

export const changeWatchPartyName = (name) => async (dispatch, state) => {
  const {
    watchParty: { id },
  } = state()
  dispatch(setWatchPartyName(name))
  const chatroomRef = firestore.collection("chatRooms").doc(id)
  chatroomRef.update({
    name,
  })
}

export const leaveWatchParty = () => async (dispatch, state) => {
  const {
    watchParty: { id, showId },
  } = state()
  dispatch(setLeftWatchParty())
  const callLeaveWatchParty = functions.httpsCallable("leaveWatchParty")
  try {
    await callLeaveWatchParty({ chatroomId: id, showId })
    // analytics.logEvent("watch_party_left")
  } catch (e) {
    console.log(e.message)
    setWarning(e.message)
  }
}
export const joinWatchParty = async (key, showId) => {
  try {
    const joinParty = functions.httpsCallable("joinWatchParty")
    await joinParty({ roomKey: key, showId })
  } catch (error) {
    console.log(error.message)
    throw error
  }
}

export const attemptCreateWatchParty = (roomKey, showId, name) => async (dispatch, state) => {
  const {
    show: {
      name: showName,
      state: showState,
      venue: { name: venueName }
    },
    watchParty: { id, loading },
  } = state()
  trackWPCreate(id, showId, showName, showState, venueName)
  if (loading) return
  dispatch(setWarning(""))
  await dispatch(setIsLoading(true))
  try {
    const callCreateWatchParty = functions.httpsCallable("createWatchParty")
    const callJoinWatchParty = functions.httpsCallable("joinWatchParty")
    const created = (await callCreateWatchParty({ roomKey, name })).data
    const joined = (await callJoinWatchParty({ roomKey, showId })).data

    if (created.success && joined.success) {
      dispatch(joinedWatchParty(joined.chatroom))
      dispatch(setCurrentTab("WATCHPARTY"))
      // analytics.logEvent("watch_party_created")
    } else {
      // this shouldn't even be possible as the only time
      //   the cloud functions return instead of throw is on success
      setWarning("Unexpected error creating or joining watch party.")
    }
  } catch (e) {
    console.log(e.message)
    setWarning(e.message)
  }
  dispatch(setWatchPartyName(name))
  dispatch(setIsLoading(false))
}

export const attemptJoinWatchParty = (inputCode, showId, url = false) => async (
  dispatch,
  state,
) => {
  const {
    watchParty: { loading },
  } = state()

  let chatroom = null

  if (loading) return
  dispatch(setWarning(""))
  await dispatch(setIsLoading(true))
  try {
    const callJoinWatchParty = functions.httpsCallable("joinWatchParty")
    const { data } = await callJoinWatchParty({ roomKey: inputCode, showId })
    chatroom = data.chatroom

    if (inputCode === chatroom.roomKey) {
      dispatch(joinedWatchParty(chatroom))
      dispatch(setCurrentTab("WATCHPARTY"))
      if (url) {
        // analytics.logEvent("watch_party_joined_url")
      } else {
        // analytics.logEvent("watch_party_joined_key")
      }
    } else {
      dispatch(setWarning("Oops, looks like that Key isn't right!"))
    }
  } catch (e) {
    dispatch(setWarning(e.message))
  }

  dispatch(setIsLoading(false))
}

export const attemptInitializeWatchParty = (showId) => async (dispatch, state) => {
  const {
    show: {
      name: showName,
      state: showState,
      venue: { name: venueName }
    },
    watchParty: { loading },
  } = state()
  trackWPStart(showId, showName, showState, venueName)
  let roomKey = 0
  let id
  if (loading) return roomKey
  dispatch(setWarning(""))
  await dispatch(setIsLoading(true))
  try {
    const callInitializeWatchParty = functions.httpsCallable("initializeWatchParty")
    const { data } = await callInitializeWatchParty({ showId, hostname: window.location.hostname })
    roomKey = data.roomKey
    id = data.chatroomId
    // analytics.logEvent("watch_party_initialized")
  } catch (e) {
    console.log(e.message)
    setWarning(e.message)
  }

  if (roomKey) dispatch(setRoomKey(roomKey))
  if (id) dispatch(setChatroomId(id))
  dispatch(setIsLoading(false))
  return roomKey
}

export const sendWatchPartyInvitesSMS = (
  hostName,
  watchDomain,
  dateTime,
  recipients,
) => async (dispatch, state) => {
  const {
    watchParty: { id: chatroomId, roomKey },
    show: { name: showName },
  } = state()

  let inviteIds, sent, unsent
  const messageBody = `${hostName} has invited you to a Watch Party for the ${showName} livestream, presented\
 by Mandolin on ${dateTime.date} at ${dateTime.time}! Join the party using ${roomKey} as the watch party key. \nJoin here: `

  dispatch(setWarning(""))
  await dispatch(setIsLoading(true))
  try {
    const callSendWatchPartyInvitesSMS = functions.httpsCallable("sendWatchPartyInvitesSMS")
    const { data } = await callSendWatchPartyInvitesSMS({
      recipients,
      watchDomain,
      chatroomId,
      messageBody,
      hostname: window.location.hostname,
    })
    inviteIds = data.inviteIds
    sent = data.sent
    unsent = data.unsent
    // analytics.logEvent("watch_party_invites_sent")
  } catch (e) {
    console.log(e.message)
    setWarning(e.message)
  }

  if (inviteIds) dispatch(addInvites(inviteIds))
  dispatch(setIsLoading(false))
  return inviteIds
}

export const acceptWatchPartyInvite = (inviteCode) => async (dispatch, state) => {
  let invite
  dispatch(setWarning(""))
  await dispatch(setIsLoading(true))
  try {
    const callAcceptWatchPartyInvite = functions.httpsCallable("acceptWatchPartyInvite")
    const { data } = await callAcceptWatchPartyInvite({ inviteCode })
    invite = data.invite
    // analytics.logEvent("watch_party_invite_accepted")
  } catch (e) {
    console.log(e.message)
    setWarning(e.message)
  }
  dispatch(setIsLoading(false))
  return invite
}

export default reducer
