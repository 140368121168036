import styled from "styled-components/macro"

const ArtistName = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  background-image: ${({ theme }) => theme.colors.primaryGradient};
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  letter-spacing: -0.5px;
  font-size: 36px;
  line-height: 44px;
  @media (max-width: 1111px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const ArtistMessage = styled.div`
  color: ${({ theme }) => theme.colors.elevation6};
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: 16px;
  line-height: 21px;
  margin-top: 10px;
  @media (max-width: 1111px) {
    display: none;
  }
`

const Avatar = styled.img`
  height: 128px;
  width: 128px;
  border-radius: 50%;
  object-fit: cover;
  @media (max-width: 1111px) {
    display: ${({ withInfo }) => (withInfo ? "none" : "block")};
    min-height: 50px;
    min-width: 50px;
    height: auto;
    width: 80px;
    max-width: 70%;
  }
`

const Body = styled.div`
  margin-right: 200px;
  display: flex;
  flex-direction: column;
  margin-left: 2rem;

  h1 {
    font-size: 2rem;
  }

  @media (max-width: 1111px) {
    margin-left: 1rem;
  }
`

const Container = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  margin: 3rem;
  margin-bottom: 0;
  padding-bottom: 40px;
  @media (max-width: 1111px) {
    margin: 0;
    padding: 1rem;
  }
`

const TitleImage = styled.img`
  max-width: 500px;
  object-fit: contain;
  object-position: center left;
  margin-left: 2rem;
  @media (max-width: 1111px) {
    margin-left: 0;
    padding-left: 24px;
    max-width: 80%;
    max-height: 50px;
  }
`

const ExpandText = styled.span`
  color: ${({ theme }) => theme.colors.elevation8};
  margin-left: 4px;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
`

const PresentedBy = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold};
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colors.elevation6};
`

export { ExpandText, Avatar, ArtistMessage, ArtistName, TitleImage, Body, Container, PresentedBy }
