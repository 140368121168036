import React from "react"
import { ThemeProvider, createGlobalStyle, css, keyframes } from "styled-components/macro"
import { rgba } from "polished"

const skeletonAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`

const skeletonStyles = css`
  background: linear-gradient(
    90deg,
    ${rgba("#58586A", 0.2)},
    ${rgba("#58586A", 0.4)},
    ${rgba("#58586A", 0.2)}
  );
  background-size: 300%;
  animation: ${skeletonAnimation} 1.2s ease-in infinite;
`

const GlobalStyle = createGlobalStyle`
    body {
      background: #070716;
      color: #ffffff;
    }
    html,
    body, 
    #root {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  `

const Provider = ({ children }) => {
  const theme = {
    breakpoint: "max-width: 600px",
    transition: "0.2s cubic-bezier(0.4, 0, 0.3, 1)",
    skeletonLoad: skeletonStyles,
    colors: {
      card: rgba("#ffffff", 0.15),
    },
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default Provider
