import { ExpandMore } from "@styled-icons/material-rounded"
import styled, { css, keyframes } from "styled-components/macro"
import { fadeIn } from "../Card.styled"

export const fadeOut = keyframes`
    from { opacity: 1; }
    to   { opacity: 0; }
`
interface BidContainerProps {
  visible: boolean
}

export const BidContainer = styled.div<BidContainerProps>`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`

export const BidFrontFace = styled(BidContainer)`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 4rem auto;
  ${(props) =>
    props.visible &&
    css`
      animation: ${fadeIn} 0.8s both;
    `};
`

export const BidBackFace = styled(BidContainer)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 38% 62%;
  grid-template-areas:
    "header"
    "main";
  border-radius: 1rem;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${(props) =>
    props.visible &&
    css`
      animation: ${fadeIn} 0.8s both;
    `};
`

export const BidBackFaceHeader = styled.div`
  padding: 1.5rem;
  grid-area: header;
  border-radius: 1rem 1rem 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.94) 100%);
`

export const TimerContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const BiddingContent = styled.div`
  background-color: #ffffff;
  padding: 1.5rem 1.5rem 0.75rem 1.5rem;
  grid-area: main;
  display: grid;
  grid-gap: 0.5rem;
  border-radius: 0 0 1rem 1rem;
  hr {
    border: 1px solid #eff2f3;
    width: 100%;
  }
`
export const CurrentBidInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const BidPrice = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
`
export const PlaceBid = styled.div`
  ${({ theme }) => theme.colors.elevation1};
  font-size: 1rem;
`

export const HighBid = styled.div`
  font-size: 1rem;
  color: #3fd27a;
`

export const OutBid = styled.div`
  font-size: 1rem;
  color: #ff766d; ;
`

export const GhostButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const GhostButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
`

export const ExpandMoreIcon = styled(ExpandMore)`
  width: 20px;
  height: 20px;
  color: #394249;
`
