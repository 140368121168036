import { tint } from "polished"
import styled, { css } from "styled-components/macro"

const ArtistName = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProExtraBold};
  background-image: ${({ theme }) => theme.colors.primaryGradient};
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  ${({ theme }) =>
    theme.elements.pageTextAccent &&
    css`
      background: none;
      -webkit-background-clip: unset;
      -moz-background-clip: unset;
      -webkit-text-fill-color: unset;
      -moz-text-fill-color: unset;
      color: ${theme.elements.pageTextAccent};
    `}
  letter-spacing: -0.5px;
  font-size: 36px;
  line-height: 44px;
  @media (max-width: 1111px) {
    font-size: 18px;
    line-height: 18px;
  }
`

const ArtistMessage = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: 16px;
  line-height: 21px;
  margin-top: 10px;
  @media (max-width: 1111px) {
    display: none;
  }
`

const Avatar = styled.div`
  img {
    height: 128px;
    width: 128px;
    border-radius: 50%;
    object-fit: cover;
    @media (max-width: 1111px) {
      display: none;
    }
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  @media (max-width: 1111px) {
    margin-left: 1rem;
    margin-right: 200px;
  }
`

const Container = styled.div`
  position: relative;
  color: ${({ theme }) => theme.elements.pageText};
  display: flex;
  margin: 36px;
  @media (max-width: 1111px) {
    margin: 16px;
  }
`

const PresentedBy = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProDemiBold};
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 6px;
`

const ExpandText = styled.span`
  margin-left: 4px;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
`

const ShareButton = styled.button<{ copied: boolean }>`
  white-space: nowrap;
  transition: all 0.2s;
  background: ${({ theme }) => theme.elements.buttonBackground || theme.colors.primaryGradient};
  width: ${({ copied }) => (copied ? "196px" : "96px")};
  color: ${({ theme }) => theme.elements.starterButtonText || theme.elements.buttonText};
  border: none;
  border-radius: 6px;
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 0;
  height: 24px;
  line-height: 24px;
  padding-left: 6px;
  padding-right: 6px;

  &:hover {
    outline: 0;
    opacity: 0.8;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  > svg {
    margin-left: 8px;
    margin-bottom: 2px;
  }
`
const ScheduleButton = styled.button<{ shareCopied: boolean }>`
  white-space: nowrap;
  transition: all 0.2s;
  background: ${({ theme }) => theme.elements.buttonBackground || theme.colors.primaryGradient};
  height: 30px;
  width: 150px;
  color: ${({ theme }) => theme.elements.starterButtonText || theme.elements.buttonText};
  border: none;
  border-radius: 6px;
  font-size: 12px;
  position: absolute;
  right: ${({ shareCopied }) => (shareCopied ? "226px" : "126px")};
  top: 0;
  height: 24px;
  line-height: 24px;
  padding-left: 6px;
  padding-right: 6px;
`
const ShareInput = styled.input`
  color: #070716;
  background: #070716;
  border: none;
  position: absolute;
  left: -9999px;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
`

export {
  Avatar,
  ArtistMessage,
  ArtistName,
  Body,
  Container,
  PresentedBy,
  ExpandText,
  ShareButton,
  ShareInput,
  ScheduleButton,
}
