import React, { memo } from "react"
import { FieldBorder, FieldInternal, FieldWrapper, InputContainer, InputElement, InputLabelWrapper, LabelContent, LabelElement } from "./AXSProfileModal.styled"

const InputField = ({ label, value = "", onChange }) => (
  <FieldWrapper>
    <FieldBorder>
      <FieldInternal>
        <InputContainer>
          <InputLabelWrapper>
            <LabelElement>
              <LabelContent>{label}</LabelContent>
            </LabelElement>
          </InputLabelWrapper>
          <InputElement type="text" value={value} onChange={({ target }) => onChange(target.value)} />
        </InputContainer>
      </FieldInternal>
    </FieldBorder>
  </FieldWrapper>
)

export default memo(InputField)
