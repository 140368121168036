import styled, { css } from "styled-components"
import { sectionSpacing } from "theme/spacing"
import { atMediaMin, toRem } from "theme/utils"
import { SectionSpacingOptions } from "."

const getValue = (val: keyof typeof sectionSpacing | number) => {
  if (typeof val === "number") {
    return toRem(val)
  }

  return toRem(sectionSpacing[val])
}

const buildStyles = (spacing: any) => {
  if (!spacing) return null
  return css`
    ${Object.keys(spacing).map((key: string) => {
      const spaceType = ["top", "bottom"].includes(key)
      return `${spaceType ? "margin" : "padding"}-${key}: ${getValue(spacing[key])} !important;`
    })}
  `
}

export default styled.section<{
  fullWidth?: boolean
  spacing?: SectionSpacingOptions
  smSpacing?: SectionSpacingOptions
  mdSpacing?: SectionSpacingOptions
  lgSpacing?: SectionSpacingOptions
  xlSpacing?: SectionSpacingOptions
}>`
  max-width: ${({ fullWidth }) => (fullWidth ? "auto" : toRem(1440))};
  ${({ spacing }) => buildStyles(spacing)} ${atMediaMin("sm")} {
    ${({ smSpacing }) => buildStyles(smSpacing)}
  }

  ${atMediaMin("md")} {
    ${({ mdSpacing }) => buildStyles(mdSpacing)}
  }
  ${atMediaMin("lg")} {
    ${({ lgSpacing }) => buildStyles(lgSpacing)}
  }
  ${atMediaMin("xl")} {
    ${({ xlSpacing }) => buildStyles(xlSpacing)}
  }
`
