import styled from "styled-components/macro"
import { Modal } from "react-bootstrap"
import { darken } from "polished"
// eslint-disable-next-line import/no-extraneous-dependencies
import { Close } from "@styled-icons/material/"

export const ModalContainer = styled(Modal)`
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-dialog {
    display: flex;
    height: 66%;
    justify-content: center;
    align-items: center;
  }
`
export const ModalContent = styled.div`
  width: 90%;
  padding: 2% 6%;
  padding-bottom: 5%;
  padding-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const CloseWindow = styled(Close)`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 15px;
  cursor: pointer;
  color: #000000;

  &:hover {
    color: #dcdcdc;
  }
`

export const OnboardText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: black;
  font-size: 20px;
  line-height: 23px;
  margin: 50px 0;
  margin-top: 0.75rem;
`

export const AnchorSubtext = styled.span`
  color: black;
  margin-bottom: 0.75rem;
`
