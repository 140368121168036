import styled, { keyframes } from "styled-components/macro"

const slideInFromBottom = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`

const slideOutFromBottom = keyframes`
  100% {
    transform: translateY(100%);
  }
  0% {
    transform: translateY(0);
  }
`

export const Container = styled.div<{ hide: boolean }>`
  background: ${({ theme }) => theme.colors.primaryGradient};
  display: flex;
  flex-direction: row;
  @media (max-width: 1111px) {
    flex-direction: column;
  }
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  z-index: 1000;
  color: ${({ theme }) => theme.colors.elevation8};
  span {
    margin: 12px;
  }
  a {
    color: ${({ theme }) => theme.colors.elevation8};
    text-decoration: underline;
  }
  animation: 1s ease-out 0s 1 ${({ hide }) => (hide ? slideOutFromBottom : slideInFromBottom)};
  visibility: ${({ hide }) => (hide ? `hidden` : `inherit`)};
  transition: 1s;
`

export const ConsentOptions = styled.div`
  display: flex;
  min-width: fit-content;
`
export const StyledButton = styled.button`
  color: #000;
  display: flex;
  padding: 8px 12px;
  margin: 8px;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.elevation8};
  border-radius: 1000px;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const CookiesButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
