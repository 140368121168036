import React from "react"
import Container from "./Skeleton.styled"

interface SkeletonProps {
  display?: boolean
  children: JSX.Element | JSX.Element[]
}

const Skeleton: React.FC<SkeletonProps> = ({ display, children }) => {
  return display ? <Container>{children}</Container> : <>{children}</>
}

export default Skeleton
