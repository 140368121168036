import React from "react"
import styled, { css } from "styled-components/macro"

const buttonStyles = css`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  align-items: center;
  border-radius: 44px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  line-height: 20px;
  height: 48px;
  letter-spacing: 0.2px;
  position: relative;
  width: 100%;
  margin: 16px auto 0 auto;
  justify-content: start;
  span {
    position: absolute;
    left: calc(38% - 42px);
  }
  &:active,
  &:focus {
    outline: 0;
  }
  &:hover {
    text-decoration: none;
  }
`

const StyledAppleButton = styled.button`
  ${buttonStyles}
  background: #000000;
  img {
    margin: 0 0 2px 14px;
  }
`

const StyledFacebookButton = styled.button`
  ${buttonStyles}
  background: #1877f2;
  img {
    margin: 0 0 0 14px;
  }
`

const StyledGoogleButton = styled.button`
  ${buttonStyles};
  background: #ffffff;
  color: #000000;
  img {
    margin: 0 0 0 10px;
  }
`

const AuthButtons = () => (
  <div>
    <StyledGoogleButton data-testid="signIn_google_button">
      <img src="/images/google-logo.svg" height="32px" width="32px" alt="Google sign in button" />
      <span>Continue with Google</span>
    </StyledGoogleButton>
    <StyledFacebookButton data-testid="signIn_facebook_button">
      <img src="/images/facebook-logo.svg" height="24px" width="24px" alt="Facebook sign in button" />
      <span>Continue with Facebook</span>
    </StyledFacebookButton>
    <StyledAppleButton data-testid="signIn_apple_button">
      <img src="/images/apple-logo.svg" height="24px" width="24px" alt="Apple sign in button" />
      <span>Continue with Apple</span>
    </StyledAppleButton>
  </div>
)

export default AuthButtons
