import React from "react"
import { LocalActivity } from "@styled-icons/material"
import { Badge } from "./TicketBadge.styled"

interface TicketBadgeProps {
  display?: boolean
  children?: JSX.Element | JSX.Element[]
}

const TicketBadge: React.FC<TicketBadgeProps> = ({ display, children }) => {
  return display ? (
    <>
      {children}
      <Badge>
        <LocalActivity />
      </Badge>
    </>
  ) : (
    <>{children}</>
  )
}

export default React.memo(TicketBadge)
