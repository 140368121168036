import styled from "styled-components/macro"
import { toRem } from "theme/utils"
import { transparentize } from "polished"

interface AnnounceProps {
  backImg: string
  backColor: string
}

interface CheckProps {
  checked: boolean
  onColor: string
  offColor: string
  borderColor: string
}

export const BlurredImage = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateZ(0);
  bottom: 0;
  filter: blur(24px);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`

export const Badge = styled.img.attrs({ src: "/images/vip-meet.png" })`
  width: 124px;
  height: auto;
  z-index: 1;
  position: absolute;
  top: 60px;
`

export const Title = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBold}
  letter-spacing: -0.5px;
  font-size: ${toRem(28)};
  line-height: ${toRem(32)};
  color: ${({ theme }) => theme.colors.elevation8};
  z-index: 1;
  position: absolute;
  top: 164px;
`

export const WaitCardContainer = styled.div`
  height: 340px;
  width: 95%;
  background-color: ${({ theme }) => theme.colors.elevation7};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`

export const AnnounceCardContainer = styled.div<AnnounceProps>`
  ${({ theme }) => theme.fonts.TTNormsProBlack};
  height: 340px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  text-align: left;
  border-radius: 10px;
  color: #fff;
  background: ${(AnnounceProps) =>
    `linear-gradient(${transparentize(0.025, AnnounceProps.backColor)},${transparentize(
      0.025,
      AnnounceProps.backColor,
    )}), url(${AnnounceProps.backImg})`};
  background-size: cover;

  .title {
    font-size: 40px;
    padding-bottom: 40px;
  }
`

export const TimeText = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProMedium};
  color: #000;
  font-size: 30px;
`
export const TimeLabel = styled.span`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: #000;
  font-size: 16px;
`
export const StyledCheckBox = styled.button<CheckProps>`
  width: 18px;
  height: 18px;
  border: 2px solid
    ${(CheckProps) => (CheckProps.checked ? CheckProps.onColor : CheckProps.borderColor)};
  background-color: ${(CheckProps) =>
    CheckProps.checked ? CheckProps.onColor : CheckProps.offColor};
  border-radius: 3px;
  outline: none;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
`
