import { Network } from "opensea-js"

const StagingConfig = {
  FIREBASE: {
    apiKey: "AIzaSyCzcWGi-QudzoZ4W5CvbmN0ozj6EzYhkdw",
    authDomain: window.location.host,
    localhostAuthDomain: "mandolin-staging-42ed5.firebaseapp.com",
    databaseURL: "https://mandolin-staging-42ed5-default-rtdb.firebaseio.com",
    projectId: "mandolin-staging-42ed5",
    storageBucket: "mandolin-staging-42ed5.appspot.com",
    messagingSenderId: "774655659627",
    appId: "1:774655659627:web:99d7e24367460335486b2e",
  },
  MUX: {
    muxDataEnvKey: "m8u9grqopjgo8ge2jju8smfn6",
  },
  OPTIMIZELY: {
    optimizelyKey: "7Kn8Nyb7R4WDc8T9vkKv4",
  },
  ETHEREUM: {
    proxyApi: "https://mandolin-nft-proxy-production.herokuapp.com",
    network: Network.Main,
    openseaApi: "https://api.opensea.io/api/v1",
    openseaApiKey: "5f1cdd73cb764f5aac389ce005a1b68d",
    provider: "wss://eth-rinkeby.alchemyapi.io/v2/lvrMD2fc5gdpNHgBWC_u59WUxY27gDr6",
    wethContractAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  },
  STRAPI: {
    restURL: "https://content-dev-testing.mandolin.com",
  },
  API: {
    wsURL: "wss://ws.staging.mndl.in",
    restURL: "https://api.staging.mandolin.com",
  },
  PRESENTER: {
    baseUrl: "https://present.dev.mandolin.com",
  },
}

export default StagingConfig
