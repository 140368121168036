import firebase from "firebase/app"
import React, { FC, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setTicketsData } from "../redux/tickets"
import { claimTicket } from "../services/tickets"

import { RootState } from "../redux/store"
import useRestClient from "../hooks/useRestClient"

interface TicketDocument {
  email: string
  name: string
  showRef: firebase.firestore.DocumentReference
  status: string
  userRef: firebase.firestore.DocumentReference
}

const TicketProvider: FC = ({ children }) => {
  const {
    auth: { externalContentID, externalLogin, uid },
    user: { email },
  } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const { restClient } = useRestClient()

  // Claims any unclaimed tickets associated with the email
  const unclaimedTickets = async (passed: firebase.firestore.QuerySnapshot<TicketDocument>) => {
    const { docs } = passed
    await Promise.all(docs.map(async ({ id }) => claimTicket(id)))
  }

  // Stores all claimed tickets in redux store
  const claimedTickets = (passed: firebase.firestore.QuerySnapshot<TicketDocument>) => {
    const { docs } = passed

    const tickets = docs.map((doc) => ({
      id: doc.id,
      email: doc.data().email,
      name: doc.data().name,
      showId: doc.data().showRef.id,
      status: doc.data().status,
      userId: doc.data().userRef.id,
    }))
    dispatch(setTicketsData(tickets))
  }

  useEffect(() => {
    let unsubscribeUnclaimed = () => {}
    let unsubscribeClaimed = () => {}

    const collection = firebase
      .firestore()
      .collection("tickets") as firebase.firestore.CollectionReference<TicketDocument>
    if (email) {
      unsubscribeUnclaimed = collection
        .where("email", "==", email)
        .where("status", "==", "unclaimed")
        .onSnapshot(unclaimedTickets)
    } else {
      dispatch(setTicketsData([]))
    }

    if (uid) {
      unsubscribeClaimed = collection
        .where("userRef", "==", firebase.firestore().collection("users").doc(uid))
        .onSnapshot(claimedTickets)
    } else {
      dispatch(setTicketsData([]))
    }

    return () => {
      unsubscribeUnclaimed()
      unsubscribeClaimed()
    }
  }, [email, uid])

  useEffect(() => {
    const claimExternalTicketForUser = async () => {
      if (uid && restClient && externalLogin && externalLogin.accessToken && externalContentID) {
        try {
          await restClient.claimExternalTicket({
            externalShowId: externalContentID,
            externalCredential: externalLogin.accessToken,
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
    claimExternalTicketForUser()
  }, [uid, externalLogin, externalContentID])

  return <>{children}</>
}

export default TicketProvider
