import styled from "styled-components"

const ReadMoreButton = styled.span`
  display: inline-block !important;
  cursor: pointer;
  width: auto;
  color: ${({ theme }) => theme.elements.pageTextAccent};
`

// eslint-disable-next-line import/prefer-default-export
export { ReadMoreButton }
