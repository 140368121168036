import styled, { keyframes } from "styled-components/macro";
import { tint } from 'polished'

const MessageMenuButton = styled.span`
  position: absolute;
  top: -20px;
  right: -35px;
  cursor: pointer;
  @media (min-width: 1024px) {
    top: 4px;
    right: 0px;
  }

  svg {
    height: 32px;
    width: 32px;
    @media (min-width: 1024px) {
      height: 50px;
      width: 50px;
    }
  }
`;

const Message = styled.div`
  padding: 20px;
  padding-right: 40px;
  display:flex;
  align-items:stretch;
  min-height: 38px;
  min-width: 200px;
  @media (min-width: 1024px) {
    padding: 2rem 1rem;
    padding-right: 60px;
  }
`;

const Avatar = styled.div`
  margin-right: 10px;
  img {
    margin-top: -1px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    @media (min-width: 1024px) {
      width: 80px;
      height: 80px;
    }
  }
`;

const MessageContent = styled.div`
  position: relative;
  width: 100%;
`;

const MessageHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessageUsername = styled.span`
  color: rgb(255, 255, 255);
  ${({ theme }) => theme.fonts.TTNormsProBold};
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 0;
  letter-spacing: -0.2px;
  @media (min-width: 1024px) {
    font-size: 30px;
  }
`;

const MessageBody = styled.span`
  color: rgb(255, 255, 255);
  ${({ theme }) => theme.fonts.TTNormsProRegular};
  font-size: 20px;
  @media (min-width: 1024px) {
    font-size: 50px;
  }
`;

const MessageTimestamp = styled.span`
  color: rgb(185, 185, 185);
  ${({ theme }) => theme.fonts.TTNormsProRegular};
  font-size: 10px;
  display: none;
`;

const skeleton = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;
const SkeletonAvatar = styled.div`
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  border-radius: 22px;
  margin-right: 1rem;
  background: linear-gradient(
    90deg,
    rgba(48, 48, 48, 0.2),
    rgba(48, 48, 48, 0.4),
    rgba(48, 48, 48, 0.2)
  );
  background-size: 400% 100%;
  animation: ${skeleton} 1.3s ease infinite;
`;

const SkeletonUsername = styled.span`
  display: block;
  width: 200px;
  height: 14px;
  background: linear-gradient(
    90deg,
    rgba(48, 48, 48, 0.2),
    rgba(48, 48, 48, 0.4),
    rgba(48, 48, 48, 0.2)
  );
  background-size: 400% 100%;
  animation: ${skeleton} 1.3s ease infinite;
`;

const SkeletonText = styled.div`
  width: 100%;
  height: 14px;
  margin-top: 6px;
  background: linear-gradient(
    90deg,
    rgba(48, 48, 48, 0.2),
    rgba(48, 48, 48, 0.4),
    rgba(48, 48, 48, 0.2)
  );
  background-size: 400% 100%;
  animation: ${skeleton} 1.3s ease infinite;
`;

const ChatForm = styled.div`
  height: 40px;
  min-height: 40px;
  position: relative;
  margin: 24px;
  left: 0;
  right: 0;
  bottom: 0px;
  border: 1px solid white;
  border-radius: 20px;
  display: flex;
  transition: 0.2s border-color ease;
  &:focus-within {
    border-color: #f6ba00;
  }
`;

const ChatFormInput = styled.input`
  width: calc(100% - 75px);
  background: transparent;
  border: none;
  color: white;
  font-size: 12px;
  padding: 10px 20px;
  &:active,
  &:focus {
    outline: none;
  }
`;

const ChatArea = styled.div`
  flex-grow: 1;
  max-width: 100%;
  overflow: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ChatHeader = styled.div`
  align-items: center;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.elevation2};
  width: 100%;
  color: rgb(185, 185, 185);
  height: 40px;
  padding: 1rem;
  z-index: 1;
  display: flex;
  border-color: transparent;
  border-bottom: 1px solid rgb(54, 54, 54);
  .sign-out {
    align-items: center;
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    svg {
      height: 20px;
      width: 20px;
      margin-left: 6px;
      margin-bottom: 4px;
    }
  }
  div {
    ${({ theme }) => theme.fonts.TTNormsProExtraBold};
    font-size: 14px;
    letter-spacing: 0;
  }
  @media (max-width: 1111px) {
    display: none;
  }
`;

const ChatBox = styled.div`
  background: ${({ theme }) => theme.colors.elevation2};
  height: 100%;
  max-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ScrollTrigger = styled.div`
  display: block;
  background: #f6ba00;
  border-radius: 50%;
  padding: 4px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 80px;
  z-index: 1;
  cursor: pointer;
  &:hover {
    background: #d09d00;
  }
  svg {
    display:block;
    width: 40px;
    height: 40px;
    opacity: 1;
  }
`;

const SubmitIcon = styled.svg`
  position: absolute;
  right: 20px;
  fill: #f6ba00;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  &:hover {
    fill: #d09d00;
  }
`;

const EmojiIcon = styled.svg`
  position: absolute;
  right: 50px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  fill: white;
  &:hover {
    fill: #cecece;
  }
  @media (max-width: 1111px) {
    display: none;
  }
`;

const DropdownContainer = styled.div`
position:relative;
z-index: 100;
margin-left: 10px;
`

const DropdownTray = styled.div`
position: absolute;
top: 0;
left: 0;
z-index: 1;
max-width: 250px;
overflow: hidden;
background-color: #fff;
border-radius: 4px;
display:none;
text-overflow: ellipsis;
padding: 32px 0px 0px;
${({ open }) => open && (
    `display:block;`
  )};
div {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  padding: 8px 10px;
  cursor: pointer;
  &:hover, &:focus {
    background-color: rgba(0,0,0,0.1);
  }
}
`

const DisabledBadge = styled.span`
  font-size: 10px;
  letter-spacing: 1px;
  color: #fff;
  text-transform:uppercase;
  background-color: red;
  border-radius: 4px;
  padding: 2px 4px;
  line-height: 1.5;
  position:absolute;
  left: 110%;
  top: 50%;
  transform: translateY(-50%);
`

const DropdownIcon = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 6px;
  font-size: 18px;
  position: relative;
  z-index: 2;
  ${
  ({ open }) => open && `
    background-color: #fff !important;
    `}
    &:hover, &:focus {
    background: ${ ({ theme }) => tint(0.15, theme.colors.elevation2)};
    border-radius: 4px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`

export {
  Avatar,
  ChatArea,
  ChatBox,
  ChatForm,
  ChatFormInput,
  ChatHeader,
  Message,
  MessageBody,
  MessageContent,
  MessageHead,
  MessageMenuButton,
  MessageTimestamp,
  MessageUsername,
  ScrollTrigger,
  SkeletonAvatar,
  SkeletonText,
  SkeletonUsername,
  SubmitIcon,
  EmojiIcon,
  DropdownContainer,
  DropdownIcon,
  DropdownTray,
  DisabledBadge
};
