import styled from 'styled-components/macro'

const Header = styled.div`
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  @media (${({ theme }) => theme.breakpoint}) {
    padding: 0 16px;
  }
`

const HeaderText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProBlack};
  font-size: 40px;
  line-height: 100%;
  color: #ffffff;
  @media (${({ theme }) => theme.breakpoint}) {
    display: none;
  }
`

const HeaderSubText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #ffffff;
  padding-top: 10px;
  padding-right: 20px;
`

const StageTag = styled.div`
  align-items: flex-start;
  background: #e05a5a;
  border-radius: 0px 4px 4px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 64px;
  width: 364px;
  position: relative;
  @media (${({ theme }) => theme.breakpoint}) {
    height: 56px;
    width: 320px;
  }
  div {
    line-height: 100%;
    position: absolute;
    &:first-child {
      ${({ theme }) => theme.fonts.TTNormsProBold};
      font-size: 16px;
      height: 16px;
      left: 12px;
      top: 12px;
      @media (${({ theme }) => theme.breakpoint}) {
        font-size: 14px;
        height: 14px;
        left: 8px;
        top: 10px;
      }
    }
    &:last-child {
      ${({ theme }) => theme.fonts.TTNormsProDemiBold};
      font-size: 12px;
      line-height: 14px;
      height: 12px;
      width: 344px;
      left: 12px;
      top: 32px;
      letter-spacing: -0.01em;
      @media (${({ theme }) => theme.breakpoint}) {
        font-size: 10px;
        height: 10px;
        width: 288px;
        left: 8px;
        top: 25px;
      }
    }
  }
`

const Row = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 32px;
  }
`
export { Header, HeaderText, Row, StageTag, HeaderSubText }
