import styled from "styled-components/macro"
import { transparentize } from "polished"

interface ArtistProps {
  count: number
  active: boolean
}

interface LocalProps {
  onmic: boolean
  active: boolean
  grid: boolean
}

const FanContainer = styled.div<{ video: boolean }>`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ theme }) => transparentize(0.2, theme.colors.elevation1)};
  video {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: contain;
    object-position: center;
    visibility: ${({ video }) => !video && "hidden"};
  }
  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .avatarContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const OnMicFanContainer = styled.div<LocalProps>`
  height: ${(localProps) => (localProps.grid ? "0" : "100%")};
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: ${(localProps) => (localProps.grid ? "99%" : "100%")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ theme }) => transparentize(0.2, theme.colors.elevation1)};
  video {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: contain;
    object-position: center;
    border: ${(localProps) => localProps.active && "2px solid #0f0"};
  }
  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .avatarContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .micIcon {
    display: ${(localProps) => (localProps.onmic ? "none" : "flex")};
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 20;
  }
`
const HostContainer = styled.div<{
  active: boolean
  hidden: boolean
}>`
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 2px;
  height: ${({ hidden }) => (hidden ? "0px" : "fit-content")};
  video {
    width: 100%;
    object-fit: contain;
    object-position: center;
    background-color: ${({ theme }) => transparentize(0.4, theme.colors.elevation3)};
    outline: ${({ active }) => (active ? "2px solid #0f0" : "none")};
    border-radius: 4px;
  }
`
const ArtistContainer = styled.div<ArtistProps>`
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: contain;
    object-position: center;
  }
`
const HostTag = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 20px;
  width: 40px;
  z-index: 1000;
  background-color: ${({ theme }) => transparentize(0.4, theme.colors.elevation3)};
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.elevation6};
  font-family: ${({ theme }) => theme.fonts.TTNormsProExtraBold};
`
const NameTag = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 2px 8px;
  z-index: 1000;
  background-color: ${({ theme }) => transparentize(0.2, theme.colors.elevation3)};
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.elevation6};
  font-family: ${({ theme }) => theme.fonts.TTNormsPro};
`

const TileAvatar = styled.img`
  height: 56%;
  width: 32%;
  border-radius: 50%;
`

export {
  HostContainer,
  FanContainer,
  OnMicFanContainer,
  ArtistContainer,
  HostTag,
  NameTag,
  TileAvatar,
}
