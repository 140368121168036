import styled, { css } from "styled-components/macro"
import { Modal } from "react-bootstrap"
import { darken, readableColor } from "polished"
// eslint-disable-next-line import/no-extraneous-dependencies
import { Close } from "@styled-icons/material/"

const ModalContainer = styled(Modal)`
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-dialog {
    display: flex;
    height: 66%;
    justify-content: center;
    align-items: center;
  }
`

const ModalContent = styled.div`
  width: 80%;
  padding: 2% 6%;
  padding-bottom: 5%;
  padding-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const ModalBody = styled.div`
  width: 100%;
  height: 90vh;
  max-height: 680px;
  display: flex;
  flex-direction: row;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  @media (max-width: 1024px) {
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
  }
`

const OnboardText = styled.div`
  ${({ theme }) => theme.fonts.TTNormsProNormal};
  color: black;
  font-size: 20px;
  line-height: 23px;
  margin: 50px 0;
  margin-top: 0.75rem;
`

const ConfirmationAnchor = styled.a`
  margin-bottom: 0.5rem;
  /* Had issues using the theme.colors.warning theme with this, just defaults to black */
  color: #fe532f !important;
  &:hover {
    color: ${({ theme }) => readableColor(darken(0.1, theme.colors.warning))};
    text-decoration: none;
    cursor: pointer;
  }
`

const AnchorSubtext = styled.span`
  color: black;
  margin-bottom: 0.75rem;
`

const CloseWindow = styled(Close)`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 15px;
  cursor: pointer;
  color: #000000;

  &:hover {
    color: #dcdcdc;
  }
`

export {
  ModalContainer,
  ModalContent,
  ModalBody,
  OnboardText,
  ConfirmationAnchor,
  AnchorSubtext,
  CloseWindow,
}
