import React, { FC } from "react"
import { SurveyQuestionCheckboxContainer } from "../CobrandSurvey.styled"
import { SurveyQuestionProps } from "../CobrandSurvey.types"

const delimiter = ","

const MultipleChoiceQuestion: FC<SurveyQuestionProps> = ({
  question,
  value,
  setSurveyResponse,
}) => (
  <>
    {question.options?.map((option, index) => (
      <SurveyQuestionCheckboxContainer>
        <input
          type="checkbox"
          value={option}
          id={`option-${index}`}
          checked={(value as string).includes(option)}
          onChange={(e) => {
            const checked = e.target.checked
            setSurveyResponse((curr: any) => {
              const currentValue = curr[question.id] || ""
              let values: string[] = currentValue.split(delimiter)
              values = values.filter((v) => v && v !== option)
              if (checked) values.push(option)
              return {
                ...curr,
                [question.id]: values.join(delimiter),
              }
            })
          }}
        />{" "}
        <label htmlFor={`option-${index}`}>{option}</label>
      </SurveyQuestionCheckboxContainer>
    ))}
  </>
)

export default MultipleChoiceQuestion
